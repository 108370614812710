import React, { useEffect, useState } from "react";
import Collapsible from "react-collapsible";
import { getAllRemiseSBC, createRemise, updateRemise, deleteRemise, getAllPromoSBC } from "../../../../domains/users/service/user.services";
import { FormElements, Lists, Icon, ModalAlert, PopupWrapper } from "../../../components";
import { getConfig } from "../../../../domains/proposals/services/proposal.services";
import { useTranslation } from "react-i18next";

const RemiseSBC = () => {
    const { t } = useTranslation();
    const [remises, setRemises] = useState([]);
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [addModalClick, setAddModalClick] = useState(false);
    const [editModalClick, setEditModalClick] = useState(false);
    const [remiseToDelete, setRemiseToDelete] = useState({});
    const [isDeletedRemise, setIsDeleteRemise] = useState(false);
    const [remiseToEdit, setRemiseToEdit] = useState({});
    const [allProducts, setAllProducts] = useState([]);
    const [codeRemise, setCodeRemise] = useState([]);
    const [submitError, setSubmitError] = useState(false);
    const [disablePanierClick, setDisablePanierClick] = useState(false);
    const [disableParcClick, setDisableParcClick] = useState(false);
    const initialFormData = {
        remiseCode: '',
        canalVente: '',
        referenceProduit: '',
        conditionParc: '',
        referencePanier: '',
        conditionPanier: '',
        referenceParc: ''
    };
    const [formData, setFormData] = useState(initialFormData);
    useEffect(() => {
        getConfig("US_EQUIPCLI").then((res) => {
            let allProduct = [];
            res.data.map((result) => {
                if (result.usageCode === "US_EQUIPCLI") {
                    allProduct.push(result.product)
                }
            })
            setAllProducts(allProduct);
        });
    }, []);
    useEffect(() => {
        const hasSeparatorPanier = formData.referencePanier && formData.referencePanier.length > 0 && formData.referencePanier.includes(',');
        const hasSeparatorParc = formData.referenceParc && formData.referenceParc.length > 0 && formData.referenceParc.includes(',');
        hasSeparatorPanier ? setDisablePanierClick(true) : setDisablePanierClick(false);
        hasSeparatorParc ? setDisableParcClick(true) : setDisableParcClick(false);
    }, [formData.referencePanier, formData.referenceParc]);
    useEffect(() => {
        getAllRemiseSBC().then((res) => {
            setRemises(res.data);
        });
    }, [addModalClick, editModalClick, isDeletedRemise]);
    const fillComboRemiseCode = () => {
        getAllPromoSBC().then((res) => {
            const promoCodes = res.data.map(promoItem => ({
                name: promoItem.codePromo, 
                value: promoItem.codePromo
            }));
            setCodeRemise(promoCodes);
            setAddModalClick(true);
        });
        
    };
    const handleAdd = () => {
        fillComboRemiseCode(); 
    }
    const closeAddModal = () => {
        setAddModalClick(false);
    }
    const closeEditModal = () => {
        setEditModalClick(false);
    }
    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        setRemiseToEdit({
            ...remiseToEdit,
            [e.target.name]: e.target.value
        });
    }
    const show = () => {
        return true;
    };

    const optionsPrincipalProducts = allProducts.map(product => ({
        value: product, name: product
    }));
    const optionsCanalVente = [
        { value: "Vente Indirecte", name: t("users:caracteristique.canal-false") },
        { value: "Vente Directe", name: t("users:caracteristique.canal-true") }
    ]
    const optionsconditionPanier = [
        { value: "OR", name: t("users:caracteristique.panier-true") },
        { value: "AND", name: t("users:caracteristique.panier-false") }
    ]
    const optionsConditionParc = [
        { value: "OR", name: t("users:caracteristique.panier-true") },
        { value: "AND", name: t("users:caracteristique.panier-false") }
    ]
    const optionsDisable = [
        { value: "Désactivé", name: t("users:user-menu.disabled") },
        { value: "Activé", name: t("users:user-menu.enabled") }
    ]
    const isFormValid = () => {
        const hasSeparatorPanier = formData.referencePanier && formData.referencePanier.includes(',');
        const hasSeparatorParc = formData.referenceParc && formData.referenceParc.includes(',');

        if (hasSeparatorPanier && hasSeparatorParc) {
            return formData.remiseCode && formData.referenceProduit && formData.conditionPanier && formData.conditionParc;
        } else if (hasSeparatorPanier) {
            return formData.remiseCode && formData.referenceProduit && formData.conditionPanier;
        } else if (hasSeparatorParc) {
            return formData.remiseCode && formData.referenceProduit && formData.conditionParc;
        } else {
            return formData.remiseCode && formData.referenceProduit;
        }
    };
    const handleSave = () => {
        setFormData({ remiseCode: "" });  
        createRemise(formData).then(() => {
            setAddModalClick(false);
        });
    };
    const handleEdit = () => {
        const { idRemise } = remiseToEdit;
        updateRemise(idRemise, remiseToEdit)
            .then(() => {
                setRemiseToEdit({});
                setEditModalClick(false)
            })
            .catch(() => {
                setRemiseToEdit({});
            });
    }
    const handleDelete = () => {
        const { idRemise } = remiseToDelete;
        deleteRemise(idRemise)
            .then(() => {
               
                setIsDeleteRemise(false);
            }); 
        setRemiseToDelete({});
    };
    
    const formAddEdit = (remise) => {
        const { canalVente, referenceProduit, conditionPanier, conditionParc, referenceParc, referencePanier } = remise;
        return (
            <>
                 <label className="preview__form-item">
                    <div className="carbon-form__label-wrapper">
                        <div className="carbon-form__label">{`${t("users:user-menu.reference_produit")} *`}</div>
                    </div>
                    <FormElements.Textarea
                        name="referenceProduit"
                        value={referenceProduit}
                        onChange={handleInputChange}
                        rows={1}
                        disabled={editModalClick}
                    />               
                </label>
                <label className="preview__form-item">
                    <div className="carbon-form__label-wrapper">
                        <div className="carbon-form__label">{`${t("users:user-menu.reference_panier")}`}</div>
                    </div>
                    <FormElements.Textarea
                        name="referencePanier"
                        value={referencePanier}
                        onChange={handleInputChange}
                        rows={4}
                        disabled={editModalClick}
                    />
                </label>
                <label className="preview__form-item">
                    <div className="carbon-form__label-wrapper">
                        <div className="carbon-form__label">{`${t("users:user-menu.reference_parc")}`}</div>
                    </div>
                    <FormElements.Textarea
                        name="referenceParc"
                        value={referenceParc}
                        onChange={handleInputChange}
                        rows={4}
                        disabled={editModalClick}
                    />
                </label>
                <label className="preview__form-item">
                    <div className="carbon-form__label-wrapper">
                        <div className="carbon-form__label">{`${t("users:user-menu.condition_panier")} *`}</div>
                    </div>
                    <FormElements.Select
                        name="conditionPanier"
                        value={conditionPanier}
                        required
                        onChange={handleInputChange}
                        options={optionsconditionPanier}
                        validator={(value) => value === "true" || value === "false"}
                        disabled={editModalClick || !disablePanierClick}
                    />
                </label>
                <label className="preview__form-item">
                    <div className="carbon-form__label-wrapper">
                        <div className="carbon-form__label">{`${t("users:user-menu.condition_parc")} *`}</div>
                    </div>
                    <FormElements.Select
                        name="conditionParc"
                        value={conditionParc}
                        options={optionsConditionParc}
                        required
                        onChange={handleInputChange}
                        validator={(value) => value === "true" || value === "false"}
                        disabled={editModalClick || !disableParcClick}
                    />

                </label>
                <label className="preview__form-item">
                    <div className="carbon-form__label-wrapper">

                        <div className="carbon-form__label">{`${t("users:user-menu.canal_vente")}`}</div>
                    </div>
                    <FormElements.Select
                        name="canalVente"
                        value={canalVente}
                        onChange={handleInputChange}
                        options={optionsCanalVente}
                        disabled={editModalClick}
                    />
                </label>
            </>
        );
    };
    const addModalPage = () => {
        const { remiseCode } = formData;
        return (

            <div className="new-promo__popup">
                <div className="modal-alert__header">
                    <h1 className="modal-alert__title">{t("users:caracteristique.addModal-title")}</h1>
                    <button type="button" className="modal-alert__close" onClick={() => closeAddModal()}>
                        <Icon name="dialog-close" />
                    </button>
                </div>
                <div className="contact-form__item">
                    <div className="new-promo__tabs">
                        <label className="preview__form-item">
                            <div className="carbon-form__label-wrapper">
                                <div className="carbon-form__label">{`${t("users:user-menu.remise_code")} *`}</div>
                            </div>
                            <FormElements.Select
                                name="remiseCode"
                                value={remiseCode}
                                onChange={handleInputChange}
                                options={codeRemise}
                                disabled={editModalClick}
                            />
                        </label>
                        {formAddEdit(remises)}

                    </div>
                    <div className=" ">{t("forms.mandatory")}</div>
                </div>
                <div className="modal-alert__footer">
                    <FormElements.Button className="step-navigation__save medium tertiary" onClick={() => closeAddModal()} >
                        {t("navigation.cancel")}
                    </FormElements.Button>
                    <FormElements.Button className="step-navigation__save medium primary" onClick={() => handleSave()} disabled={!isFormValid()} >
                        {t("navigation.save")}
                    </FormElements.Button>
                </div>
            </div>
        );

    }
    const editModalPage = () => {
        const { remiseCode, disabledRemise } = remiseToEdit;
        return (

            <div className="new-promo__popup">
                <div className="modal-alert__header">
                    <h1 className="modal-alert__title">{t("users:caracteristique.editModal-title")}</h1>
                    <button type="button" className="modal-alert__close" onClick={() => closeEditModal()}>
                        <Icon name="dialog-close" />
                    </button>
                </div>
                <div className="contact-form__item">
                    <div className="new-promo__tabs">
                        <label className="preview__form-item">
                            <div className="carbon-form__label-wrapper">
                                <div className="carbon-form__label">{`${t("users:user-menu.remise_code")} *`}</div>
                            </div>
                            <FormElements.Input name="remiseCode" value={remiseCode} disabled />
                        </label>
                        {formAddEdit(remiseToEdit)}
                        <label className="preview__form-item">
                            <div className="carbon-form__label-wrapper">
                                <div className="carbon-form__label">{t("users:user-menu.status")}</div>
                            </div>
                            <FormElements.Select
                                name="disabledRemise"
                                value={disabledRemise}
                                onChange={handleInputChange}
                                options={optionsDisable}

                            />
                        </label>

                    </div>
                    <div className=" ">{t("forms.mandatory")}</div>
                </div>
                <div className="modal-alert__footer">
                    <FormElements.Button className="step-navigation__save medium tertiary" onClick={() => closeEditModal()} >
                        {t("navigation.cancel")}
                    </FormElements.Button>
                    <FormElements.Button className="step-navigation__save medium primary" onClick={() => handleEdit()} >
                        {t("navigation.save")}
                    </FormElements.Button>
                </div>
            </div>
        );

    }
    const userActions = [
        {
            name: "edit",
            type: "button",
            icon: "ui-edit",
            ariaLabel: t("proposals:list-proposals-page.actions.edit"),
            tooltipPosY: "top",
            tooltipPosX: "right",
            onClick: (row) => {
                setEditModalClick(true);
                setRemiseToEdit(row);
            },
            showCondition: show
        },
        {
            name: "delete",
            type: "button",
            icon: "ui-unlink",
            ariaLabel: t("users:caracteristique.disable"),
            tooltipPosY: "top",
            tooltipPosX: "right",
            onClick: (row) => {
                setRemiseToDelete(row);
                setIsDeleteRemise(true);
            },
            showCondition: show
        },
    ]
    const columns = [
        { name: "remiseCode", type: "text", dbRef: "remiseCode", sortBy: "principalProduct", label: t("users:user-menu.remise_code") },
        { name: "referenceProduit", type: "text", dbRef: "referenceProduit", label: t("users:user-menu.reference_produit") },  
        { name: "referencePanier", type: "text", dbRef: "referencePanier", label: t("users:user-menu.reference_panier") },     
        { name: "conditionPanier", type: "text", dbRef: "conditionPanier", label: t("users:user-menu.condition_panier") },
        { name: "referenceParc", type: "text", dbRef: "referenceParc", label: t("users:user-menu.reference_parc") },
        { name: "conditionParc", type: "text", dbRef: "conditionParc", label: t("users:user-menu.condition_parc") },
        { name: "canalVente", type: "text", dbRef: "canalVente", label: t("users:user-menu.canal_vente") },
        { name: "disabledRemise", type: "text", dbRef: "disabledRemise", label: t("users:user-menu.status-disable") },
        { name: "actions", type: "actions", actions: userActions }
    ];

    return (
        <div>
            <div className="list-remise">
                <Collapsible trigger={t("users:user-menu.remise-sbc")} className="Collapsible" is-closed>
                    <div className="step-navigation__action">
                        <FormElements.Button className="step-navigation__save medium dashed" onClick={() => handleAdd()} >
                            {t("users:caracteristique.add-caracteristique")}
                            <Icon name="ui-add" />
                        </FormElements.Button>
                    </div>
                    {(addModalClick &&
                        <PopupWrapper classNamePopup="popup--full-page" visible>
                            <form id="new-client-form" className="new-promo">
                                {addModalPage()}
                            </form>
                        </PopupWrapper>

                    )}
                    {(editModalClick &&
                        <PopupWrapper classNamePopup="popup--full-page" visible>
                            <form id="new-client-form" className="new-promo">
                                {editModalPage()}
                            </form>
                        </PopupWrapper>
                    )}
                    <Lists.ListTable columns={[...columns]} actionsCount="2" rowKey="idRemise" content={remises} />
                </Collapsible>
            </div>
            <ModalAlert
                visible={showAlertSuccess}
                onCancel={() => {
                    setShowAlertSuccess(false);
                }}
                title={t("users:user-menu.success-title")}
                iconConfig="actions-validate"
                cancelButtonText={t("users:user-menu.close")}>
                <p>{t("users:user-menu.successmessage")}</p>
            </ModalAlert>
            <ModalAlert
                className="new-proposal__alert"
                visible={submitError}
                onCancel={() => {
                    setSubmitError(false);
                    setAddModalClick(false);
                }}
                title={t("users:user-menu.error-submit")}
                icon="missing"
                cancelButtonText={t("users:user-menu.close")}>
                <p>{t("users:caracteristique.error-description")}</p>
            </ModalAlert>
            <ModalAlert
                className="new-proposal__alert"
                visible={isDeletedRemise}
                onCancel={() => {
                    setIsDeleteRemise(false);
                    setRemiseToDelete({});
                }}
                onAccept={handleDelete}
                title={t("users:caracteristique.disable-remise")}
                icon="ui-cart-buy"
                cancelButtonText={t("users:user-menu.cancel")}
                acceptButtonText={t("users:caracteristique.disable")}>
                <p>{t("users:caracteristique.disable-description")}</p>
            </ModalAlert>
        </div>
    );
};

export default RemiseSBC;