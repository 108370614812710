import React, { useContext, useState, useEffect } from "react";
import Collapsible from "react-collapsible";

import SelectSubstitution from "../select-substitution/select-substitution";
import { AuthContext, roles } from "../../../../../context";
import "./substitutionPanel.scss";

const SubstitutionPanel = (props) => {
    const {
        cartItems,
        formValidity,
        setFormValidity,
        userSusbtitutions,
        offers = userSusbtitutions && userSusbtitutions.length > 0 ? cartItems.filter(({ type }) => type === "principal") : [],
        notifiyEvolvedMigration,
        setUserSusbtitutions,
        mode
    } = props;
    const { user } = useContext(AuthContext);

    const [validateSubstitution, setValidateSubstitution] = useState(
        offers.map((p) => {
            const { product } = p;
            return { product, noSelected: false };
        })
    );

    const checkPageValidation = () => {
        const validations = offers.map((p) => {
            const { product } = p;
            let validated = false;
            // offer is valid if Aucun is selected or a parc is chosen
            if(!userSusbtitutions.some((e) => e.offerProduct === product))
            {
                return { product, valid: true };
            }

            if (
                validateSubstitution.some((e) => e.noSelected === true && e.product === product) ||
                userSusbtitutions.some((e) => e.offerProduct === product && e.checkedValue === true)
            ) {
                validated = true;
            }
            return { product, valid: validated };
        });

        // page is valid if All offers are valid
        const notvalid = validations.some((o) => o.valid === false);

        setFormValidity({ ...formValidity, substitutionChoiceValid: !notvalid });
    };

    useEffect(() => {
      if( mode === "edit" ) 
      {
        checkPageValidation()
      }   
        
     }, [mode]);

    return (
        <>
            {offers.map((p) => {
                const { product, label: newProductLabel, usageCode } = p;
                const productfiltered = userSusbtitutions.filter((e) => e.offerProduct === product);
                if (productfiltered && productfiltered.length > 0) {
                    return (
                        <div className="subpanels" key={`div${product}`}>
                            <Collapsible trigger={newProductLabel} key={`collap${product}`} className="Collapsible" open>
                                <SelectSubstitution
                                    key={`subpanels${product}`}
                                    cartItems={cartItems}
                                    formValidity={formValidity}
                                    setFormValidity={setFormValidity}
                                    salesDisplay={user.role === roles.sales.role}
                                    setUserSusbtitutions={setUserSusbtitutions}
                                    userSusbtitutions={userSusbtitutions}                                    
                                    notifiyEvolvedMigration={notifiyEvolvedMigration}
                                    offer={product}                                   
                                    parcToSubstitute={productfiltered[0].label}
                                    usageCode={usageCode}
                                    newProductLabel={newProductLabel}
                                    setValidateSubstitution={setValidateSubstitution}
                                    validateSubstitution={validateSubstitution}
                                    checkPageValidation={checkPageValidation}
                                />
                            </Collapsible>
                        </div>
                    );
                }
                return <div key={`div${product}`} />;
            })}
        </>
    );
};

export default SubstitutionPanel;
