import React, { createContext, useCallback, useEffect, useState } from "react";
import jwt from "jsonwebtoken";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { getInitials } from "../../shared/utils/string.utils";
import ModalAlert from "../../shared/components/modal-alert/modal-alert";
import { getPortalUserRole, setAttachment, checkUserAuth, getUserPermissions, reportAuthentification } from "../../domains/users/service/user.services";
import AttachmentList from "../../domains/users/components/attachment-list/attachment-list";
import Impersonate from "../../domains/clients/components/impersonate/impersonate";

const AuthContext = createContext({});

const AuthProvider = (props) => {
    const { logout: a0Logout, user: a0User, isAuthenticated: a0Auth, isLoading: a0IsLoading, error, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const { children } = props;
    const { t } = useTranslation();

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);

    const [persona, setPersona] = useState(localStorage.getItem("x-user-email"));
    const [attachmentFail, setAttachmentFail] = useState(false);
    const [attachmentLoading, setAttachmentLoading] = useState(false);
    const [enableCodeAuth, setEnableCodeAuth] = useState(false);

    const [needsRedirect, setNeedsRedirect] = useState(false);

    const [permissions, setPermissions] = useState();
    const [tiersDiligenceFail, setTiersDiligenceFail] = useState(false);
    const [tiersSanctionFail, setTiersSanctionFail] = useState(false);
    const [uniqueTiers, setUniqueTiers] = useState(false);
    const [isImpersonate, setIsImpersonate] = useState(false);
    const [visibleImpersonate, setVisibleImpersonate] = useState(false);

    const [loggedOut, setLoggedOut] = useState(false);
    const [a0TokenLoaded, setA0TokenLoaded] = useState(!!localStorage.getItem("a0-token"));
    const [enableButton, setEnableButton] = useState(false);

    useEffect(() => {
        setNeedsRedirect(!localStorage.getItem("x-authorization"));
    }, []);

    useEffect(() => {
        if (a0Auth && a0User && !localStorage.getItem("a0-token")) {
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                scope: "read:token"
            }).then((accessToken) => {
                localStorage.setItem("a0-token", accessToken);
                setA0TokenLoaded(true);
            });
        }
    }, [a0Auth, a0User, getAccessTokenSilently]);

    useEffect(() => {
        const localEmail = localStorage.getItem("x-user-email");
        const localToken = localStorage.getItem("x-authorization");

        if (localEmail && !localEmail.endsWith("@sage.com") && !localToken) setNeedsRedirect(true);
    }, []);

    useEffect(() => {
        if (a0Auth && a0User && !persona && !loggedOut) {
            localStorage.setItem("x-user-email", a0User["https://sage.com/sci/profiles"][0].email);
            localStorage.setItem("x-user-name", a0User["https://sage.com/sci/profiles"][0].name);
            setPersona(a0User["https://sage.com/sci/profiles"][0].email);
        }
    }, [a0Auth, persona, a0User, loggedOut]);

    const logout = useCallback(() => {
        a0Logout({ returnTo: window.location.origin });
        setIsLoading(false);
        setIsAuthenticated(false);
        localStorage.removeItem("a0-token");
        localStorage.removeItem("x-authorization");
        localStorage.removeItem("x-user-email");
        localStorage.removeItem("x-user-name");
        setUser(undefined);
        setPersona(undefined);
        setLoggedOut(true);
    }, [a0Logout]);

    useEffect(() => {
        const localToken = localStorage.getItem("x-authorization");
        if (localToken && !isAuthenticated && !loggedOut) {
            setAttachmentLoading(true);
            checkUserAuth()
                .then((res) => {
                    const { name } = res.data;
                    setUser({ ...res.data, initials: getInitials(name) });
                    setIsAuthenticated(true);
                    setIsLoading(false);
                    setAttachmentLoading(false);
                })
                .catch(() => {
                    logout();
                });
        }
    }, [isAuthenticated, logout, loggedOut]);

    useEffect(() => {
        const localToken = localStorage.getItem("x-authorization");
        if (a0Auth && a0User && a0TokenLoaded && !localToken && !isAuthenticated && !loggedOut) {
            const newUser = {
                initials: getInitials(a0User["https://sage.com/sci/profiles"][0].name),
                name: a0User["https://sage.com/sci/profiles"][0].name,
                email: a0User["https://sage.com/sci/profiles"][0].email
            };
            if (newUser.email.endsWith("@sage.com")) {
                getPortalUserRole()
                    .then((res) => {
                        const { user: u, token } = res.data;
                        localStorage.setItem("x-authorization", token);
                        setUser({ ...newUser, ...u });
                        setIsAuthenticated(true);
                        setIsLoading(false);
                    })
                    .catch(() => {
                        logout();
                    });
            }
        }
    }, [a0Auth, a0User, a0TokenLoaded, isAuthenticated, logout, loggedOut]);

    useEffect(() => {
        if (isAuthenticated && user) {
            getUserPermissions().then((res) => {
                setPermissions(res.data);
                setIsLoading(false);
            });
        }
    }, [isAuthenticated, user]);

    const login = () => {
        setLoggedOut(false);
        loginWithRedirect().then();
    };

    const impersonate = (email) => {
        localStorage.setItem("x-user-email", email);
        setPersona(email);

        if (!email.endsWith("@sage.com")) {
            setIsImpersonate(true);
        }
        setVisibleImpersonate(false);
    };

    const handleSubmitAttachment = (attachmentChoice, tiersList) => {
        const newUser = {
            initials: getInitials(a0User["https://sage.com/sci/profiles"][0].name),
            name: a0User["https://sage.com/sci/profiles"][0].name,
            email: a0User["https://sage.com/sci/profiles"][0].email
        };

        const { tiersCode, tiersName, pivotId, role, roleLabel } = attachmentChoice;

        setAttachment({ ...attachmentChoice, name: newUser.name })
            .then((res) => {
                const token = res.data;
                localStorage.setItem("x-authorization", token);
                const decodedToken = jwt.decode(token);
                if (decodedToken) {
                    const {
                        role: newRole,
                        roleLabel: newRoleLabel,
                        tiersParent: newTiersParent,
                        rolePaie: newRolePaie,
                        roleBms: newRoleBms,
                        agreements: newAgreements,
                        roleISV,
                        emailInitial,
                        isImpersonate: isImpersonateUser,
                        adminRole,
                        superUser
                    } = decodedToken;
                    setUser({
                        ...newUser,
                        tiersCode,
                        tiersName,
                        pivotId,
                        role: newRole,
                        roleLabel: newRoleLabel,
                        tiersParent: newTiersParent,
                        rolePaie: newRolePaie,
                        roleBms: newRoleBms,
                        agreements: newAgreements,
                        roleISV,
                        emailInitial,
                        isImpersonate: isImpersonateUser,
                        adminRole,
                        superUser
                    });
                } else setUser({ ...newUser, tiersCode, tiersName, pivotId, role, roleLabel });
                setIsAuthenticated(true);
                setIsLoading(false);
                setAttachmentLoading(false);
                setIsImpersonate(false);
                if (tiersList && tiersList.length > 0) {
                    reportAuthentification(tiersList);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                setIsAuthenticated(false);
                setAttachmentLoading(false);

                const { apiCode } = err.response.data;

                if (err.response && err.response.data) {
                    if (apiCode === "unauthorized_tiers_sanctionPolicy") {
                        setTiersSanctionFail(true);
                    } else if (apiCode === "unauthorized_tiers_diligence") {
                        setTiersDiligenceFail(true);
                    } else {
                        setAttachmentFail(true);
                    }
                } else {
                    setAttachmentFail(true);
                }
            });
    };

    const handleFailAttachment = () => {
        setAttachmentFail(true);
        logout();
    };

    const handleTiersBlocked = (e) => {
        e.preventDefault();
        if (tiersSanctionFail) setTiersSanctionFail(false);
        if (tiersDiligenceFail) setTiersDiligenceFail(false);

        if (uniqueTiers) {
            setUniqueTiers(false);
            logout();
        }
    };

    const getMagicToken = useCallback((urlToken, email) => {
        localStorage.setItem("x-user-email", email);
        localStorage.setItem("x-authorization", urlToken);
    }, []);

    const handleCancelImpersonate = () => {
        setVisibleImpersonate(false);
    };

    const handleButtonTitle = () => {
        if (!attachmentFail && !tiersDiligenceFail && !tiersSanctionFail && !enableCodeAuth) {
            return t("users:attachment.select");
        }
        if (attachmentFail && !tiersDiligenceFail && !tiersSanctionFail && !enableCodeAuth) {
            return t("users:attachment.validate");
        }
        if (enableCodeAuth) {
            return t("users:attachment.validate");
        }
        return t("users:attachment.accept");
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                isLoading,
                user,
                persona,
                login,
                logout,
                error,
                getMagicToken,
                needsRedirect,
                permissions,
                setVisibleImpersonate
            }}>
            {children}

            <ModalAlert
                className={!enableCodeAuth ? "attachment-list__alert" : "smallPopUpClass"}
                visible={
                    !a0IsLoading &&
                    a0Auth &&
                    a0User &&
                    a0TokenLoaded &&
                    persona &&
                    !attachmentLoading &&
                    ((!isAuthenticated && !persona.endsWith("@sage.com") && needsRedirect) || isImpersonate)
                }
                onCancel={handleFailAttachment}
                cancelButtonText={t("users:attachment.cancel")}
                enableSubmit={(attachmentFail && !tiersDiligenceFail && !tiersDiligenceFail) || enableCodeAuth ? enableButton : true}
                onAccept={!attachmentFail && !tiersDiligenceFail && !tiersSanctionFail ? handleSubmitAttachment : handleFailAttachment}
                title={!attachmentFail ? t("users:attachment.title") : t("users:attachment.titleRattachement")}
                acceptButtonText={handleButtonTitle()}
                submit="attachment-list__form">
                <AttachmentList
                    attachmentFail={attachmentFail}
                    setAttachmentFail={setAttachmentFail}
                    onSubmitAttachment={handleSubmitAttachment}
                    onFailAttachment={handleFailAttachment}
                    onTiersBlocked={handleTiersBlocked}
                    persona={persona}
                    tiersDiligenceFail={tiersDiligenceFail}
                    tiersSanctionFail={tiersSanctionFail}
                    uniqueTiers={uniqueTiers}
                    setUniqueTiers={setUniqueTiers}
                    userEmail={persona}
                    setEnableButton={setEnableButton}
                    enableCodeAuth={enableCodeAuth}
                    setEnableCodeAuth={setEnableCodeAuth}
                />
            </ModalAlert>

            <Impersonate impersonate={impersonate} handleCancelImpersonate={handleCancelImpersonate} visibleImpersonate={visibleImpersonate} />
        </AuthContext.Provider>
    );
};

const AuthConsumer = AuthContext.Consumer;

export { AuthContext, AuthProvider, AuthConsumer };
