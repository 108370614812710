import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../../../shared/components/form-elements/input/input";
import Button from "../../../../shared/components/form-elements/button/button";
import Icon from "../../../../shared/components/icon-svg/icon";
import "./search-menu-proposal.scss";
import useOnClickOutside from "../../../../shared/custom-hook/use-click-outside";

const SearchMenuProposal = (props) => {
    const {
        titleSearchMenu,
        handleSearch,
        openSearchMenu,
        setOpenSearchMenu,
        handleSearchChange,
        searchValue,
        searchOnClient,
        handleSearchCheckboxChange,
        handleResetSearch,
        isPartner,
        displayCommercialEmail = true,
        displaySubscriptionRef = false,
        translationSource = "proposals:list-proposals-page",
        displayRangeDate = false,
        displayProbability = false,
        displayProposalRef = false
    } = props;
    const { t } = useTranslation();
    const searchPopup = useRef();
    const [isDisabled, setDisabled] = useState(false);
    const [errorDate, setErrorDate] = useState(false);
    const checkRange = (startDate, endDate, e) => {
        if (startDate && endDate && startDate > endDate) {
            setErrorDate(true);
            setDisabled(true);
        } else {
            setErrorDate(false);
            setDisabled(false);
        }
        handleSearchChange(e);
    };
    const validateStartDate = (e) => {
        checkRange(e.target.value, searchValue.endDate, e);
    };
    const validateEndDate = (e) => {
        checkRange(searchValue.startDate, e.target.value, e);
    };
    useOnClickOutside(searchPopup, () => setOpenSearchMenu(false));

    return (
        <div className={`search-menu ${openSearchMenu ? "is-open" : ""}`} ref={searchPopup}>
            <div className="full-page-header">
                <button type="button" className="full-page-header__button" onClick={() => setOpenSearchMenu(false)}>
                    <Icon name="dialog-close" />
                </button>

                <h2 className="full-page-header__title">{titleSearchMenu}</h2>
            </div>

            {!isPartner && (
                <div className="search-menu__fieldset">
                    <label className="search-menu__checkbox carbon-form__radio">
                        <input type="radio" name="raison-radio" checked={searchOnClient} onChange={handleSearchCheckboxChange} />
                        <div className="carbon-form__radio-icon" />

                        <span>{t(`${translationSource}.client-search`)}</span>
                    </label>

                    <label className="search-menu__checkbox carbon-form__radio">
                        <input type="radio" name="code-radio" checked={!searchOnClient} onChange={handleSearchCheckboxChange} />
                        <div className="carbon-form__radio-icon" />

                        <span>{t(`${translationSource}.partner-search`)}</span>
                    </label>
                </div>
            )}

            <div className="search-menu__item">
                <Input
                    name={searchOnClient ? "raisonSociale" : "partnerRaisonSociale"}
                    icon="ui-search"
                    placeholder={searchOnClient ? t(`${translationSource}.client-name-placeholder`) : t(`${translationSource}.partner-name-placeholder`)}
                    value={searchOnClient ? searchValue.raisonSociale : searchValue.partnerRaisonSociale}
                    onChange={handleSearchChange}
                />
            </div>

            <div className="search-menu__item">
                <Input
                    name={searchOnClient ? "clientCode" : "partnerCode"}
                    icon="ui-search"
                    placeholder={searchOnClient ? t(`${translationSource}.client-placeholder`) : t(`${translationSource}.partner-placeholder`)}
                    value={searchOnClient ? searchValue.clientCode : searchValue.partnerCode}
                    onChange={handleSearchChange}
                />
            </div>

            {!isPartner && displayCommercialEmail && (
                <div className="search-menu__item">
                    <Input
                        name="commercialEmail"
                        icon="ui-search"
                        placeholder={t("proposals:list-proposals-page.commercial-email-placeholder")}
                        value={searchValue.commercialEmail}
                        onChange={handleSearchChange}
                    />
                </div>
            )}

            {displayProposalRef && (
                <div className="search-menu__item">
                    <Input
                        name="proposalReference"
                        icon="ui-search"
                        placeholder={t("proposals:list-proposals-page.reference-placeholder")}
                        value={searchValue.proposalReference}
                        onChange={handleSearchChange}
                    />
                </div>
            )}
            {displaySubscriptionRef && (
                <div className="search-menu__item">
                    <Input
                        name="reference"
                        icon="ui-search"
                        placeholder={t("proposals:list-proposals-page.reference-placeholder")}
                        value={searchValue.reference}
                        onChange={handleSearchChange}
                    />
                </div>
            )}
            {displayRangeDate && (
                <>
                    <div className="search-menu__item">
                        <label>
                            <span>{t(`${translationSource}.start-date`)}</span>
                        </label>
                        <Input
                            name="startDate"
                            icon="ui-calendar"
                            className="carbon-form__date-input"
                            value={searchValue.startDate}
                            type="date"
                            onChange={validateStartDate}
                        />
                    </div>
                    <div className="search-menu__item carbon-form__date-input">
                        <label>
                            <span>{t(`${translationSource}.end-date`)}</span>
                        </label>
                        <Input
                            name="endDate"
                            icon="ui-calendar"
                            className="carbon-form__date-input"
                            value={searchValue.endDate}
                            type="date"
                            onChange={validateEndDate}
                        />
                        {errorDate ? <div className="new-date-error">{`${t(`${translationSource}.date-error`)}`}</div> : ""}
                    </div>
                </>
            )}
            {displayProbability && (
                <div className="search-menu__item">
                    <Input
                        name="probability"
                        icon="ui-search"
                        type="number"
                        placeholder={t("proposals:list-proposals-page.probability")}
                        value={searchValue.probability}
                        onChange={handleSearchChange}
                    />
                </div>
            )}
            <div className="search-menu__actions">
                <Button className="medium primary search-menu__button search-submit" onClick={handleSearch} disabled={isDisabled}>
                    {t("clients:list-clients-page.search")}
                </Button>

                <Button className="medium secondary search-menu__button search-reset" onClick={handleResetSearch}>
                    {t("clients:list-clients-page.reset")}
                </Button>
            </div>
        </div>
    );
};

export default SearchMenuProposal;
