import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import Input from "../form-elements/input/input";
import Button from "../form-elements/button/button";
import Icon from "../icon-svg/icon";
import "./search-menu.scss";
import useOnClickOutside from "../../custom-hook/use-click-outside";

const SearchMenu = (props) => {
    const {
        titleSearchMenu,
        handleSearch,
        openSearchMenu,
        setOpenSearchMenu,
        handleSearchChange,
        searchValue,
        showSearchRaison,
        showSearchCodeTiers,
        handleSearchCheckboxChange,
        handleResetSearch
    } = props;
    const { t } = useTranslation();
    const searchPopup = useRef();

    useOnClickOutside(searchPopup, () => setOpenSearchMenu(false));

    return (
        <div className={`search-menu ${openSearchMenu ? "is-open" : ""}`} ref={searchPopup}>
            <div className="full-page-header">
                <button type="button" className="full-page-header__button" onClick={() => setOpenSearchMenu(false)}>
                    <Icon name="dialog-close" />
                </button>

                <h2 className="full-page-header__title">{titleSearchMenu}</h2>
            </div>

            <div className="search-menu__fieldset">
                <label className="search-menu__checkbox carbon-form__checkbox">
                    <input type="checkbox" name="raison-checkbox" checked={showSearchRaison} onChange={handleSearchCheckboxChange} />
                    <div className="carbon-form__checkbox-icon" />

                    <span>{t("clients:client-information.raisonClient")}</span>
                </label>

                <label className="search-menu__checkbox carbon-form__checkbox">
                    <input type="checkbox" name="code-checkbox" checked={showSearchCodeTiers} onChange={handleSearchCheckboxChange} />
                    <div className="carbon-form__checkbox-icon" />

                    <span>{t("clients:client-information.codeTiers")}</span>
                </label>
            </div>

            {showSearchRaison && (
                <div className="search-menu__item">
                    <Input
                        name="raisonSociale"
                        icon="ui-search"
                        placeholder={t("clients:client-information.raisonTiers-placeholder")}
                        value={searchValue.raisonSociale}
                        onChange={handleSearchChange}
                    />
                </div>
            )}

            {showSearchCodeTiers && (
                <div className="search-menu__item">
                    <Input
                        name="codeTiers"
                        icon="ui-search"
                        placeholder={t("clients:client-information.codeTiers-placeholder")}
                        value={searchValue.codeTiers}
                        onChange={handleSearchChange}
                    />
                </div>
            )}

            <div className="search-menu__actions">
                <Button className="medium primary search-menu__button search-submit" onClick={handleSearch}>
                    {t("clients:list-clients-page.search")}
                </Button>

                <Button className="medium secondary search-menu__button search-reset" onClick={handleResetSearch}>
                    {t("clients:list-clients-page.reset")}
                </Button>
            </div>
        </div>
    );
};

export default SearchMenu;
