import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { AuthContext, roles } from "../../../../context";
import { createNewProposal, getProposal, modifyProposal} from "../../services";
import {updateProposal } from "../../services/proposal.services"
import { getClient } from "../../../clients/services/client.services";
import { parseAmount, getProductPrice, getProductPriceFromCartItems } from "../../utils/price.calculator";
import checkTiersValidity from "../../../clients/utils/tiers.validator";
import { Icon, ModalAlert, PageWrapper, PopupWrapper } from "../../../../shared/components";
import CgvFooter from "../cgv-footer/cgv-footer";
import CartTotal from "../cart-total/cart-total";
import Cart from "../cart/cart";
import NavigationStep from "../navigation-step/navigation-step";
import SelectOffer from "./select-offer/select-offer";
import SelectConfig from "./select-config/select-config";
import SelectClient from "./select-client/select-client";
import SubstitutionPanel from "./substitution-panel/substitutionPanel";
import SelectFin from "./select-fin/select-fin";
import SepaLoading from "./sepa-loading/sepa-loading";
import SepaRecap from "./sepa-recap/sepa-recap";
import { handleRestrictions } from "../../utils/toast.handler";
import { checkExistUsageFree } from "../../../users/service/user.services";
import { checkOptionalsConfig, getSubscriptionUpdated } from "../../../subscription/service/subscription.service";
import { getSubtitutions, getParcMigrationList } from "../../services/proposal.services";
import "./new-proposal.scss";

const { REACT_APP_SEPA_URL } = process.env;

const NewProposal = (props) => {
    const { mode, reference, isSubcription } = props;

    const history = useHistory();
    const location = useLocation();
    const { user, permissions } = useContext(AuthContext);
    const { t } = useTranslation();
    const { step, client, usageCode = "US_EQUIPCLI" } = queryString.parse(location.search);
    const [abandonPopupVisible, setAbandonPopupVisible] = useState(false);
    const [checkPopupVisible, setCheckPopupVisible] = useState(false);

    const [offerLabel, setOfferLabel] = useState();
    const [showAlertOrder, setShowAlertOrder] = useState(false);
    const [hasProposalError, setHasProposalError] = useState(false);
    const [showAlertCriteria, setShowAlertCriteria] = useState(false);

    const [showSubstitution, setShowSubstitution] = useState(false);
    const [notifiyEvolvedMigration, setNotifiyEvolvedMigration] = useState(false);
    const [disableCgv, setDisableCgv] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [showToastMessage, setShowToastMessage] = useState("");
    const [toastType, setToastType] = useState("");
    const [toastDiligencelink, setToastDiligencelink] = useState(false);

    const [proposalValid, setProposalValid] = useState(false);
    const [apiLoading, setApiLoading] = useState(false);
    const [invoicingOffer, setInvoicingOffer] = useState(false);
    const [configLoaded, setConfigLoaded] = useState(false);
    const {
        proposal: { hiddenFields }
    } = permissions;

    const [formValidity, setFormValidity] = useState({
        contactEmailValid: !!(usageCode === "US_VDA" || usageCode === "US_PEI"),
        commercialEmailValid: false,
        administratorEmailValid: false,
        clientSelectedValid: !!(usageCode === "US_VDA" || usageCode === "US_PEI"),
        substitutionChoiceValid: false,
        cgvValid: hiddenFields.includes("cgv")
    });

    const [cgv, setCgv] = useState(false);
    const [userSusbtitutions, setUserSusbtitutions] = useState([]);
    const [removeFirstStep, setRemoveFirstStep] = useState(false);
    const [cartItems, setCartItems] = useState([]);
    const [cartItemsPrevLength, setCartItemsPrevLength] = useState(0);
    const [cartItemsPrevUpsell, setCartItemsPrevUpsell] = useState([]);
    const [cartMeta, setCartMeta] = useState({
        created: new Date(),
        modified: new Date(),
        total: 0,
        probability: "",
        clientPivotId: "",
        clientReference: "",
        clientCode: "",
        clientName: "",
        clientCountry: "",
        commercialEmail: "",
        administratorPivotId: "",
        administratorEmail: "",
        administratorFirstName: "",
        administratorLastName: "",
        administratorCivility: "",
        cartComment: "",
        cartRefuseReason: "",
        cartRefuseComment: "",
        clientContactEmail: "",
        clientContactFirstName: "",
        clientContactLastName: "",
        clientContactCivility: "",
        opCom: "",
        partnerPivotId: "",
        partnerName: "",
        partnerCode: "",
        subscriptionReference: ""
    });

    let steps = [];
    if (mode === "new" || mode === "edit") {
        if (showSubstitution && formValidity.clientSelectedValid) {
            steps = [
                { name: "offer", label: t("proposals:new-proposal.steps.offer"), canAdvance: cartItems.length > 0 },
                { name: "config", label: t("proposals:new-proposal.steps.config"), canAdvance: cartItems.length > 0 && configLoaded },
                { name: "client", label: t("proposals:new-proposal.steps.client"), canAdvance: formValidity.clientSelectedValid },
                // Hide Substitution
                { name: "substitution", label: "Substitution", canAdvance: formValidity.substitutionChoiceValid },
                { name: "fin", label: t("proposals:new-proposal.steps.fin"), canAdvance: true }
            ];
        } 
        else {
            steps = [
                { name: "offer", label: t("proposals:new-proposal.steps.offer"), canAdvance: cartItems.length > 0 },
                { name: "config", label: t("proposals:new-proposal.steps.config"), canAdvance: cartItems.length > 0 && configLoaded },
                { name: "client", label: t("proposals:new-proposal.steps.client"), canAdvance: formValidity.clientSelectedValid },
                { name: "fin", label: t("proposals:new-proposal.steps.fin"), canAdvance: true }
            ];
        }

        if (client || reference || usageCode === "US_VDA" || usageCode === "US_PEI") {
            steps.splice(2, 1);           
        }
        if(mode ==="edit" && (isSubcription || removeFirstStep))
        {
            steps.splice(0, 1);
        }
    
    } else if (mode === "sepa") {
        if (usageCode === "US_VDA" || usageCode === "US_PEI") {
            steps = [{ name: "recap", label: t(""), canAdvance: true }];
        } else {
            steps = [
                { name: "order", label: t("proposals:new-proposal.sepa-steps.order"), canAdvance: formValidity.cgvValid && formValidity.administratorEmailValid },
                { name: "pay", label: t("proposals:new-proposal.sepa-steps.pay"), canAdvance: true },
                { name: "recap", label: t("proposals:new-proposal.sepa-steps.recap"), canAdvance: true }
            ];
        }
    }
    else if(mode ==="update")
    {
        steps = [
             { name: "fin", label: t("proposals:new-proposal.steps.fin"), canAdvance: true }
        ];
    }


    let stepIndex = steps.findIndex((s) => s.name === step);

    if (stepIndex < 0) {
        if (mode === "new" || mode === "edit") stepIndex = 0;
        if (mode === "sepa") {
            const { field_shop_key_crypted: callbackToken } = queryString.parse(location.search);
            if (callbackToken) stepIndex = 1;
            else stepIndex = 0;
        }
    }

    const currentStep = steps[stepIndex].name;

    const hasCgv = mode === "sepa" && currentStep === "order" && !hiddenFields.includes("cgv");

    const checkforSubstitutions = () => {
        if (client) {
            getSubtitutions().then((res) => {
                const { valid, userSusbtitutions: susbtitutions } = res.data;
                const offers = cartItems && cartItems.length > 0 ? cartItems.filter(({ type }) => type === "principal") : [];
                const offerslist = offers.map((a) => a.product);
                const { pivotId } = user;
                if (offerslist.length > 0) {
                    getParcMigrationList(client, { partnerId: pivotId, offerslist: offerslist.join() }).then((resp) => {
                        const parcs = resp.data;
                        if (parcs.length > 0) {
                            if (susbtitutions && susbtitutions.length > 0) {
                                if (!valid) {
                                    setNotifiyEvolvedMigration(true);
                                    setUserSusbtitutions(parcs);
                                } else {
                                    const mergeParclist = parcs.map((parcItem) => {
                                        const exists = susbtitutions.find((subItem) => subItem.parcPivotId === parcItem.parcPivotId);
                                        return exists ? { ...parcItem, checkedValue: true } : { ...parcItem, checkedValue: false };
                                    });
                                    setUserSusbtitutions(mergeParclist);
                                }
                            } else {
                                setUserSusbtitutions(parcs);
                            }
                            setShowSubstitution(true);
                            setFormValidity({ ...formValidity, clientSelectedValid: true });
                        }
                    });
                }
            });
        }
    };

    const refreshProposal = useCallback(() => {
        if (reference && !isSubcription) {
            getProposal(reference, { mode: "edit" }).then((res) => {
                const { proposalMeta, proposalItems, proposalCgv = false } = res.data;
                const { commercialEmail, clientContactEmail, administratorEmail, clientPivotId, subscription, partnerPivotId } = proposalMeta;
                const { productParent } = proposalItems[0];

                getSubtitutions(reference)
                    .then((resp) => {
                        const { valid, userSusbtitutions: susbtitutions } = resp.data;
                        const offers = proposalItems && proposalItems.length > 0 ? proposalItems.filter(({ type }) => type === "principal") : [];
                        const offerslist = offers.map((a) => a.product);                        
                        getParcMigrationList(clientPivotId, { partnerId: partnerPivotId, offerslist: offerslist.join() }).then((result) => {
                            const parcs = result.data;
                            if (parcs.length > 0) {
                                if (susbtitutions && susbtitutions.length > 0) {
                                    const parcEvolved = susbtitutions.filter((o1) => !parcs.some((o2) => o1.parcPivotId.toString() === o2.parcPivotId.toString())).length > 0;

                                    if (!valid || parcEvolved) {
                                        setNotifiyEvolvedMigration(true);
                                        setUserSusbtitutions(parcs);
                                    } else {
                                        const mergeParclist = parcs.map((parcItem) => {
                                            const { parcPivotId: pivottest } = parcItem;
                                            const exists = susbtitutions.find((subItem) => subItem.parcPivotId.toString() === pivottest.toString());
                                            return exists ? { ...parcItem, checkedValue: true } : { ...parcItem, checkedValue: false };
                                        });
                                        setUserSusbtitutions(mergeParclist);
                                    }
                                } else {
                                    setUserSusbtitutions(parcs);
                                }
                                setShowSubstitution(true);
                            } else if (susbtitutions && susbtitutions.length > 0) {
                                setNotifiyEvolvedMigration(true);
                            }
                        });
                        setCartMeta(proposalMeta);
                        setCartItems(proposalItems);                        
                        setCgv(proposalCgv);
                    })
                    .catch(() => {
                        setCartMeta(proposalMeta);
                        setCartItems(proposalItems);                        
                        setCgv(proposalCgv);
                    });

                if (subscription) {
                    if (!removeFirstStep) setRemoveFirstStep(true);

                    checkOptionalsConfig({ usageCode, clientPivotId, product: productParent })
                        .then((optionResp) => {
                            const { isInvoicingOffer } = optionResp.data;
                            setInvoicingOffer(isInvoicingOffer);
                        })
                        .catch(() => {
                            setCheckPopupVisible(true);
                        });
                    getSubscriptionUpdated(subscription, usageCode)
                        .then((response) => {
                            const { subscriptionItems } = response.data;
                            setCartItemsPrevUpsell(subscriptionItems);
                        })
                        .catch(() => {
                            setHasProposalError(true);
                        });
                }

                setFormValidity({
                    contactEmailValid: clientContactEmail && clientContactEmail.length > 0,
                    commercialEmailValid: commercialEmail && commercialEmail.length > 0,
                    administratorEmailValid: administratorEmail && administratorEmail.length > 0,
                    cgvValid: hasCgv ? proposalCgv : true,
                    clientSelectedValid: true
                });
            });
        } else if (reference && isSubcription) {
            getProposal(reference, { subscription: true, mode: "edit" })
                .then((res) => {
                    const { proposalMeta, proposalItems, proposalCgv = false } = res.data;
                    const { commercialEmail, clientContactEmail, administratorEmail, clientPivotId } = proposalMeta;
                    const { productParent } = proposalItems[0];
                    const { status, statusColor, allowedActions, statusLabel, statusUpdates, ...proposalMetaNew } = proposalMeta;

                    setCgv(proposalCgv);

                    getSubscriptionUpdated(reference, usageCode).then((response) => {
                        const { subscriptionItems } = response.data;

                        const mergeItems = subscriptionItems.map((subItem) => {
                            const { description, type } = proposalItems.find((propItem) => propItem.product === subItem.product && propItem);
                            return { ...subItem, description, type };
                        });
                        const { product: subscriptionProduct } = mergeItems.find((propItem) => propItem.type === "principal");

                        setCartMeta({
                            ...proposalMetaNew,
                            subscriptionReference: reference,
                            subscriptionPproduct: subscriptionProduct
                        });
                        setCartItems(mergeItems);
                        setCartItemsPrevUpsell(mergeItems);
                    });

                    checkOptionalsConfig({ usageCode, clientPivotId, product: productParent })
                        .then((optionResp) => {
                            const { isInvoicingOffer } = optionResp.data;
                            setInvoicingOffer(isInvoicingOffer);
                        })
                        .catch(() => {
                            setCheckPopupVisible(true);
                        });

                    setFormValidity({
                        contactEmailValid: clientContactEmail && clientContactEmail.length > 0,
                        commercialEmailValid: commercialEmail && commercialEmail.length > 0,
                        administratorEmailValid: administratorEmail && administratorEmail.length > 0,
                        cgvValid: hasCgv ? proposalCgv : true
                    });
                })
                .catch(() => {
                    setHasProposalError(true);
                });
        }// eslint-disable-next-line
    }, [hasCgv, reference, isSubcription, usageCode]);

    useEffect(() => {
        refreshProposal();
    }, [refreshProposal]);

    useEffect(() => {
        const { total } = cartMeta;
        if (mode === "new" && stepIndex > 0 && typeof total !== "undefined" && total === 0 && cartItems.length === 0) {
            history.replace(`/proposals/new?${client ? `client=${client}` : `${usageCode ? `usageCode=${usageCode}` : ""}`}`);
        } else {
            setProposalValid(true);
        }
    }, [cartItems, cartMeta, client, history, mode, stepIndex, usageCode]);

    useEffect(() => {
        if (client) {
            getClient(client).then((res) => {
                const {
                    pivotId,
                    tiersCode,
                    tiersName,
                    tiersCountry,
                    sanctionPolicy,
                    sanctionDateFin,
                    dueDiligence,
                    dueDiligenceDateFin,
                    tiersBloque
                } = res.data;
                setCartMeta((c) => ({
                    ...c,
                    clientPivotId: pivotId,
                    clientCode: tiersCode,
                    clientName: tiersName,
                    clientCountry: tiersCountry,
                    clientSanctionPolicy: sanctionPolicy,
                    clientSanctionDateFin: sanctionDateFin,
                    clientDueDiligence: dueDiligence,
                    clientDueDiligenceDateFin: dueDiligenceDateFin,
                    clientTiersBloque: tiersBloque
                }));
            });
        }
    }, [client]);

    const appliRemise = () => {
        const { appliedPromo, promoValue, referencePromo } = cartMeta;
        const references = referencePromo ? referencePromo.split(",").map(ref => ref.trim()) : [];

        if (appliedPromo && promoValue && references.length > 0 && cartItems && cartItems.length > 0) {
            const selectedPromoValue = promoValue;

            const producteligible = cartItems.find((o) => references.includes(o.product));
            if (producteligible) {
                const isParentProduct = producteligible.type === "principal";

                cartItems.map((o) => {
                    const isProductEligible = references.includes(o.product);
                    const isParentProductEligible = references.includes(o.productParent);

                    if ((isParentProduct && isParentProductEligible) || (!isParentProduct && isProductEligible)) {
                        o.originalPrice = o.originalPrice ? o.originalPrice : o.price;
                        o.price = o.originalPrice - (o.originalPrice * (selectedPromoValue / 100));
                    }
                    return o;
                });
            }
        }
    };
    useEffect(() => {
        if (mode === "sepa" && cartMeta && cartMeta.appliedPromo) {
            appliRemise();
        }
        const totalPrice = cartItems.reduce((acc, curr) => acc + parseAmount(curr.price - curr.price * curr.discount), 0);
        setCartMeta((c) => ({ ...c, total: parseAmount(totalPrice) }));

   }, [cartItems,mode]);

    useEffect(() => {
        if (mode === "new" && user) {
            const { tiersCode, tiersName, pivotId, email } = user;
            if (tiersCode && pivotId) setCartMeta((c) => ({ ...c, partnerPivotId: pivotId, partnerCode: tiersCode, partnerName: tiersName }));
            if (usageCode === "US_VDA" || usageCode === "US_PEI") {
                getClient(pivotId).then((res) => {
                    const { tiersCountry } = res.data;
                    setCartMeta((c) => ({
                        ...c,
                        clientPivotId: pivotId,
                        clientCode: tiersCode,
                        clientName: tiersName,
                        clientCountry: tiersCountry,
                        clientContactEmail: email
                    }));
                });
            }
        }
    }, [mode, user, usageCode]);

    const handleStepChange = (newStep) => {
        const { pathname } = location;

        if (usageCode) {
            history.replace(`${pathname}?usageCode=${usageCode}${client ? `&client=${client}` : ""}&step=${steps[newStep].name}`, { originStep: step });
        } else {
            history.replace(`${pathname}?${client ? `client=${client}` : ""}&step=${steps[newStep].name}`, { originStep: step });
        }
    };

    const handleDeleteOffer = (principalProductReference) => {        
        setCartItems(cartItems.filter(({ product, productParent }) => product !== principalProductReference && productParent !== principalProductReference));
    };

    const cleanCartMeta = () => {
        return Object.fromEntries(Object.entries(cartMeta).filter(([, v]) => (v && v.toString().length >= 0) || typeof v === "number"));
    };

    const handleAcceptCgv = (checked) => {
        setCgv(checked);
        setFormValidity({ ...formValidity, cgvValid: checked });
    };

    const handleOrder = (res) => {
        const { financialControlReason, reference: reqRef } = res.data;

        if (user.role === roles.partner.role && !financialControlReason) {
            history.push(`/proposals/${reqRef}/sepa?step=order&usageCode=${usageCode}`);
            refreshProposal();
        } else {
            history.push({
                pathname: "/proposals/list/",
                state: { action: "order", reference: reqRef, financialControlReason }
            });
        }
    };

    const getSubtitutionsToSave = () => {
        if (userSusbtitutions && userSusbtitutions.length > 0) {
            const susbtitutionsupdate = userSusbtitutions.filter((e) => e.checkedValue === true);
            if (susbtitutionsupdate.length > 0) {
                return susbtitutionsupdate;
            }
        }
        return [];
    };

    const cartItemsWithCasVente = () => {
        let casvente = null;
        const selectedSubstitutions = getSubtitutionsToSave();
        const principalProducts = cartItems ? cartItems.filter(({ productParent }) => !productParent) : null;
        principalProducts.forEach((p) => {
            const { product: referenceproduit } = p;
            const productfiltered =
                selectedSubstitutions && selectedSubstitutions.length > 0 && selectedSubstitutions.filter((e) => e.offerProduct === referenceproduit);
            if (productfiltered && productfiltered && productfiltered.length > 0) {
                if (productfiltered.length === 1) {
                    casvente = "100000002";
                } else {
                    casvente = "100000005";
                }
            } else if (isSubcription) {
                casvente = "100000001";
            } else {
                casvente = "100000000";
            }
            p.casvente = casvente;
            const cartItemsFiltered = cartItems.filter(({ productParent }) => productParent === referenceproduit);
            cartItemsFiltered.forEach((el) => { el.casvente = casvente; });
        });

        return cartItems;
    };

    const handleSave = () => {
        setApiLoading(true);
        const { clientSanctionPolicy, clientSanctionDateFin, clientDueDiligence, clientDueDiligenceDateFin, clientTiersBloque } = cartMeta;
        const clientChoice = {
            sanctionPolicy: clientSanctionPolicy,
            sanctionDateFin: clientSanctionDateFin,
            dueDiligence: clientDueDiligence,
            dueDiligenceDateFin: clientDueDiligenceDateFin,
            tiersBloque: clientTiersBloque
        };

        if (
            (formValidity.contactEmailValid &&
                formValidity.commercialEmailValid &&
                checkTiersValidity(clientChoice, setShowToast, setToastType, setShowToastMessage, user, false, true, setToastDiligencelink)) ||
            usageCode === "US_VDA" ||
            usageCode === "US_PEI"
        ) {
            if (mode === "new" || (mode === "edit" && isSubcription)) {
                createNewProposal("create", usageCode, cartItemsWithCasVente(), cleanCartMeta(), getSubtitutionsToSave())
                    .then((response) => {
                        setApiLoading(false);
                        history.push({
                            pathname: "/proposals/list/",
                            state: { action: "create", reference: response.data.reference }
                        });
                    })
                    .catch((error) => {
                        setApiLoading(false);
                        handleRestrictions(error, setShowToast, setToastType, setShowToastMessage, user, setToastDiligencelink);
                    });
            } else if (mode === "edit" && !isSubcription) {
                modifyProposal(reference, "edit", { cartItems: cartItemsWithCasVente(), cartMeta: cleanCartMeta(), usageCode, userSusbtitutions: getSubtitutionsToSave() })
                    .then(() => {
                        setApiLoading(false);
                        history.push({
                            pathname: "/proposals/list/",
                            state: { action: "edit", reference }
                        });
                    })
                    .catch((error) => {
                        setApiLoading(false);
                        handleRestrictions(error, setShowToast, setToastType, setShowToastMessage, user, setToastDiligencelink);
                    });
            }
            else if (mode === "update" ) {
                updateProposal(reference, {cartMeta: cleanCartMeta() },"edit")
                    .then(() => {
                        setApiLoading(false);
                        history.push({
                            pathname: "/proposals/list/",
                            state: { action: "edit", reference }
                        });
                    })
                    .catch((error) => {
                        setApiLoading(false);
                        handleRestrictions(error, setShowToast, setToastType, setShowToastMessage, user, setToastDiligencelink);
                    });
            }
        }
    };

    const handlePurchase = () => {
        setApiLoading(true);
        const { clientSanctionPolicy, clientSanctionDateFin, clientDueDiligence, clientDueDiligenceDateFin, clientTiersBloque, clientContactEmail, clientContactFirstName, clientContactLastName } = cartMeta;
        const clientChoice = {
            sanctionPolicy: clientSanctionPolicy,
            sanctionDateFin: clientSanctionDateFin,
            dueDiligence: clientDueDiligence,
            dueDiligenceDateFin: clientDueDiligenceDateFin,
            tiersBloque: clientTiersBloque
        };

        if (
            (formValidity.contactEmailValid &&
                formValidity.commercialEmailValid &&
                formValidity.administratorEmailValid &&
                checkTiersValidity(clientChoice, setShowToast, setToastType, setShowToastMessage, user, false, true, setToastDiligencelink)) ||
            usageCode === "US_VDA" ||
            usageCode === "US_PEI"
        ) {
            if (mode === "new" || (mode === "edit" && isSubcription)) {
                createNewProposal("order", usageCode, cartItemsWithCasVente(), cleanCartMeta(), getSubtitutionsToSave())
                    .then((res) => {
                        setApiLoading(false);
                        handleOrder(res);
                    })
                    .catch((error) => {
                        setApiLoading(false);
                        handleRestrictions(error, setShowToast, setToastType, setShowToastMessage, user, setToastDiligencelink);
                    });
            } else if (mode === "edit" && !isSubcription) {
                modifyProposal(reference, "order", {
                    cartItems: cartItemsWithCasVente(),
                    cartMeta: cleanCartMeta(),
                    usageCode,
                    userSusbtitutions: getSubtitutionsToSave()
                })
                    .then((res) => {
                        setApiLoading(false);
                        handleOrder(res);
                    })
                    .catch((error) => {
                        setApiLoading(false);
                        handleRestrictions(error, setShowToast, setToastType, setShowToastMessage, user, setToastDiligencelink);
                    });
            } else if (mode === "sepa") {
                modifyProposal(reference, "pay", { cartMeta: cleanCartMeta(), usageCode }).then((res) => {
                    setApiLoading(false);
                    history.replace(`/proposals/${reference}/sepa?step=pay`);
                    const url = `${REACT_APP_SEPA_URL}/shop-form?shop_key=${res.data}&app_source=SAAS&shop_ref=${reference}&email=${clientContactEmail}&lastname=${clientContactLastName}&firstname=${clientContactFirstName}`;
                    window.open(url, "_self");
                });
            }
        }
    };

    const handleAbandon = () => {
        // Check this condition
        if (mode === "sepa") history.push("/proposals/list");
        else if (usageCode === "US_VDA" || usageCode === "US_PEI") {
            history.push("/");
        } else {
            history.push("/proposals/list");
        }
    };

    const handleQuitCheck = () => {
        history.push("/subscriptions/list");
    };

    const handleQuit = () => {
        history.push("/proposals/list");
    };

    const handleAddOfferToCart = (offer) => {
        const { principalProduct, principalProductLabel, principalProductDescription, principalProductLpp, principalProductUnitCode } = offer;
        const { secondaryProduct, secondaryProductLabel, secondaryProductDescription, secondaryProductLpp, secondaryProductUnitCode } = offer;
        const { productOrder, usageCode: uc, defaultQuantity: secondaryQuantity } = offer;
        const { ppPrice, ppDiscount, spPrice, ppCasvente, spDiscount, spCasvente } = offer;

        const principalInCart = cartItems.filter(({ product }) => product === principalProduct);
        const pProductss = cartItems.filter(({ productParent }) => !productParent);
        setCartItemsPrevLength(pProductss.length);
        const principal = {
            product: principalProduct,
            price: ppPrice,
            quantity: 1,
            usageCode: uc,
            label: principalProductLabel,
            description: principalProductDescription,
            lpp: principalProductLpp,
            unitCode: principalProductUnitCode,
            type: "principal",
            discount: ppDiscount,
            casvente: ppCasvente
        };

        const secondary = {
            product: secondaryProduct,
            price: spPrice,
            quantity: secondaryQuantity ,
            usageCode: uc,
            productParent: principalProduct,
            label: secondaryProductLabel,
            description: secondaryProductDescription,
            lpp: secondaryProductLpp,
            unitCode: secondaryProductUnitCode,
            type: "one-to-n",
            showOrder: productOrder,
            discount: spDiscount,
            casvente: spCasvente
        };

        const { product: productPr, label } = principal;
        if (usageCode !== "US_EQUIPCLI") {
            checkExistUsageFree(usageCode, productPr).then((res) => {
                const { usageCodeExist } = res.data;
                if (!usageCodeExist) {
                    if (principalInCart.length <= 0) {
                        // principal not in cart, add both principal and secondary to cart
                        setCartItems([...cartItems, principal, secondary]);
                    } else {
                        // principal is in cart, check if secondary in cart as well

                        const secondaryInCart = cartItems.filter(
                            ({ product, productParent }) => product === secondaryProduct && productParent === principalProduct
                        );

                        if (secondaryInCart.length <= 0) {
                            // secondary not in cart, add secondary to cart
                            setCartItems([...cartItems, secondary]);
                        }
                    }
                } else {
                    const {casvente : casventeupsell} =  (cartItems && cartItems[0]) || {casvente:null}
                    if (!isSubcription && casventeupsell !=="100000001")
                    {
                        setShowAlertOrder(true);
                        setOfferLabel(label);
                    }
                }
            });
        } else if (usageCode === "US_EQUIPCLI") {
            if (principalInCart.length <= 0) {
                // principal not in cart, add both principal and secondary to cart
                setCartItems([...cartItems, principal, secondary]);
            } else {
                // principal is in cart, check if secondary in cart as well
                const secondaryInCart = cartItems.filter(({ product, productParent }) => product === secondaryProduct && productParent === principalProduct);

                if (secondaryInCart.length <= 0) {
                    // secondary not in cart, add secondary to cart
                    setCartItems([...cartItems, secondary]);
                }
            }
        }
    };

    /**
     * Return the cart with computed progressivity
     * @param {*} productRef Product ref (current modified product used to check for linkedRef)
     * @param {*} fullConfig Full price config used to compute linkedRef prices
     * @param {*} refQuantity Quantity of the reference product (current modified product quantity)
     * @returns The new cart with progressivity applied
     */
    const getCartWithProgressivity = (productRef, fullConfig) =>
        cartItems.map((item) => {
            // Find the config of the current item
            const config = fullConfig.find((pric) => pric.product === item.product && pric.productParent === item.productParent);

            // If the is a linked ref pointing to the current productRef, then apply progressivity to the item
            if (config && config.price && config.price.find((p) => p.linkedRef === productRef.product)) {
                const { product, productParent, usageCode: uc, type, label, description, showOrder, unitCode, lpp, discount, quantity } = item;

                const newRefPrice = getProductPrice(config.price, quantity, productRef);

                const newCartItem = {
                    product,
                    productParent,
                    label,
                    description,
                    type,
                    usageCode: uc,
                    quantity,
                    price: newRefPrice,
                    showOrder,
                    unitCode,
                    lpp,
                    discount
                };
                return newCartItem;
            }
            return item;
        });

    const handleUpdateConfigCart = (configOption, newQuantity, type, fullConfig) => {
        const pProductss = cartItems.filter(({ productParent }) => !productParent);
        const {
            product,
            productParent,
            usageCode: uc,
            label,
            description,
            price,
            showOrder,
            unitCode,
            lpp,
            discount,
            criteria,
            componentReference,
            linkedProduct,
            isOption
        } = configOption;
        setCartItemsPrevLength(pProductss.length);
        if ((newQuantity < 0 && !isOption) || (newQuantity <= 0 && isOption)) {
            // check if there s 1 to n products left so we can delete the whole offer
            const oneToNListCount = cartItems.filter(
                ({ product: cartProd, productParent: cartProdParent, type: typeProduct }) =>
                    productParent === cartProdParent && product !== cartProd && typeProduct === "one-to-n"
            ).length;
            if (oneToNListCount === 0) {
                const { subscription: subscriptionref } = cartMeta;

                if (mode === "edit" && (isSubcription || subscriptionref) && criteria === "1" && criteria >= 1) {
                    setShowAlertCriteria(true);
                } else {
                    handleDeleteOffer(productParent);
                }
            } else {
                // Check if componentLinkedRef already exist in the cart
                const mandatoryLinkedRef = cartItems.filter(
                    ({ productParent: cartProdParent, componentReference: cpRef, linkedProduct: linkedP, product: cartProduct }) =>
                        product === cpRef && product !== cartProduct && cartProdParent === productParent && linkedP
                );

                let newCartItems;

                // When removing component from cart => remove linkedComponentRef if exist in the cart

                if (mandatoryLinkedRef && mandatoryLinkedRef.length > 0) {
                    newCartItems = cartItems.filter(
                        ({ product: cp, productParent: cpp }) =>
                            (cp !== product || cpp !== productParent) && !mandatoryLinkedRef.some(({ product: linkedRefP }) => linkedRefP === cp)
                    );
                } else newCartItems = cartItems.filter(({ product: cp, productParent: cpp }) => cp !== product || cpp !== productParent);
                setCartItems(newCartItems);
            }
        } else {
            const newPrice = getProductPriceFromCartItems(price, newQuantity, cartItems);

            const newCartItem = {
                product,
                productParent,
                label,
                description,
                type,
                usageCode: uc,
                quantity: newQuantity,
                price: newPrice,
                showOrder,
                unitCode,
                lpp,
                discount,
                componentReference,
                linkedProduct
            };

            const newCart = getCartWithProgressivity({ ...configOption, quantity: newQuantity }, fullConfig, newQuantity);

            // Verify if there is a link with another components when adding product

            const linkedComponentProduct = fullConfig.filter(
                ({ linkedProduct: linkP, mandatory, componentReference: componentRef, productParent: linkedProductParent }) =>
                    linkP && mandatory && mandatory === "1" && product === componentRef && linkedProductParent === productParent
            );

            const newCartComponentLinked =
                linkedComponentProduct && linkedComponentProduct.length >= 0
                    ? linkedComponentProduct.map((item) => {
                        return {
                            ...item,
                            type: item.componentType && item.componentType === "option" ? "component" : "one-to-n",
                            price: item.price && item.price.length > 0 ? getProductPriceFromCartItems(item.price,item.defaultQuantity, cartItems) : 0,
                            quantity: item.defaultQuantity && item.defaultQuantity >= 0 ? item.defaultQuantity : 1
                        };
                    })
                    : [];

            const existsInCart = newCart.findIndex(({ product: cp, productParent: cpp }) => product === cp && productParent === cpp);

            if (existsInCart < 0) {
                const parentInCart = newCart.find(({ product: pp }) => pp === productParent);
                if (parentInCart) {
                    setCartItems([...newCart, newCartItem, ...newCartComponentLinked]);
                }
            } else {
                setCartItems(
                    newCart
                        .map((cartItem, index) => {
                            if (index === existsInCart) {
                                return {
                                    ...cartItem,
                                    quantity: newQuantity,
                                    price: newPrice
                                };
                            }
                            return cartItem;
                        })
                        .concat(newCartComponentLinked)
                );
            }
        }
    };

    const stepPagesContent = () => {
        if (!proposalValid) return <></>;
        return (
            <>
                {currentStep === "offer" && (
                    <SelectOffer
                        usageCode={usageCode}
                        onAddToCart={handleAddOfferToCart}
                        cartItems={cartItems}
                        onStepChange={handleStepChange}
                        currentStep={stepIndex}
                        setShowSubstitution={setShowSubstitution}
                        setConfigLoaded={setConfigLoaded}
                    />
                )}
                {currentStep === "config" && (
                    <SelectConfig
                        usageCode={usageCode}
                        cartMeta={cartMeta}
                        onUpdateCart={handleUpdateConfigCart}
                        cartItems={cartItems}
                        onAddToCart={handleAddOfferToCart}
                        invoicingOffer={invoicingOffer}
                        setConfigLoaded={setConfigLoaded}
                        checkforSubstitutions={checkforSubstitutions}
                        configLoaded={configLoaded}
                        cartItemsPrevLength={cartItemsPrevLength}
                        clientPivotId={client}
                        cartItemsPrevUpsell={cartItemsPrevUpsell}
                    />
                )}
                {currentStep === "client" && (
                    <SelectClient
                        cartItems={cartItems}
                        cartMeta={cartMeta}
                        setCartMeta={setCartMeta}
                        formValidity={formValidity}
                        setFormValidity={setFormValidity}
                        displayClientList={client || reference}
                        showToast={showToast}
                        setShowToast={setShowToast}
                        showToastMessage={showToastMessage}
                        toastType={toastType}
                        setToastType={setToastType}
                        setShowToastMessage={setShowToastMessage}
                        setShowSubstitution={setShowSubstitution}
                        setUserSusbtitutions={setUserSusbtitutions}
                    />
                )}
                {currentStep === "substitution" && (
                    <SubstitutionPanel
                        cartItems={cartItems}
                        cartMeta={cartMeta}
                        formValidity={formValidity}
                        setFormValidity={setFormValidity}
                        salesDisplay={user.role === roles.sales.role}
                        setUserSusbtitutions={setUserSusbtitutions}
                        userSusbtitutions={userSusbtitutions}
                        notifiyEvolvedMigration={notifiyEvolvedMigration}
                        mode={mode}
                    />
                )}
                {currentStep === "fin" && (
                    <SelectFin
                        cartItems={cartItems}
                        cartMeta={cartMeta}
                        setCartMeta={setCartMeta}
                        setCartItems={setCartItems}
                        formValidity={formValidity}
                        setFormValidity={setFormValidity}
                        mode={mode}
                        showToast={showToast}
                        setShowToast={setShowToast}
                        showToastMessage={showToastMessage}
                        toastType={toastType}
                        usageCode={usageCode}
                        userSusbtitutions={userSusbtitutions}
                        toastDiligencelink={toastDiligencelink}
                    />
                )}
            </>
        );
    };
const updateStepPagesContent =()=>{
    return (
        <>
            {currentStep === "fin" && (
                <SelectFin
                    cartItems={cartItems}
                    cartMeta={cartMeta}
                    setCartMeta={setCartMeta}
                    setCartItems={setCartItems}
                    formValidity={formValidity}
                    setFormValidity={setFormValidity}
                    mode={mode}
                    showToast={showToast}
                    setShowToast={setShowToast}
                    showToastMessage={showToastMessage}
                    toastType={toastType}
                    usageCode={usageCode}
                    userSusbtitutions={userSusbtitutions}
                    notifiyEvolvedMigration={notifiyEvolvedMigration}
                    cgv={hasCgv}
                    toastDiligencelink={toastDiligencelink}
                    setDisableCgv={setDisableCgv}
                />
            )}
                </>
    )
}
    const sepaStepPagesCount = () => {
        return (
            <>
                {currentStep === "order" && (
                    <SelectFin
                        cartItems={cartItems}
                        cartMeta={cartMeta}
                        setCartMeta={setCartMeta}
                        setCartItems={setCartItems}
                        formValidity={formValidity}
                        setFormValidity={setFormValidity}
                        mode={mode}
                        showToast={showToast}
                        setShowToast={setShowToast}
                        showToastMessage={showToastMessage}
                        toastType={toastType}
                        usageCode={usageCode}
                        userSusbtitutions={userSusbtitutions}
                        notifiyEvolvedMigration={notifiyEvolvedMigration}
                        cgv={hasCgv}
                        toastDiligencelink={toastDiligencelink}
                        setDisableCgv={setDisableCgv}
                    />
                )}
                {currentStep === "pay" && <SepaLoading cartMeta={cartMeta} />}
                {currentStep === "recap" && <SepaRecap cartMeta={cartMeta} usageCode={usageCode} />}
            </>
        );
    };

    const modalTitle = (isMobile) => {
        if (reference) {
            if (mode === "sepa") {
                return isMobile ? `${t(`proposals:new-proposal.${currentStep}-mobile`)}` : `${t(`proposals:new-proposal.${currentStep}`)} ${reference}`;
            }

            if (mode === "edit" && isSubcription) {
                return `${t("proposals:new-proposal.title")} - ${t("proposals:new-proposal.edit-subscription")} ${reference}`;
            }

            return `${t("proposals:new-proposal.proposal")} ${reference}`;
        }
        if (currentStep === "substitution" || (currentStep === "fin" && userSusbtitutions && userSusbtitutions.length > 0 && userSusbtitutions.filter((e) => e.checkedValue === true).length > 0)) {
            return `${t("proposals:new-proposal.title")} - ${t("proposals:new-proposal.substitution")} `;
        }
        return `${t("proposals:new-proposal.title")} - ${t("proposals:new-proposal.purchase")} `;
    };

    return (
        <PageWrapper className="new-proposal-page" unscrollable fullPage>
            <PopupWrapper classNamePopup="popup--full-page" visible>
                <main className={`new-proposal ${hasCgv ? "new-proposal--cgv" : ""}`}>
                    <div className="new-proposal__popup">
                        <div className={`new-proposal__header ${mode === "sepa" ? "new-proposal__header--sepa" : ""}`}>
                            <h1 className="new-proposal__title">{modalTitle(false)}</h1>
                            <h1 className="new-proposal__title-mobile">{modalTitle(true)}</h1>
                            <button type="button" className="new-proposal__close" onClick={() => setAbandonPopupVisible(true)}>
                                <Icon name="dialog-close" />
                            </button>
                            <div className={`new-proposal__cart-icon ${mode === "sepa" ? "new-proposal__cart-icon--sepa" : ""}`}>
                                <Icon name="ui-cart-buy" />
                                <span className="badge">{cartItems.length}</span>
                            </div>
                        </div>
                        <div className={`new-proposal__content ${currentStep === "offer" ? "" : "new-proposal__content--background"}`}>
                            {(mode === "new" || mode === "edit") && stepPagesContent()}
                            {mode === "sepa" && sepaStepPagesCount()}
                            {mode === "update" && updateStepPagesContent()}
                        </div>

                        {cartMeta.clientName && (
                            <div className="new-proposal__client new-proposal__cart-radius">
                                <div className="new-proposal__client-avatar">
                                    <Icon name="ui-avatar" />
                                </div>
                                <div className="new-proposal__client-content">
                                    <div className="client-content__name">{cartMeta.clientName}</div>
                                    <div className="client-content__description" />
                                </div>
                            </div>
                        )}

                        <div className={`new-proposal__cart-total ${mode}`}>
                            <CartTotal items={cartItems} total={cartMeta.total} mode={mode} clientPivotId={cartMeta.partnerPivotId || cartMeta.clientPivotId} selectedClient={cartMeta.clientPivotId} />
                        </div>

                        <div className="new-proposal__cart-items">
                            <Cart items={cartItems} onUpdateCart={handleUpdateConfigCart} step={currentStep} mode={mode} invoicingOffer={invoicingOffer} />
                        </div>

                        {hasCgv && (
                            <div className="new-proposal__cgv">
                                <CgvFooter cgv={cgv} onAcceptCgv={handleAcceptCgv} disableCgv={disableCgv} />
                            </div>
                        )}

                        <div className="new-proposal__steps">
                            <NavigationStep
                                currentStep={stepIndex}
                                steps={steps}
                                onStepChange={handleStepChange}
                                onSave={handleSave}
                                onPurchase={handlePurchase}
                                onCancel={isSubcription ? handleQuitCheck : handleAbandon}
                                onClose={isSubcription ? handleQuitCheck : handleQuit}
                                canSave={formValidity.commercialEmailValid && formValidity.administratorEmailValid && formValidity.contactEmailValid}
                                canSubmit={formValidity.commercialEmailValid && formValidity.administratorEmailValid && formValidity.contactEmailValid}
                                mode={mode}
                                apiLoading={apiLoading}
                                usageCode={usageCode}
                            />
                        </div>
                    </div>
                </main>
                <ModalAlert
                    className="new-proposal__alert"
                    visible={checkPopupVisible || abandonPopupVisible}
                    onCancel={checkPopupVisible ? false : () => setAbandonPopupVisible(false)}
                    onAccept={checkPopupVisible || isSubcription ? handleQuitCheck : handleAbandon}
                    title={checkPopupVisible ? t("proposals:modal-alert.edit-subscription") : t("proposals:modal-alert.title")}
                    icon="ui-cart-buy"
                    cancelButtonText={t("proposals:modal-alert.cancel")}
                    acceptButtonText={checkPopupVisible ? t("proposals:modal-alert.ok-edit") : t("proposals:modal-alert.abandon")}>
                    <p>{checkPopupVisible ? t("subscriptions:list-subscriptions-page.no-options-message") : t("proposals:modal-alert.question")}</p>
                </ModalAlert>
                <ModalAlert
                    className="new-proposal__alert"
                    visible={showAlertOrder}
                    onCancel={() => {
                        setShowAlertOrder(false);
                    }}
                    title={t("proposals:modal-alert.title-exist-offer", { offer: offerLabel })}
                    icon="ui-cart-buy"
                    cancelButtonText={t("proposals:modal-alert.cancel-exist-usage")}>
                    <p>{t("proposals:modal-alert.exist-offer-message", { offer: offerLabel })}</p>
                </ModalAlert>
                <ModalAlert
                    className="new-proposal__alert"
                    visible={hasCgv && notifiyEvolvedMigration}
                    onCancel={() => {
                        setDisableCgv(true);
                        setCgv(false);
                        setFormValidity({ ...formValidity, cgvValid: false });
                        setNotifiyEvolvedMigration(false);
                    }}
                    title={t("proposals:modal-alert.title-exist-offer", { offer: offerLabel })}
                    icon="ui-cart-buy"
                    cancelButtonText={t("proposals:modal-alert.ok-edit")}>
                    <p>{t("proposals:select-subtitution.migrationBlocked")}</p>
                </ModalAlert>
                <ModalAlert
                    className="new-proposal__alert"
                    icon="missing"
                    visible={hasProposalError}
                    onClickButton={() => {
                        history.push("/proposals/list");
                    }}
                    title={t("proposals:sepa-loading.title-error")}
                    acceptButtonText="Ok">
                    <>
                        <div className="attachment-list__description">
                            <p>{t("proposals:sepa-loading.message-error")}</p>
                        </div>
                    </>
                </ModalAlert>
                <ModalAlert
                    className="new-proposal__alert"
                    visible={showAlertCriteria}
                    onCancel={() => {
                        setShowAlertCriteria(false);
                    }}
                    title={t("proposals:modal-alert.criteria-title")}
                    icon="ui-cart-buy"
                    cancelButtonText={t("proposals:modal-alert.cancel-exist-usage")}>
                    <p>{t("proposals:modal-alert.criteria-message")}</p>
                </ModalAlert>
            </PopupWrapper>
        </PageWrapper>
    );
};

export default NewProposal;
