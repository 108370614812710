import React, { useEffect, useState } from "react";
import Collapsible from "react-collapsible";
import { getAllOffers, disableOffer } from "../../../domains/proposals/services";
import { FormElements, Lists, Icon, ModalAlert } from "../../components";
import { updateOffersSort } from "../../../domains/proposals/services/proposal.services";
import "./config.scss"
import { useTranslation } from "react-i18next";

const DisableOffer = () => {
    const { t } = useTranslation();
    const [offers, setOffers] = useState([]);
    const [checkedOffers, setCheckedOffers] = useState([]);
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    useEffect(() => {
        getAllOffers().then((res) => {
            setOffers(res.data);
        });
    }, []);
    const handleChangeStatus = (e, row) => {
        offers.forEach((offre) => {
            if (offre.principalProduct === e.target.value && offre.usageCode === row.usageCode) {
                offre.disabled = e.target.checked;
            }
            setCheckedOffers(offers);
        });
    };

  const moveRowDown = (row, offers) => {
    let index = -1;
    for (let i = 0; i < offers.length; i++) {
      if (offers[i].principalProduct === row.principalProduct && offers[i].usageCode === row.usageCode) {
        index = i;
        break;
      }
    }
    if (index === offers.length - 1) return;
    const newData = [...offers];
    newData.splice(index + 1, 0, newData.splice(index, 1)[0]);
    setOffers(newData);
  };

  const moveRowToTop = (row, offers) => {
    let index = -1;
    for (let i = 0; i < offers.length; i++) {
      if (offers[i].principalProduct === row.principalProduct && offers[i].usageCode === row.usageCode) {
        index = i;
        break;
      }
    }
    if (index === 0) return;
    const newData = [...offers];
    newData.splice(index - 1, 0, newData.splice(index, 1)[0]);
    setOffers(newData);
  };

    const disableOffre = () => {
        disableOffer(checkedOffers)
            .then((res) => {
              updateOffersSort(offers).then(() => {
                setShowAlertSuccess(true);
              });         
            })
            .catch(() => {
                setShowAlertSuccess(false);
            });
    };

  const show = () => {
    return true;
  };
  
  const userActions = [
    {
      name: "sort-up",
      type: "button",
      icon: "ui-sort-up",
      ariaLabel: t("users:user-menu.sortedOffersUp"),
      tooltipPosY: "top",
      tooltipPosX: "right",
      onClick: (row) => {
        moveRowToTop(row, offers)
      },
      showCondition: show
    },
    {
      name: "sort-down",
      type: "button",
      icon: "ui-sort-down",
      ariaLabel: t("users:user-menu.sortedOffersDown"),
      tooltipPosY: "top",
      tooltipPosX: "right",
      onClick: (row) => {
        moveRowDown(row, offers)
      },
      showCondition: show
    }
  ]
  const columns = [
    { name: "principalProduct", type: "text", dbRef: "principalProduct", sortBy: "principalProduct", label: t("users:user-menu.reference") },
    { name: "label", type: "text", dbRef: "label", label: t("users:user-menu.offre") },
    { name: "usageCode", type: "text", dbRef: "usageCode", label: t("users:user-menu.usage-code") },
    {
      name: "codeOffer",
      type: "checkbox",
      defaultChecked: "disabled",
      value: "principalProduct",
      label: t("users:user-menu.status-disable"),
      onChange: (e, row) => {
        handleChangeStatus(e, row);
      }
    },
    { name: "actions", type: "actions", actions: userActions }
  ];

  return (
    <div>
      <div className="list-config">
        <Collapsible trigger={t("users:user-menu.title-offer-admin")} className="Collapsible" is-closed>
          <Lists.ListTable columns={[...columns]} actionsCount="3" content={offers} rowKey="offerReference" />
          <FormElements.Button className="button step-navigation__next medium primary" onClick={() => disableOffre(offers)}>
            {t("users:user-menu.offer-admin")}
            <Icon name="ui-uploaded" />
          </FormElements.Button>
        </Collapsible>
      </div>
      <ModalAlert
        visible={showAlertSuccess}
        onCancel={() => {
          setShowAlertSuccess(false);
        }}
        title={t("users:user-menu.success-title")}
        iconConfig="actions-validate"
        cancelButtonText={t("users:user-menu.close")}>
        <p>{t("users:user-menu.successmessage")}</p>
      </ModalAlert>
    </div>
  );
};

export default DisableOffer;