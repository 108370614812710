import React, { useEffect, useState } from "react";
import Collapsible from "react-collapsible";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { deletePromo, getAllPromoSBC, updatePromo, createNewPromo } from "../../../../domains/users/service/user.services";
import { FormElements, Lists, Icon, ModalAlert, PopupWrapper } from "../../../components";
import "./remise.scss";

const PromoSBC = () => {
    const { t } = useTranslation();
    const [promo, setPromos] = useState([]);
    const [isDeletePromo, setIsDeletePromo] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const [promoToDelete, setPromoToDelete] = useState({});
    const [promoToEdit, setPromoToEdit] = useState({});
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [editClick, setEditClick] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [addClick, setAddClick] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const initialFormData = {
        codePromo: "",
        dateValidity: "",
        dateFin: "",
        duration: "",
        opecom: "",
        ticketSn: "",
        description: "",
        valuePromo: "",
        typePromo: "",
        libelle: ""
    };
    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
        let newPromo = [];
        getAllPromoSBC().then((res) => {
            newPromo = res.data.map((promoItem) => {
                const { dateValidity, dateFin, typePromo, opecom, disabledPromo } = promoItem;
                return {
                    ...promoItem,
                    dateValidity: format(new Date(dateValidity), "yyyy-MM-dd").toString(),
                    dateFin: dateFin && dateFin.length > 0 ? format(new Date(dateFin), "yyyy-MM-dd").toString() : "",
                    typePromo: typePromo && typePromo === true ? t("users:user-menu.true-type") : t("users:user-menu.false-type"),
                    opecom: opecom && opecom === true ? t("users:user-menu.true-opecom") : t("users:user-menu.false-opecom"),
                    disabledPromoFormatted: disabledPromo && disabledPromo === true ? t("users:user-menu.disabled") : t("users:user-menu.enabled")
                };
            });
            setPromos(newPromo);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeletePromo, editClick, addClick]);

    const show = () => {
        return true;
    };

    const optionsOpecom = [
        { value: "Tarif négocié", name: t("users:user-menu.true-opecom") },
        { value: "Promo LPC", name: t("users:user-menu.false-opecom") }
    ];
    const optionsType = [
        { value: "Montant", name: t("users:user-menu.true-type") },
        { value: "%", name: t("users:user-menu.false-type") }
    ];
    const optionsDisable = [
        { value: "true", name: t("users:user-menu.disabled") },
        { value: "false", name: t("users:user-menu.enabled") }
    ];
    const handleInputChange = (e) => {
        setPromoToEdit({
            ...promoToEdit,
            [e.target.name]: e.target.value
        });
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    const handleAddPromo = () => {
        setAddClick(true);
    };
    const checkDateValidity = (dateValidity, dateFin, e) => {
        if (dateValidity && dateFin && dateFin < dateValidity) {
            setDateError(true);
        } else {
            setDateError(false);
        }

        handleInputChange(e);
    };
    const validateStartDate = (e) => {
        checkDateValidity(e.target.value, formData.dateFin, e);
    };
    const validateEndDate = (e) => {
        checkDateValidity(formData.dateValidity, e.target.value, e);
    };
    const closeEdit = () => {
        setEditClick(false);
    };
    const closeAdd = () => {
        setAddClick(false);
    };
    const handleReset = () => {
        setAddClick(false);
        setFormData({
            ...formData,
            codePromo: "",
            dateValidity: "",
            dateFin: "",
            duration: "",
            opecom: "",
            ticketSn: "",
            description: "",
            valuePromo: "",
            typePromo: "",
            libelle: ""
        });

        setAddClick(true);
    };
    const handleSavePromo = () => {
        handleReset();
        getAllPromoSBC().then((res) => {
            if (res.data && res.data.length > 0) {
                res.data.forEach((element) => {
                    if (element.codePromo !== formData.codePromo) {
                        createNewPromo(formData).then(() => {
                            setAddClick(false);
                            setShowSuccessMessage(true);
                        });
                    } else {
                        setSubmitError(true);
                    }
                });
            } else {
                createNewPromo(formData).then(() => {
                    setAddClick(false);
                    setShowSuccessMessage(true);
                });
            }
        });
    };
    const handleDelete = () => {
        const { codePromo } = promoToDelete;
        deletePromo(codePromo)
            .then(() => {
                setPromoToDelete({});
                setIsDeletePromo(false);
            })
            .catch(() => {
                setPromoToDelete({});
                setIsDeletePromo(false);
            });
    };
    const handleEdit = () => {
        const { codePromo } = promoToEdit;
        updatePromo(codePromo, promoToEdit)
            .then(() => {
                setPromoToEdit({});
                setEditClick(false);
            })
            .catch(() => {
                setPromoToEdit({});
            });
    };
    const isFormValid = () => {
        return (
            !dateError &&
            formData.codePromo &&
            formData.dateValidity &&
            formData.libelle &&
            formData.opecom &&
            formData.ticketSn &&
            formData.valuePromo &&
            formData.typePromo &&
            formData.description &&
            formData.duration &&
            formData.dateFin
        );
    };
    // const isFormEditValid = () => {
    //     return  formData.libelle && formData.description;
    // };
    const formAddEdit = (editedPromo) => {
        const { dateValidity, dateFin, duration, opecom, typePromo, valuePromo, libelle, description, ticketSn } = editedPromo;

        return (
            <>
                <label className="preview__form-item">
                    <div className="carbon-form__label-wrapper">
                        <div className="carbon-form__label">{`${t("users:user-menu.start-date")} *`}</div>
                    </div>
                    <FormElements.Input
                        maxLength="100"
                        icon="ui-calendar"
                        className="carbon-form__date-input"
                        type="date"
                        name="dateValidity"
                        required
                        onChange={validateStartDate}
                        value={dateValidity}
                        disabled={editClick}
                    />
                </label>

                <label className="preview__form-item">
                    <div className="carbon-form__label-wrapper">
                        <div className="carbon-form__label">{`${t("users:user-menu.end-date")} *`}</div>
                    </div>
                    <FormElements.Input
                        maxLength="100"
                        icon="ui-calendar"
                        className="carbon-form__date-input"
                        type="date"
                        name="dateFin"
                        required
                        onChange={validateEndDate}
                        value={dateFin}
                        disabled={editClick}
                    />
                    {dateError ? <div className="new-date-error">{`${t("proposals:list-proposals-page.date-error")}`}</div> : ""}
                </label>

                <label className="preview__form-item">
                    <div className="carbon-form__label-wrapper">
                        <div className="carbon-form__label">{`${t("users:user-menu.duration")} *`}</div>
                    </div>
                    <FormElements.Input name="duration" type="number" onChange={handleInputChange} value={duration} disabled={editClick} required />
                    {/* eslint-disable-next-line no-restricted-globals */}
                    {isNaN(duration) ? <div className="new-date-error">{`${t("proposals:list-proposals-page.number-verif")}`}</div> : ""}
                </label>

                <label className="preview__form-item">
                    <div className="carbon-form__label-wrapper">
                        <div className="carbon-form__label">{`${t("users:user-menu.opecom")} *`}</div>
                    </div>
                    <FormElements.Select
                        name="opecom"
                        value={opecom}
                        required
                        onChange={handleInputChange}
                        options={optionsOpecom}
                        validator={(value) => value === "true" || value === "false"}
                        disabled={editClick}
                    />
                </label>

                <label className="preview__form-item">
                    <div className="carbon-form__label-wrapper">
                        <div className="carbon-form__label">{`${t("users:user-menu.type")} *`}</div>
                    </div>
                    <FormElements.Select
                        name="typePromo"
                        value={typePromo}
                        options={optionsType}
                        onChange={handleInputChange}
                        validator={(value) => value === "true" || value === "false"}
                        disabled={editClick}
                    />
                </label>
                <label className="preview__form-item">
                    <div className="carbon-form__label-wrapper">
                        <div className="carbon-form__label">{`${t("users:user-menu.value")} *`}</div>
                    </div>
                    <FormElements.Input
                        type="number"
                        name="valuePromo"
                        onChange={handleInputChange}
                        value={valuePromo}
                        validator={(value) => value.length > 0}
                        disabled={editClick}
                    />
                </label>
                <label className="preview__form-item">
                    <div className="carbon-form__label-wrapper">
                        <div className="carbon-form__label">{`${t("users:user-menu.libelle")} *`}</div>
                    </div>
                    <FormElements.Input
                        name="libelle"
                        onChange={handleInputChange}
                        value={libelle}
                        maxLength="38"
                        validator={(value) => value.length > 0 && value.length < 38}
                    />
                </label>
                <label className="preview__form-item">
                    <div className="carbon-form__label-wrapper">
                        <div className="carbon-form__label">{`${t("users:user-menu.description")} *`}</div>
                    </div>
                    <FormElements.Textarea name="description" value={description} onChange={handleInputChange} rows={4} maxLength="130" />
                </label>
                <label className="preview__form-item">
                    <div className="carbon-form__label-wrapper">
                        <div className="carbon-form__label">{`${t("users:user-menu.ticket_sn")} *`}</div>
                    </div>
                    <FormElements.Input
                        name="ticketSn"
                        onChange={handleInputChange}
                        value={ticketSn}
                        maxLength="38"
                        validator={(value) => value.length > 0 && value.length < 38}
                        disabled={editClick}
                    />
                </label>
            </>
        );
    };
    const addPromoPage = () => {
        const { codePromo } = formData;
        return (
            <div className="new-promo__popup">
                <div className="modal-alert__header">
                    <h1 className="modal-alert__title">{t("users:user-menu.add-title")}</h1>
                    <button type="button" className="modal-alert__close" onClick={() => closeAdd()}>
                        <Icon name="dialog-close" />
                    </button>
                </div>

                <div className="contact-form__item">
                    <div className="new-promo__tabs">
                        <label className="preview__form-item">
                            <div className="carbon-form__label-wrapper">
                                <div className="carbon-form__label">{`${t("users:user-menu.remise_code")} *`}</div>
                            </div>
                            <FormElements.Input value={codePromo} name="codePromo" onChange={handleInputChange} validator={(value) => value.length > 0} />
                        </label>
                        {formAddEdit(formData)}
                    </div>
                    <div className=" ">{t("forms.mandatory")}</div>
                </div>
                <div className="modal-alert__footer">
                    <FormElements.Button className="step-navigation__save medium tertiary" onClick={() => closeAdd()}>
                        {t("navigation.cancel")}
                    </FormElements.Button>
                    <FormElements.Button className="step-navigation__save medium primary" onClick={() => handleSavePromo()} disabled={!isFormValid()}>
                        {t("navigation.save")}
                    </FormElements.Button>
                </div>
            </div>
        );
    };

    const editPromoPage = (editedPromo) => {
        const { codePromo, disabledPromo } = editedPromo;
        return (
            <div className="new-promo__popup">
                <div className="modal-alert__header">
                    <h1 className="modal-alert__title">{t("users:user-menu.edit-title")}</h1>
                    <button type="button" className="new-client__close" onClick={() => closeEdit()}>
                        <Icon name="dialog-close" />
                    </button>
                </div>
                <div className="contact-form__item">
                    <div className="new-promo__tabs">
                        <label className="preview__form-item">
                            <div className="carbon-form__label-wrapper">
                                <div className="carbon-form__label">{t("users:user-menu.remise_code")}</div>
                            </div>
                            <FormElements.Input name="codePromo" value={codePromo} disabled />
                        </label>
                        {formAddEdit(promoToEdit)}
                        <label className="preview__form-item">
                            <div className="carbon-form__label-wrapper">
                                <div className="carbon-form__label">{t("users:user-menu.status")}</div>
                            </div>
                            <FormElements.Select
                                name="disabledPromo"
                                value={disabledPromo}
                                required
                                onChange={handleInputChange}
                                options={optionsDisable}
                                validator={(value) => value === "true" || value === "false"}
                            />
                        </label>
                    </div>
                </div>
                <div className="modal-alert__footer">
                    <FormElements.Button className="step-navigation__save medium tertiary" onClick={() => closeEdit()}>
                        {t("navigation.cancel")}
                    </FormElements.Button>
                    <FormElements.Button className="step-navigation__save medium primary" onClick={() => handleEdit(codePromo)}>
                        {t("navigation.save")}
                    </FormElements.Button>
                </div>
            </div>
        );
    };
    const userActions = [
        {
            name: "edit",
            type: "button",
            icon: "ui-edit",
            ariaLabel: t("proposals:list-proposals-page.actions.edit"),
            tooltipPosY: "top",
            tooltipPosX: "right",
            onClick: (row) => {
                setEditClick(true);
                setPromoToEdit(row);
            },
            showCondition: show
        },
        {
            name: "delete",
            type: "button",
            icon: "ui-unlink",
            ariaLabel: t("users:caracteristique.disable"),
            tooltipPosY: "top",
            tooltipPosX: "right",
            onClick: (row) => {
                setPromoToDelete(row);
                setIsDeletePromo(true);
            },
            showCondition: show
        }
    ];

    const columns = [
        { name: "codePromo", type: "text", dbRef: "codePromo", sortBy: "codePromo", label: t("users:user-menu.remise_code") },
        { name: "dateValidity", type: "text", dbRef: "dateValidity", label: t("users:user-menu.start-date") },
        { name: "dateFin", type: "text", dbRef: "dateFin", label: t("users:user-menu.end-date") },
        { name: "duration", type: "text", dbRef: "duration", label: t("users:user-menu.duration") },
        { name: "opecom", type: "text", dbRef: "opecom", label: t("users:user-menu.opecom") },
        { name: "typePromo", type: "text", dbRef: "typePromo", label: t("users:user-menu.type") },
        { name: "valuePromo", type: "text", dbRef: "valuePromo", label: t("users:user-menu.value") },
        { name: "libelle", type: "text", dbRef: "libelle", label: t("users:user-menu.libelle") },
        { name: "description", type: "text", dbRef: "description", label: t("users:user-menu.description") },
        { name: "ticketSn", type: "text", dbRef: "ticketSn", label: t("users:user-menu.ticket_sn") },
        { name: "disabledPromoFormatted", type: "text", dbRef: "disabledPromoFormatted", label: t("users:user-menu.status-disable") },
        { name: "actions", type: "actions", actions: userActions }
    ];

    return (
        <div>
            <div className="list-config">
                <Collapsible trigger={t("users:user-menu.promo-sbc")} className="Collapsible" is-closed>
                    <div className="step-navigation__action">
                        <FormElements.Button className="step-navigation__save medium dashed" onClick={() => handleAddPromo()}>
                            {t("users:user-menu.add")}
                            <Icon name="ui-add" />
                        </FormElements.Button>
                    </div>
                    {addClick && (
                        <PopupWrapper classNamePopup="popup--full-page" visible>
                            <form id="new-client-form" className="new-promo">
                                {addPromoPage()}
                            </form>
                        </PopupWrapper>
                    )}
                    <Lists.ListTable columns={[...columns]} content={promo} actionsCount="2" rowKey="codePromo" />
                    {editClick && (
                        <PopupWrapper classNamePopup="popup--full-page" visible>
                            <form id="new-client-form" className="new-promo">
                                {editPromoPage(promoToEdit)}
                            </form>
                        </PopupWrapper>
                    )}
                </Collapsible>
            </div>
            <ModalAlert
                visible={showAlertSuccess && showSuccessMessage}
                onCancel={() => {
                    setShowAlertSuccess(false);
                }}
                title={t("users:user-menu.success-title")}
                iconConfig="actions-validate"
                cancelButtonText={t("users:user-menu.close")}>
                <p>{t("users:user-menu.successmessage")}</p>
            </ModalAlert>
            <ModalAlert
                className="new-proposal__alert"
                visible={isDeletePromo}
                onCancel={() => {
                    setIsDeletePromo(false);
                    setPromoToDelete({});
                }}
                onAccept={handleDelete}
                title={t("users:user-menu.delete-promo")}
                icon="ui-cart-buy"
                cancelButtonText={t("users:user-menu.cancel")}
                acceptButtonText={t("users:user-menu.delete")}>
                <p>{t("users:user-menu.delete-description")}</p>
            </ModalAlert>
            <ModalAlert
                className="new-proposal__alert"
                visible={submitError}
                onCancel={() => {
                    setSubmitError(false);
                    setAddClick(false);
                }}
                title={t("users:user-menu.error-submit")}
                icon="missing"
                cancelButtonText={t("users:user-menu.close")}>
                <p>{t("users:user-menu.error-description")}</p>
            </ModalAlert>
        </div>
    );
};

export default PromoSBC;
