import React, { useContext } from "react";
import { FormElements, Icon } from "../../../../shared/components";
import { AuthContext } from "../../../../context";
import "./select-interlocutor.scss";

const SelectInterlocutor = (props) => {
    const { permissions } = useContext(AuthContext);

    const {
        proposal: { hiddenFields }
    } = permissions;

    const { value, onChange, label, options, searchOptions, openModal, buttonLabel, handleInterlocutorSearch, disabled, name } = props;

    const className = options.length > 0 ? "full" : "empty";

    const mappedOptions = searchOptions.map(({ email, interlocutorPivotId }) => ({ value: email, name: email, key: interlocutorPivotId }));

    return (
        <div className={`select-interlocutor ${className}`}>
            {options && options.length > 0 && (
                <FormElements.FilterableSelect
                    className="preview-item__admin select-interlocutor__select"
                    name={name}
                    value={value}
                    onChange={onChange}
                    options={mappedOptions}
                    label={label}
                    handleSearch={handleInterlocutorSearch}
                    delay={250}
                    disabled={disabled}
                />
            )}

            {!hiddenFields.includes("create_interlocutor_button") && (
                <FormElements.Button className="select-interlocutor__button medium dashed" onClick={() => openModal(true)}>
                    {options.length <= 0 && buttonLabel}
                    <Icon name="ui-add" />
                </FormElements.Button>
            )}
        </div>
    );
};

export default SelectInterlocutor;
