import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getConfig } from "../../../services";
import { ModalAlert } from "../../../../../shared/components";
import Option from "./option/option";
import "./select-config.scss";

const SelectConfig = (props) => {
    const {
        usageCode,
        cartItems,
        onUpdateCart,
        onAddToCart,
        invoicingOffer,
        setConfigLoaded,
        cartMeta,
        checkforSubstitutions,
        cartItemsPrevLength,
        cartItemsPrevUpsell
    } = props;
    const { t } = useTranslation();
    const history = useHistory();

    const [config, setConfig] = useState([]);
    const [principalProducts, setPrincipalProducts] = useState([]);
    const [sortedPrincipalProducts, setSortedPrincipalProducts] = useState([]);
    const [hasConfigError, setHasConfigError] = useState(false);

    useEffect(() => {
        getConfig(usageCode)
            .then((res) => {
                setConfig(res.data.filter(({ productParent }) => productParent));
                setPrincipalProducts(res.data.filter(({ productParent }) => !productParent));
                setConfigLoaded(true);
            })
            .catch(() => {
                setHasConfigError(true);
            });
    }, [usageCode, setConfigLoaded]);

    useEffect(() => {
        const pp = cartItems.filter(({ productParent }) => !productParent);
        if (cartItemsPrevLength !== pp.length) {
            checkforSubstitutions();
        }
        // eslint-disable-next-line
    }, [cartItems, cartItemsPrevLength]);

    useEffect(() => {
        if (cartItems && cartItems.length > 0 && principalProducts.length > 0) {
            setSortedPrincipalProducts(() =>
                [...principalProducts].sort((a, b) => {
                    const findA = cartItems.findIndex(({ product: cartProduct }) => cartProduct === a.product);
                    const findB = cartItems.findIndex(({ product: cartProduct }) => cartProduct === b.product);
                    if (findA === -1) return 1;
                    if (findB === -1) return -1;
                    return findA - findB;
                })
            );
        }
    }, [cartItems, principalProducts]);

    useEffect(() => {
        const defaultProducts = config.filter(
            ({ defaultQuantity, mandatory, linkedProduct }) =>
                (defaultQuantity >=0 && mandatory && mandatory === "1" )|| (typeof defaultQuantity !== "number" && !linkedProduct && mandatory && mandatory === "1")
        );
        const mandatoryLinkedComponents = config.filter(({ linkedProduct, mandatory }) => linkedProduct && mandatory && mandatory === "1");

        // check if default products are already in cart. If not, add them if the parent is in the cart
        defaultProducts.forEach((defaultProduct) => {
            const { product, productParent, defaultQuantity, optional, mandatory } = defaultProduct;
            const parentInCart = cartItems.find(({ productParent: cpp }) => productParent === cpp);
            
            if (parentInCart) {
                const existsInCart = cartItems.find(({ product: cp, productParent: cpp }) => product === cp && productParent === cpp);

                if ((existsInCart && existsInCart.quantity < defaultQuantity) || !existsInCart) {
                    const type = optional && mandatory ? "component" : "one-to-n";
                    onUpdateCart(defaultProduct, defaultQuantity, type, config);
                }
            }
        });

        // Add mandatoryLinkedComponent to the cart if componentRef is already on the cart
        mandatoryLinkedComponents.forEach((componentLinked) => {
            const { product, componentReference, productParent, defaultQuantity, componentType } = componentLinked;
            const moduleInCart = cartItems.find(({ product: pr, productParent: prP }) => pr === componentReference && prP === productParent);
            const defaultQ = defaultQuantity && defaultQuantity >= 0 ? defaultQuantity : 1;
            if (moduleInCart) {
                const existsInCart = cartItems.find(({ product: cp, productParent: cpp }) => product === cp && productParent === cpp);

                const type = componentType === "module" ? "one-to-n" : "component";
                if ((existsInCart && existsInCart.quantity < defaultQuantity) || !existsInCart) {
                    onUpdateCart(componentLinked, defaultQ, type, config);
                }
            }
        });
    }, [config, cartItems, onUpdateCart]);

    const handleCartUpdateChecks = (configOption, newQuantity, oldQuantity, configType, oldQuantityValue) => {
        const { product, productParent, mandatory, maxQuantity, defaultQuantity, criteria, isOption } = configOption;

        if (configType === "component") {
            // only checks here are the max/default quantity and whether or not it's mandatory
            if (newQuantity >= defaultQuantity && newQuantity <= maxQuantity && isOption && (!mandatory || mandatory === "0" || (mandatory === "1" && newQuantity >= 0))) {
                onUpdateCart(configOption, newQuantity, configType, config);
            }
            if (newQuantity >= defaultQuantity && newQuantity <= maxQuantity && !isOption && (!mandatory || mandatory === "0" || (mandatory === "1" && newQuantity >= 0))) {
                onUpdateCart(configOption, newQuantity, configType, config);
            }
            // Component without quantity
            else if (!defaultQuantity && !maxQuantity) {
                const newQuantityValue = oldQuantityValue && oldQuantityValue !== -1 ? oldQuantityValue : newQuantity;
                onUpdateCart(configOption, newQuantityValue, configType, config);
            }
        } else if (configType === "one-to-n") {
            // get 1 to n list from cart and check criteria            
            const oneToNListCount = cartItems.filter(
                ({ product: cartProd, productParent: cartProdParent, type }) => productParent === cartProdParent && product !== cartProd && type === "one-to-n"
            ).length;

            const newQuantityValue = oldQuantityValue && oldQuantityValue !== -1 ? oldQuantityValue : newQuantity;
            const checkCriteria = newQuantityValue > 0 ? oneToNListCount + 1 : oneToNListCount;

            // When there's none 1 to n config offers, we need to add the principal offer too
            if (oneToNListCount === 0) {                
                const checkPrincipalProduct = principalProducts.filter(({ product: productPrincipal }) => configOption.productParent === productPrincipal);
                const { rank1, rank2 } = configOption.price[0];
                if (checkPrincipalProduct.length > 0) {
                    const newOffer = {
                        principalProduct: checkPrincipalProduct[0].product,
                        principalProductLabel: checkPrincipalProduct[0].label,
                        principalProductDescription: checkPrincipalProduct[0].description,
                        principalProductLpp: checkPrincipalProduct[0].lpp,
                        principalProductUnitCode: "",
                        ppPrice: 0,
                        ppDiscount: checkPrincipalProduct[0].discount,
                        secondaryProduct: configOption.product,
                        secondaryProductLabel: configOption.label,
                        secondaryProductDescription: configOption.description,
                        secondaryProductLpp: configOption.lpp,
                        secondaryProductUnitCode: configOption.unitCode,
                        spPrice: rank1 + rank2,
                        spDiscount: configOption.discount,
                        productOrder: configOption.showOrder,
                        defaultQuantity,
                        usageCode: checkPrincipalProduct[0].usageCode
                    };                    
                    onAddToCart(newOffer);
                }
            }

            if (
                criteria === "0" ||
                (criteria === "1" && checkCriteria >= 1) ||
                (criteria === "2" && checkCriteria <= 1) ||
                (criteria === "3" && checkCriteria === 1) ||
                (!criteria && checkCriteria >= 1)
            ) {
                onUpdateCart(configOption, newQuantityValue, configType, config);
            } else if (checkCriteria < 1) {
                // Delete the whole offer when there's not secondary offers left
                onUpdateCart(configOption, newQuantityValue, configType, config);
            }
        }        
    };

    return (
        <>
            <h2 className="new-proposal__content-title">
                {sortedPrincipalProducts.filter(({ pProduct }) => cartItems.some(({ product: cp }) => pProduct === cp)).length > 1
                    ? t("proposals:select-config.plural-title")
                    : t("proposals:select-config.title")}
            </h2>
            <h3 className="new-proposal__content-sub-title">{t("proposals:select-config.sub-title")}</h3>

            <div className="options-lists-wrapper">
                <div className="options-list">
                    {sortedPrincipalProducts.map((principalProduct) => {
                        const { product: pProduct, label: pLabel } = principalProduct;
                        const { casvente } = cartItems && cartItems.length > 0 && cartItems[0];
                        const configOptions = config.filter((c) => c.productParent === pProduct);
                        let mention = false;

                        configOptions.forEach((configOption) => {
                            if (configOption.criteria === "1" || configOption.criteria === "3") {
                                mention = true;
                            }
                        });
                        const { subscriptionPproduct } = cartMeta;
                        if (subscriptionPproduct && subscriptionPproduct !== pProduct) {
                            return <></>;
                        }
                        if (casvente === "100000001" && cartItemsPrevUpsell) {
                            const item = cartItemsPrevUpsell.find(({ productParent }) => !productParent);
                            if (item) {
                                const { product: pProductss } = item;
                                if (pProductss !== pProduct) return <></>;
                            }
                        }
                        return (
                            <React.Fragment key={pProduct}>
                                <h2 className="options-list__category">{pLabel}</h2>
                                {/* au depart qu'un module possible */}
                                {/* {mention && <p className="options-list__choose">{t("proposals:select-config.choose-one-module")}</p>} */}
                                {invoicingOffer && <p className="options-list__choose">{t("proposals:select-config.disabled-modules")}</p>}

                                {mention}
                                <ul>
                                    {configOptions.map((configOption) => {
                                        const { product: configProduct, productParent: configProductParent } = configOption;

                                        return (
                                            <Option
                                                key={`${configProduct}-${configProductParent}`}
                                                configOption={configOption}
                                                onCartUpdate={handleCartUpdateChecks}
                                                cartItems={cartItems}
                                                invoicingOffer={invoicingOffer}
                                                cartItemsPrevUpsell={cartItemsPrevUpsell}
                                            />
                                        );
                                    })}
                                </ul>
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
            <ModalAlert
                className="new-proposal__alert"
                icon="missing"
                visible={hasConfigError}
                onClickButton={() => {
                    history.push("/proposals/list");
                }}
                title={t("proposals:sepa-loading.title-error")}
                acceptButtonText="Ok">
                <>
                    <div className="attachment-list__description">
                        <p>{t("proposals:sepa-loading.message-error")}</p>
                    </div>
                </>
            </ModalAlert>
        </>
    );
};

export default SelectConfig;
