import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { modifyProposal, getRefusalReasons } from "../../services";
import { FormElements } from "../../../../shared/components";
import "./cart-refuse.scss";

const CartRefuse = (props) => {
    const { content, onSubmit, readOnly, onValid } = props;
    const { t } = useTranslation();
    const { reference, cartRefuseReason, cartRefuseComment } = content;

    const [reasonsList, setReasonsList] = useState([]);
    const [cartReason, setCartReason] = useState({
        reason: cartRefuseReason || "",
        message: cartRefuseComment || ""
    });

    useEffect(() => {
        getRefusalReasons().then((res) => {
            if (res.data && res.data.length > 0) {
                setReasonsList(res.data);
            }
        });
    }, []);

    useEffect(() => {
        if (cartReason.reason && cartReason.reason.length >= 0 && cartReason.message && cartReason.message.length >= 0) {
            onValid(true);
        } else onValid(false);
    }, [onValid, cartReason]);

    const handleChange = (e) => {
        setCartReason({ ...cartReason, [e.target.name]: e.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!readOnly) {
            modifyProposal(reference, "refuse", cartReason).then((res) => {
                if (res.data) {
                    onSubmit();
                }
            });
        }
    };

    return (
        <form id="cart-refuse-form" onSubmit={handleSubmit} className="preview__form">
            <label className="preview__form-item preview__form-item--fullwidth">
                <div className="carbon-form__label-wrapper">
                    <div className="carbon-form__label">{`${t("proposals:cart-refuse.reason")} *`}</div>
                </div>
                <FormElements.Select
                    value={cartReason.reason}
                    onChange={handleChange}
                    name="reason"
                    label={t("proposals:cart-refuse.select-reason")}
                    options={reasonsList}
                    disabled={readOnly}
                    required
                />
            </label>

            <label className="preview__form-item preview__form-item--fullwidth">
                <div className="carbon-form__label-wrapper">
                    <div className="carbon-form__label">{`${t("proposals:cart-refuse.message")} *`}</div>
                </div>

                <FormElements.Textarea rows="6" maxLength="256" name="message" value={cartReason.message} onChange={handleChange} disabled={readOnly}>
                    {t("proposals:cart-refuse.message-for-sage")}
                </FormElements.Textarea>
            </label>
        </form>
    );
};

export default CartRefuse;
