import React from "react";
import { useTranslation } from "react-i18next";
import Card from "carbon-react/lib/components/card";
import CardRow from "carbon-react/lib/components/card/card-row";
import CardColumn from "carbon-react/lib/components/card/card-column";
import { FormElements, Icon } from "../../../../shared/components";
import { getCurrency } from "../../../../shared/utils/string.utils";
import "./card-component.scss";

const CardComponent = (props) => {
    const {
        offer,
        onAddToCart,
        cardHeader = "card__header",
        buttonName = "proposals:select-offer.add",
        iconShow = "message-success-2",
        buttonStyle = "medium secondary",
        usage,
        enable = true,
        message
    } = props;
    const { t } = useTranslation();
    const { offerType, principalProductLabel, secondaryProductLabel, secondaryProductDescription } = offer;
    const { usageCode, ppPrice, spPrice, ppDiscount, spDiscount } = offer;
    const [eur, cents] = usageCode === "US_EQUIPCLI" ? getCurrency(ppPrice * ppDiscount + spPrice * spDiscount) : getCurrency(0);
    return (
        <Card spacing="small" interactive={enable} action={() => onAddToCart(offer)} className="card-compo">
            <div className={cardHeader}>
                <p className="card__offer">{offerType}</p>
                <h2 className="card__offer-name">{principalProductLabel}</h2>
                <h2 className="card__offer-name">
                    {secondaryProductLabel.length > 40 ? `${secondaryProductLabel.slice(0, 35)}${t("...")}` : secondaryProductLabel}
                </h2>
            </div>
            <CardRow className="card__cta">
                <CardColumn align="center">
                    {message && <div className="card_error_message">{t(message)}</div>}
                    <FormElements.Button className={buttonStyle} disabled={!enable} onClick={() => onAddToCart(offer)}>
                        {t(buttonName)}
                    </FormElements.Button>
                </CardColumn>
            </CardRow>
            <CardRow className="cart__description">
                <CardColumn align="left" className="card__row">
                    {(eur !== "0" || cents !== "00") && !usage && (
                        <p className="card__offer-price">
                            <span className="offer-price__amount-euros">{parseFloat(eur).toLocaleString()}</span>
                            <span className="offer-price__amount-cents">{`,${cents}`}</span>
                            {` €/${t("date.month")}`}
                        </p>
                    )}
                    {/* <p className="card__offer-info">{principalProductDescription}</p> */}

                    {secondaryProductDescription && secondaryProductDescription.length > 0 && (
                        <ul className="card__offer-options">
                            {secondaryProductDescription.map((includedOption) => (
                                <li key={includedOption}>
                                    {iconShow && iconShow !== "" && <Icon name={iconShow} />}
                                    {includedOption}
                                </li>
                            ))}
                        </ul>
                    )}
                </CardColumn>
            </CardRow>
        </Card>
    );
};
export default CardComponent;
