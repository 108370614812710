import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getActuMessage } from "../../../domains/users/service/user.services";
import "./actu.scss";

const Actu = () => {
    const { t } = useTranslation();
    const [actusubtitle, setActusubtitle] = useState("");

    const actumessage = useCallback(() => {
        getActuMessage().then((res) => {
            if (res && res.data) {
                const { message } = res.data[0];
                setActusubtitle(message);
            }
        });
    }, []);
    useEffect(() => {
        actumessage();
    }, [actumessage]);

    return (
        <div className="WrapperHome">
            <div className="gridDashboard">
            <div>
                <h2 className="actu__title">{t("actu.title")}</h2>
            </div>
            <div className="actu__card">
                <div className="actu__card-description" dangerouslySetInnerHTML={{ __html: actusubtitle }} />
            </div>
            </div>
        </div>
    );
};

export default Actu;
