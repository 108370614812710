import React from "react";
import { useTranslation } from "react-i18next";
import "./not-found.scss";

const NotFound = () => {
    const { t } = useTranslation();

    return <main className="not-found">{t("not-found.message")}</main>;
};

export default NotFound;
