import { portalAPI } from "../../../config";

const { REACT_APP_FAILOVER } = process.env;

const selectCgu = () => {
    return portalAPI.get("/cgu/");
};
const insertCguInfos = (fileName) => {
    return portalAPI.post(`/cgu/cguInfos/${fileName}`, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};
const uploadCgu = (versionCgu, formdata) => { 
    return portalAPI.post(`/cgu/savecgu/${versionCgu}`,formdata);
};


export {
    insertCguInfos,
    uploadCgu,
    selectCgu
};
