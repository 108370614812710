import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "../../../../shared/components";
import { useHistory } from "react-router-dom";
import ListTable from "../../../../shared/components/lists/list-table/list-table";
import { getAllProposalsDashboard } from "../../../proposals/services";
import { AuthContext, roles } from "../../../../context";
import "./dashboard-proposal.scss";
import DashboardError from "../dashboard-error/dashboard-error";

const DashboardProposal = () => {
    const { t } = useTranslation();
    const { isAuthenticated, user } = useContext(AuthContext);
    const [dashboardTotal, setDashboardTotal] = useState();
    const [dashboardTotalInProgress, setDashboardTotalInProgress] = useState({
        totalProposalsInProgress: 0,
        sumProposalsInProgress: 0
    });
    const [dashboardTotalBloque, setDashboardTotalBloque] = useState({
        totalProposalsBloque: 0,
        sumProposalsBloque: 0
    });
    const [dashboardTotalAccorde, setDashboardTotalAccorde] = useState({
        totalProposalsAccorde: 0,
        sumProposalsAccorde: 0
    });
    const [dashboardTotalClientRefus, setDashboardTotalClientRefus] = useState({
        totalProposalsClientRefus: 0,
        sumProposalsClientRefus: 0
    });
    const [dashboardTotalControl, setDashboardTotalControl] = useState({
        totalProposalsControl: 0,
        sumProposalsControl: 0
    });

    const [updateDate, setUpdateDate] = useState(undefined);
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (isAuthenticated && user) {
            setLoading(true);
            getAllProposalsDashboard({})
                .then((res) => {
                    const { count, sumTotal, proposalsDash } = res.data;
                    if (res.data) {
                        const date = new Date();
                        setUpdateDate(date.toLocaleString());
                        setDashboardTotal({ sumProposals: sumTotal, totalProposals: count });
                        const proposal = proposalsDash;
                        for (let index = 0; index < proposal.length; index += 1) {
                            if (proposal[index].status === "en_cours") {
                                setDashboardTotalInProgress({ sumProposalsInProgress: proposal[index].sum, totalProposalsInProgress: proposal[index].count });
                            } else if (proposal[index].status === "en_controle") {
                                setDashboardTotalControl({ sumProposalsControl: proposal[index].sum, totalProposalsControl: proposal[index].count });
                            } else if (proposal[index].status === "accordee") {
                                setDashboardTotalAccorde({ sumProposalsAccorde: proposal[index].sum, totalProposalsAccorde: proposal[index].count });
                            } else if (proposal[index].status === "bloquee") {
                                setDashboardTotalBloque({ sumProposalsBloque: proposal[index].sum, totalProposalsBloque: proposal[index].count });
                            } else if (proposal[index].status === "refus_client") {
                                setDashboardTotalClientRefus({
                                    sumProposalsClientRefus: proposal[index].sum,
                                    totalProposalsClientRefus: proposal[index].count
                                });
                            }
                        }
                    }
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, [isAuthenticated, user]);
    const redirectProposal = (row) => {
        const { statusLabel } = row;
        history.push(`/proposals/list?status=${statusLabel}`);
    }
    if (loading) return <Loading />;
    const columns = [
        { name: "status", type: "text-button", dbRef: "statut", label: t("users:dashbord-proposal.status"), onClick: (row) => { redirectProposal(row); } },
        {
            name: "number", type: "text-button", dbRef: "number", label: t("users:dashbord-proposal.number"), onClick: (row) => {
                redirectProposal(row);
            }
        },
        { name: "value", type: "text-button", dbRef: "value", label: t("users:dashbord-proposal.value"), onClick: (row) => { redirectProposal(row); } }
    ];
    const contentrows = [{
        number: dashboardTotalInProgress.totalProposalsInProgress.toLocaleString(),
        value: dashboardTotalInProgress.sumProposalsInProgress.toLocaleString("fr-FR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        statut: t("users:dashbord-proposal.inprogress"),
        statusLabel: "en_cours"
    },
    {
        number: dashboardTotalControl.totalProposalsControl.toLocaleString(),
        value: dashboardTotalControl.sumProposalsControl.toLocaleString("fr-FR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        statut: t("users:dashbord-proposal.incontrol"),
        statusLabel: "en_controle"
    },
    {
        number: dashboardTotalAccorde.totalProposalsAccorde.toLocaleString(),
        value: dashboardTotalAccorde.sumProposalsAccorde.toLocaleString("fr-FR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        statut: t("users:dashbord-proposal.accorde"),
        statusLabel: "accordee"
    },
    {
        number: dashboardTotalBloque.totalProposalsBloque.toLocaleString(),
        value: dashboardTotalBloque.sumProposalsBloque.toLocaleString("fr-FR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        statut: t("users:dashbord-proposal.bloque"),
        statusLabel: "bloquee"
    },
    {
        number: dashboardTotalClientRefus.totalProposalsClientRefus.toLocaleString(),
        value: dashboardTotalClientRefus.sumProposalsClientRefus.toLocaleString("fr-FR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        statut: t("users:dashbord-proposal.refus-client"),
        statusLabel: "refus_client"
    }];
    if (user.role !== roles.partner.role) {
        contentrows.splice(4, 1)
    }
    return (
        <div className="dashboard">
            <div className="borderdash">
                <div className="total__header dashbord_header">
                    <strong>{dashboardTotal ? `${t("users:dashbord-proposal.title-dashboard", { totalProposals: dashboardTotal.totalProposals.toLocaleString() })}` : ""}</strong>
                    <div className="margin__total">
                        {dashboardTotal && (<>{`${t("users:dashbord-proposal.total")}`}  <span className="total-title"> {`${dashboardTotal.sumProposals.toLocaleString("fr-FR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} `}</span></>)}
                    </div>
                </div>
                {dashboardTotal ? (<ListTable columns={[...columns]} content={contentrows} rowKey="status" />) : (<DashboardError />)}
                <div className="dashboard__lastUpdate">{`${t("users:last-update")}: ${updateDate}`}</div>
             </div>
        </div>
    );
};

export default DashboardProposal;
