import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getOffers } from "../../../services";
import CardComponent from "../../card-component/card-component";
import { ModalAlert } from "../../../../../shared/components";
import "./select-offer.scss";
import CardMultipleProduct from "../../card-multiple-product/card-multiple-product";

const SelectOffer = (props) => {
    const { onAddToCart, usageCode, cartItems, onStepChange, currentStep, setConfigLoaded } = props;
    const { t } = useTranslation();
    const history = useHistory();

    const [offers, setOffers] = useState([]);
    const [filteredOffers, setFilteredOffers] = useState([]);

    const [groupOffers, setGroupOffers] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [hasOfferError, setHasOfferError] = useState(false);

    useEffect(() => {
        getOffers(usageCode)
            .then((res) => {
                setOffers(res.data);
                setFilteredOffers(res.data);
                setLoaded(true);
                setConfigLoaded(false);
            })
            .catch(() => {
                setHasOfferError(true);
            });
    }, [usageCode, setConfigLoaded]);

    useEffect(() => {
        const groups = [];
        const groupsOffers = [];
        offers.forEach((item) => {
            const { principalProduct } = item;
            const list = groups[principalProduct];
            if (list) list.push(item);
            else groups[principalProduct] = [item];
        });
        Object.entries(groups).forEach(([, value]) => {
            if (value.length > 1) groupsOffers.push(value);
            else groupsOffers.push(value[0]);
        });
        setGroupOffers(groupsOffers);
    }, [offers]);

    useEffect(() => {
        const newFilter = [];
        const filterSelectedOffers = (offer) => {
            const { principalProduct, secondaryProduct } = offer;
            const principalInCart = cartItems.filter(({ product }) => product === principalProduct);
            const secondaryInCart = cartItems.filter(({ product, productParent }) => product === secondaryProduct && productParent === principalProduct);
            return principalInCart.length <= 0 || secondaryInCart.length <= 0;
        };
        groupOffers.forEach((offer) => {
            if (Array.isArray(offer)) {
                const newList = offer.filter((ofer) => filterSelectedOffers(ofer));
                if (newList.length > 0) newFilter.push(newList);
            } else if (filterSelectedOffers(offer)) newFilter.push(offer);
        });
        setFilteredOffers(newFilter);
    }, [groupOffers, cartItems]);

    useEffect(() => {
        // TODO : put filtredOffers.length <= 0 when double subscription generation will be ready and remove cartItems

        if (loaded && filteredOffers.length <= 0) {
            setConfigLoaded(false);
            onStepChange(currentStep + 1);
        }
    }, [currentStep, filteredOffers, loaded, onStepChange, setConfigLoaded]);

    return (
        <>
            {/* <h2 className="new-proposal__content-title">{t("proposals:select-offer.title-provisional")}</h2> */}
            <h2 className="new-proposal__content-title">{t("proposals:select-offer.title")}</h2>
            <h3 className="new-proposal__content-sub-title">{t("proposals:select-offer.sub-title")}</h3>
            <div className="new-proposal__cards-wrapper">
                {filteredOffers.map((offer) => {
                    if (Array.isArray(offer)) {
                        const { secondaryProduct, principalProduct } = offer[0];
                        return <CardMultipleProduct key={`${secondaryProduct}_${principalProduct}`} offer={offer} onAddToCart={onAddToCart} />;
                    }
                    const { secondaryProduct, principalProduct } = offer;
                    return <CardComponent key={`${secondaryProduct}_${principalProduct}`} offer={offer} onAddToCart={onAddToCart} />;
                })}
            </div>
            <ModalAlert
                className="new-proposal__alert"
                icon="missing"
                visible={hasOfferError}
                onClickButton={() => {
                    history.push("/proposals/list");
                }}
                title={t("proposals:sepa-loading.title-error")}
                acceptButtonText="Ok">
                <>
                    <div className="attachment-list__description">
                        <p>{t("proposals:sepa-loading.message-error")}</p>
                    </div>
                </>
            </ModalAlert>
        </>
    );
};

export default SelectOffer;
