import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import App from "./app";
import * as serviceWorker from "./serviceWorker";
import "./config/index";
import "./shared/index.scss";

const tagManagerArgs = {
    gtmId:  process.env.REACT_APP_GTMID
  } 
  TagManager.initialize(tagManagerArgs);

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

serviceWorker.unregister();
