import { useCallback, useState } from "react";

const useDebouncedAction = (callback, delay = 1000) => {
    const [savedTimeout, setSavedTimeout] = useState();

    return useCallback(
        (...args) => {
            if (savedTimeout) {
                clearTimeout(savedTimeout);
            }
            const timeoutToSave = setTimeout(() => {
                callback.apply(null, args);
            }, delay);
            setSavedTimeout(timeoutToSave);
        },
        [callback, delay, savedTimeout]
    );
};

export default useDebouncedAction;
