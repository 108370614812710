import Select from "./select/select";
import Input from "./input/input";
import Textarea from "./textarea/textarea";
import QuantityPicker from "./quantity-picker/quantity-picker";
import Button from "./button/button";
import FilterableSelect from "./filterable-select/filterable-select";
import MultiSelectCompo from "./MultiSelect/multi-select";

const FormElements = { Input, Select, FilterableSelect, Textarea, QuantityPicker, Button, MultiSelectCompo };

export default FormElements;
