import React, { useState } from "react";
import { addInterlocutorsToTiersPivotId } from "../../../users/service/user.services";
import { emailRegex, phoneNumberRegex, nameRegex, NumbersOnlyRegex } from "../../utils/regex";
import Contact from "../../../clients/components/contact/contact";
import "./interlocutor.scss";

const Interlocutor = ({ onAccept, setInterlocutorDetails, enableSubmit, clientPivotId, displaySiretPartner = false, userEmail = "" }) => {
    const [interlocutorMeta, setInterlocutorMeta] = useState({
        civility: "",
        firstName: "",
        lastName: "",
        email: displaySiretPartner ? userEmail : "",
        phone: ""
    });

    const [interlocutorFirstNameError, setInterlocutorFirstNameError] = useState(false);
    const [interlocutorNameError, setInterlocutorNameError] = useState(false);
    const [interlocutorEmailError, setInterlocutorEmailError] = useState(false);
    const [interlocutorPhoneError, setInterlocutorPhoneError] = useState(false);
    const [interlocutorPartnerCodeError, setInterlocutorPartnerCodeError] = useState(false);
    const [interlocutorSiretError, setInterlocutorSiretError] = useState(false);

    const luhnChecksum = (code) => {
        const len = code.length;
        const parity = len % 2;
        let sum = 0;
        for (let i = len - 1; i >= 0; i -= 1) {
            let d = parseInt(code.charAt(i), 10);
            if (i % 2 === parity) {
                d *= 2;
            }
            if (d > 9) {
                d -= 9;
            }
            sum += d;
        }
        return sum % 10;
    };

    const luhnAlgorithm = (fullcode) => {
        return luhnChecksum(fullcode) === 0;
    };

    const interlocutorFields = ["civility", "firstName", "lastName", "email", "phone"];

    const fields = {
        civility: {
            type: "select",
            validator: (value) => value === "100000000" || value === "100000001",
            options: [
                { value: 100000000, name: "M" },
                { value: 100000001, name: "Mme" }
            ]
        },
        email: {
            type: "text",
            validator: (value) => value.length > 0 && emailRegex.test(value),
            errorMessage: true,
            displayError: interlocutorEmailError,
            disabled: displaySiretPartner
        },
        phone: {
            type: "text",
            validator: (value) => value.length > 0 && phoneNumberRegex.test(value),
            errorMessage: true,
            displayError: interlocutorPhoneError
        },
        firstName: {
            type: "text",
            validator: (value) => value.length > 0 && nameRegex.test(value),
            errorMessage: true,
            displayError: interlocutorFirstNameError
        },
        lastName: { type: "text", validator: (value) => value.length > 0 && nameRegex.test(value), errorMessage: true, displayError: interlocutorNameError }
    };

    if (displaySiretPartner) {
        interlocutorFields.push("partnerCode", "siretClient");
        fields.partnerCode = {
            type: "text",
            validator: (value) => value.length === 8 && NumbersOnlyRegex.test(value),
            errorMessage: true,
            displayError: interlocutorPartnerCodeError
        };
        fields.siretClient = {
            type: "text",
            validator: (value) => value.length === 14 && luhnAlgorithm(value),
            errorMessage: true,
            displayError: interlocutorSiretError
        };
    }
    const handleChange = async (e) => {
        let newInfo = interlocutorMeta;
        if (e.target.name === "firstName") {
            if (e.target.value.length <= 1 || nameRegex.test(e.target.value)) {
                setInterlocutorFirstNameError(false);
            } else {
                setInterlocutorFirstNameError(true);
            }
            newInfo = {
                ...interlocutorMeta,
                [e.target.name]: e.target.value.toUpperCase()
            };
        } else if (e.target.name === "lastName") {
            if (e.target.value.length <= 1 || nameRegex.test(e.target.value)) {
                setInterlocutorNameError(false);
            } else {
                setInterlocutorNameError(true);
            }
            newInfo = {
                ...interlocutorMeta,
                [e.target.name]: e.target.value.toUpperCase()
            };
        } else if (e.target.name === "email") {
            if (e.target.value.length === 0 || emailRegex.test(e.target.value)) {
                setInterlocutorEmailError(false);
            } else {
                setInterlocutorEmailError(true);
            }
            newInfo = {
                ...interlocutorMeta,
                [e.target.name]: e.target.value
            };
        } else if (e.target.name === "phone") {
            if (e.target.value.length === 0 || phoneNumberRegex.test(e.target.value)) {
                setInterlocutorPhoneError(false);
            } else {
                setInterlocutorPhoneError(true);
            }
            newInfo = {
                ...interlocutorMeta,
                [e.target.name]: e.target.value
            };
        } else if (e.target.name === "partnerCode") {
            if (e.target.value.length <= 8) {
                if (e.target.value.length === 0 || NumbersOnlyRegex.test(e.target.value)) {
                    setInterlocutorPartnerCodeError(false);
                } else if (e.target.value.length === 8 && NumbersOnlyRegex.test(e.target.value)) {
                    setInterlocutorPartnerCodeError(false);
                } else {
                    setInterlocutorPartnerCodeError(true);
                }
                newInfo = {
                    ...interlocutorMeta,
                    [e.target.name]: e.target.value
                };
            }
        } else if (e.target.name === "siretClient") {
            if (e.target.value.length <= 14) {
                if (e.target.value.length === 0 || fields.siretClient.validator(e.target.value)) {
                    setInterlocutorSiretError(false);
                } else if (e.target.value.length === 14 && fields.siretClient.validator(e.target.value)) {
                    setInterlocutorSiretError(false);
                } else {
                    setInterlocutorSiretError(true);
                }
                newInfo = {
                    ...interlocutorMeta,
                    [e.target.name]: e.target.value
                };
            }
        } else {
            newInfo = {
                ...interlocutorMeta,
                [e.target.name]: e.target.value
            };
        }
        setInterlocutorMeta(newInfo);

        if (displaySiretPartner) {
            if (!("siretClient" in newInfo) || !("partnerCode" in newInfo)) {
                newInfo = {
                    ...interlocutorMeta,
                    siretClient: "",
                    partnerCode: ""
                };
            }
            setInterlocutorDetails(newInfo);
        }
        const formNotValid = Object.entries(newInfo)
            .map(([key, value]) => fields[key].validator(value))
            .includes(false);
        enableSubmit(!formNotValid);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        enableSubmit(false);
        addInterlocutorsToTiersPivotId(clientPivotId, interlocutorMeta).then((res) => {
            const { PivotId } = res.data[0];
            onAccept({ ...interlocutorMeta, interlocutorPivotId: PivotId });
            enableSubmit(true);
        });
    };

    if (displaySiretPartner) {
        return (
            <Contact
                contactMeta={interlocutorMeta}
                contactFields={interlocutorFields}
                fieldsRules={fields}
                handleChange={handleChange}
                i18nPrefix="proposals:select-fin.interlocutor"
            />
        );
    }

    return (
        <form id="interlocutor-form" onSubmit={handleSubmit} className="interlocutor-form">
            <Contact
                contactMeta={interlocutorMeta}
                contactFields={interlocutorFields}
                fieldsRules={fields}
                handleChange={handleChange}
                i18nPrefix="proposals:select-fin.interlocutor"
            />
        </form>
    );
};

export default Interlocutor;
