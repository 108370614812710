import React, { useEffect, useState, useCallback, useRef } from "react";
import useOnClickOutside from "../../../../shared/custom-hook/use-click-outside";
import { getInvoicePopUp } from "../../../users/service/user.services";
import { FormElements } from "../../../../shared/components";
import { useTranslation } from "react-i18next";
import "./invoice-menu-popup.scss";
const PopUpMenuInvoice = (props) => {
    const { openPopUpMenu, setOpenPopUpMenu ,onClose} = props;
    const invoicePopup = useRef();
    const [popupBilling, setPopupBilling] = useState("");
    const { t } = useTranslation();
    useOnClickOutside(invoicePopup, () => setOpenPopUpMenu(false));
    const actumessage = useCallback(() => {
        getInvoicePopUp().then((res) => {
            if (res && res.data) {
                const { message } = res.data[0];
                setPopupBilling(message);
            }
        });
    }, []);
    useEffect(() => {
        if(openPopUpMenu)
        {
            actumessage();
        } 
    }, [actumessage,openPopUpMenu]);
    return (
        <div className={`popup-menu ${openPopUpMenu ? "is-open" : ""}`} ref={invoicePopup}>
            <div className="popup__card">
                <div className="popup__card-description" dangerouslySetInnerHTML={{ __html: popupBilling }} />
            </div>
            <div className="popup__card-close">
                    <FormElements.Button className="button step-navigation__next medium primary" onClick={() => onClose()}>
                        {t("navigation.close")}
                    </FormElements.Button>
                    </div>
        </div>
    );
};

export default PopUpMenuInvoice;
