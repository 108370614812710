import { portalAPI } from "../../../config/axios";

const getProbabilities = () => {
    return portalAPI.get("/proposal/meta/probabilities");
};

const getRefusalReasons = () => {
    return portalAPI.get("/proposal/meta/refusal");
};

const getAllStatus = () => {
    return portalAPI.get("/proposal/meta/status");
};

export { getProbabilities, getRefusalReasons, getAllStatus };
