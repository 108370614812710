import React, { useContext, useEffect } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import queryString from "query-string";
import { AuthContext } from "./auth.context";
import roles from "./roles.json";
import { ErrorPage } from "../../domains/error";
import Loading from "../../shared/components/loading/loading";

const ProtectedRoute = (props) => {
    const { component: Component, history, path, location, entity, action, mode } = props;
    const { isAuthenticated, isLoading, error, getMagicToken, needsRedirect, permissions, user } = useContext(AuthContext);

    const { token, email } = queryString.parse(location.search);

    useEffect(() => {
        if (token && email) {
            history.replace(history.location.pathname);
            getMagicToken(token, email);
        }
    }, [getMagicToken, token, email, history]);

    const isRestricted = () => {
        const { actions } = permissions[entity];

        if (Array.isArray(action)) {
            return !actions.some((r) => action.includes(r));
        }

        return !actions.includes(action);
    };

    const isSanctionPolicyLimited = () => {
        return user.role === roles.partnerLimited.role && user.roleLabel === "tiers_sanction_policy";
    };

    const isBlockedTiersLimited = () => {
        return user.role === roles.partnerLimited.role && user.roleLabel === "blocked_tiers";
    };

    const isDiligenceTiersLimited = () => {
        return user.role === roles.partnerLimited.role && user.roleLabel === "tiers_diligence";
    };

    if (error) {
        return <ErrorPage />;
    }

    if (isLoading && needsRedirect) {
        return <Redirect to={{ pathname: "/", state: { nextLocation: history.location.pathname } }} />;
    }

    if (isLoading || !permissions) {
        return <Loading />;
    }

    if (action && isRestricted() && isSanctionPolicyLimited()) {
        return <Redirect to={{ pathname: "/", state: { status: "sanctionPolicy" } }} />;
    }

    if (action && isRestricted() && isDiligenceTiersLimited()) {
        return <Redirect to={{ pathname: "/", state: { status: "diligenceBlocked" } }} />;
    }

    if (action && isRestricted() && isBlockedTiersLimited()) {
        return <Redirect to={{ pathname: "/", state: { status: "blockedTiers" } }} />;
    }
    if (action && isRestricted()) return <Redirect to={{ pathname: "/" }} />;

    if (isAuthenticated && user && permissions) return <Route path={path} render={() => <Component mode={mode} />} />;

    return <Loading />;
};

export default withRouter(ProtectedRoute);
