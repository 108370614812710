import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./impersonate.scss";
import Select from "../../../../shared/components/form-elements/select/select";

const Impersonate = (props) => {
    const { onSubmit } = props;
    const { t } = useTranslation();

    const [impersonateEmail, setImpersonateEmail] = useState("");

    const impersonationGroups = [
        {
            name: "Interne sage hors crédit client",
            options: [
                { name: "cedric.maes@sage.com", value: "cedric.maes@sage.com" },
                { name: "karine.strikar@sage.com", value: "karine.strikar@sage.com" },
                { name: "charlotte.fee@sage.com", value: "charlotte.fee@sage.com" }
            ]
        },
        {
            name: "Interne Sage du crédit client",
            options: [
                { name: "kader.harouat@sage.com", value: "kader.harouat@sage.com" },
                { name: "mireille.leignadier@sage.com", value: "mireille.leignadier@sage.com" },
                { name: "marie-laure.meunier@sage.com", value: "marie-laure.meunier@sage.com" }
            ]
        },
        {
            name: "Partenaire rattaché à un seul tiers partenaire",
            options: [
                { name: "r.serard@bsi-caen.fr", value: "r.serard@bsi-caen.fr" },
                { name: "frederic.philippon@concept-erp.com", value: "frederic.philippon@concept-erp.com" },
                { name: "axelle.demay@alticap.com", value: "axelle.demay@alticap.com" }
            ]
        },
        {
            name: "Partenaire étant rattaché à plusieurs tiers partenaires",
            options: [
                { name: "antoine.desmarescaux@kardol.fr", value: "antoine.desmarescaux@kardol.fr" },
                { name: "imarmoiton@absyscyborg.com", value: "imarmoiton@absyscyborg.com" },
                { name: "c.jahier@apogea.net", value: "c.jahier@apogea.net" }
            ]
        },
        {
            name: "Partenaire attente adhésion code de conduite et date expirée ",
            options: [{ name: "l.rizzo@afirms.fr", value: "l.rizzo@afirms.fr" }]
        },
        {
            name: "Partenaire Transaction commerciale interdite (Sanction Case 1)",
            options: [{ name: "velasquez@cantv.net", value: "velasquez@cantv.net" }]
        },
        {
            name: "Partenaire Conforme au code de conduite et date valide  ",
            options: [{ name: "adatechsarl@gmail.com", value: "adatechsarl@gmail.com" }]
        },
        {
            name: "Partenaire  Transaction commerciale accordée par Direction Juridique et date expirée (Sanction case 2 )",
            options: [{ name: "m.mamadou@groupels.com", value: "m.mamadou@groupels.com" }]
        },
        {
            name: "Partenaire transaction Transaction commerciale en attente accord Direction Juridique (Sanction Case 2 )",
            options: [{ name: "mohamedali.chouiref@discovery.com.tn", value: "mohamedali.chouiref@discovery.com.tn" }]
        },
        {
            name: "Partenaire transaction commerciale interdite par Direction Juridique (Sanction Case 1 )",
            options: [{ name: "mesianada@hotmail.com", value: "mesianada@hotmail.com" }]
        },
        {
            name: "Partenaire transaction commerciale interdite (Sanction Case 1) et Non conforme au code de conduite ",
            options: [{ name: "stephane.lemasson@test.com", value: "stephane.lemasson@test.com" }]
        }
    ];

    const handleChange = (e) => {
        setImpersonateEmail(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (impersonateEmail.length > 0) {
            onSubmit(impersonateEmail);
        }
    };

    return (
        <form id="impersonate-form" onSubmit={handleSubmit}>
            <Select
                name="impersonate"
                onChange={handleChange}
                optgroups={impersonationGroups}
                required
                label={t("users:impersonate.title")}
                value={impersonateEmail}
            />
        </form>
    );
};

export default Impersonate;
