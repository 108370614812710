import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCurrency } from "../../../../../../shared/utils/string.utils";
import QuantityPicker from "../../../../../../shared/components/form-elements/quantity-picker/quantity-picker";
import Icon from "../../../../../../shared/components/icon-svg/icon";
import { getProductPriceFromCartItems } from "../../../../utils/price.calculator";
import "./option.scss";

const Option = (props) => {
    const { configOption, onCartUpdate, cartItems, invoicingOffer, cartItemsPrevUpsell } = props;
    const {
        label,
        description,
        price,
        maxQuantity,
        defaultQuantity = 0,
        optional,
        mandatory,
        criteria,
        discount,
        product: currentProduct,
        linkedProduct,
        componentReference,
        componentType
    } = configOption;
    const { t } = useTranslation();

    const [cartQuantity, setCartQuantity] = useState(0);
    const [existInCart, setExistInCart] = useState(false);
    useEffect(() => {
        const { product, productParent } = configOption;
        const configOptionInCart = cartItems.filter(
            ({ product: cartProduct, productParent: cartProductParent }) => product === cartProduct && productParent === cartProductParent
        );

        setCartQuantity(configOptionInCart.length > 0 ? configOptionInCart[0].quantity : 0);
        setExistInCart(configOptionInCart.length > 0);
    }, [cartItems, configOption]);

    const getPrice = () => {
        const { productParent } = configOption;
        let newCartQuantity;
        if (productParent === "CSPSA0005") {
            newCartQuantity = defaultQuantity > 0 ? 2 : defaultQuantity;
        } else {
            newCartQuantity = cartQuantity;
        }

        const calculatePrice = getProductPriceFromCartItems(price, Math.max(newCartQuantity, 1), cartItems);

        const [eur, cents] = getCurrency(calculatePrice - calculatePrice * discount);
        if ((defaultQuantity > 0 && cartQuantity <= 1 && productParent === "CSPSA0005") || (eur === "0" && cents === "00")) return "";
        return `${parseFloat(eur).toLocaleString()},${cents} €/${t("date.month")}`;
    };

    const getDescription = () => {
        const { productParent } = configOption;
        if (cartQuantity === 1 && productParent === "CSPSA0005") return "";
        return description[0];
    };

    const getPriceWithDescription = () => {
        const { productParent, unitType } = configOption;
        const priceOfOptions = getPrice();
        if (priceOfOptions === "" && productParent === "CSPSA0005") return "";
        if (productParent === "CSPSA0005") {
            const descriptionCompta = `${priceOfOptions}${t(" - ")}${getDescription()}`;
            return (
                <>
                    <p className="option__description">{descriptionCompta}</p>
                </>
            );
        }
        if (
            productParent === "CSPSA0044" ||
            productParent === "MILCL1056" ||
            productParent === "CSPSA0028" ||
            productParent === "CSPSA0037" ||
            productParent === "CSPSA0041" ||
            productParent === "S5KSA0017"
        ) {
            let descriptionSee = `${priceOfOptions}${t("proposals:select-config.see-label", { unitType: unitType.toLowerCase() })}`;
            if (
                cartQuantity === 0 &&
                (productParent === "CSPSA0044" ||
                    productParent === "MILCL1056" ||
                    productParent === "CSPSA0028" ||
                    productParent === "CSPSA0037" ||
                    productParent === "CSPSA0041" ||
                    productParent === "S5KSA0017")
            ) {
                descriptionSee = priceOfOptions;
            }
            return (
                <>
                    <p className="option__price">{descriptionSee}</p>
                    <p className="option__description">{getDescription()}</p>
                </>
            );
        }
        return (
            <>
                <p className="option__price">{getPrice()}</p>
                <p className="option__description">{getDescription()}</p>
            </>
        );
    };

    let readonlyModule = false;
    let oldQuantityValue = -1;
    if (invoicingOffer && optional === "1" && criteria && maxQuantity > 0) {
        if (cartItemsPrevUpsell) {
            const item = cartItemsPrevUpsell.find((p) => p.product === currentProduct);
            if (item) {
                readonlyModule = true;
                const { quantity: olldquantity } = item;
                oldQuantityValue = olldquantity;
            }
        }
    }

    // Component without maxQuantity
    const componentWithoutQuantity = (maxQuantity && maxQuantity <= 1) || !maxQuantity;

    // Check if component is module or option
    const optionType = componentType === "module" ? "one-to-n" : "component";

    //  Optional with criteria like SEE

    const isModule = componentType === "module";
    // Check if component is linked
    const linkedOption = !!(optionType === "component" && linkedProduct);

    // Search if component_reference is on the cart to enable linkedOption;
    const enableLinkedOption = linkedOption && cartItems.some((item) => item.product === componentReference);

    // Add relationship between componentRef and linked
    const disabledLinkedOption = linkedOption && !enableLinkedOption;

    // If upsell/downsell disable QuantityPicker for mandatory options

    const disableMandatoryUpsell = invoicingOffer && mandatory && mandatory === "1";

    // (!invoicingOffer || maxQuantity ) enable Add/Remove button on upsell for SEE and hide it for Paie & compta

    const enableOrDisableUpsell = !invoicingOffer || maxQuantity;

    // Disable add or remove mandatoryLinkedOption

    const mandatoryLinkedOption = linkedOption && mandatory && mandatory === "1";

    // Mandatory Option

    const mandatoryOption = mandatory && mandatory === "1" && componentType === "option";

    return (
        <li className={`option ${cartQuantity >= 1 ? "option--selected" : ""}`}>
            <h3 className="option__name">{`${label} ${defaultQuantity > 0 ? t("proposals:select-config.by-default", { defaultQuantity }) : ""}`}</h3>
            {getPriceWithDescription()}

            <div className="option__action_btn">
                {/* for one to n products, show buttons */}
                {(isModule || componentWithoutQuantity) &&
                    existInCart &&
                    enableOrDisableUpsell &&
                    !mandatoryOption &&
                    !disabledLinkedOption &&
                    !mandatoryLinkedOption && (
                        <button type="button" className="option__add-remove" onClick={() => onCartUpdate(configOption, -1, cartQuantity, optionType)}>
                            <Icon name="ui-unlink" />
                            <span className="label">{t("proposals:select-config.remove")}</span>
                        </button>
                    )}

                {(isModule || componentWithoutQuantity) && !existInCart && enableOrDisableUpsell && !disabledLinkedOption && !mandatoryLinkedOption && (
                    <button
                        type="button"
                        className="option__add-remove"
                        onClick={() => onCartUpdate(configOption, cartQuantity + 1, cartQuantity, optionType, oldQuantityValue)}>
                        <Icon name="ui-add" />
                        <span className="label">{t("proposals:select-config.add")}</span>
                    </button>
                )}

                {maxQuantity > 0 && !disabledLinkedOption && (
                    <QuantityPicker
                        minQuantity={defaultQuantity || 0}
                        maxQuantity={maxQuantity || 100}
                        readOnly={disableMandatoryUpsell || defaultQuantity === maxQuantity || readonlyModule}
                        changeQuantity={(newQuantity) => onCartUpdate(configOption, newQuantity, cartQuantity, optionType)}
                        quantity={cartQuantity}
                    />
                )}
            </div>
        </li>
    );
};

export default Option;
