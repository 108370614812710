import React from "react";
import { Link } from "react-router-dom";
import Icon from "../../icon-svg/icon";
import "./header-full-mobile.scss";

const HeaderFullMobile = ({ title, children }) => {
    return (
        <header className="full-page-header">
            <Link to={{ pathname: "/" }} className="full-page-header__button">
                <Icon name="dialog-arrow-left" />
            </Link>

            <h1 className="full-page-header__title">{title}</h1>

            {children}
        </header>
    );
};

export default HeaderFullMobile;
