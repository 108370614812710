import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../../context";
import "./profile.scss";
import NewClient from "../../../clients/pages/new-client/new-client";
import { getInterlocutors } from "../../service/user.services";
import { PageWrapper } from "../../../../shared/components";

const Profile = () => {
    const { user } = useContext(AuthContext);
    const history = useHistory();
    const [adminSaas, setAdminSaas] = useState();
    const [showNewClient, setShowNewClient] = useState(true);

    const handleClodeNewClient = () => {
        setShowNewClient(false);
        history.push("/dashboard");
    };

    useEffect(() => {
        getInterlocutors(user.pivotId, { role: 62 }).then((res) => {
            const { data: adminList } = res;
            if (adminList.length > 0) {
                setAdminSaas({
                    administratorEmail: adminList[0].email,
                    administratorFirstName: adminList[0].firstName,
                    administratorLastName: adminList[0].lastName,
                    administratorCivility: adminList[0].civility,
                    administratorTel: adminList[0].phone
                });
            }
        });
    }, [user]);

    return (
        <PageWrapper className="new-proposal-page" unscrollable fullPage>
            {showNewClient && (
                <NewClient updateModal={handleClodeNewClient} clientPivotId={user.pivotId} withTabs={false} withPopup profileAdmin={adminSaas} profile />
            )}
        </PageWrapper>
    );
};

export default Profile;
