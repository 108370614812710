import React, { useEffect, useRef, useState } from "react";
import Icon from "../../icon-svg/icon";
import "./filterable-select.scss";
import Input from "../input/input";
import useOnClickOutside from "../../../custom-hook/use-click-outside";
import useDebouncedAction from "../../../custom-hook/use-debounced-action";

const FilterableSelect = (props) => {
    const { name, value, onChange, handleSearch, options, required = false, className = "", label, disabled, delay } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [displayValue, setDisplayValue] = useState(value);

    useEffect(() => {
        setDisplayValue(value);
    }, [value]);

    const onSelectOptionInternal = (e) => {
        e.preventDefault();
        setDisplayValue(e.target.dataset.value);
        onChange(e.target.dataset.value);
        setIsOpen(false);
    };

    const debouncedSearch = useDebouncedAction((e) => handleSearch(e.target.value), delay);

    const onChangeInternal = (e) => {
        e.preventDefault();
        e.persist();
        setDisplayValue(e.target.value);
        debouncedSearch(e);
    };

    const openDropDown = () => {
        setIsOpen(!isOpen);
    };

    const optionsList = useRef();

    useOnClickOutside(optionsList, () => setIsOpen(false));

    return (
        <div className={`carbon-form__filter-select ${className}`} ref={optionsList}>
            <div className="filterable-input" role="button" tabIndex={0} onClick={openDropDown} onKeyDown={() => {}}>
                <Input
                    placeholder={label}
                    onChange={onChangeInternal}
                    value={displayValue}
                    required={required}
                    disabled={disabled}
                    name={name}
                    disableAutocomplete
                />
                <div className="carbon-form__select-icon">
                    <Icon name="dialog-carret-down" />
                </div>
            </div>
            {options && options.length > 0 && (
                <div className={`filterable-options ${isOpen ? "is-open" : ""}`}>
                    {options.map((option) => (
                        <span
                            role="button"
                            tabIndex={0}
                            className="option"
                            onClick={onSelectOptionInternal}
                            key={option.key || option.value}
                            data-value={option.value}
                            onKeyDown={() => {}}>
                            {option.name}
                        </span>
                    ))}
                </div>
            )}
        </div>
    );
};

export default FilterableSelect;
