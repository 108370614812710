import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "../../../../shared/components";
import { useHistory } from "react-router-dom";
import "./dashboard-invoice.scss";
import ListTable from "../../../../shared/components/lists/list-table/list-table";
import { getAllInvoicesDashboard } from "../../../invoices/service/invoices.services";
import { AuthContext } from "../../../../context";
import { getDateByUTC } from "../../../../shared/utils/string.utils";
import DashboardError from "../dashboard-error/dashboard-error";

const DashboardInvoice = () => {
    const { t } = useTranslation();
    const [dashboardInfo, setDashboardInfo] = useState();
    const { isAuthenticated, user } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const redirectInvoice = (row) => {
        const { statusLabel } = row;
        history.push(`/invoices/list?limit=50&status=${statusLabel}`);
    }
    const columns = [
        { name: "status", type: "text-button", dbRef: "status", label: t("users:dashbord-proposal.status"), onClick: (row) => { redirectInvoice(row); } },
        { name: "number", type: "text-button", dbRef: "number", label: t("users:dashbord-proposal.number"), onClick: (row) => { redirectInvoice(row); } },
        { name: "value", type: "text-button", dbRef: "value", label: t("users:dashbord-proposal.value"), onClick: (row) => { redirectInvoice(row); } }
    ];
    useEffect(() => {
        if (isAuthenticated && user) {
            setLoading(true);
            getAllInvoicesDashboard()
                .then((res) => {
                    setDashboardInfo(res.data);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, [isAuthenticated, user]);

    if (loading) return <Loading />;
    const contentRows = [{
        number: dashboardInfo.current.nb.toLocaleString(),
        value: dashboardInfo.current.total.toLocaleString("fr-FR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        status: t("users:dashboard-invoice.current"),
        statusLabel: "current"
    }, {
        number: dashboardInfo.previous.nb.toLocaleString(),
        value: dashboardInfo.previous.total.toLocaleString("fr-FR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        status: t("users:dashboard-invoice.previous"),
        statusLabel: "previous"
    },
    {
        number: dashboardInfo.unsettled.nb.toLocaleString(),
        value: dashboardInfo.unsettled.total.toLocaleString("fr-FR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        status: t("users:dashboard-invoice.unsettled"),
        statusLabel: "unsettled"
    }];
    return (
        <div className="dashboard">
            <div className="borderdash">
                <div className="total__header dashbord_header">
                    <strong>{t("users:dashboard-invoice.title", { totalFactures: dashboardInfo ? dashboardInfo.all.nb.toLocaleString() : "" })}</strong>
                    <div className="margin__total">
                        {dashboardInfo && (<>{`${t("users:dashboard-invoice.total")}`}  <span className="total-title">{`${dashboardInfo.all.total.toLocaleString("fr-FR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</span></>)}
                    </div>
                </div>
                {dashboardInfo ? (<ListTable columns={[...columns]} content={contentRows} rowKey="status" />) : (<DashboardError />)}
                <div className="dashboard__lastUpdate">{`${t("users:last-update")}: ${getDateByUTC(dashboardInfo.lastUpdate)}`}</div></div>
        </div>
    );
};

export default DashboardInvoice;
