import React from "react";
import { useParams } from "react-router-dom";
import NewProposal from "../../components/new-proposal/new-proposal";

const NewProposalPage = (props) => {
    const { mode } = props;
    const { reference } = useParams();

    return <NewProposal reference={reference} mode={mode} />;
};

export default NewProposalPage;
