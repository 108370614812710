import React from "react";
import "./button.scss";

const Button = (props) => {
    const { children, onClick, className = "", submit = false, disabled = false } = props;

    if (submit) {
        return (
            <button type="submit" className={`button ${className}`} disabled={disabled} form={submit}>
                {children}
            </button>
        );
    }

    return (
        <button type="button" className={`button ${className}`} onClick={onClick} disabled={disabled}>
            {children}
        </button>
    );
};

export default Button;
