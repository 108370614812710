import { proxyPivotAPI } from "../../../config";

//const { REACT_APP_FAILOVER } = process.env;

const getAllInvoices = async (query) => {
    return proxyPivotAPI.get("/invoice", {
        params: {
            ...query
        },
        headers: { "X-BACKEND": "ELK" } // REACT_APP_FAILOVER
    });
};

const downloadInvoicePdf = async (id, linkPdfInvoice) => {
    const param = linkPdfInvoice ? { linkPdf: encodeURIComponent(linkPdfInvoice) } : {};
    return proxyPivotAPI.get(`/invoice/${id}`, {
        params: param,
        responseType: "blob",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf"
        }
    });
};

const exportInvoices = async (query) => {
    return proxyPivotAPI.get("/invoice/export", { headers: { "X-BACKEND": "ELK" }, responseType: "blob", params: { ...query } });
};

const getAllInvoicesDashboard = async (query) => {
    return proxyPivotAPI.get("/elk/invoice/dashboard", {
        params: {
            ...query
        }
    });
};
export { getAllInvoices, downloadInvoicePdf, exportInvoices, getAllInvoicesDashboard };
