import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import CheckboxSubstitution from "../substitution-checkbox/checkboxSubstitution";
import "./select-substitution.scss";

const SelectSubstitution = (props) => {
    const {
        offer,
        salesDisplay,
        userSusbtitutions,
        parcToSubstitute,
        notifiyEvolvedMigration,
        usageCode,
        newProductLabel,
        checkPageValidation,
        validateSubstitution
    } = props;
    const { t } = useTranslation();
    const [displayProrata, setDisplayProrata] = useState(false);
    const [aucunCheckedValue, setAucunCheckedValue] = useState(false);
    const handleSelectAucun = (p) => {
        userSusbtitutions.forEach((obj) => {
            const item = userSusbtitutions.find((e) => e.parcPivotId === obj.parcPivotId && e.offerProduct === p);
            if (item) {
                item.checkedValue = false;
            }
        });
        const ligne = validateSubstitution.find((e) => e.product === p);
        ligne.noSelected = !ligne.noSelected;

        setAucunCheckedValue(!aucunCheckedValue);
        setDisplayProrata(false);
        checkPageValidation();
    };

    const handleSelectParc = (parcPivotId) => {
        const ligne = userSusbtitutions.find((e) => e.parcPivotId === parcPivotId);
        ligne.checkedValue = !ligne.checkedValue;
        if (ligne.checkedValue) {
            const ligneValiadtion = validateSubstitution.find((e) => e.product === ligne.offerProduct);
            ligneValiadtion.noSelected = false;
            setAucunCheckedValue(false);
            setDisplayProrata(true);
        } else if (!userSusbtitutions.some((e) => e.offerProduct === ligne.offerProduct && e.checkedValue === true)) {
            setDisplayProrata(false);
        }

        checkPageValidation();
    };

    useEffect(() => {
        const checkedligne = userSusbtitutions.some((e) => e.offerProduct === offer && e.checkedValue === true);
       if(checkedligne)
       {
        setDisplayProrata(true);
       }else
       {
        setDisplayProrata(false);  
       }
        
     }, [offer,userSusbtitutions]);


    return (
        <>
            {notifiyEvolvedMigration && (
                <div className="substitution__messages">
                    <p>{t("proposals:select-subtitution.migrationEvolved")}</p>
                </div>
            )}

            <Trans
                i18nKey={`proposals:select-subtitution.${!salesDisplay ? "multipleSubMessageVI" : "multipleSubMessageVD"}`}
                parent="div"
                className="substitution__messages"
                values={{ newParcCommercialName: newProductLabel, parcCommercialName: parcToSubstitute }}
            />
            <div className="substitution__messages">
            <p>{t("proposals:select-subtitution.selectionMessage")}</p>
                <CheckboxSubstitution
                    key={`chk${offer}`}
                    handleSelectParc={handleSelectParc}
                    handleSelectAucun={handleSelectAucun}
                    userSusbtitutions={userSusbtitutions}
                    offer={offer}
                    checkPageValidation={checkPageValidation}
                    aucunCheckedValue={aucunCheckedValue}
                />
            </div>
            {salesDisplay && displayProrata && (
               
               <div className="substitution__messages">
                   <p>
                       {t(`proposals:select-subtitution.${offer === "CSPSA0001" && usageCode === "US_EQUIPCLI" ? "prorataPaieVD" : "prorataVD"}`, {
                           newParcCommercialName: newProductLabel
                       })}
                   </p>
               </div>                
            
           )}
            {!salesDisplay && displayProrata && (
               
                <div className="substitution__messages">
                    <p>
                        {t(`proposals:select-subtitution.${offer === "CSPSA0001" && usageCode === "US_EQUIPCLI" ? "confirmPaie" : "confirmMessage"}`, {
                            newParcCommercialName: newProductLabel
                        })}
                    </p>
                </div>                
             
            )}
        </>
    );
};

export default SelectSubstitution;
