import React from "react";
import { useTranslation } from "react-i18next";
import Input from "../../../../../../shared/components/form-elements/input/input";
import Button from "../../../../../../shared/components/form-elements/button/button";
import "./search-client-list.scss";

const SearchClientList = (props) => {
    const { searchValue, handleSearchChange, handleSearch, handleSearchReset } = props;
    const { t } = useTranslation();
    return (
        <div className="search-client__wrapper">
            <div className="search-menu__item">
                <Input
                    name="raisonSociale"
                    icon="ui-search"
                    placeholder={t("clients:client-information.raisonTiers-placeholder")}
                    value={searchValue.raisonSociale}
                    onChange={handleSearchChange}
                />
            </div>

            <div className="search-menu__item">
                <Input
                    name="codeTiers"
                    icon="ui-search"
                    placeholder={t("clients:client-information.codeTiers-placeholder")}
                    value={searchValue.codeTiers}
                    onChange={handleSearchChange}
                />
            </div>

            <div className="search-menu__item">
                <Input
                    name="siret"
                    icon="ui-search"
                    placeholder={t("clients:client-information.siret-placeholder")}
                    value={searchValue.siret}
                    onChange={handleSearchChange}
                />
            </div>

            <Button className="medium primary search-menu__button search-submit" onClick={handleSearch}>
                {t("clients:list-clients-page.search")}
            </Button>

            <Button className="medium secondary search-menu__button search-reset" onClick={handleSearchReset}>
                {t("clients:list-clients-page.reset")}
            </Button>
        </div>
    );
};

export default SearchClientList;
