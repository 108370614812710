const getInitials = (query) => {
    let initials = query.match(/\b\w/g) || [];
    initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();

    return initials;
};

const getCurrency = (amount) => {
    const parsed = parseFloat(parseFloat(amount).toFixed(4));
    const newPrice = parsed.toFixed(2);
    return newPrice.split(".");
};

const getDateByUTC = (seconds) => {
    console.log(seconds);
    let d = new Date(seconds); // The 0 there is the key, which sets the date to the epoch
    return d.toLocaleString();
}
export { getInitials, getCurrency, getDateByUTC };
