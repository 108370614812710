import React, { useContext, useState } from "react";
import { AuthContext } from "../../../context";
import Header from "./header/header";
import Menu from "./menu/menu";
import Footer from "./footer/footer";
import "./page-wrapper.scss";

const PageWrapper = (props) => {
    const { children, className = "", unscrollable, fullPage } = props;

    const [open, setOpen] = useState(false);

    const { isAuthenticated, permissions } = useContext(AuthContext);

    const hasMenuPermission = () => {
        const { actions } = permissions.menu;
        return actions.includes("read");
    };

    const unscrollableClass = unscrollable ? "page-wrapper--unscrollable" : "";
    const fullPageClass = fullPage ? "page-wrapper--full-page" : "";
    const authClass = isAuthenticated && permissions && hasMenuPermission() ? "" : "page-wrapper--unauthenticated";

    return (
        <div className={`page-wrapper ${unscrollableClass} ${fullPageClass} ${authClass} ${open ? "no-scroll" : ""}`}>
            <Header className="page-wrapper__header" open={open} setOpen={setOpen} />

            {isAuthenticated && permissions && hasMenuPermission() && <Menu className="page-wrapper__menu" open={open} setOpen={setOpen} />}

            <div className={`page-wrapper__page ${className}`}>{children}</div>

            <Footer className="page-wrapper__footer" />
        </div>
    );
};

export default PageWrapper;
