import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { Loading, ModalAlert } from "../../../../../shared/components";
import redirectSepa from "../../../assets/images/redirect-sepaa.png";
import { sendSepaConfirmation } from "../../../services/proposal.services";
import "./sepa-loading.scss";

const SepaLoading = (props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const [hasSepaError, setHasSepaError] = useState();

    const { cartMeta } = props;
    const { reference = "" } = cartMeta;

    const { field_shop_key_crypted: callbackToken } = queryString.parse(location.search);

    useEffect(() => {
        if (callbackToken && reference) {
            sendSepaConfirmation(reference, callbackToken)
                .then(() => {
                    localStorage.setItem("x-sepa", callbackToken.toString());
                    history.push(`/proposals/${reference}/sepa?step=recap`);
                })
                .catch(() => {
                    setHasSepaError(true);
                });
        }
    }, [reference, callbackToken, history]);

    return (
        <div className="step-payment">
            <div className="sepa-loading__redirect-img">
                <img src={redirectSepa} alt={t("proposals:sepa-loading.message-waiting")} />
            </div>
            <p className="sepa-loading__message">{t(`proposals:sepa-loading.message-waiting${callbackToken ? "-sepa" : ""}`)}</p>
            <p className="sepa-loading__message">{t(`proposals:sepa-loading.message-redirect${callbackToken ? "-sepa" : ""}`)}</p>
            <Loading />
            <ModalAlert
                className="new-proposal__alert"
                icon="missing"
                visible={hasSepaError}
                onClickButton={() => {
                    history.push("/proposals/list");
                }}
                title={t("proposals:sepa-loading.title-error")}
                acceptButtonText="Ok">
                <>
                    <div className="attachment-list__description">
                        <p>{t("proposals:sepa-loading.message-error")}</p>
                    </div>
                </>
            </ModalAlert>
        </div>
    );
};

export default SepaLoading;
