import React from "react";
import { Trans, useTranslation } from "react-i18next";
import "./compatibility-page.scss";

const CompatibilityPage = () => {
    const { t } = useTranslation();

    return (
        <div className="compatibility-page">
            <h1 className="compatibility-page__title">{t("compatibility-page.title")}</h1>
            <Trans className="compatibility-page__msg" i18nKey="compatibility-page.message" parent="div" />
        </div>
    );
};

export default CompatibilityPage;
