import React from "react";
import homeBackground from "../../assets/images/home-bg.jpg";
import "./home-banner.scss";

const HomeBanner = (props) => {
    const { children } = props;

    return (
        <div className="banner">
            <picture className="banner__background">
                <source media="(max-width: 599px)" srcSet={homeBackground} />
                <source media="(min-width: 600px)" srcSet={homeBackground} width="1920" height="862" />
                <img src={homeBackground} alt="" width="1920" height="862" />
            </picture>
            <div className="banner__message">
                <div className="banner__message-content">{children}</div>
            </div>
        </div>
    );
};

export default HomeBanner;
