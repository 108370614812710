import React, { useEffect, useState, useCallback, useRef } from "react";
import useOnClickOutside from "../../../shared/custom-hook/use-click-outside";
import { getLatestNews } from "../../../domains/users/service/user.services";
import  FormElements  from "../form-elements/";
import { useTranslation} from "react-i18next";
import "./latestnews.scss";

const LatestNews = (props) => {
    const { openPopUpMenu, setOpenPopUpMenu, onClose } = props;
    const [evolsubtitle, setEvolsubtitle] = useState("");
    const latestNewsPopup = useRef();
    const { t } = useTranslation();
    useOnClickOutside(latestNewsPopup, () => setOpenPopUpMenu(false));

    const evolmessage = useCallback(() => {
        getLatestNews().then((res) => {
            if (res && res.data) {
                const { message } = res.data[0];
                setEvolsubtitle(message);
            }
        });
    }, []);
    useEffect(() => {
        if (openPopUpMenu) {
            evolmessage();

        }

    }, [evolmessage, openPopUpMenu]);

    return (
        <>

            <div style={{ overflow: "auto" }} className={`popupNews-menu ${openPopUpMenu ? "is-open" : ""}`} ref={latestNewsPopup}>
                <div className="popup__card">
                    <div className="popup__card-description" dangerouslySetInnerHTML={{ __html: evolsubtitle }} />
                </div>
                <div className="popup__card-close">
                    <FormElements.Button className="button  medium secondary" onClick={() => onClose()}>
                        {t("navigation.close")}
                    </FormElements.Button>
                </div>
            </div></>
    )
}
export default LatestNews;