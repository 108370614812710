import React from "react";
import { Document, Page, Image, Text, StyleSheet, View } from "@react-pdf/renderer";
import { format } from "date-fns";
import logoSage from "../../assets/images/sage_logo.png";
import { getCurrency } from "../../../../shared/utils/string.utils";

const styles = StyleSheet.create({
    page: {
        fontFamily: "Helvetica",
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: "column"
    },
    proposaldetail: {
        fontSize: "9px"
    },
    cartComment: {
        fontSize: "9.3px",
        border: "1px solid #eee"
    },
    module: {
        fontFamily: "Helvetica-Bold",
        fontSize: "12px",
        padding: "10 0 0 4",
        borderBottom: "7px solid black"
    },
    module_quantity: {
        fontFamily: "Helvetica-Bold",
        fontSize: "12px",
        padding: "10 0 0 4",
        borderBottom: "7px solid #00e14b",
        textAlign: "center",
        lineHeight:"0.5em"
    },
    module_quantity_Component: {
        fontSize: "9px",
        borderBottom: "1px solid #eee",
        padding: 8,
        textAlign: "center",
        lineHeight:"0.5em"
    },
    module_total: {
        fontFamily: "Helvetica-Bold",
        fontSize: "12px",
        padding: "10 0 0 4",
        borderBottom: "7px solid #00e14b",
        textAlign: "right",
        lineHeight:"0.5em"
    },
    item: { fontSize: "9px", borderBottom: "1px solid #eee", padding: 8,lineHeight:"0.5em" },
    itemTotal: { fontSize: "9px", borderBottom: "1px solid #eee", padding: 8,lineHeight:"0.5em",fontFamily: "Helvetica-Bold"},
    itemSousTotal:{  fontWeight:"bold", textAlign: "right", fontSize: "9px", borderBottom: "1px solid #eee", padding: 8,lineHeight:"0.5em",fontFamily: "Helvetica-Bold" },
    itemPrice: {  fontWeight:"bold", textAlign: "right", fontSize: "9px", borderBottom: "1px solid #eee", padding: 8,lineHeight:"0.5em" },
    headerdate: {
        fontSize: "8px",
        paddingBottom: 20
    },
    businessCloud: {
        fontFamily: "Helvetica-Bold",
        fontSize: "25px"
    },
    totalsTTC: {
        fontFamily: "Helvetica-Bold",
        fontSize: "12px",
        padding: 8
    },
    totals: {
        fontFamily: "Helvetica",
        fontSize: "11px",
        padding: 8
    },
    totalsPrice: {
        fontFamily: "Helvetica",
        textAlign: "right",
        fontSize: "11px",
        padding: 8
    },
    totalsPriceTTC: {
        fontFamily: "Helvetica-Bold",
        textAlign: "right",
        fontSize: "12px",
        padding: 8
    }
});

const HandleComponents = (childrens) => {
    return childrens.map((p) => {
        const { label, eurPrice, centsPrice,quantity } = p;
        return (
            <View style={{ flex: 1, flexDirection: "row" }}>
                <View style={{ flex: 1 }}>
                    <Text style={styles.item}>{label}</Text>
                </View>
                <View style={{ flex: 1 }}>
                    <Text style={styles.module_quantity_Component}>{quantity}</Text>
                </View>
                <View style={{ flex: 1 }}>
                    <Text style={styles.itemPrice}>{`${eurPrice.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$& ")},${centsPrice} € HT / mois`}</Text>
                </View>
            </View>
        );
    });
};

const HandlePriceModule=(p)=>
{
    const { childrens, productItem } = p;
    const {type ,cart, product: productParent} = productItem;
        if (type === "principal") {
            let priceTotal = 0;
            let totalOneToN =0;
            let priceTotalcomponent =0;
            childrens.forEach((proposall) => {
            const { type: typep, cart :cartp, productParent: productParentp, price, discount: discountp } = proposall;        
            if (productParentp === productParent) {
                if (typep === "one-to-n" && cart === cartp) {
                        totalOneToN = totalOneToN+  parseFloat(price) - parseFloat(price) * discountp     
                    }
                if (productParentp === productParent && typep === "component" && cart === cartp)
                {
                    priceTotalcomponent =priceTotalcomponent+  parseFloat(price) - parseFloat(price) * discountp;
                }                  
            }
        });
        priceTotal= getCurrency(totalOneToN+priceTotalcomponent)
        return ( <>{`${priceTotal.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$& ") } €  HT / mois`}</> );
    }
};
const HandleModules = (moduleslist) => {
    return moduleslist.map((p) => {
        const { childrens, productItem } = p;
        return (
            <>
            <View style={{ flex: 1, flexDirection: "row" }}>
                <View style={{ flex: 1 }}>
                    <Text  style={styles.module}>{productItem.label}</Text>
                </View>  
            </View>
            <View style={{ marginTop:"9px" }}>
                {HandleComponents(childrens)}
                <View style={{ flex: 1, flexDirection: "row" }}>
                <View style={{ flex: 1 }}>
                    <Text style={styles.itemTotal}>Sous total:</Text>
                </View>
                
                <View style={{ flex: 1 }}>
                    <Text style={styles.itemSousTotal}>{HandlePriceModule(p)}</Text>
                </View>
            </View>
            </View>
            </>
        );
    });
};
const HandleTotals = (proposalMeta) => {
    const { eurTotalHT, centsTotalHT, taxe, eurTotalTva, centsTotalTva, eurTotalTTC, centsTotalTTC } = proposalMeta;
    return (
        <>
            <View style={{ flex: 1, flexDirection: "row" }}>
                <View style={{ flex: 1 }}>
                    <Text style={styles.totalsTTC}>Total HT</Text>
                </View>
                <View style={{ flex: 1 }}>
                    <Text style={styles.totalsPriceTTC}>{`${eurTotalHT.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$& ")},${centsTotalHT} €/ mois`}</Text>
                </View>
            </View>
            <View style={{ flex: 1, flexDirection: "row" }}>
                <View style={{ flex: 1 }}>
                    <Text style={styles.totals}>{`TVA ${taxe}%`}</Text>
                </View>
                <View style={{ flex: 1 }}>
                    <Text style={styles.totalsPrice}>{`${eurTotalTva.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$& ")},${centsTotalTva} €/ mois`}</Text>
                </View>
            </View>
            <View style={{ flex: 1, flexDirection: "row" }}>
                <View style={{ flex: 1 }}>
                    <Text style={styles.totalsTTC}>Total TTC</Text>
                </View>
                <View style={{ flex: 1 }}>
                    <Text style={styles.totalsPriceTTC}>{`${eurTotalTTC.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$& ")},${centsTotalTTC} €/ mois`}</Text>
                </View>
            </View>
        </>
    );
};
const ProposaPdf = (props) => {
    const { proposalMeta, productlist } = props;
    const {
        reference,
        clientCode,
        clientName,
        partnerCode,
        partnerName,
        clientReference,
        created,
        statusLabel,
        administratorEmail,
        clientContactEmail,
        commercialEmail,
        validity,
        cartComment,
        opecomlibelle
    } = proposalMeta;
    const datenow = format(new Date(), "dd/MM/yyyy");
    return (
        <Document>
            <Page style={styles.page} size="A4">
                <View style={{}}>
                    <Text style={styles.headerdate}>{`Date d'impression: ${datenow}`}</Text>
                </View>

                
                        <View style={[{display: "flex",alignContent:"center",flexDirection:"row",alignItems:"center"}]}>
                    <View style={[{ height: 35,width:60}]}>
                        <Image source={logoSage} style={{paddingLeft:"1.5",objectFit:"contain"}} />
                    </View>
                    <View style={[{marginLeft:"20%",width: 40}]}>
                        <Text style={styles.businessCloud}>Sage Business Cloud</Text>
                    </View>
                </View>
                <View style={{ paddingTop: 30 }}>
                    <Text style={styles.proposaldetail}>{`Proposition: ${reference}`}</Text>
                    <Text style={styles.proposaldetail}>{`Code client: ${clientCode}`}</Text>
                    <Text style={styles.proposaldetail}>{`Raison sociale client : ${clientName}`}</Text>
                    {partnerCode && <Text style={styles.proposaldetail}>{`Code client revendeur : ${partnerCode}`}</Text>}
                    {partnerName && <Text style={styles.proposaldetail}>{`Raison sociale revendeur : ${partnerName}`}</Text>}
                    {clientReference && <Text style={styles.proposaldetail}>{`Référence : ${clientReference}`}</Text>}
                    <Text style={styles.proposaldetail}>{`Date de création : ${created}`}</Text>
                    <Text style={styles.proposaldetail}>{`Date de validité : ${validity}`}</Text>
                    <Text style={styles.proposaldetail}>{`Statut : ${statusLabel}`}</Text>
                    {opecomlibelle && opecomlibelle.length>0 ? <Text style={styles.proposaldetail}>{`Remise opecom: ${opecomlibelle}`}</Text> :null  }  
                    {cartComment && cartComment.length>0 ? <Text style={styles.proposaldetail}>{`Commentaire :`}</Text> :null  }  
                   {cartComment && cartComment.length>0 ? <Text style={styles.cartComment}>{cartComment}</Text> :null  }  
                    <Text style={styles.proposaldetail}> </Text>
                    <Text style={styles.proposaldetail}>{`Administrateur de la souscription: ${administratorEmail}`}</Text>
                    <Text style={styles.proposaldetail}>{`Interlocuteur du client: ${clientContactEmail}`}</Text>
                    <Text style={styles.proposaldetail}>{`Contact commercial: ${commercialEmail}`}</Text>
                </View>
                <View style={{ minHeight: 80 }}>{HandleModules(productlist)}</View>
                <View style={{}}>{HandleTotals(proposalMeta)}</View>
            </Page>
        </Document>
    );
};
export default ProposaPdf;
