import React, { useState, useContext } from "react";
import { PageWrapper } from "../../components";
import { insertCguInfos, uploadCgu, selectCgu } from "../../../domains/cgu/services/cgu.service";
import { FormElements, Icon, ModalAlert } from "../../components";
import { AuthContext } from "../../../context";
import DisableOffer from "./disable-offer";
import PromoSBC from "./remise/caracteristique-promo";
import RemiseSBC from "./remise/critere-application";
import { useTranslation } from "react-i18next";

import "./config.scss";
const Config = () => {

  const { t } = useTranslation();

  const { user } = useContext(AuthContext);

  const [fileSelected, setFileSelected] = useState();
  const [fileName, setFileName] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const maxSizePdf = 10485760;

  const handleChange = (e) => {
    setFileSelected(e.target.files[0]);
    setIsFilePicked(true);
  }
  const handleChangeName = (e) => {
    setFileName(e.target.value);
  }
  const handleSubmit = (e) => {
    if (isFilePicked && fileName && fileName.length > 0 && fileSelected.type === "application/pdf" && fileSelected.size < maxSizePdf) {
      insertCguInfos(fileName).then((response) => { 
        selectCgu().then((response) => {
          const versionCgu = response.data.map((f) => f.fileVersion);
          const lastVersion = versionCgu ? versionCgu[0] : 0;
          const formdata = new FormData();
          formdata.append('file', fileSelected);
          uploadCgu(lastVersion, formdata).then((res) => {
            setShowAlertSuccess(true);
          })
            .catch((error) => {
              setShowAlertError(true);
            });
        }).catch((error) => {
          setShowAlertError(true);
        });
      })
    }
  }
  return (
    <PageWrapper className="faq-wrapper" fullPage>
      <form className="form-config" encType="multipart/form-data" method="post">
        <div className="heading">
          <h1 className="heading"> {t("users:user-menu.config-title")}</h1>
        </div>
        <div className="list-config">
          <h2 className="faq__section-title">{t("users:user-menu.cgu-title")}</h2>
          <input type="file" accept="application/pdf" name="uploaded_file" onChange={handleChange} className="input-config" />
          {fileSelected && fileSelected.size > maxSizePdf && (<div className="config-error">{t("users:user-menu.error-size")}</div>)}
          {fileSelected && fileSelected.type !== "application/pdf" && (<div className="config-error">{t("users:user-menu.error-type")}</div>)}
          <input type="text" placeholder={t("users:user-menu.filename-placeholder")} name="fileName" onChange={handleChangeName} className="input-config" />
          {!fileName && fileSelected && (<div className="config-error">{t("users:user-menu.error-name")}</div>)}
          <FormElements.Button className="button step-navigation__next medium primary" onClick={handleSubmit} disabled={(fileSelected && fileSelected.type !== "application/pdf") || (fileSelected && fileSelected.size > maxSizePdf) || (!fileSelected && !fileName)} >
            {t("users:user-menu.upload")}
            <Icon name="ui-uploaded" />
          </FormElements.Button>

        </div>
        <div className="offers-config">  {(user && user.superUser && <DisableOffer/>)} </div>
        <div className="offers-config">  {(user && user.superUser && <PromoSBC />)} </div>
        <div className="offers-config">  {(user && user.superUser && <RemiseSBC/>)} </div>
      <ModalAlert
        visible={showAlertSuccess}
        onCancel={() => {
          setShowAlertSuccess(false);
        }}
        title={t("users:user-menu.success-title")}
        iconConfig="actions-validate"
        cancelButtonText={t("users:user-menu.close")}
      >
        <p> {t("users:user-menu.success-message")}</p>
      </ModalAlert>
      <ModalAlert
        visible={showAlertError}
        onCancel={() => {
          setShowAlertError(false);
          setShowAlertSuccess(false);
        }}
        title={t("users:user-menu.success-title")}
        icon="message-warning"
        cancelButtonText={t("users:user-menu.close")}
      >
        <p> {t("users:user-menu.error-cgu")}</p>
      </ModalAlert>
      </form>
    </PageWrapper>
  );
}
export default Config;
