import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../context";
import Icon from "../../../../shared/components/icon-svg/icon";
import "./user-menu.scss";

const UserMenu = (props) => {
    const { openUserMenu, setOpenUserMenu } = props;
    const { logout, user, persona, permissions, setVisibleImpersonate } = useContext(AuthContext);
    const { t } = useTranslation();

    const {
        impersonate: { hiddenFields },
        profile: { hiddenFields: hiddenFieldsProfile }
    } = permissions;

    return (
        <div className={`user-menu ${openUserMenu ? "is-open" : ""}`}>
            {/* User infos */}
            <div className="user-menu__user">
                <button type="button" className="full-page-header__button" onClick={() => setOpenUserMenu(!openUserMenu)}>
                    <Icon name="dialog-arrow-left" />
                </button>
                <div className="user-menu__avatar">{user.initials}</div>
                <div>
                    <h2 className="user-menu__username">{user.name}</h2>
                    <p className="user-menu__email">{user.email}</p>
                    {persona && <p className="user-menu__role">{t("users:user-menu.impersonate", { impersonate: persona })}</p>}
                    {user.tiersName && <p className="user-menu__role">{t("users:user-menu.tiersName", { tiersName: user.tiersName })}</p>}
                    {user.tiersCode && <p className="user-menu__role">{t("users:user-menu.tiersCode", { tiersCode: user.tiersCode })}</p>}
                    <p className="user-menu__status">
                        <span className="user-menu__status-indicator user-menu__status-indicator--online" />
                        {t("users:user-menu.available")}
                    </p>
                </div>
            </div>

            {/* Links */}
            <ul>
                {!hiddenFieldsProfile.includes("profile_link") && (
                    <li>
                        <Link to="/profile" className="user-menu__link">
                            <div className="user-menu__link-icon">
                                <Icon name="ui-infos" />
                            </div>
                            <span className="user-menu__link-label">{t("users:user-menu.my-account")}</span>
                            <Icon name="dialog-chevron-right" className="user-menu__link-chevron" />
                        </Link>
                    </li>
                )}
                <li>
                    <a href="https://account.sso.sage.com/#/linked-accounts" className="user-menu__link">
                        <div className="user-menu__link-icon">
                            <Icon name="ui-security" />
                        </div>
                        <span className="user-menu__link-label">{t("users:user-menu.security-options")}</span>
                        <Icon name="dialog-chevron-right" className="user-menu__link-chevron" />
                    </a>
                </li>
                {user && (user.adminRole || user.superUser) &&
                    <li>
                        <Link to="/config" className="user-menu__link">
                            <div className="user-menu__link-icon">
                                <Icon name="ui-infos" />
                            </div>
                            <span className="user-menu__link-label">{t("users:user-menu.config-menu-title")}</span>
                            <Icon name="dialog-chevron-right" className="user-menu__link-chevron" />
                        </Link>
                    </li>
                }                   
                {!hiddenFields.includes("impersonate_menu") && (
                    <li>
                        <button type="button" onClick={() => setVisibleImpersonate(true)} className="user-menu__link user-menu__link--separator">
                            <div className="user-menu__link-icon">
                                <Icon name="ui-login" />
                            </div>
                            <span className="user-menu__link-label">
                                <em>{t("users:impersonate.title")}</em>
                            </span>
                            <Icon name="dialog-chevron-right" className="user-menu__link-chevron" />
                        </button>
                    </li>
                )}
                <li>
                    <button type="button" onClick={() => logout()} className="user-menu__link">
                        <div className="user-menu__link-icon">
                            <Icon name="ui-logout" />
                        </div>
                        <span className="user-menu__link-label">
                            <strong>{t("users:user-menu.logout")}</strong>
                        </span>
                        <Icon name="dialog-chevron-right" className="user-menu__link-chevron" />
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default UserMenu;
