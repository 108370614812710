import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { AuthContext } from "../../../../context";
import useOnClickOutside from "../../../custom-hook/use-click-outside";
import UserMenu from "../../../../domains/users/components/user-menu/user-menu";
import Icon from "../../icon-svg/icon";
import "./menu.scss";

const Menu = (props) => {
    const { className = "", open, setOpen } = props;
    const [openUserMenu, setOpenUserMenu] = useState(false);
    const { t } = useTranslation();
    const { isAuthenticated, user } = useContext(AuthContext);
    const mainMenu = useRef();

    const links = [
        {
            name: "dashboard",
            link: "/dashboard",
            icon: "ui-home"
        },
        {
            name: "clients",
            link: "/clients/list",
            icon: "ui-clients"
        },
        {
            name: "proposals",
            link: "/proposals/list",
            icon: "ui-document"
        },
        {
            name: "subscriptions",
            link: "/subscriptions/list",
            icon: "ui-cloud"
        },
        {
            name: "invoices",
            link: "/invoices/list",
            icon: "ui-invoice"
        }
    ];

    useOnClickOutside(mainMenu, () => setOpen(false));

    return (
        <div className={`main-menu-wrapper ${className} ${open ? "is-open" : ""}`}>
            <div className="main-menu-overlay" />

            <div className={`main-menu ${open ? "is-open" : ""}`} ref={mainMenu}>
                {isAuthenticated && user && (
                    <div className="main-menu__user">
                        <div className="main-menu__user-avatar">{user.initials}</div>
                        <button type="button" className="main-menu__user-name" onClick={() => setOpenUserMenu(!openUserMenu)}>
                            {user.name}
                            <Icon name="dialog-chevron-right" />
                        </button>
                        <p className="main-menu__user-position">{user.roleLabel}</p>
                    </div>
                )}

                {/* Navigation links */}
                <ul className="main-nav">
                    {links.map(({ name, link, icon }) => {
                        return (
                            <li className={`main-nav__${name}`} key={name}>
                                <NavLink to={link} className="main-nav__link" activeClassName="is-active">
                                    <div className="main-nav__link-icon">
                                        <Icon name={icon} />
                                    </div>
                                    <span className="main-nav__link-label">{t(`menu.${name}`)}</span>
                                    <Icon name="dialog-chevron-right" className="main-nav__link-chevron" />
                                </NavLink>
                            </li>
                        );
                    })}
                    <li className="main-nav__hub">
                        <Link to="/faq" className="main-nav__link" target="_blank" rel="noopener noreferrer">
                            <div className="main-nav__link-icon">
                                <Icon name="ui-help" />
                            </div>
                            <span className="main-nav__link-label">{t("menu.faq")}</span>
                            <Icon name="ui-external-link" className="main-nav__link-chevron" />
                        </Link>
                    </li>
                    <li className="main-nav__hub">
                        <a
                            href="https://www.sage-partner-marketing-hub.com/portal/auth/login"
                            className="main-nav__link"
                            target="_blank"
                            rel="noopener noreferrer">
                            <div className="main-nav__link-icon">
                                <Icon name="services-helpdesk" />
                            </div>
                            <span className="main-nav__link-label">{t("menu.hub")}</span>
                            <Icon name="ui-external-link" className="main-nav__link-chevron" />
                        </a>
                    </li>
                    <li className="main-nav__support">
                        <a href="mailto:contact-SBC@sage.com" className="main-nav__link">
                            <div className="main-nav__link-icon">
                                <Icon name="ui-customer-support" />
                            </div>
                            <span className="main-nav__link-label">{t("menu.support")}</span>
                            <Icon name="ui-mail" className="main-nav__link-chevron" />
                        </a>
                    </li>
                </ul>

                {/* User menu */}
                <UserMenu openUserMenu={openUserMenu} setOpenUserMenu={setOpenUserMenu} />
            </div>
        </div>
    );
};

export default Menu;
