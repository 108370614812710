import i18next from "../../config/i18next";
import i18nUsers from "./i18n/fr.json";
import Dashboard from "./pages/dashboard/dashboard";
import Impersonate from "./components/impersonate/impersonate";
import DashboardProposal from "./components/dashboard-proposal/dashboard-proposal";

i18next.addResourceBundle("fr", "users", i18nUsers);

const Users = { Dashboard, Impersonate, DashboardProposal };

export default Users;
