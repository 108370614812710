import React from "react";
import { useTranslation } from "react-i18next";
import "./error-page.scss";

const ErrorPage = () => {
    const { t } = useTranslation();

    return <main className="error-page">{t("error.message")}</main>;
};

export default ErrorPage;
