import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { format } from "date-fns";
import { AuthContext, roles } from "../../../../context";
import { getAllClients, checkTiers, exportTiers, getFilialList } from "../../services/client.services";
import { PageWrapper, Lists, Loading, SearchMenu, Toast, FormElements, Downloading } from "../../../../shared/components";

import checkTiersValidity from "../../utils/tiers.validator";
import Icon from "../../../../shared/components/icon-svg/icon";
import NewClient from "../new-client/new-client";
import "./list-clients-page.scss";

const ListClientsPage = () => {
    const { isAuthenticated, user, permissions } = useContext(AuthContext);
    const [openSearchMenu, setOpenSearchMenu] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const { page = "0", raisonSociale = "", codeTiers = "", etablissement, clients } = queryString.parse(location.search);
    const currentPage = parseInt(page, 10);
    const pageSize = 50;

    const [clientsList, setClientsList] = useState([]);
    const [totalClients, setTotalClients] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showToastMessage, setShowToastMessage] = useState("");
    const [toastType, setToastType] = useState("");
    const [clientFilter, setClientFilter] = useState(clients);
    const [toastDiligencelink, setToastDiligencelink] = useState(false);
    const [queryParam, setQueryParam] = useState({
        codeTiers,
        raisonSociale,
        etablissement
    });

    const [searchValue, setSearchValue] = useState({
        codeTiers,
        raisonSociale
    });
    const [showSearchRaison, setSearchShowRaison] = useState(true);
    const [showSearchCodeTiers, setSearchShowCodeTiers] = useState(false);

    const [showClientDetail, setShowClientDetails] = useState(false);
    const [selectedClient, setSelectedClient] = useState();
    const [disableExport, setDisableExport] = useState(false);
    const [establishmentList, setEstablishmentList] = useState([]);
    const [isDownloading, setIsDownloading] = useState(false);
    const [clientListOptions] = useState([{ name: "Avec souscription" }, { name: "Sans souscription" }]);
    const cleanQueryParams = (params) => {
        return Object.fromEntries(Object.entries(params).filter(([, v]) => v && v.toString().length >= 0));
    };

    useEffect(() => {
        if (isAuthenticated && user) {
            setLoading(true);
            const { etablissement: selectedEtablissement } = queryParam;
            if (!clientFilter) {
                getAllClients({
                    index: pageSize * currentPage,
                    limit: pageSize,
                    showFilials: !(typeof selectedEtablissement === "string" && selectedEtablissement.length > 0),
                    ...queryParam
                })
                    .then((res) => {
                        if (res.data.clientsList) {
                            setClientsList(res.data.clientsList);
                            setTotalClients(res.data.countTotal);
                            setLoading(false);
                        }
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            } else if (clientFilter === "Avec souscription") {
                getAllClients({
                    filter: "onesub",
                    index: pageSize * currentPage,
                    limit: pageSize,
                    showFilials: !(typeof selectedEtablissement === "string" && selectedEtablissement.length > 0),
                    ...queryParam
                }).then((res) => {
                    if (res.data.clientsList) {
                        setClientsList(res.data.clientsList);
                        setTotalClients(res.data.count);
                        setLoading(false);
                    }
                });
            } else {
                getAllClients({
                    filter: "nosub",
                    index: pageSize * currentPage,
                    limit: pageSize,
                    showFilials: !(typeof selectedEtablissement === "string" && selectedEtablissement.length > 0),
                    ...queryParam
                }).then((res) => {
                    if (res.data.clientsList) {
                        setClientsList(res.data.clientsList);
                        setTotalClients(res.data.count);
                        setLoading(false);
                    }
                });
            }
        }
    }, [isAuthenticated, user, currentPage, queryParam, clientFilter]);

    useEffect(() => {
        const params = queryString.stringify(cleanQueryParams({ ...queryParam, clients: clientFilter }));
        history.push(`/clients/list?${params}`);
    }, [user, queryParam, history, clientFilter]);

    useEffect(() => {
        if (user && user.tiersParent && user.role === roles.partner.role) {
            getFilialList().then((res) => {
                const etablissementList = res.data;
                etablissementList.unshift({ name: `${user.tiersName} (${t("proposals:list-proposals-page.tiers-parent")})`, value: user.pivotId });
                setEstablishmentList(etablissementList);
            });
        }
    }, [user, t]);

    const changePage = (pageNumber) => {
        const params = queryString.stringify(cleanQueryParams({ ...queryParam, page: pageNumber }));
        history.push(`/clients/list?${params}`);
    };

    const handleResetSearch = () => {
        history.push("/clients/list");
        setQueryParam({
            codeTiers: "",
            raisonSociale: ""
        });
        setSearchValue({
            codeTiers: "",
            raisonSociale: ""
        });
    };

    const handleSearch = () => {
        const { codeTiers: code, raisonSociale: raison } = searchValue;
        history.push(`/clients/list?codeTiers=${code}&raisonSociale=${raison}&clients=${clientFilter}`);
        setOpenSearchMenu(false);
        setTotalClients(0);
        setQueryParam({
            ...queryParam,
            ...searchValue
        });
    };

    const handleChangeFilial = (e) => {
        setQueryParam({
            ...queryParam,
            etablissement: e.target.value,
            page: 0
        });
    };

    const handleChangeClientsSub = (e) => {
        setClientFilter(e.target.value);
        setQueryParam({
            codeTiers: "",
            raisonSociale: ""
        });
        setSearchValue({
            codeTiers: "",
            raisonSociale: ""
        });
        history.push(`/clients/list?clients=${e.target.value}`);
    };

    const handleSearchCheckboxChange = (e) => {
        if (e.target.name === "raison-checkbox") {
            if (showSearchRaison) {
                setSearchValue({
                    ...searchValue,
                    raisonSociale: ""
                });
            }
            setSearchShowRaison(!showSearchRaison);
        } else {
            if (showSearchCodeTiers) {
                setSearchValue({
                    ...searchValue,
                    codeTiers: ""
                });
            }
            setSearchShowCodeTiers(!showSearchCodeTiers);
        }
    };

    const handleSearchChange = (e) => {
        setSearchValue({
            ...searchValue,
            [e.target.name]: e.target.value
        });
    };

    const getCartLink = (row) => {
        const { pivotId } = row;
        if (user.role === roles.partnerLimited.role) {
            if (user.roleLabel === "tiers_sanction_policy") {
                setShowToast(true);
                setToastType("error");
                setShowToastMessage(t("clients:tiers.sanctionPolicy-limited"));
            }
            if (user.roleLabel === "tiers_diligence") {
                setShowToast(true);
                setToastType("error");
                setToastDiligencelink(true);
                setShowToastMessage(t("clients:tiers.dueDiligence"));
            } else {
                setShowToast(true);
                setToastType("error");
                setShowToastMessage(t("clients:tiers.tiersBlocked-limited"));
            }
        } else {
            checkTiers(pivotId).then((res) => {
                const { access, criteria } = res.data;
                const tiersValidity = checkTiersValidity(
                    access,
                    criteria,
                    setShowToast,
                    setToastType,
                    setShowToastMessage,
                    true,
                    undefined,
                    setToastDiligencelink
                );
                if (tiersValidity) {
                    history.push(`/proposals/new?client=${pivotId}`);
                }
            });
        }
    };

    const getEmailLink = (row) => {
        const { email } = row;
        return `mailto: ${email}`;
    };

    const {
        clients: { actions, hiddenFields }
    } = permissions;

    const userActions = [
        {
            name: "subscription",
            type: "link",
            icon: "ui-cloud",
            ariaLabel: t("clients:list-clients-page.actions.subscription"),
            tooltipPosY: "top",
            tooltipPosX: "right",
            link: () => "",
            showCondition: ({ saas }) => saas && actions.includes("subscription")
        },
        {
            name: "proposal",
            type: "button",
            icon: "ui-cart-add",
            ariaLabel: t("clients:list-clients-page.actions.proposal"),
            tooltipPosY: "top",
            tooltipPosX: "right",
            onClick: getCartLink,
            showCondition: ({ saas }) => !saas && actions.includes("proposal")
        },
        {
            name: "mailto",
            type: "link",
            icon: "ui-mail",
            link: getEmailLink,
            ariaLabel: t("clients:list-clients-page.actions.mailto"),
            tooltipPosY: "top",
            tooltipPosX: "right",
            showCondition: ({ email }) => email && actions.includes("mailto")
        }
    ].filter(({ name }) => actions.includes(name));

    const columns = [
        {
            name: "code",
            type: "text-button",
            dbRef: "tiersCode",
            label: t("clients:list-clients-page.code"),
            onClick: (row) => {
                const { pivotId } = row;
                setSelectedClient(pivotId);
                setShowClientDetails(true);
            }
        },
        { name: "name", type: "text", dbRef: "tiersName", label: t("clients:list-clients-page.name") },
        { name: "siret", type: "text", dbRef: "siret", label: t("clients:list-clients-page.siret") },
        { name: "zipCode", type: "text", dbRef: "zipCode", label: t("clients:list-clients-page.zipCode") },
        { name: "city", type: "text", dbRef: "city", label: t("clients:list-clients-page.city") },
        { name: "email", type: "text", dbRef: "email", label: t("clients:list-clients-page.email") },
        { name: "phone", type: "text", dbRef: "phone", label: t("clients:list-clients-page.phone") },
        { name: "actions", type: "actions", actions: userActions }
    ].map((column) => ({ ...column, showCondition: () => !hiddenFields.includes(column.dbRef) }));

    const filters =
        user &&
        user.tiersParent &&
        establishmentList.length > 1 &&
        [
            {
                key: "establishment-filter",
                label: t("proposals:list-proposals-page.filial-list"),
                options: establishmentList,
                className: "establishment-filter",
                handler: handleChangeFilial,
                value: etablissement
            }
        ].filter(({ key }) => actions.includes(key));

    const filtersClient = [
        {
            key: "client-filter",
            label: t("proposals:list-proposals-page.clients-list"),
            options: clientListOptions,
            className: "establishment-filter",
            handler: handleChangeClientsSub,
            value: clientFilter
        }
    ];
    const handleExport = () => {
        setIsDownloading(true);
        setDisableExport(true);
        const { etablissement: selectedEtablissement } = queryParam;
        const filter = {
            showFilials: !(typeof selectedEtablissement === "string" && selectedEtablissement.length > 0)
        };
        if (clientFilter && clientFilter === "Avec souscription") {
            filter.filter = "onesub";
        } else if (clientFilter && clientFilter === "Sans souscription") {
            filter.filter = "nosub";
        }
        exportTiers({ ...queryParam, ...filter }).then((res) => {
            const { data } = res;
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
            const link = document.createElement("a");
            link.download = `clients-${format(new Date(), "dd/MM/yyyy")}.xlsx`;
            link.href = URL.createObjectURL(blob);
            document.body.append(link);
            link.click();
            link.remove();
            setDisableExport(false);
            setIsDownloading(false);
        });
    };

    if (loading) {
        return (
            <PageWrapper className="list-clients-page" fullPage>
                <Loading />
            </PageWrapper>
        );
    }

    return (
        <PageWrapper className="list-clients-page" fullPage>
            <Lists.HeaderFullMobile title={t("clients:list-clients-page.title-mobile")}>
                <FormElements.Button className="full-page-header__button" onClick={() => setOpenSearchMenu(!openSearchMenu)}>
                    <Icon name="ui-search" />
                </FormElements.Button>
            </Lists.HeaderFullMobile>
            {isDownloading && <Downloading> </Downloading>}
            <Toast
                show={showToast}
                onDismiss={() => setShowToast(false)}
                message={showToastMessage}
                toastType={toastType}
                toastDiligencelink={toastDiligencelink}
            />

            <Lists.ListHeader
                title={`${t("clients:list-clients-page.title")} (${totalClients.toLocaleString()})`}
                description={t("clients:list-clients-page.description")}
                quickFilters={filters ? filters.concat(filtersClient) : filtersClient}
                popoverActions={[
                    {
                        key: "search",
                        label: t("clients:list-clients-page.search"),
                        className: `medium secondary ${openSearchMenu ? "is-open" : ""}`,
                        icon: "ui-search",
                        action: () => setOpenSearchMenu(!openSearchMenu),
                        render: (
                            <SearchMenu
                                titleSearchMenu={t("clients:list-clients-page.search")}
                                openSearchMenu={openSearchMenu}
                                setOpenSearchMenu={setOpenSearchMenu}
                                handleSearchChange={handleSearchChange}
                                handleSearch={handleSearch}
                                searchValue={searchValue}
                                showSearchRaison={showSearchRaison}
                                showSearchCodeTiers={showSearchCodeTiers}
                                handleSearchCheckboxChange={handleSearchCheckboxChange}
                                handleResetSearch={handleResetSearch}
                            />
                        )
                    },
                    {
                        key: "filter",
                        label: t("clients:list-clients-page.filter"),
                        className: "medium secondary",
                        icon: "ui-filters",
                        action: ""
                    }
                ].filter(({ key }) => actions.includes(key))}
                buttonActions={[
                    {
                        key: "export",
                        label: t("clients:list-clients-page.export"),
                        className: "medium secondary",
                        action: handleExport,
                        disabled: disableExport
                    }
                ].filter(({ key }) => actions.includes(key))}
            />

            <Lists.ListTable columns={[...columns]} content={clientsList} rowKey="tiersCode" actionsCount="3" />

            <Lists.ListPagination numberElem={totalClients} page={currentPage} pageSize={pageSize} changePage={changePage} />

            {showClientDetail && <NewClient updateModal={setShowClientDetails} clientPivotId={selectedClient} withTabs={false} />}
        </PageWrapper>
    );
};

export default ListClientsPage;
