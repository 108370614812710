import React from "react";
import { useTranslation } from "react-i18next";
import Icon from "../icon-svg/icon";
import "./assistance.scss";

const Assistance = () => {
    const { t } = useTranslation();

    return (
        <>
            <a href="mailto:contact-SBC@sage.com" className="banner__help">
                <div className="banner__container">
                    <div className="banner__container-text">
                        <div className="banner__help-icon">
                            <Icon name="ui-customer-s" />
                        </div>
                        <span className="banner__help-text">
                            {t("home-banner.contact")}
                            <br />
                            {t("home-banner.by-mail")}
                        </span>
                    </div>
                </div>
            </a>
        </>
    );
};

export default Assistance;
