import { useEffect } from "react";

const useOnTabOutside = (ref, handler) => {
    useEffect(() => {
        const listener = (event) => {
            if (event.keyCode === 9) {
                if (!ref.current) {
                    return;
                }
                handler(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [ref, handler]);
};

export default useOnTabOutside;
