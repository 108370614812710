import React, { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Icon } from "../../../../../shared/components";
import { AuthContext, roles } from "../../../../../context";
import "./sepa-recap.scss";

const SepaRecap = (props) => {
    const { t } = useTranslation();
    const { cartMeta, usageCode } = props;
    const { reference = "", commercialEmail = "", clientContactEmail = "" ,partnerCode} = cartMeta;
    const { user } = useContext(AuthContext);
    const isFinalClient = user && user.role === roles.endUser.role;
    const isPartner= user && user.role === roles.partner.role;
    return (
        <>
            <h2 className="new-proposal__content-title">{t("proposals:sepa-recap.title")}</h2>
            <p className="sepa__content-description">
                {t("proposals:sepa-recap.description-order", { reference })}
                <span className="description-address">
                    {t("proposals:sepa-recap.description-address")}
                    <span className="description-mail">{isFinalClient ? clientContactEmail : commercialEmail}</span>
                </span>
            </p>
            <div className="preview__section">
                <h3 className="preview__section-title">{t("proposals:sepa-recap.information.title")}</h3>

                {usageCode !== "US_VDA" && usageCode !== "US_PEI" && (
                    <>
                        <div className="preview__card">
                            <Icon name="ui-calendar" className="preview__card-icon" />
                            <div>
                                <div className="preview__card-title">{t("proposals:sepa-recap.information.engagement.title")}</div>
                                <div className="preview__card-description">{t("proposals:sepa-recap.information.engagement.monthly")}</div>
                            </div>
                        </div>

                        <div className="preview__card">
                            <Icon name="ui-bank" className="preview__card-icon" />
                            <div>
                                <div className="preview__card-title">{t("proposals:sepa-recap.information.payment.title")}</div>
                                {isPartner || (partnerCode && partnerCode.length > 0) ? (
                                    <>
                                        {" "}
                                        <div className="preview__card-description">{t("proposals:sepa-recap.information.payment.lcr")}</div>
                                        <div className="preview__card-description">{t("proposals:sepa-recap.information.payment.withdrawal")}</div>
                                        <div className="preview__card-description">{t("proposals:sepa-recap.information.payment.mandate")}</div>
                                    </>
                                ) : (
                                    <>
                                        <div className="preview__card-description">{t("proposals:sepa-recap.information.payment.withdrawal")}</div>
                                        <div className="preview__card-description">{t("proposals:sepa-recap.information.payment.mandate")}</div>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className="preview__card">
                            <Icon name="ui-document-commercial" className="preview__card-icon" />
                            <div>
                                <div className="preview__card-title">{t("proposals:sepa-recap.information.invoice.title")}</div>
                                <div className="preview__card-description">
                                    {isFinalClient ? (
                                        <Trans i18nKey="proposals:sepa-recap.information.invoice.client-description">
                                            <a
                                                href={t("proposals:sepa-recap.information.invoice.client-description-link")}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                _
                                            </a>
                                        </Trans>
                                    ) : (
                                        t("proposals:sepa-recap.information.invoice.partner-description")
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className="preview__card">
                    <Icon name="ui-cloud" className="preview__card-icon" />
                    <div>
                        <div className="preview__card-title">{t("proposals:sepa-recap.information.service-access.title")}</div>
                        <div className="preview__card-description">{t("proposals:sepa-recap.information.service-access.email-description")}</div>
                        {usageCode !== "US_VDA" && usageCode !== "US_PEI" && (
                            <div className="preview__card-description">
                                {isFinalClient
                                    ? t("proposals:sepa-recap.information.service-access.client-activate-description")
                                    : t("proposals:sepa-recap.information.service-access.partner-activate-description")}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SepaRecap;
