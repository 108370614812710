import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NumbersOnlyRegex } from "../../../proposals/utils/regex";
import { FormElements } from "../../../../shared";
import "./codeTiers-auth.scss";

const CodeTiersFormAuth = (props) => {
    const { t } = useTranslation();
    const { enableSubmit, selectedPartnerCode } = props;

    const [partnerCodeError, setPartnerCodeError] = useState(false);

    const [selectedCode, setSelectedCode] = useState({
        tiercode: ""
    });

    const partnerCode = {
        type: "text",
        validator: (value) => value.length === 8 && NumbersOnlyRegex.test(value),
        errorMessage: true,
        displayError: partnerCodeError
    };

    const handleChange = async (e) => {
        let newInfo = "";
        if (e.target.value.length <= 8) {
            if (e.target.value.length === 0 || NumbersOnlyRegex.test(e.target.value)) {
                setPartnerCodeError(false);
            } else if (e.target.value.length === 8 && NumbersOnlyRegex.test(e.target.value)) {
                setPartnerCodeError(false);
            } else {
                setPartnerCodeError(true);
            }
        }
        newInfo = {
            tiercode: e.target.value
        };
        setSelectedCode(newInfo);
        const formNotValid = partnerCode.validator(newInfo.tiercode);
        selectedPartnerCode(newInfo.tiercode);
        enableSubmit(formNotValid);
    };

    return (
        <>
            <div className="contact-form__item">
                <div className="contact-form__label">{t("users:attachment.codeClientMessage")}</div>
                {/* <div className="contact-form__label">{t("users:attachment.partner")}</div> */}
                <FormElements.Input
                    className="contact-form__input"
                    type={partnerCode.type}
                    name={partnerCode}
                    value={selectedCode.tiercode}
                    placeholder={partnerCode.placeholder ? partnerCode.placeholder : ""}
                    onChange={handleChange}
                    validator={partnerCode.validator}
                />
                {partnerCode.displayError && <div className="new-client__error">{`${t("clients:client-information.name-error")}`}</div>}
            </div>
        </>
    );
};
export default CodeTiersFormAuth;
