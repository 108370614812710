import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCurrency } from "../../../../shared/utils/string.utils";
import { getClientSimple } from "../../../clients/services/client.services";
import "./cart-total.scss";

const CartTotal = (props) => {
    const { t } = useTranslation();
    const { items, mode, total, souscriptionDetail, clientPivotId, selectedClient } = props;
    items.sort((a, b) => a.showOrder - b.showOrder);
    const [eurTotal, centsTotal] = getCurrency(total);
    const titleHT = t("proposals:cart.total-ht");
    const tvaText = t("proposals:cart.tva");
    const titleTTC = t("proposals:cart.total-ttc");
    const cartRate = t("proposals:cart.rate");
    const [totals, setTotals] = useState([{ eur: eurTotal, cents: centsTotal, title: titleHT, mention: "" }]);

    useEffect(() => {
        if (clientPivotId && (mode === "sepa" || souscriptionDetail)) {
            getClientSimple(clientPivotId)
                .then((res) => {
                    const { tva: taxe } = res.data;
                    const taxeNumber = taxe / 100;
                    const [eurTotalTva, centsTotalTva] = getCurrency(total * taxeNumber);
                    const [eurTotalTTC, centsTotalTTC] = getCurrency(total + total * taxeNumber);
                    setTotals([
                        { eur: eurTotal, cents: centsTotal, title: titleHT, className: "cart-subtotal" },
                        {
                            eur: eurTotalTva,
                            cents: centsTotalTva,
                            title: `${tvaText} ${taxe}%`,
                            description: cartRate,
                            className: "cart-subtotal"
                        },
                        {
                            eur: eurTotalTTC,
                            cents: centsTotalTTC,
                            title: titleTTC,
                            mention: "",
                            className: "cart-subtotal-ttc"
                        }
                    ]);
                })
                .catch(() => {
                    setTotals([{ eur: eurTotal, cents: centsTotal, title: titleHT, mention: "" }]);
                });
        } else {
            setTotals([{ eur: eurTotal, cents: centsTotal, title: titleHT, mention: "" }]);
        }
    }, [clientPivotId, total, mode, cartRate, titleHT, titleTTC, centsTotal, eurTotal, tvaText, souscriptionDetail]);

    return (
        <>
            {totals.map(({ eur, cents, title, mention, description, className }) => (
                <div key={title} className={`${clientPivotId} cart-total ${className} ${mode} ${!selectedClient ? "new-proposal__cart-radius" : "" } `}>
                    <span className={`cart-total__title ${mode}`}>{title}</span>
                    <span className="cart-total__amount">
                        <span className={`cart-total__amount-euros ${mode}`}>{parseFloat(eur).toLocaleString()}</span>
                        <span className={`cart-total__amount-cents ${mode}`}>{`,${cents} €/${t("proposals:cart.monthly-price")}`}</span>
                    </span>
                    {mention && <span className="cart-total__mention">{mention}</span>}
                    {description && <span className="cart-total__description">{description}</span>}
                </div>
            ))}
        </>
    );
};

export default CartTotal;
