import React from "react";
import Icon from "../../icon-svg/icon";
import "./select.scss";

const Select = (props) => {
    const { name, onChange, options, optgroups, required = false, className = "", label, disabled, value, data, select } = props;

    return (
        <div className={`carbon-form__select ${className}`}>
            <select className="select" name={name} value={value} onChange={onChange} required={required} disabled={disabled}>
                <option value="">{label}</option>
                {options &&
                    options.map((option) => (
                        <option key={option.key || option.value} value={option.value}>
                            {option.name}
                        </option>
                    ))}
                {data &&
                    data.map((option) => (
                        <option key={option.key} value={option.value} selected={option.email === select ? select : ""}>
                            {option.email.toLowerCase()}
                        </option>
                    ))}
                {optgroups &&
                    optgroups.map((group) => (
                        <optgroup key={group.name} label={group.name}>
                            {group.options.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </optgroup>
                    ))}
            </select>
            <div className="carbon-form__select-icon">
                <Icon name="dialog-carret-down" />
            </div>
        </div>
    );
};

export default Select;
