import React from "react";
import { createPortal } from "react-dom";
import "./popup-wrapper.scss";

const PopupWrapper = ({ children, visible, className = "", classNamePopup = "" }) => {
    if (visible) {
        return createPortal(
            <div className={`popup-wrapper ${className}`}>
                <div className={`popup ${classNamePopup}`}>{children}</div>
            </div>,
            document.getElementById("modal-root")
        );
    }
    return null;
};

export default PopupWrapper;
