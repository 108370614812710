import React from "react";
import { useTranslation } from "react-i18next";
import "./list-pagination.scss";

const ListPagination = (props) => {
    const { t } = useTranslation();
    const { numberElem = 0, page = 0, pageSize = 50, changePage, message } = props;

    const maxPageNumber = Math.ceil(numberElem / pageSize);
    const incrementedPage = page + 1;

    let paginationNumbers = [];

    // Generate first three elements in pagination if necessary
    if (incrementedPage - 2 > 1) {
        paginationNumbers = paginationNumbers.concat([1, "...", incrementedPage - 1]);
    } else if (incrementedPage - 2 === 1) {
        paginationNumbers = paginationNumbers.concat([1, incrementedPage - 1]);
    }
    if (incrementedPage - 1 === 1) {
        paginationNumbers.push(1);
    }
    // Generate current number in pagination
    paginationNumbers.push(incrementedPage);

    // Generate last three element in pagination if necessary
    if (incrementedPage + 1 === maxPageNumber) {
        paginationNumbers.push(incrementedPage + 1);
    }
    if (incrementedPage + 2 < maxPageNumber) {
        paginationNumbers = paginationNumbers.concat([incrementedPage + 1, "...", maxPageNumber]);
    }
    if (incrementedPage + 2 === maxPageNumber) {
        paginationNumbers = paginationNumbers.concat([incrementedPage + 1, maxPageNumber]);
    }

    const keys = paginationNumbers.map((num, index) => (num === "..." ? `${num}${index}` : num));

    const handleClickPage = (pageNumber, index) => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
        if (pageNumber !== "...") {
            changePage(pageNumber - 1);
        } else {
            const findSkip = index > incrementedPage ? paginationNumbers[index - 1] + 1 : paginationNumbers[index + 1] - 1;
            changePage(findSkip);
        }
    };

    return (
        <>
            <div className="pagination">
                <button type="button" className="pagination__button" onClick={() => handleClickPage(page)} disabled={page - 1 < 0}>
                    {t("pagination.previous")}
                </button>
                {paginationNumbers.map((number, index) => (
                    <div className="pagination__step" key={keys[index]}>
                        <button
                            className={number === incrementedPage ? "step__active" : "step__default"}
                            type="button"
                            onClick={() => handleClickPage(number, index)}>
                            {number}
                        </button>
                    </div>
                ))}
                <button type="button" className="pagination__button" onClick={() => handleClickPage(page + 2)} disabled={page + 1 > maxPageNumber}>
                    {t("pagination.next")}
                </button>
            </div>
            {message && <div className="limit-page">{message}</div>}
        </>
    );
};

export default ListPagination;
