import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CardComponent from "../card-component/card-component";
import { AuthContext, roles } from "../../../../context";
import { getUsageDetails } from "../../services/proposal.services";
import { Toast } from "../../../../shared/components";
import { checkExistUsageFree } from "../../../users/service/user.services";
import { getOffers } from "../../services";
import "./offers-list.scss";

const OffersList = () => {
    const history = useHistory();
    const { isAuthenticated, user } = useContext(AuthContext);
    const [usageList, setUsageList] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState("");
    const [showToastMessage, setShowToastMessage] = useState("");
    const [toastDiligencelink, setToastDiligencelink] = useState(false);
    const usagesCodes = ["US_VDA", "US_PEI"];
    const [referenceVDA, setReferenceVDA] = useState("");
    const [referencePEI, setReferencePEI] = useState("");
    const [vdaActive, setVdaActive] = useState(true);
    const [peiActive, setPeiActive] = useState(true);
    const [vdaChecked, setVdaChecked] = useState(false);
    const [peiChecked, setPeiChecked] = useState(false);
    const [visibleUsage, setVisibleUsage] = useState([]);
    const { t } = useTranslation();

    const handleAddNewProposal = (offer) => {
        if (user.role === roles.partnerLimited.role) {
            if (user.roleLabel === "tiers_sanction_policy") {
                setShowToast(true);
                setToastType("error");
                setShowToastMessage(t("clients:tiers.sanctionPolicy-limited"));
            }
            if (user.roleLabel === "tiers_diligence") {
                setShowToast(true);
                setToastType("error");
                setToastDiligencelink(true);
                setShowToastMessage(t("clients:tiers.dueDiligence"));
            } else {
                setShowToast(true);
                setToastType("error");
                setShowToastMessage(t("clients:tiers.tiersBlocked-limited"));
            }
        } else {
            const { usageCode } = offer;
            history.push(`/proposals/new?usageCode=${usageCode}`);
        }
    };

    useEffect(() => {
        if (
            isAuthenticated &&
            user &&
            (user.role === roles.partner.role || user.role === roles.partnerLimited.role) &&
            (user.roleBms || user.rolePaie || user.roleISV)
        ) {
            getUsageDetails().then((response) => {
                const { data } = response;
                setUsageList(data);
            });
        }
    }, [isAuthenticated, user]);

    const setOfferDiponibility = (usageCode, subOffer, offers) => {
        const { returnValue } = subOffer;
        if (returnValue && returnValue.length > 0 && returnValue.length === offers.length) {
            if (usageCode === "US_VDA") {
                setVdaActive(false);
                setReferenceVDA(Object.values(subOffer).map((k) => k.map((f) => f.reference)));
            } else if (usageCode === "US_PEI") {
                setPeiActive(false);
                setReferencePEI(Object.values(subOffer).map((k) => k.map((f) => f.reference)));
            }
        }
    };
    const CheckVisibilty = (usage) => {
        if (visibleUsage && visibleUsage.length > 0 && visibleUsage.includes(usage) > 0) return true;
        return false;
    };
    const checkUsVDAPEI = () => {
        if (isAuthenticated && user && (user.role === roles.partner.role || user.role === roles.partnerLimited.role) && user.agreements.length > 0) {
            if (!vdaChecked || !peiChecked) {
                usagesCodes.map((usageCode) => {
                    getOffers(usageCode)
                        .then((res) => {
                            const offerspp = res && res.data && res.data.map((offer) => offer.principalProduct);
                            const s = new Set(offerspp);
                            const products = [...s];
                            if (products.length > 0) {
                                visibleUsage.push(usageCode);
                                setVisibleUsage([usageCode, ...visibleUsage]);
                                checkExistUsageFree(usageCode, products).then((resp) => {
                                    const subOffers = resp.data;
                                    setOfferDiponibility(usageCode, subOffers, products);
                                });
                            }
                            if (usageCode === "US_VDA") setVdaChecked(true);
                            if (usageCode === "US_PEI") setPeiChecked(true);
                        })
                        .catch(() => {
                            setVdaChecked(false);
                            setPeiChecked(false);
                        });

                    return usageCode;
                });
            }
        }
    };
    useEffect(() => {
        checkUsVDAPEI();
        // eslint-disable-next-line
    }, [isAuthenticated, user]);

    return (
        <>
            <Toast
                show={showToast}
                onDismiss={() => setShowToast(false)}
                message={showToastMessage}
                toastType={toastType}
                toastDiligencelink={toastDiligencelink}
            />
            {usageList && usageList.length > 0 && (
                <div>
                    <h2 className="actu__title">{t("titlefferlist.title")}</h2>
                    <p className="WrapperTitle">{t("titlefferlist.subtitle")}</p>

                    <div className="offerslist__cards-wrapper">
                        {usageList.map((usage) => {
                            const { usageCode, usageTitle, usageSubTitle, usageType, usageDescription } = usage;
                            const cardObject = {
                                usageCode,
                                offerType: usageType,
                                principalProductLabel: usageTitle,
                                secondaryProductLabel: usageSubTitle,
                                secondaryProductDescription: usageDescription
                            };
                            const usageC = usageCode === "US_VDA" ? "VDA" : "PEI";
                            const interactionPossibility = usageCode === "US_VDA" ? vdaActive : peiActive;
                            const reference = usageCode === "US_VDA" ? referenceVDA : referencePEI;
                            const message = <>{t("proposals:modal-alert.exist-vda-pei", { usageC, ref: reference })}</>;
                            const messageC = (usageCode === "US_VDA" && !vdaActive) || (usageCode === "US_PEI" && !peiActive) ? message.props.children : "";
                            return (
                                ((usageCode === "US_PEI" && (user.rolePaie || user.roleBms)) || usageCode === "US_VDA") && (
                                    <CardComponent
                                        key={usageCode}
                                        offer={cardObject}
                                        onAddToCart={handleAddNewProposal}
                                        cardHeader="card__header_green"
                                        buttonName="proposals:select-offer.order"
                                        iconShow="message-success-2"
                                        buttonStyle="medium offersfree"
                                        usage
                                        enable={CheckVisibilty(usageCode) && interactionPossibility}
                                        message={messageC}
                                    />
                                )
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
};

export default OffersList;
