import React from "react";
import { Route, Switch } from "react-router-dom";
import { AuthProvider, ProtectedRoute } from "./context";
import HomePage from "./shared/pages/homepage/homepage";
import FaqPage from "./shared/pages/faq/faq";
import Users from "./domains/users";
import Clients from "./domains/clients";
import Offers from "./domains/proposals";
import Subscriptions from "./domains/subscription";
import Invoices from "./domains/invoices";
import NotFound from "./domains/error/pages/not-found/not-found";
import Profile from "./domains/users/pages/profile/profile";
import Config from "./shared/pages/config/config";

const AppRoutes = () => {
    return (
        <AuthProvider>
            <Switch>
                <Route exact path="/" component={HomePage} />
                {/* user routes */}
                <ProtectedRoute exact path="/dashboard" component={Users.Dashboard} entity="dashboard" action="read" />
                {/* client routes */}
                <ProtectedRoute exact path="/clients/list" component={Clients.ListClientsPage} entity="clients" action="read" />
                {/* TODO : F006 create new tiers */}
                {/* <ProtectedRoute exact path="/clients/new" component={Clients.NewClient} entity="client" action="create" /> *}
                {/* proposal routes */}
                <ProtectedRoute exact path="/proposals/list" component={Offers.ListProposalsPage} entity="proposals" action="read" />
                <ProtectedRoute exact path="/proposals/new" component={Offers.NewProposalPage} entity="proposal" mode="new" action={["create", "simulate"]} />
                <ProtectedRoute exact path="/proposals/:reference/edit" component={Offers.NewProposalPage} entity="proposal" mode="edit" action="edit" />
                <ProtectedRoute exact path="/proposals/:reference/update" component={Offers.NewProposalPage} entity="proposal" mode="update" action="edit" />

                <ProtectedRoute
                    exact
                    path="/subscriptions/:reference/edit"
                    component={Offers.NewSubscirptionPage}
                    entity="proposal"
                    mode="edit"
                    action="edit"
                />

                <ProtectedRoute exact path="/proposals/:reference/sepa" component={Offers.NewProposalPage} entity="proposal" mode="sepa" action="pay" />
                {/* subscription routes */}
                <ProtectedRoute exact path="/subscriptions/list" component={Subscriptions.ListSubscriptionPage} entity="subscriptions" action="read" />
                {/* config */}
                <ProtectedRoute exact path="/config" component={Config} entity="config" action="read" />
                {/* invoice routes */}
                <ProtectedRoute exact path="/invoices/list" component={Invoices.ListInvoicesPage} entity="invoices" action="read" />
                {/* generic */}
                <ProtectedRoute exact path="/faq" component={FaqPage} entity="faq" action="read" />
                {/* profile */}
                <ProtectedRoute exact path="/profile" component={Profile} entity="profile" action="read" />
                {/* 404 */}
                <Route component={NotFound} />
            </Switch>
        </AuthProvider>
    );
};

export default AppRoutes;
