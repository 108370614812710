import React from "react";
import { useTranslation } from "react-i18next";
import Icon from "../../icon-svg/icon";
import logo from "../../../assets/images/sage_logo.svg";
import "./footer.scss";

const Footer = (props) => {
    const { className = "" } = props;
    const { t } = useTranslation();

    return (
        <div className={`main-footer ${className}`}>
            <img className="main-footer__logo" src={logo} alt="Sage" />

            <div className="main-footer__social">
                <p className="main-footer__social-follow">{t("footer.follow")}</p>
                <ul>
                    <li>
                        <a href="https://www.facebook.com/SageFrance" className="main-footer__social-link" aria-label="Facebook">
                            <Icon name="social-facebook" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/sageofficial/" className="main-footer__social-link" aria-label="Instagram">
                            <Icon name="social-instagram" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/sage-software/" className="main-footer__social-link" aria-label="Linkedin">
                            <Icon name="social-linkedin" />
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/SageFrance" className="main-footer__social-link" aria-label="Twitter">
                            <Icon name="social-twitter" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/user/Sageenfrance" className="main-footer__social-link" aria-label="Youtube">
                            <Icon name="social-youtube" />
                        </a>
                    </li>
                </ul>
            </div>

            <div className="main-footer__links">
                <span className="main-footer__link main-footer__copyright">{t("footer.copyright")}</span>
                <ul>
                    <li>
                        <a href="https://www.sage.com/fr-fr/informations-legales/" className="main-footer__link">
                            {t("footer.legal")}
                        </a>
                    </li>
                    <li>
                        <a href="https://www.sage.com/fr-fr/informations-legales/protection-vie-privee-cookies/" className="main-footer__link">
                            {t("footer.cookies")}
                        </a>
                    </li>
                    <li>
                        <a href="https://www.sage.com/fr-fr/informations-legales/rgpd/" className="main-footer__link">
                            {t("footer.gdpr")}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Footer;
