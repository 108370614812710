import React from "react";
import { useTranslation } from "react-i18next";
import { getCurrency } from "../../../../shared/utils/string.utils";
import emptyCart from "../../assets/images/empty-cartC.png";
import { Icon } from "../../../../shared/components";
import "./cart.scss";

const Cart = (props) => {
    const { t } = useTranslation();
    const { items, onUpdateCart, step, mode, invoicingOffer } = props;

    items.sort((a, b) => a.showOrder - b.showOrder);
    const principalProducts = items.filter(({ productParent }) => !productParent);

    const OneToNComponenet = (prop) => {
        const { oneToN } = prop;
        
        const oneToNList = oneToN.map((child) => {
            const { product, label, description, quantity,price,discount } = child;
            const [eur, cents] = getCurrency(price - price * discount);
            const displayRemovecart= (step === "offer" || step === "config") && !invoicingOffer 
            return (
                <div className="cart-product__secondary-offer" key={`otn_${product}`}>   
                {displayRemovecart && (
                        <button
                            type="button"
                            className="cart-product__secondary-offer__delete-pp"
                            onClick={() => onUpdateCart(child, -1, "one-to-n")}>
                            <Icon name="ui-unlink" />
                        </button>
                    )} 
                    {!displayRemovecart && (<br/>)} 
                                 
                    <h3 className="cart-product__secondary-offer__name">{label}</h3>                    
                    <span className="cart-product__secondary-offer__description">{description && description.length > 0 ? description[0] : ""}</span>
                    <span className="cart-product__option-quantity-pp">{quantity}</span>                   
                    <span className="cart-product__option-price-pp">{ `${parseFloat(eur).toLocaleString()},${cents} €/${t("proposals:cart.monthly-price")}`}</span>                    
                </div>
            );
        });
        return oneToNList        
    };
    return (
        <>
            {(!items || items.length <= 0) && (
                <div className="cart-empty">
                    <img className="cart-empty__image" src={emptyCart} alt={t("proposals:cart.empty.title")} />
                    <h2 className="cart-empty__title">{t("proposals:cart.empty.title")}</h2>
                </div>
            )}

            {principalProducts && principalProducts.length > 0 && (
                <ul className={`cart__products-list ${mode}`}>
                    {principalProducts.map((principalProduct) => {
                        const { product: pProduct, label: pLabel } = principalProduct;
                        const oneToN = items.filter(({ productParent, type }) => productParent === pProduct && type === "one-to-n");


                        const components = items.filter(({ productParent, type }) => productParent === pProduct && type === "component");
                        return (
                            <li className={`cart-product ${step !== "offer" ? "config" : ""}`} key={`pp_${pProduct}`}>
                                <div className="cart-product__principal-offer">
                                    <h3 className="cart-product__principal-offer-name">{pLabel}</h3>
                                    <OneToNComponenet oneToN={oneToN} />
                                </div>

                                {step !== "offer" && (
                                    <ul className="cart-product__options">
                                        {components.map(({ product, label, quantity, price, discount }) => {
                                            const [compEur, compCents] = getCurrency(price - price * discount);
                                            return (
                                                <li className="cart-product__option" key={`comp_${product}`}>
                                                    <span className="cart-product__option-label">{label}</span>
                                                    <span className="cart-product__option-quantity">{quantity}</span>
                                                    <span className="cart-product__option-price">
                                                        {`${parseFloat(compEur).toLocaleString()},${compCents} €/${t("proposals:cart.monthly-price")}`}
                                                    </span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                            </li>
                        );
                    })}
                </ul>
            )}
        </>
    );
};

export default Cart;
