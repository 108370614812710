import React from "react";
import Button from "../form-elements/button/button";
import Icon from "../icon-svg/icon";
import PopupWrapper from "../popup-wrapper/popup-wrapper";
import "./modal-alert.scss";

const ModalAlert = (props) => {
    const { className = "", classNameContent = "" } = props;
    const { enableSubmit = true, visible, onCancel, onAccept, submit, title, icon, cancelButtonText, acceptButtonText, children, onClickButton ,iconConfig } = props;

    return (
        <PopupWrapper className={`popup-wrapper--alert ${className}`} classNamePopup="modal-alert" visible={visible}>
            <div className="modal-alert__header">
                {icon && (
                    <div className="modal-alert__icon">
                        <Icon name="message-warning" />
                    </div>
                )}
                 {iconConfig && (
                    <div className="modal-alert__icon-config">
                        <Icon name ="message-success-2" />
                    </div>
                )}
                <h1 className="modal-alert__title">{title}</h1>
                {onCancel && (
                    <button type="button" className="modal-alert__close" onClick={onCancel}>
                        <Icon name="dialog-close" />
                    </button>
                )}
            </div>

            <div className={`modal-alert__content ${classNameContent} ${className}__content`}>{children}</div>

            <div className="modal-alert__footer">
                {onCancel && (
                    <Button className="medium tertiary" onClick={onCancel}>
                        {cancelButtonText}
                    </Button>
                )}
                {onAccept && (
                    <Button className="medium primary" onClick={onAccept} disabled={!enableSubmit} submit={submit}>
                        {acceptButtonText}
                    </Button>
                )}
                {onClickButton && (
                    <Button className="medium primary" onClick={onClickButton} disabled={!enableSubmit}>
                        {acceptButtonText}
                    </Button>
                )}
            </div>
        </PopupWrapper>
    );
};
export default ModalAlert;
