import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Icon from "../icon-svg/icon";
import LatestNews from "../latestnews/latestnews"
import "./help-menu.scss";

const HelpMenu = (props) => {
    const { openHelpMenu } = props;
    const { t } = useTranslation();
    const [openLatestNewsPopup, setopenLatestNewsPopup] = useState(false);

    if (openLatestNewsPopup) {
        return (<LatestNews openPopUpMenu={openLatestNewsPopup} setOpenPopUpMenu={setopenLatestNewsPopup} onClose={() => { setopenLatestNewsPopup(false); }} />)
    }
    
    return (
        <div className={`help-menu ${openHelpMenu ? "is-open" : ""}`}>
            <h2 className="help-menu__title">{t("help-menu.title")}</h2>
            <ul>
                <li>
                    <Link to="/faq" className="help-menu__link">
                        <div className="help-menu__link-icon">
                            <Icon name="ui-help" />
                        </div>
                        <span className="help-menu__link-label">{t("menu.faq")}</span>
                    </Link>
                </li>
                <li>
                    <a
                        href="https://www.sage-partner-marketing-hub.com/portal/auth/login"
                        className="help-menu__link"
                        target="_blank"
                        rel="noopener noreferrer">
                        <div className="help-menu__link-icon">
                            <Icon name="services-helpdesk" />
                        </div>
                        <span className="help-menu__link-label">{t("menu.hub")}</span>
                    </a>
                </li>
                <li>
                    <div style={{ cursor: "pointer" }} onClick={() => setopenLatestNewsPopup(true)} onKeyDown={() => setopenLatestNewsPopup(true)}  className="help-menu__link" role="link">
                        <div className="help-menu__link-icon">
                            <Icon name="ui-help" />
                        </div>
                        <span className="help-menu__link-label">{t("menu.evolution")}</span>
                    </div>

                </li>
            </ul>
        </div>
    );
};

export default HelpMenu;
