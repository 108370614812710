import React from "react";
import "./loading.scss";

const Loading = () => (
    <div className="loading">
        <div className="spinner" />
    </div>
);

export default Loading;
