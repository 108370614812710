import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { PageWrapper, HomeBanner, Toast, Actu } from "../../components";
import { AuthContext } from "../../../context";
import "./homepage.scss";
import { handleProposalToast } from "../../../domains/proposals/utils/toast.handler";
import OffersList from "../../../domains/proposals/components/offers-list/offers-list";
import DashboardProposal from "../../../domains/users/components/dashboard-proposal/dashboard-proposal";
import DashboardSubscription from "../../../domains/users/components/dashboard-subscription/dashboard-subscription";
import DashboardInvoice from "../../../domains/users/components/dashboard-invoice/dashboard-invoice";
import Assistance from "../../components/assistance/assistance";
import DashboardClients from "../../../domains/users/components/dashboard-client/dashboard-client";

const HomePage = (props) => {
    const { t } = useTranslation();
    const { history } = props;
    const { isAuthenticated, user } = useContext(AuthContext);
    const [showToast, setShowToast] = useState(false);
    const [showToastMessage, setShowToastMessage] = useState("");
    const [toastType, setToastType] = useState("");
    const [toastDiligencelink, setToastDiligencelink] = useState(false);
    const location = useLocation();

    useEffect(() => {
        handleProposalToast(location, history, setShowToastMessage, setToastType, setShowToast, setToastDiligencelink);
    }, [history, location]);

    useEffect(() => {
        if (isAuthenticated) {
            if (history.location.state && history.location.state.nextLocation) {
                history.replace(history.location.state.nextLocation);
            }
        }
    }, [isAuthenticated, history]);

    return (
        <PageWrapper className="homepage">
            <Toast
                show={showToast}
                onDismiss={() => setShowToast(false)}
                message={showToastMessage}
                toastType={toastType}
                toastDiligencelink={toastDiligencelink}
            />
            {!isAuthenticated && (
                <HomeBanner>
                    <h2 className="banner__message-title">{t("homepage.title")}</h2>
                    <p className="banner__message-paragraph">{t("homepage.subtitle")}</p>
                </HomeBanner>
            )}
            {isAuthenticated && (
                <>
                    <Assistance />
                    <Actu />
                    <div className="WrapperHome">
                        <div className={`${user.role === "internal_sales" ? "gridDashboard" : ""} `}>
                            <div>
                                <h2 className="actu__title">{t("users:dashbord-proposal.title")}</h2>
                                <div className="WrapperTitle">{t("users:dashbord-proposal.subtitle")}</div>
                            </div>
                            <div className={`${user.role === "internal_sales" ? "dashboard__headerSales" : "dashboard__header"} `}>
                                <DashboardProposal />
                                <DashboardSubscription />
                                <DashboardInvoice />
                                <DashboardClients />
                            </div>
                        </div>
                        <div>
                            <OffersList />
                        </div>
                    </div>
                </>
            )}
        </PageWrapper>
    );
};

export default HomePage;
