import React from "react";
import "./pill.scss";

const Pill = (props) => {
    const { pillLabel, pillColor } = props;

    return (
        <div className="pill__wrapper">
            <span className="pill" style={{ borderColor: pillColor }}>
                {pillLabel}
            </span>
        </div>
    );
};

export default Pill;
