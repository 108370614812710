import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../context";
import logo from "../../../assets/images/sage_logo.svg";
import useOnClickOutside from "../../../custom-hook/use-click-outside";
import Icon from "../../icon-svg/icon";
import UserMenu from "../../../../domains/users/components/user-menu/user-menu";
import HelpMenu from "../../help-menu/help-menu";
import Button from "../../form-elements/button/button";
import "./header.scss";

const Header = (props) => {
    const { className = "", open, setOpen } = props;
    const { t } = useTranslation();
    const { login, isAuthenticated, user, permissions } = useContext(AuthContext);
    const [openUserMenu, setOpenUserMenu] = useState(false);
    const [openHelpMenu, setOpenHelpMenu] = useState(false);
    const userMenuWrapper = useRef();
    const helpMenuWrapper = useRef();
    const cartLink = "/proposals/new";

    useOnClickOutside(userMenuWrapper, () => setOpenUserMenu(false));
    useOnClickOutside(helpMenuWrapper, () => setOpenHelpMenu(false));

    const hasHeaderPermission = () => {
        const { actions } = permissions.header;
        return actions.includes("read");
    };

    const linkAllowed = (item) => {
        const { hiddenFields } = permissions.header;
        return !hiddenFields.includes(item);
    };
    const environnementTitle = () => {
        let titreEnv;
        const urlPathname = window.location.href.toLowerCase();
        switch (true) {
            case urlPathname.includes("qa."):
                titreEnv = "Environnement: Recette";
                break;
            case urlPathname.includes("integration."):
                titreEnv = "Environnement: Integration";
                break;
            case urlPathname.includes("dev."):
                titreEnv = "Environnement: DEV";
                break;
            case urlPathname.includes("pp."):
                titreEnv = "Environnement: Preprod";
                break;
            default:
                titreEnv = "";
        }
        return titreEnv;
    }; 

    return (
        <>
            {/* Header desktop */}
            <header id="main-header" className={`main-header ${className}`}>
                <h1 className="sage-100-logo">
                    <img className="sage-logo" src={logo} alt={t("title")} />
                    <span>{t("title")}</span>
                  <div className="envtitle">{environnementTitle()}</div>
                </h1>

                {isAuthenticated && user && permissions && hasHeaderPermission() ? (
                    <>
                        {linkAllowed("proposal") && (
                            <div className="main-header__button-wrapper">
                                <a
                                    href={cartLink}
                                    className="main-header__button main-header__cart has-tooltip tooltip-bottom"
                                    aria-label="Faire une proposition">
                                    <Icon name="ui-cart-add" />
                                </a>
                            </div>
                        )}

                        {linkAllowed("help") && (
                            <div className="main-header__button-wrapper" ref={helpMenuWrapper}>
                                <button
                                    type="button"
                                    className={`main-header__button help-menu-toggle ${openHelpMenu ? "is-open" : ""}`}
                                    onClick={() => setOpenHelpMenu(!openHelpMenu)}>
                                    <Icon name="ui-help" />
                                </button>
                                <HelpMenu openHelpMenu={openHelpMenu} />
                            </div>
                        )}

                        {linkAllowed("user") && (
                            <div className="main-header__button-wrapper" ref={userMenuWrapper}>
                                <button
                                    type="button"
                                    className={`main-header__button user-menu-toggle ${openUserMenu ? "is-open" : ""}`}
                                    onClick={() => setOpenUserMenu(!openUserMenu)}>
                                    <span className="user-menu-toggle__avatar">
                                        {user.initials}
                                        <span className="user-menu-toggle__status user-menu-toggle__status--online" />
                                    </span>
                                </button>
                                <UserMenu openUserMenu={openUserMenu} setOpenUserMenu={setOpenUserMenu} />
                            </div>
                        )}
                    </>
                ) : (
                    <Button className="medium tertiary" type="button" onClick={() => login()}>
                        {t("header.login")}
                    </Button>
                )}
            </header>

            {/* Header mobile */}
            <header className={`main-header-mobile ${className}`}>
                {isAuthenticated && user ? (
                    <button type="button" className="main-header-mobile__menu-button" onClick={() => setOpen(!open)}>
                        <Icon name="dialog-menu" />
                    </button>
                ) : (
                    <></>
                )}

                {<h1 className="full-page-header__title">{t("title")}</h1>}


                {isAuthenticated && user && permissions && linkAllowed("caddy") && (
                    <a href={cartLink} className="main-header-mobile__cart">
                        <Icon name="ui-cart-add" />
                    </a>
                )}

                {(!isAuthenticated || !user) && (
                    <Button className="medium primary" type="button" onClick={() => login()}>
                        {t("header.login")}
                    </Button>
                )}
            </header>
        </>
    );
};

export default Header;
