import { portalAPI } from "../../../config";

const { REACT_APP_FAILOVER } = process.env;

const getAllProposals = (query) => {
    return portalAPI.get("/proposal", { params: { ...query }, headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const getAllProposalsDashboard = (query) => {
    return portalAPI.get("/proposal/dashboard", { params: { ...query }, headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const getOffers = (usageCode) => {
    return portalAPI.get(`/product?step=offer&usageCode=${usageCode}`, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};
const getAllOffers = () => {
    return portalAPI.get(`/product/offers`, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};
const updateOffersSort = (newOffersSort) => {
    return portalAPI.put(`/product/sort`, newOffersSort);
};
const disableOffer = (checkedOffers) => {
    return portalAPI.put(`/product/disable`,checkedOffers, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};
const getConfig = (usageCode) => {
    return portalAPI.get(`/product?step=config&usageCode=${usageCode}`, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const getUsageDetails = () => {
    return portalAPI.get("/product/usages");
};

const modifyProposal = (reference, action, newProposalInfo) => {
    return portalAPI.put(`/proposal/${reference}?action=${action}`, newProposalInfo, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const createNewProposal = (action, usageCode, cartItems, cartMeta, userSusbtitutions) => {
    return portalAPI.post(
        `/proposal?action=${action}&usageCode=${usageCode}`,
        { cartItems, cartMeta, userSusbtitutions },
        { headers: { "X-BACKEND": REACT_APP_FAILOVER } }
    );
};

const getProposal = (reference, queryParam) => {
    return portalAPI.get(`/proposal/${reference}`, { params: { ...queryParam }, headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const sendSepaConfirmation = (reference, encryptedToken) => {
    return portalAPI.post(`/proposal/${reference}/sepa`, { encryptedToken });
};

const exportProposals = (queryParam) => {
    return portalAPI.get("/proposal/export", { params: { ...queryParam }, responseType: "blob" });
};

const getSubtitutions = (reference) => {
    return portalAPI.get(`/substitution/migration/${reference}`);
};

const getSubstitutionList = (reference, queryParam) => {
    return portalAPI.get(`/substitution/${reference}`, { params: { ...queryParam } });
};

const getParcMigrationList = (clientPivotId, queryParam) => {
    return portalAPI.get(`/substitution/parc/${clientPivotId}`, { params: { ...queryParam }, headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const downloadProposalPdf = (reference) => {
    return portalAPI.get(`/proposal/download/${reference}`);
};
const updateProposal = (reference,newProposalInfo,action) => {
    return portalAPI.put(`/proposal/${reference}/accordee?action=${action}`, newProposalInfo, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};
export {
    getProposal,
    getAllProposals,
    getOffers,
    getConfig,
    createNewProposal,
    modifyProposal,
    sendSepaConfirmation,
    exportProposals,
    getUsageDetails,
    getAllProposalsDashboard,
    getSubtitutions,
    getSubstitutionList,
    getParcMigrationList,
    downloadProposalPdf,
    updateProposal,
    getAllOffers,
    disableOffer,
    updateOffersSort
};
