import i18n from "../../../config/i18next";

const checkTiersValidity = (access, criteria, setShowToast, setToastType, setShowToastMessage, fromCientList, onSaveOrSubmit, setToastDiligencelink) => {
    if (access === "denied" && criteria === "sanctionPolicy") {
        setShowToast(true);
        setToastType("error");
        setShowToastMessage(i18n.t("clients:tiers.sanctionPolicy-denied"));
        return false;
    }
    if (access === "limited" && criteria === "sanctionPolicy" && fromCientList) {
        setShowToast(true);
        setToastType("error");
        setShowToastMessage(i18n.t("clients:tiers.sanctionPolicy-limited"));
        return false;
    }
    if (access === "limited" && criteria === "sanctionPolicy" && onSaveOrSubmit) {
        setShowToast(true);
        setToastType("error");
        setShowToastMessage(i18n.t("clients:tiers.sanctionPolicy-limited"));
        return false;
    }
    if (access === "denied" && criteria === "dueDiligence") {
        setShowToast(true);
        setToastType("error");
        setToastDiligencelink(true);
        setShowToastMessage(i18n.t("clients:tiers.dueDiligence"));
        return false;
    }
    if (access === "denied" && criteria === "tiersBlocked") {
        setShowToast(true);
        setToastType("error");
        setShowToastMessage(i18n.t("clients:tiers.tiersBlocked"));
        return false;
    }

    if (access === "denied" && criteria === "none") {
        setShowToast(true);
        setToastType("error");
        setShowToastMessage(i18n.t("clients:tiers.tiersIssue"));
        return false;
    }

    return true;
};

export default checkTiersValidity;
