import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext, roles } from "../../../../context";
import { FormElements, Icon } from "../../../../shared/components";
import "./navigation-step.scss";

const NavigationStep = (props) => {
    const { currentStep, steps, onStepChange, onSave, onPurchase, onCancel, canSave, canSubmit, mode, onClose, apiLoading, usageCode } = props;
    const { t } = useTranslation();
    const { user, permissions } = useContext(AuthContext);

    const {
        proposal: { hiddenFields }
    } = permissions;
    const statusIndicator = (index) => {
        if (index < currentStep) return "complete";
        if (index === currentStep) return "current";
        return "incomplete";
    };

    const isExternalPartner = user && user.role === roles.partner.role;

    return (
        <div className={`step-navigation step-navigation__${mode}`}>
            {currentStep === 0 && mode === "sepa" && usageCode !== "US_VDA" && usageCode !== "US_PEI" && (
                <div className="step-navigation__action">
                    <FormElements.Button className="step-navigation__order medium tertiary" onClick={() => onCancel()}>
                        {t("navigation.cancel")}
                    </FormElements.Button>
                    <FormElements.Button
                        className="step-navigation__save medium primary"
                        onClick={() => onPurchase()}
                        disabled={!steps[currentStep].canAdvance || apiLoading}>
                        {isExternalPartner ? t("navigation.pay") : t("navigation.order")}
                    </FormElements.Button>
                </div>
            )}

            {currentStep > 0 && mode !== "sepa" && (
                <FormElements.Button className="step-navigation__previous medium tertiary" onClick={() => onStepChange(currentStep - 1)}>
                    <Icon name="dialog-chevron-left" />
                    {t("navigation.previous")}
                </FormElements.Button>
            )}

            {/* Custom step sequence */}
            {steps[0].label !== "" && (
                <ol className="step-sequence">
                    {steps.map((step, index) => (
                        <li className="step-sequence__step" data-status={statusIndicator(index)} key={step.name}>
                            <span className="step-sequence__step-marker">
                                <Icon name="message-success-2" />
                            </span>
                            <span className="step-sequence__step-number">{index + 1}</span>
                            <span className="step-sequence__step-label">{step.label}</span>
                        </li>
                    ))}
                </ol>
            )}

            {currentStep === steps.length - 1 && mode === "sepa" && (
                <div className="step-navigation__action">
                    <FormElements.Button className="step-navigation__save step-navigation__save--sepa medium tertiary" onClick={() => onClose()}>
                        {t("navigation.close")}
                    </FormElements.Button>
                </div>
            )}
            {(currentStep === 0 && mode === "update") && ( 
                <div className="step-navigation__action">
                    {!hiddenFields.includes("save_button") && (
                        <FormElements.Button className="step-navigation__save medium primary" onClick={() => onSave()} disabled={!canSave || apiLoading}>
                            {t("navigation.save")}
                        </FormElements.Button>
                    )}
                </div>
            )}
            {currentStep < steps.length - 1 && mode !== "sepa" && (
                <FormElements.Button
                    className="step-navigation__next medium primary"
                    onClick={() => onStepChange(currentStep + 1)}
                    disabled={!steps[currentStep].canAdvance}>
                    {t("navigation.next")}
                    <Icon name="dialog-chevron-right" />
                </FormElements.Button>
            )}

            {currentStep === steps.length - 1 && mode !== "sepa"&& mode !== "update" && (
                <div className="step-navigation__action">
                    {!hiddenFields.includes("submit_button") && (
                        <FormElements.Button className="step-navigation__order medium primary" onClick={() => onPurchase()} disabled={!canSubmit || apiLoading}>
                            {isExternalPartner ? t("navigation.order") : t("navigation.submit")}
                        </FormElements.Button>
                    )}
                    {!hiddenFields.includes("save_button") && (
                        <FormElements.Button className="step-navigation__save medium primary" onClick={() => onSave()} disabled={!canSave || apiLoading}>
                            {t("navigation.save")}
                        </FormElements.Button>
                    )}
                </div>
            )}
        </div>
    );
};

export default NavigationStep;
