import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { addDays, format } from "date-fns";
import { fr } from "date-fns/esm/locale";
import { AuthContext, roles as rolesJson } from "../../../../../context";
import { getInterlocutors , checkExistAdmin,getIdShadow  } from "../../../../users/service/user.services";
import { checkEmailIsPartner } from "../../../../clients/services/client.services";
import { getProbabilities } from "../../../services";
import { FormElements, Icon, ModalAlert, Pill, Toast } from "../../../../../shared/components";
import useDebounce from "../../../../../shared/utils/debounce";
import Interlocutor from "../../interlocutor/interlocutor";
import SelectInterlocutor from "../../select-interlocutor/select-interlocutor";
import { emailRegex } from "../../../utils/regex";
import { getDetailsRemise } from "../../../../users/service/user.services";
import RemiseForm from "../../../../../shared/pages/config/remise/remise-form";
import "./select-fin.scss";

const SelectFin = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { isAuthenticated, user, permissions } = useContext(AuthContext);
    const {
        cartItems,
        cartMeta,
        setCartMeta,
        setCartItems,
        formValidity,
        setFormValidity,
        mode,
        showToast,
        setShowToast,
        usageCode,
        userSusbtitutions,
        cgv,
        toastDiligencelink,
        offers = userSusbtitutions && userSusbtitutions.length > 0 && cartItems ? cartItems.filter(({ type }) => type === "principal") : [],
        setDisableCgv,
    } = props;
    const { clientPivotId, reference = "", probability, clientReference, cartComment /* , opCom */, modified, codeCamp,internalMonitoring,reminderDate,responseDate } = cartMeta;
    const { commercialEmail, administratorEmail, statusLabel, statusColor, clientContactEmail,status, dateFinRemise } = cartMeta;
    const { appliedPromo, promoValue } = cartMeta;

    const { commercialEmailValid } = formValidity;

    const debouncedCommercialEmail = useDebounce(commercialEmail, 1000);

    const [probabilities, setProbabilities] = useState([]);

    const [interlocutorAdminList, setInterlocutorAdminList] = useState([]);
    const [interlocutorAdminListOptions, setInterlocutorAdminListOptions] = useState([]);
    const [interlocutorAdminValue, setInterlocutorAdminValue] = useState();
    const [showInterlocutorAdminModal, setShowInterlocutorAdminModal] = useState(false);
    const [enableSubmitAdminInterlocutor, setEnableSubmitAdminInterlocutor] = useState(false);

    const [interlocutorContactList, setInterlocutorContactList] = useState([]);
    const [interlocutorContactListOptions, setInterlocutorContactListOptions] = useState([]);
    const [interlocutorContactValue, setInterlocutorContactValue] = useState();
    const [showInterlocutorContactModal, setShowInterlocutorContactModal] = useState(false);
    const [enableSubmitContactInterlocutor, setEnableSubmitContactInterlocutor] = useState(false);
    const [hasAdminSaas, setHasAdminSaas] = useState(false);
    const [hasInterlocutorError, setHasInterlocutorError] = useState(false);
    const [showToastMessage, setShowToastMessage] = useState("");
    const [toastType, setToastType] = useState("");
    const commercialEmailSet = commercialEmail && commercialEmail.length > 0;
    const administratorEmailSet = administratorEmail && administratorEmail.length > 0;
    const contactEmailSet = clientContactEmail && clientContactEmail.length > 0;
    const tiersCode = cartMeta.clientCode;
    const {partnerCode} = cartMeta;
    const [partnerPivotId, setPartnerPivotId] = useState();
    const [administratorEmailPrevious,setAdministratorEmailPrevious] = useState("");
    const [editAccordee, setEditAccordee] = useState(false);
    const [remiseOpecom, setRemiseOpecom] = useState([]);
    const [showRemiseModal, setShowRemiseModal] = useState(false);
    const [promos, setPromos] = useState([]); 
    const [firstload, setFirstload] = useState(true); 
    const [remisesapplicable, setRemisesapplicable] = useState([]);
    const [selectedCodeRemise, setSelectedCodeRemise] = useState(null);
    const [showProposalMessage, setShowProposalMessage] = useState(false);

    
    const {
        proposal: { hiddenFields, roFields }
    } = permissions;

    const salesDisplay = user.role === rolesJson.sales.role;
    const getInterlocutorList = (tiersPivotId, settersList, interlocutorSearchValue) => {
        getInterlocutors(tiersPivotId, { mail: interlocutorSearchValue }).then((res) => {
            settersList.forEach((setter) => setter(res.data));
        });
    };
    const getInterlocutorSaaS = useCallback(
        (pivotId, partner) => {
            getInterlocutors(pivotId, { role: 62 })
                .then((res) => {
                    const { data: adminList } = res;
                    if (adminList.length > 0) {
                        setInterlocutorAdminValue(adminList[0].email);
                        setHasAdminSaas(true);
                        setCartMeta((c) => ({
                            ...c,
                            administratorEmail: adminList[0].email,
                            administratorFirstName: adminList[0].firstName,
                            administratorLastName: adminList[0].lastName,
                            administratorCivility: adminList[0].civility
                        }));
                        setFormValidity((f) => ({ ...f, administratorEmailValid: true }));

                        if (!partner) {
                            getInterlocutorList(pivotId, [setInterlocutorContactList, setInterlocutorContactListOptions]);
                        }
                    } else if (partner) {
                        getInterlocutorList(pivotId, [setInterlocutorAdminList, setInterlocutorAdminListOptions]);
                    } else {
                        getInterlocutorList(pivotId, [
                            setInterlocutorAdminList,
                            setInterlocutorAdminListOptions,
                            setInterlocutorContactList,
                            setInterlocutorContactListOptions
                        ]);
                    }
                })
                .catch(() => {
                    setHasInterlocutorError(true);
                });
        },
        [setCartMeta, setFormValidity]
    );
    useEffect(() => {
        const { validRemise } = cartMeta;
        if (validRemise === false) {
            setShowProposalMessage(true);
        }
    }, [cartMeta.validRemise]);

    useEffect(() => {
        if (administratorEmailSet) {
            setInterlocutorAdminValue(administratorEmail);
        }
    }, [administratorEmail, administratorEmailSet]);

    useEffect(() => {
        if (contactEmailSet) {
            setInterlocutorContactValue(clientContactEmail);
        }
        else{
            setFormValidity({...formValidity, contactEmailValid: false });
        }  // eslint-disable-next-line
    }, [clientContactEmail, contactEmailSet,setFormValidity]);

    useEffect(() => {
        getProbabilities().then((res) => {
            if (res.data && res.data.length > 0) {
                setProbabilities(res.data);
            }
        });
    }, []);

 useEffect(() => {
    if (isAuthenticated && user && (!commercialEmailSet || mode === "edit" || mode === "sepa")) {
        const { email } = user;
        if (email && !commercialEmailSet ) {
            setCartMeta((c) => ({ ...c, commercialEmail: email }));
            setFormValidity((f) => ({ ...f, commercialEmailValid: true }));
        }

        if (administratorEmail && administratorEmail !== administratorEmailPrevious && interlocutorAdminList && interlocutorAdminList.length>0 ) {
            getIdShadow().then((res) => {
                const { access_token : accessToken } = res.data;
                checkExistAdmin(administratorEmail, tiersCode, accessToken).then((result) => {
                    setAdministratorEmailPrevious(administratorEmail);
                    const { existAdminSaas } = result.data;
                    if (!existAdminSaas) {
                        setFormValidity((f) => ({ ...f, administratorEmailValid: true }));
                        if (setDisableCgv) setDisableCgv(false);
                    } else {
                        setFormValidity({ ...formValidity, administratorEmailValid: false });
                        if (setDisableCgv) setDisableCgv(true);
                        if (!showToast) {
                            setShowToast(true);
                            setToastType("error");
                            setShowToastMessage(t("proposals:select-fin.exist-administrateur-email"));
                        }
                    }
                });
            });
        }
    } // eslint-disable-next-line
}, [commercialEmailSet, isAuthenticated, user, setCartMeta, setFormValidity, mode, administratorEmail, tiersCode,interlocutorAdminList]);


    useEffect(() => {
        if (isAuthenticated && user && user.role && user.pivotId && user.role === rolesJson.partner.role) {
            getInterlocutorSaaS(user.pivotId, true);
            if (clientPivotId) {
                getInterlocutorList(clientPivotId, [setInterlocutorContactList, setInterlocutorContactListOptions]);
            }
            setPartnerPivotId(user.pivotId);
        } else if (clientPivotId) {
            getInterlocutorSaaS(clientPivotId, false);
            setPartnerPivotId(clientPivotId);
        }
    }, [getInterlocutorSaaS, clientPivotId, isAuthenticated, user]);

    useEffect(() => {
        if (interlocutorAdminListOptions.length > 0) {
            setInterlocutorAdminList(interlocutorAdminListOptions);
        }
    }, [interlocutorAdminListOptions]);
    useEffect(() => {
        if (mode==="update" && status==="accordee") {
            setEditAccordee(true);
        }
    }, [mode,status]);

    useEffect(() => {
        if (interlocutorContactListOptions.length > 0) {
            setInterlocutorContactList(interlocutorContactListOptions);
        }
    }, [interlocutorContactListOptions]);

    useEffect(() => {
        if (!commercialEmailSet && emailRegex.test(debouncedCommercialEmail.toString()) && !debouncedCommercialEmail.toString().endsWith("@sage.com")) {
            checkEmailIsPartner(debouncedCommercialEmail).then((res) => {
                const { isPartner } = res.data;
                if (!res.data || !isPartner) {
                    setFormValidity((f) => ({ ...f, commercialEmailValid: false }));
                } else {
                    setFormValidity((f) => ({ ...f, commercialEmailValid: true }));
                }
            });
        }
    }, [commercialEmailSet, setFormValidity, debouncedCommercialEmail]);

    useEffect(() => {
        if (cartItems && cartItems.length > 0) {
            getDetailsRemise(cartItems, clientPivotId, user.role).then((res) => {
                if (res.data && res.data.length > 0) {
                    setRemiseOpecom(res.data);
                    setRemisesapplicable(res.data);
                    setPromos(res.data);
                }
            });
        }
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItems]);
    
   /*eslint no-param-reassign: "error"*/
    const appliRemise = (selectedCodePromo) => {

        const selcetedRemise = remisesapplicable.find((o) => o.remiseCode === selectedCodePromo);
        const selectedPromoValue = selcetedRemise && selcetedRemise.valuePromo;
        if ( selcetedRemise && (!cartMeta.appliedPromo || (firstload && cartMeta.appliedPromo === selcetedRemise.remiseCode) || (!firstload && cartMeta.appliedPromo !== selcetedRemise.remiseCode)) && selectedPromoValue ) {

            const references = selcetedRemise && selcetedRemise.referenceProduit.split(',').map(ref => ref.trim());
            let isParentProduct;
            const appliPromo = cartItems.map((o) => {
                const isProductEligible = references && references.includes(o.product);
                const  isParentProductEligible = references && references.includes(o.productParent);
                if (isProductEligible) {
                    const producteligible = cartItems.find(item => item.product === o.product);
                    isParentProduct = producteligible.type === "principal";
                }
    
                if (isProductEligible || isParentProductEligible) {
                    o.originalPrice = o.originalPrice ? o.originalPrice : o.price;
                    o.price = o.originalPrice - (o.originalPrice * (selectedPromoValue / 100));
                    o.appliedPromo = selcetedRemise.remiseCode;
                    o.IdAppliedPromo = selcetedRemise.refcaRemise;
                } else {
                    o.price = o.originalPrice ? o.originalPrice : o.price;
                }
                return o;
            });
            setFirstload(false);
            setCartMeta({
                ...cartMeta,
                IdAppliedPromo: selcetedRemise.refcaRemise,
                appliedPromo: selcetedRemise.remiseCode,
                promoValue: selectedPromoValue,
                refProductPromo: selcetedRemise.referenceProduit,
                isParentRefPromo: isParentProduct, 
                libellePromo: selcetedRemise.name,
                descriptionPromo: selcetedRemise.description,
                pourcentageRemiseOpecom: selcetedRemise.value,
                dateFinRemise: selcetedRemise.dateFin
            });
            setCartItems(appliPromo);
        } else {
            setCartMeta({
                ...cartMeta,
                appliedPromo: '',
                promoValue: '',
                IdAppliedPromo: null,
                refProductPromo: '',
                libellePromo: '',
                descriptionPromo: '',
                pourcentageRemiseOpecom: '',
                dateFinRemise: ''
            });
            setCartItems(cartItems.map((x) => {
                x.price = x.originalPrice ? x.originalPrice : x.price;
                return x;
            }));
        }
    };


    useEffect(() => {
        if (appliedPromo && promoValue && cartItems.length > 0 && cartMeta && firstload && remisesapplicable.length > 0) {
                appliRemise(appliedPromo)
        }
    }, [promoValue,appliedPromo,cartItems,cartMeta, remisesapplicable, mode]);

    const handleChangePromo = (e) => {
        const selectedPromoValue = e.target.value
        appliRemise(selectedPromoValue);
    };
    const handleChange = (e) => {
        setCartMeta({
            ...cartMeta,
            [e.target.name]: e.target.value
        });
    };
    useEffect(() => {
        const {IdAppliedPromo, libellePromo, descriptionPromo } = cartMeta;
        setSelectedCodeRemise(cartMeta.appliedPromo 
            ? { idPromo: IdAppliedPromo, libelle: libellePromo, description: descriptionPromo }
            : null
        );
    }, [cartMeta.appliedPromo]);
    const handleCommercialEmailChange = (e) => {
        setCartMeta({
            ...cartMeta,
            commercialEmail: e.target.value
        });

        if (user.role !== rolesJson.partner.role) {
            if (emailRegex.test(e.target.value) && e.target.value.endsWith("@sage.com")) {
                setFormValidity({ ...formValidity, commercialEmailValid: true });
            } else {
                setFormValidity({ ...formValidity, commercialEmailValid: false });
            }
        } else if (emailRegex.test(e.target.value) && !e.target.value.endsWith("@sage.com")) {
            setFormValidity({ ...formValidity, commercialEmailValid: true });
        } else {
            setFormValidity({ ...formValidity, commercialEmailValid: false });
        }
    };

  const handleAdminEmailChange = (newEmail, temp) => {
    let findInterlocutor = interlocutorAdminList.find(({ email }) => email === newEmail);
    if (newEmail) {
        getIdShadow().then((res) => {
            const { access_token : accessToken } = res.data;
            checkExistAdmin(newEmail, tiersCode, accessToken).then((result) => {
                const { existAdminSaas } = result.data;
                if (!existAdminSaas) {
                    if (!findInterlocutor && temp && temp.length > 0) {
                        findInterlocutor = temp.find(({ email }) => email === newEmail);
                    }
                    if (findInterlocutor) {
                        const { firstName, lastName, civility, interlocutorPivotId } = findInterlocutor;
                        setCartMeta((c) => ({
                            ...c,
                            administratorEmail: newEmail,
                            administratorFirstName: firstName,
                            administratorLastName: lastName,
                            administratorCivility: civility,
                            administratorPivotId: interlocutorPivotId
                        }));
                        setFormValidity((f) => ({ ...f, administratorEmailValid: true }));
                        if (setDisableCgv) setDisableCgv(false);
                    }
                } else {
                    setFormValidity({ ...formValidity, administratorEmailValid: false });
                    if (setDisableCgv) setDisableCgv(true);
                    setShowToast(true);
                    setToastType("error");
                    setShowToastMessage(t("proposals:select-fin.exist-administrateur-email"));
                }
            });
        });
    }
};

    const handleAdminInterlocutorSearch = (interlocutorSearchValue) => {
        setCartMeta((c) => ({
            ...c,
            administratorEmail: "",
            administratorFirstName: "",
            administratorLastName: "",
            administratorCivility: "",
            administratorPivotId: ""
        }));
        setFormValidity((f) => ({ ...f, administratorEmailValid: false }));
        if (isAuthenticated && user && user.role && user.pivotId && user.role === rolesJson.partner.role) {
            getInterlocutorList(user.pivotId, [setInterlocutorAdminListOptions], interlocutorSearchValue);
        } else if (clientPivotId) {
            getInterlocutorList(clientPivotId, [setInterlocutorAdminListOptions], interlocutorSearchValue);
        }
    };

    const handleAddAdminInterlocutor = (interlocutor) => {
        const interlocutorAdminTemp = [...interlocutorAdminList, interlocutor];
        setInterlocutorAdminListOptions(interlocutorAdminTemp);
        if (user.role !== rolesJson.partner.role) {
            const newInterlocutorArray = [...interlocutorContactList, interlocutor];
            setInterlocutorContactListOptions(newInterlocutorArray);
        }
        handleAdminEmailChange(interlocutor.email, interlocutorAdminTemp);
        setInterlocutorAdminValue(interlocutor.email);
        setShowInterlocutorAdminModal(false);
    };

    const handleContactEmailChange = (newEmail, temp) => {
        let findInterlocutor = interlocutorContactList.find(({ email }) => email === newEmail);

        if (!findInterlocutor && temp && temp.length > 0) {
            findInterlocutor = temp.find(({ email }) => email === newEmail);
        }
        if (findInterlocutor) {
            const { email, firstName, lastName, civility } = findInterlocutor;

            setCartMeta({
                ...cartMeta,
                clientContactEmail: email,
                clientContactFirstName: firstName,
                clientContactLastName: lastName,
                clientContactCivility: civility
            });
            setFormValidity({ ...formValidity, contactEmailValid: true });
        }
    };

    const handleContactInterlocutorSearch = (interlocutorSearchValue) => {
        setCartMeta({
            ...cartMeta,
            clientContactEmail: "",
            clientContactFirstName: "",
            clientContactLastName: "",
            clientContactCivility: ""
        });
        setFormValidity({ ...formValidity, contactEmailValid: false });
        getInterlocutorList(clientPivotId, [setInterlocutorContactListOptions], interlocutorSearchValue);
    };

    const handleAddContactInterlocutor = (interlocutor) => {
        const newInterlocutorArray = [...interlocutorContactList, interlocutor];
        setInterlocutorContactListOptions(newInterlocutorArray);

        if (user.role !== rolesJson.partner.role) {
            const newAdminArray = [...interlocutorAdminList, interlocutor];
            setInterlocutorAdminListOptions(newAdminArray);
        }
        handleContactEmailChange(interlocutor.email, newInterlocutorArray);
        setInterlocutorContactValue(interlocutor.email);
        setShowInterlocutorContactModal(false);
    };
   
    const SubstitutionPanelVI = () => {
        if (cartItems && cartItems.length > 0) {
            return (
                <div>
                    <div className="preview__section">
                        <h2 className="preview__section-title">{t("proposals:select-subtitution.title")}</h2>
                        <div className="preview__Substitution_card">
                            <div className="preview_Substitution-description">{t("proposals:select-subtitution.offerslist")}</div>
                            {offers.map((pp) => {
                                const { product: referenceproduit, label: productCommercialName, usageCode: usageCodepp } = pp;

                                const productfiltered = userSusbtitutions.filter((e) => e.checkedValue === true && e.offerProduct === referenceproduit);

                                if (productfiltered && productfiltered.length > 0) {
                                    return (
                                        <div key={`dvi_${referenceproduit}`}>
                                            <p className="preview__card-title">{productCommercialName}</p>
                                            <ul>
                                                {productfiltered.map((p) => {
                                                    const { label, parcPivotId } = p;
                                                    return (
                                                        <li className="substitution__list" key={`li_${parcPivotId}`}>
                                                            {label}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                            {salesDisplay && (
                                                <div className="preview_Substitution-description">
                                                    <p>
                                                        {t(
                                                            `proposals:select-subtitution.${
                                                                referenceproduit === "CSPSA0001" && usageCodepp === "US_EQUIPCLI"
                                                                    ? "prorataPaieVD"
                                                                    : "prorataVD"
                                                            }`,
                                                            { newParcCommercialName: productCommercialName }
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                            {!salesDisplay && (
                                                <div className="preview_Substitution-description">
                                                    <p>
                                                        {t(
                                                            `proposals:select-subtitution.${
                                                                referenceproduit === "CSPSA0001" && usageCodepp === "US_EQUIPCLI"
                                                                    ? "confirmPaie"
                                                                    : "confirmMessage"
                                                            }`,
                                                            { newParcCommercialName: productCommercialName }
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    );
                                }
                                return <div />;
                            })}
                        </div>
                    </div>
                </div>
            );
        }
        return <div />;
    };

    const NewSubstitutionPanelMultiVD = () => {
        const productfiltered = userSusbtitutions.filter((e) => e.checkedValue === true);
        const offersfiltered = [];
        const paieOffer = [];
        offers.forEach((p) => {
            const { product: prodcutp } = p;
            if (userSusbtitutions.some((e) => e.offerProduct === prodcutp)) {
                if (prodcutp === "CSPSA0001") {
                    paieOffer.push(p);
                } else {
                    offersfiltered.push(p);
                }
            }
        });
        const listlibelleOffers = offersfiltered && offersfiltered.length > 0 ? offersfiltered.map((a) => a.label).join() : "";
        return (
            <div>
                <div className="carbon-form__label-wrapper">
                    <div className="carbon-form__label">{t("proposals:select-subtitution.title")}</div>
                </div>
                <div className="preview__Substitution_card">
                    <Trans i18nKey="proposals:select-subtitution.firstMessageVD" parent="div" className="preview_Substitution-description" />
                    <ul>
                        {productfiltered.map((p) => {
                            const { label, parcPivotId } = p;
                            return (
                                <li className="substitution__list" key={`li_${parcPivotId}`}>
                                    {label}
                                </li>
                            );
                        })}
                    </ul>
                    <Trans i18nKey="proposals:select-subtitution.secondMessageVD" parent="div" className="preview_Substitution-description" />
                    <div className="substitution__messages">
                        {paieOffer &&
                            paieOffer.length > 0 &&
                            paieOffer.map((pp) => {
                                const { product: referenceproduit, label: productCommercialName, usageCode: usageCodepp } = pp;
                                return (
                                    <p className="preview_Substitution-description" key={`p_${referenceproduit}`}>
                                        {t(
                                            `proposals:select-subtitution.${
                                                referenceproduit === "CSPSA0001" && usageCodepp === "US_EQUIPCLI" ? "prorataPaieVD" : "prorataVD"
                                            }`,
                                            { newParcCommercialName: productCommercialName }
                                        )}
                                    </p>
                                );
                            })}
                        {offersfiltered && offersfiltered.length > 0 && (
                            <p className="preview_Substitution-description">
                                {t("proposals:select-subtitution.prorataVD", { newParcCommercialName: listlibelleOffers })}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const SubstitutionPanelVD = () => {
        if (cartItems && cartItems.length > 0) {
            if (userSusbtitutions.length > 0 && userSusbtitutions.filter((e) => e.checkedValue === true).length > 0) {
                return <NewSubstitutionPanelMultiVD />;
            }
        }
        return <></>;
    };

    return (
        <>
            <h2 className="new-proposal__content-title">
                {mode === "sepa" ? t("proposals:select-fin.title-vd") : t("proposals:select-fin.title")}
                {statusLabel && <Pill pillColor={statusColor} pillLabel={statusLabel} />}
            </h2>
            <p className="new-proposal__content-description">
                {mode === "sepa" ? t("proposals:select-fin.descriptions-vd") : t("proposals:select-fin.description")}
            </p>

            <div className="preview__section">
                <div className="preview__form">
                    <Toast
                        show={showToast}
                        onDismiss={() => setShowToast(false)}
                        message={showToastMessage}
                        toastType={toastType}
                        insideNestedPortal
                        toastDiligencelink={toastDiligencelink}
                    />
                    {!hiddenFields.includes("reference") && (
                        <label className="preview__form-item">
                            <div className="carbon-form__label-wrapper">
                                <div className="carbon-form__label">{t("proposals:select-fin.reference")}</div>
                            </div>
                            <FormElements.Input name="reference" value={reference} disabled />
                        </label>
                    )}

                    {!hiddenFields.includes("clientReference") && (
                        <label className="preview__form-item">
                            <div className="carbon-form__label-wrapper">
                                <div className="carbon-form__label">{t("proposals:select-fin.clientReference")}</div>
                            </div>
                            <FormElements.Input
                                name="clientReference"
                                onChange={handleChange}
                                value={clientReference}
                                disabled={roFields.includes("clientReference") || editAccordee}
                            />
                        </label>
                    )}

                    {/* {!hiddenFields.includes("opCom") && usageCode !== "US_VDA" && usageCode !== "US_PEI" && (
                        <label className="preview__form-item">
                            <div className="carbon-form__label-wrapper">
                                <div className="carbon-form__label">{t("proposals:select-fin.opCom")}</div>
                            </div>
                            <FormElements.Input name="opCom" onChange={handleChange} value={opCom} disabled={roFields.includes("opCom")} />
                        </label>
                    )} */}

                    {!hiddenFields.includes("codeCamp") && usageCode !== "US_VDA" && usageCode !== "US_PEI" && user.role === rolesJson.sales.role  && (
                        <label className="preview__form-item">
                            <div className="carbon-form__label-wrapper">
                                <div className="carbon-form__label">{t("proposals:select-fin.codeCamp")}</div>
                            </div>
                              <FormElements.Input
                              maxLength="100"
                              name="codeCamp"
                              onChange={handleChange}
                              value={codeCamp}
                              disabled={roFields.includes("codeCamp")}
                          />                       
                        </label>
                    )}

                    {!hiddenFields.includes("probability") && usageCode !== "US_VDA" && usageCode !== "US_PEI" && user.role !== rolesJson.endUser.role && (
                        <label className="preview__form-item">
                            <div className="carbon-form__label-wrapper">
                                <div className="carbon-form__label">{t("proposals:select-fin.probability")}</div>
                            </div>                             
                            <FormElements.Select
                                className="preview-item__probability"
                                name="probability"
                                value={probability}
                                onChange={handleChange}
                                options={probabilities}
                                disabled={roFields.includes("probability") || editAccordee}
                            />
                        </label>
                    )}

                    {!hiddenFields.includes("commercialEmail") && (
                        <label className="preview__form-item">
                            <div className="carbon-form__label-wrapper">
                                <div className="carbon-form__label">{`${t("proposals:select-fin.commercial-contact")} *`}</div>
                            </div>                         
                            <FormElements.Input
                                name="commercialEmail"
                                onChange={handleCommercialEmailChange}
                                value={commercialEmail}
                                disabled={roFields.includes("commercialEmail") || editAccordee}
                            />
                            {!commercialEmailValid && <div className="carbon-form__error">{`${t("proposals:select-fin.commercial-contact-error")}`}</div>}
                        </label>
                    )}

                    {!hiddenFields.includes("administratorEmail") && usageCode !== "US_VDA" && usageCode !== "US_PEI" && (
                        <label className="preview__form-item">
                            <div className="carbon-form__label-wrapper">
                                <div className="carbon-form__label">{`${t("proposals:select-fin.client-contact")} *`}</div>
                            </div>
                            {(roFields.includes("clientContactEmail") || editAccordee) && (
                                <FormElements.Input  value={interlocutorContactValue} name="clientContactEmail" disabled />
                            )}
                            {interlocutorContactList && !roFields.includes("clientContactEmail")&& !editAccordee && (
                                <SelectInterlocutor
                                    value={interlocutorContactValue}
                                    onChange={handleContactEmailChange}
                                    label={t("proposals:select-fin.interlocutor-placeholder")}
                                    options={interlocutorContactList}
                                    searchOptions={interlocutorContactListOptions}
                                    openModal={setShowInterlocutorContactModal}
                                    buttonLabel={t("proposals:select-fin.create-interlocutor")}
                                    handleInterlocutorSearch={handleContactInterlocutorSearch}
                                    name="clientContactEmail"
                                />
                            )}
                        </label>
                    )}
                    {!hiddenFields.includes("administratorEmail") && (
                        <label className="preview__form-item">
                            <div className="carbon-form__label-wrapper">
                                <div className="carbon-form__label">{`${t("proposals:select-fin.admin-contact")} *`}</div>
                                <div className="carbon-form__label-helper">
                                    <Icon name="ui-infos" />
                                    <div className="carbon-tooltip-wrapper">
                                        <div className="carbon-tooltip">
                                            <div className="carbon-tooltip__inner">
                                                {t("proposals:select-fin.tooltip-description")}
                                                <div className="carbon-tooltip__arrow" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(roFields.includes("administratorEmail") || hasAdminSaas || editAccordee) && (
                                <FormElements.Input className="preview-item__admin" value={interlocutorAdminValue} name="administratorEmail" disabled />
                            )}
                            {interlocutorAdminList && !roFields.includes("administratorEmail") && !hasAdminSaas && !editAccordee && (
                                <SelectInterlocutor
                                    value={interlocutorAdminValue}
                                    onChange={handleAdminEmailChange}
                                    label={t("proposals:select-fin.interlocutor-placeholder")}
                                    options={interlocutorAdminList}
                                    searchOptions={interlocutorAdminListOptions}
                                    openModal={setShowInterlocutorAdminModal}
                                    buttonLabel={t("proposals:select-fin.create-interlocutor")}
                                    handleInterlocutorSearch={handleAdminInterlocutorSearch}
                                    name="administratorEmail"
                                />
                            )}
                        </label>
                    )}

                     {!hiddenFields.includes("reminderDate") && (user.role === rolesJson.sales.role|| user.role === rolesJson.partner.role)  && (
                        <label className="preview__form-item">
                            <div className="carbon-form__label-wrapper">
                                <div className="carbon-form__label">{t("proposals:select-fin.reminder")}</div>
                            </div>
                            <FormElements.Input   
                                maxLength="100"
                                name="reminderDate"
                                icon="ui-calendar"
                                className="carbon-form__date-input"
                                type="date"
                                onChange={handleChange}
                                value={reminderDate}
                                disabled={roFields.includes("reminderDate")}
                            />
                        </label>
                    )}
                    {!hiddenFields.includes("remiseCode") && user.role !== rolesJson.endUser.role && remiseOpecom && remiseOpecom.length > 0 && (

                        <label className="preview__form-item">
                            <div className="carbon-form__label-wrapper">
                                <div className="carbon-form__label">{t("proposals:select-fin.remise-libelle")}</div>
                            </div>

                            <div className={"select-interlocutor full"}>

                                <FormElements.Select
                                    className="preview-item__admin select-interlocutor__select"
                                    name="remiseCode"
                                    value={appliedPromo}
                                    onChange={handleChangePromo}                                    
                                    options={promos}
                                    disabled={roFields.includes("remiseCode") || editAccordee}
                                />
                                <FormElements.Button className="select-interlocutor__button medium dashed" onClick={() => setShowRemiseModal(true)}>
                                    <Icon name="ui-list" />
                                </FormElements.Button>
                            </div>
                        </label>

                    )} 
                     {!hiddenFields.includes("responseDate") &&(user.role === rolesJson.sales.role|| user.role === rolesJson.partner.role) && (
                        <label className="preview__form-item">
                            <div className="carbon-form__label-wrapper">
                                <div className="carbon-form__label">{t("proposals:select-fin.response")}</div>
                            </div>
                            <FormElements.Input 
                                maxLength="100"
                                icon="ui-calendar"
                                className="carbon-form__date-input"
                                type="date"
                                name="responseDate"
                                onChange={handleChange}
                                value={responseDate}
                                disabled={roFields.includes("responseDate")}
                            />
                        </label>
                    )}
    
                    {!hiddenFields.includes("internalMonitoring") &&(user.role === rolesJson.sales.role|| user.role === rolesJson.partner.role)&& (
                        <label className="preview__form-item preview__form-item--fullwidth">
                            <div className="carbon-form__label-wrapper">
                                <div className="carbon-form__label">{t("proposals:select-fin.internal")}</div>
                            </div>
                            <FormElements.Textarea
                                name="internalMonitoring"
                                value={internalMonitoring}
                                onChange={handleChange}
                                rows={4}
                                maxLength="256"
                                disabled={roFields.includes("internalMonitoring")}
                            />
                        </label>
                    )}
                    {!hiddenFields.includes("cartComment") && usageCode !== "US_VDA" && usageCode !== "US_PEI" && (
                        <label className="preview__form-item preview__form-item--fullwidth">
                            <div className="carbon-form__label-wrapper">
                                <div className="carbon-form__label">{t("proposals:select-fin.comment")}</div>
                            </div>
                            <FormElements.Textarea
                                name="cartComment"
                                value={cartComment}
                                onChange={handleChange}
                                placeholder={t("proposals:select-fin.comment-placeholder")}
                                rows={4}
                                maxLength="256"
                                disabled={roFields.includes("cartComment") }
                            />
                        </label>
                    )}
                </div>
                <div className="preview__mandatory">{t("forms.mandatory")}</div>
            </div>
            {!cgv && userSusbtitutions && userSusbtitutions.filter((e) => e.checkedValue === true).length > 0 && <SubstitutionPanelVI />}
            {cgv && !salesDisplay && <SubstitutionPanelVD />}
            {usageCode !== "US_VDA" && usageCode !== "US_PEI" && (
                <div className="preview__section">
                    <h3 className="preview__section-title">{t("proposals:select-fin.information")}</h3>

                    <div className="preview__card">
                        <Icon name="ui-bank" className="preview__card-icon" />
                        <div>
                            <div className="preview__card-title">{t("proposals:sepa-recap.information.payment.title")}</div>
                            {user.role === rolesJson.partner.role || (partnerCode && partnerCode.length > 0) ? (
                                <>
                                    {" "}
                                    <div className="preview__card-description">{t("proposals:sepa-recap.information.payment.lcr")}</div>
                                    <div className="preview__card-description">{t("proposals:sepa-recap.information.payment.withdrawal")}</div>
                                    <div className="preview__card-description">{t("proposals:sepa-recap.information.payment.mandate")}</div>
                                </>
                            ) : (
                                <>
                                    <div className="preview__card-description">{t("proposals:sepa-recap.information.payment.withdrawal")}</div>
                                    <div className="preview__card-description">{t("proposals:sepa-recap.information.payment.mandate")}</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="preview__card">
                        <Icon name="ui-calendar" className="preview__card-icon" />
                        <div>
                            <div className="preview__card-title">{format(new Date(modified), "PPP", { locale: fr })}</div>
                            <div className="preview__card-description">
                                {`${t("proposals:select-fin.validity-date")} ${selectedCodeRemise && dateFinRemise && new Date(addDays(new Date(modified), 30)) >= new Date(dateFinRemise)
                                    ? format(new Date(dateFinRemise), "PPP", { locale: fr })
                                    : format(addDays(new Date(modified), 30), "PPP", { locale: fr })}`}
                            </div>
                        </div>
                    </div>

                    <div className="preview__card">
                        <Icon name="services-desk" className="preview__card-icon" />
                        <div>
                            <div className="preview__card-title">{t("proposals:select-fin.service-name")}</div>
                            <div className="preview__card-description">{t("proposals:select-fin.service-description")}</div>
                        </div>
                    </div>
                    {selectedCodeRemise && (
                        <div className="preview__card">
                            <Icon name="ui-document" className="preview__card-icon" />
                            <div>
                                <div className="preview__card-title">{t("proposals:select-fin.commercial-remise")}</div>
                                <div className="preview__card-description">{`${selectedCodeRemise.libelle}`}</div>
                                <div className="preview__card-description">{`${selectedCodeRemise.description}`}</div>
                            </div>
                        </div>
                    )}
                </div>
            )}

            <ModalAlert
                className="list-proposal__alert"
                visible={showInterlocutorAdminModal}
                onAccept={handleAddAdminInterlocutor}
                onCancel={() => {
                    setShowInterlocutorAdminModal(false);
                    setEnableSubmitAdminInterlocutor(false);
                }}
                title={t("proposals:select-fin.interlocutor.create-interlocutor-title")}
                cancelButtonText={t("navigation.cancel")}
                acceptButtonText={t("navigation.save")}
                enableSubmit={enableSubmitAdminInterlocutor}
                submit="interlocutor-form">
                <Interlocutor onAccept={handleAddAdminInterlocutor} enableSubmit={setEnableSubmitAdminInterlocutor} clientPivotId={partnerPivotId} />
            </ModalAlert>

            <ModalAlert
                className="list-proposal__alert"
                visible={showInterlocutorContactModal}
                onAccept={handleAddContactInterlocutor}
                onCancel={() => {
                    setShowInterlocutorContactModal(false);
                    setEnableSubmitContactInterlocutor(false);
                }}
                title={t("proposals:select-client.add-interlocutor")}
                cancelButtonText={t("navigation.cancel")}
                acceptButtonText={t("navigation.save")}
                enableSubmit={enableSubmitContactInterlocutor}
                submit="interlocutor-form">
                <Interlocutor onAccept={handleAddContactInterlocutor} enableSubmit={setEnableSubmitContactInterlocutor} clientPivotId={clientPivotId} />
            </ModalAlert>
            <ModalAlert
                className="new-proposal__alert"
                icon="missing"
                visible={hasInterlocutorError}
                onClickButton={() => {
                    history.push("/proposals/list");
                }}
                title={t("proposals:sepa-loading.title-error")}
                acceptButtonText="Ok">
                <>
                    <div className="attachment-list__description">
                        <p>{t("proposals:sepa-loading.message-error")}</p>
                    </div>
                </>
            </ModalAlert>
            <ModalAlert
                className="list-remise__alert"
                visible={showRemiseModal}
                onCancel={() => {
                    setShowRemiseModal(false);
                }}
                title={t("users:select-fin.remise-list")}
                cancelButtonText={t("navigation.close")}>
                <RemiseForm remiseList={remiseOpecom} />
            </ModalAlert>
            <ModalAlert
                className="new-proposal__alert"
                visible={showProposalMessage}
                onClickButton={() => { setShowProposalMessage(false);               
                    history.push("/proposals/list");
                }}
                title={t("proposals:list-proposals-page.nonvalid-title")}
                icon="ui-cart-buy"
                acceptButtonText="Ok">
                
                <p>{t("proposals:list-proposals-page.nonvalid-proposal")}</p>
            </ModalAlert> 
        </>
    );
};

export default SelectFin;
