import React from "react";
import Select, { components } from "react-select";
import { useTranslation } from "react-i18next";
import "./multiselect.scss";

const Option = (props) => {
    const { isSelected, label } = props;
    return (
        <div>
            {/* eslint-disable react/jsx-props-no-spreading */}
            <components.Option {...props}>
                <input type="checkbox" checked={isSelected} onChange={() => null} />
                <label>{label}</label>
            </components.Option>
        </div>
    );
};

const MultiSelectCompo = (props) => {
    const { options, optionSelected, onChange: handler } = props;
    const { t } = useTranslation();
    return (
        <span className="d-inline-block" data-toggle="popover" data-trigger="focus" data-content="Please selecet account(s)">
            <Select
                options={options}
                className="multi-select"
                classNamePrefix="multiprefix"
                defaultValue={optionSelected}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                    Option
                }}
                onChange={handler}
                allowSelectAll
                placeholder={<div className="placeholder-text">{t("proposals:list-proposals-page.status-list")}</div>}
            />
        </span>
    );
};

export default MultiSelectCompo;
