import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { AuthContext, roles } from "../../../context";
import { terminateSubscription, getReasonsTerminate, getSubscription } from "../service/subscription.service";
import { FormElements, ModalAlert, Toast } from "../../../shared/components";
import { handleRestrictions } from "../../proposals/utils/toast.handler";
import { getInterlocutors } from "../../users/service/user.services";

import "./form-resiliation.scss";

const FormResiliation = (props) => {
    const { user } = useContext(AuthContext);
    const { pivotId } = user;
    const { setShowForm, subscription, updatePage } = props;
    const { reference } = subscription;
    const { t } = useTranslation();
    const [showModalFormResiliation, setShowModalFormResiliation] = useState(true);
    const [formValidity, setFormValidity] = useState({
        reasonValidity: false,
        checkboxValidty: false,
        interlocutorValidity: true
    });
    const [showToast, setShowToast] = useState(false);
    const [showToastMessage, setShowToastMessage] = useState("");
    const [toastType, setToastType] = useState("");
    const [toastDiligencelink, setToastDiligencelink] = useState(false);

    const [saveResil, setSaveResil] = useState(false);
    const [selectDateResil, setSelectDateResil] = useState(true);
    const [disbledSave, setDisbledSave] = useState(false);
    const [reasons, setReasons] = useState([]);
    const [interlocutor, setInterlocutor] = useState([]);
    const [commercialEmail, setCommercialEmail] = useState([]);
    const [resiliationInf, setResiliationInf] = useState({
        motifValue: "",
        commentMessage: "",
        newEmail: ""
    });
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    const dateNow = new Date().getDate();
    let lastDate;
    let firstDateResil;
    let lastDateFormatted;
    let lastDateFormattedSelect;
    let firstDateResilSelect;
    let lastDateselected;

    if (dateNow < 16) {
        lastDate = new Date(year, month + 1, 0);
        lastDateFormatted = format(lastDate, "dd/MM/yyyy");
        firstDateResil = format(new Date(year, month + 1, 1), "dd/MM/yyyy");
    } else {
        lastDate = new Date(year, month + 2, 0);
        lastDateFormatted = format(lastDate, "dd/MM/yyyy");
        firstDateResil = format(new Date(year, month + 2, 1), "dd/MM/yyyy");
        if (user.role === roles.credit.role || user.role === roles.sales.role) {
            lastDateselected = new Date(year, month + 1, 0);
            lastDateFormattedSelect = format(lastDateselected, "dd/MM/yyyy");
            firstDateResilSelect = format(new Date(year, month + 1, 1), "dd/MM/yyyy");
        }
    }
    const [dateResilSelected, setDateResilSelected] = useState(firstDateResil);
    const [dateFormattedSelected, setDateFormattedSelected] = useState(lastDateFormatted);
    const [datelast, setDatelast] = useState(lastDate);

    useEffect(() => {
        getReasonsTerminate().then((res) => {
            if (res.data && res.data.length > 0) {
                setReasons(res.data);
            }
        });
    }, []);

    useEffect(() => {
        if (user.role === roles.partner.role) {
            getInterlocutors(pivotId, { role: "external_partner" }).then((res) => {
                if (res.data && res.data.length > 0) {
                    setInterlocutor(res.data);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getSubscription(reference).then((res) => {
            const { subscriptionItems } = res.data;
            subscriptionItems.map((subItem) => setCommercialEmail(subItem.commercialEmail));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCancel = () => {
        setShowModalFormResiliation(false);
        setShowForm(false);
    };

    const handleOnclick = () => {
        if (!saveResil) {
            setDisbledSave(true);
            terminateSubscription(subscription, datelast, dateFormattedSelected, dateResilSelected, resiliationInf)
                .then(() => {
                    setSaveResil(true);
                    setDisbledSave(false);
                })
                .catch((error) => {
                    setDisbledSave(false);
                    handleRestrictions(error, setShowToast, setToastType, setShowToastMessage, user, setToastDiligencelink);
                });
        } else {
            setDisbledSave(false);
            setShowForm(false);
            setShowModalFormResiliation(false);
            updatePage();
        }
    };

    const handleOnchangeMotif = (e) => {
        if (!e.target.value) {
            setFormValidity((f) => ({ ...f, reasonValidity: false }));
        } else {
            setFormValidity((f) => ({ ...f, reasonValidity: true }));
        }
        setResiliationInf({
            ...resiliationInf,
            motifValue: e.target.value
        });
    };

    const handleOnchangeMessage = (e) => {
        setResiliationInf({
            ...resiliationInf,
            commentMessage: e.target.value
        });
    };
    const handleOnchangeInterlocutor = (e) => {
        setResiliationInf({
            ...resiliationInf,
            newEmail: e.target.value
        });
        if (!e.target.value) {
            setFormValidity((f) => ({ ...f, interlocutorValidity: false }));
        } else {
            setFormValidity((f) => ({ ...f, interlocutorValidity: true }));
        }
    };

    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setFormValidity((f) => ({ ...f, checkboxValidty: true }));
        } else {
            setFormValidity((f) => ({ ...f, checkboxValidty: false }));
        }
    };

    return (
        <>
            <Toast
                show={showToast}
                onDismiss={() => setShowToast(false)}
                message={showToastMessage}
                toastType={toastType}
                toastDiligencelink={toastDiligencelink}
            />
            <ModalAlert
                className="resiliation-form__alert"
                visible={showModalFormResiliation}
                onCancel={saveResil ? false : handleCancel}
                onClickButton={handleOnclick}
                enableSubmit={formValidity.reasonValidity && formValidity.checkboxValidty && formValidity.interlocutorValidity && !disbledSave}
                title={`${t("subscriptions:list-subscriptions-page.resiliation-form-title")} ${reference} `}
                cancelButtonText={t("subscriptions:list-subscriptions-page.resiliation-cancel")}
                acceptButtonText={
                    saveResil ? t("subscriptions:list-subscriptions-page.resiliation-close") : t("subscriptions:list-subscriptions-page.resiliation-save")
                }>
                {!saveResil && (
                    <>
                        <label>
                            <div className="resiliation-form__label-wrapper">
                                <div className="carbon-form__label">{t("subscriptions:list-subscriptions-page.resiliation-bigining-message")}</div>
                            </div>
                        </label>
                        <label className="preview__form-item">
                            <div className="resiliation-form__label-wrapper">
                                <div className="carbon-form__label">
                                    {dateNow > 15 && (user.role === roles.credit.role || user.role === roles.sales.role) ? (
                                        <>
                                            {`${t("subscriptions:list-subscriptions-page.resiliation-date-message")}`}
                                            <div className="resiliation-menu__fieldset">
                                                <label className="search-menu__checkbox carbon-form__radio">
                                                    <input
                                                        type="radio"
                                                        name="dateselected-radio"
                                                        checked={!selectDateResil}
                                                        onChange={() => {
                                                            setSelectDateResil(false);
                                                            setDateResilSelected(firstDateResilSelect);
                                                            setDateFormattedSelected(lastDateFormattedSelect);
                                                            setDatelast(lastDateselected);
                                                        }}
                                                    />
                                                    <div className="carbon-form__radio-icon" />

                                                    <span>{firstDateResilSelect}</span>
                                                </label>

                                                <label className="search-menu__checkbox carbon-form__radio">
                                                    <input
                                                        type="radio"
                                                        name="datecalculated-radio"
                                                        checked={selectDateResil}
                                                        onChange={() => {
                                                            setSelectDateResil(true);
                                                            setDateResilSelected(firstDateResil);
                                                            setDateFormattedSelected(lastDateFormatted);
                                                            setDatelast(lastDate);
                                                        }}
                                                    />
                                                    <div className="carbon-form__radio-icon" />

                                                    <span>{firstDateResil}</span>
                                                </label>
                                            </div>
                                        </>
                                    ) : (
                                        <>{`${t("subscriptions:list-subscriptions-page.resiliation-date-message")} ${firstDateResil},`}</>
                                    )}
                                </div>
                            </div>
                        </label>
                        <label className="preview__form-item">
                            <div className="resiliation-form__label-wrapper">
                                <div className="carbon-form__label">{t("subscriptions:list-subscriptions-page.resiliation-dataclient-message")}</div>
                            </div>
                        </label>
                        <label className="preview__form-item">
                            <div className="resiliation-form__label-wrapper">
                                <div className="carbon-form__label">{t("subscriptions:list-subscriptions-page.resiliation-data-message")}</div>
                            </div>
                        </label>
                        <div className="resiliation-form__item">
                            <div className="new-client-form__label">{`${t("subscriptions:list-subscriptions-page.resiliation-form-reasons")} *`}</div>
                            <FormElements.Select className="resiliation-form__select" name="reason" options={reasons} onChange={handleOnchangeMotif} />
                        </div>
                        {user.role === roles.partner.role ? (
                            <div className="resiliation-form__item">
                                <div className="new-client-form__label">{`${t("subscriptions:list-subscriptions-page.resiliation-interlocutor")} `}</div>
                                <FormElements.Select
                                    className="resiliation-form__select"
                                    name="interlocutor"
                                    data={interlocutor}
                                    select={commercialEmail}
                                    onChange={handleOnchangeInterlocutor}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="resiliation-form__item">
                            <div className="new-client-form__label">{t("subscriptions:list-subscriptions-page.add-message")}</div>

                            <FormElements.Textarea
                                name="addMessage"
                                placeholder={t("subscriptions:list-subscriptions-page.placeholder-message")}
                                rows={4}
                                maxLength="256"
                                onChange={handleOnchangeMessage}
                            />
                        </div>
                        <div className="preview__form-item">
                            <div className="resiliation-form__item">
                                <div className="resiliation-form__mandatory">{t("subscriptions:list-subscriptions-page.mandatory")}</div>
                            </div>
                        </div>
                        <div className="resiliation-form__item">
                            <label className="carbon-form__checkbox">
                                <input type="checkbox" name="read-checkbox" onChange={handleCheckboxChange} />
                                <div className="carbon-form__checkbox-icon" />
                                <span>{t("subscriptions:list-subscriptions-page.checkbox-ababdon")}</span>
                            </label>
                        </div>
                    </>
                )}
                {saveResil && (
                    <>
                        <label>
                            <div className="resiliation-form__label-wrapper">
                                <div className="carbon-form__label">{t("subscriptions:list-subscriptions-page.resiliation-confirm-save")}</div>
                            </div>
                        </label>
                        <label>
                            <div className="resiliation-form__label-wrapper">
                                <div className="carbon-form__label">
                                    {`${t("subscriptions:list-subscriptions-page.resiliation-confirm-details")} ${dateResilSelected}`}
                                </div>
                            </div>
                        </label>
                    </>
                )}
            </ModalAlert>
        </>
    );
};

export default FormResiliation;
