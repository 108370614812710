import React from "react";
import { useTranslation } from "react-i18next";
import "./cgv-footer.scss";

const CgvFooter = (props) => {
    const { cgv, onAcceptCgv, disableCgv } = props;

    const { t } = useTranslation();

    const handleChange = (e) => {
        onAcceptCgv(e.target.checked);
    };

    return (
        <div className="cgv-footer">
            <label className="cgv-footer__policy">
                <input className="cgv-footer__check" type="checkbox" name="cgv" checked={cgv} onChange={handleChange} disabled={disableCgv} />
                <p>
                    {`${t("proposals:cgv-footer.verb")} `}
                    <a href="https://media.sage.fr/cg/CGU-CPS-SBC.pdf" target="_blank" rel="noopener noreferrer">
                        {`${t("proposals:cgv-footer.sales-terms")}`}
                    </a>
                    {` ${t("proposals:cgv-footer.and")} `}
                    <a href="https://www.sage.com/fr-fr/informations-legales/protection-vie-privee-cookies/" target="_blank" rel="noopener noreferrer">
                        {`${t("proposals:cgv-footer.privacy-policy")}`}
                    </a>
                </p>
            </label>
        </div>
    );
};

export default CgvFooter;
