import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import PageWrapper from "../../../../shared/components/page-wrapper/page-wrapper";
import "./dashboard.scss";
import OffersList from "../../../proposals/components/offers-list/offers-list";
import DashboardProposal from "../../components/dashboard-proposal/dashboard-proposal";
import DashboardSubscription from "../../components/dashboard-subscription/dashboard-subscription";
import Assistance from "../../../../shared/components/assistance/assistance";
import Actu from "../../../../shared/components/actu/actu";
import DashboardInvoice from "../../components/dashboard-invoice/dashboard-invoice";
import DashboardClients from "../../components/dashboard-client/dashboard-client";
import { AuthContext } from "../../../../context";

const Dashboard = () => {
    // TODO : remove when configure genesys
    const { t } = useTranslation();
    /* const urlPathname = window.location.href.toLowerCase();
    useLiveChat(urlPathname); */
    // const [showActu, setShowActu] = useState(false);
    const { user } = useContext(AuthContext);
    // useEffect(() => {
    //     const date = new Date();
    //     const pickedDate = new Date(2021, 8, 1);

    //     if (date > pickedDate) {
    //         setShowActu(true);
    //     }
    // }, [setShowActu]);
    return (
        <PageWrapper className="dashboard">
            <Assistance />
             <Actu />
            <div className="WrapperHome">
                <div className={`${user.role === "internal_sales" ? "gridDashboard" : ""} `}>
                    <div className="offers">
                        <h2 className="actu__title">{t("users:dashbord-proposal.title")}</h2>
                        <div className="WrapperTitle">{t("users:dashbord-proposal.subtitle")}</div>
                    </div>
                    <div className={`${user.role === "internal_sales" ? "dashboard__headerSales" : "dashboard__header"} `}>
                        <DashboardProposal />
                        <DashboardSubscription />
                        <DashboardInvoice />
                        <DashboardClients />
                    </div>
                </div>
                <div>
                    {" "}
                    <OffersList />
                </div>
            </div>
        </PageWrapper>
    );
};

export default Dashboard;
