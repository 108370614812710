import React from "react";
import { useTranslation } from "react-i18next";
import { FormElements, Icon } from "../../../../shared/components";
import { getCurrency } from "../../../../shared/utils/string.utils";
import "./card-multiple-product.scss";

const CardMultipleProduct = (props) => {
    const { offer, onAddToCart, buttonName = "proposals:select-offer.add", iconShow = "message-success-2", buttonStyle = "medium secondary", usage } = props;
    const { t } = useTranslation();
    const { usageCode, ppPrice, spPrice, ppDiscount, spDiscount, offerType, principalProductLabel } = offer[0];
    const [eur, cents] = usageCode === "US_EQUIPCLI" ? getCurrency(ppPrice * ppDiscount + spPrice * spDiscount) : getCurrency(0);
    return (
        <div className="multiple-card">
            <div className="multiple-card__header">
                <h2 className="cardm__offer-name">{principalProductLabel}</h2>
            </div>
            <div className="multiple-card__container">
                {offer.map((off) => {
                    const { secondaryProduct, secondaryProductLabel, secondaryProductDescription } = off;
                    return (
                        <div
                            className="multiple-card__card"
                            key={secondaryProduct}
                            role="button"
                            tabIndex={0}
                            onClick={() => onAddToCart(off)}
                            onKeyDown={() => onAddToCart(off)}>
                            <p className="card__offer_SEE">{offerType}</p>
                            <div className="cardm__header">
                                <h2 className="cardm__offer-name">
                                    {secondaryProductLabel.length > 40 ? `${secondaryProductLabel.slice(0, 35)}${t("...")}` : secondaryProductLabel}
                                </h2>
                            </div>
                            <div>
                                <div align="left" className="cardm__row">
                                    {(eur !== "0" || cents !== "00") && !usage && (
                                        <p className="cardm__offer-price">
                                            <span className="offer-price__amount-euros">{parseFloat(eur).toLocaleString()}</span>
                                            <span className="offer-price__amount-cents">{`,${cents}`}</span>
                                            {` €/${t("date.month")}`}
                                        </p>
                                    )}
                                    <div className="card__cta">
                                        <div className="card_bouton" align="center">
                                            <FormElements.Button className={buttonStyle} onClick={() => onAddToCart(off)}>
                                                {t(buttonName)}
                                            </FormElements.Button>
                                        </div>
                                    </div>
                                    {secondaryProductDescription && secondaryProductDescription.length > 0 && (
                                        <ul className="cardm__offer-options">
                                            {secondaryProductDescription.map((includedOption) => (
                                                <li key={includedOption}>
                                                    {iconShow && iconShow !== "" && <Icon name={iconShow} />}
                                                    {includedOption}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export default CardMultipleProduct;
