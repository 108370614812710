import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { getAllClients } from "../../../../../clients/services/client.services";
import { Lists, Loading, ModalAlert } from "../../../../../../shared/components";
import { AuthContext } from "../../../../../../context";

const SelectClientList = (props) => {
    const { client, onChange, clientSearchValues, selectedTiers } = props;
    const { t } = useTranslation();
    const { isAuthenticated, user } = useContext(AuthContext);

    const [clientsList, setClientsList] = useState([]);
    const [totalClients, setTotalClients] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState();
    const [selectedClient, setSelectedClient] = useState(client);
    const [hasClientError, setHasClientError] = useState(false);

    const location = useLocation();
    const history = useHistory();
    const { page = "0" } = queryString.parse(location.search);
    const currentPage = parseInt(page, 10);
    const pageSize = 10;

    const userActions = [{ name: "tiers", type: "radio" }];

    const handleClientChange = (row) => {
        const {
            pivotId,
            siret: clientReference,
            tiersCode: clientCode,
            tiersName: clientName,
            tiersBloque,
            dueDiligence,
            dueDiligenceDateFin,
            sanctionDateFin,
            sanctionPolicy
        } = row;
        setSelectedClient({
            pivotId,
            clientReference,
            clientCode,
            clientName,
            tiersBloque,
            dueDiligence,
            dueDiligenceDateFin,
            sanctionDateFin,
            sanctionPolicy
        });
        onChange({ pivotId, clientReference, clientCode, clientName, tiersBloque, dueDiligence, dueDiligenceDateFin, sanctionDateFin, sanctionPolicy });
    };

    const columns = [
        { name: "code", type: "text", dbRef: "tiersCode", label: t("clients:list-clients-page.code") },
        { name: "name", type: "text", dbRef: "tiersName", label: t("clients:list-clients-page.name") },
        { name: "siret", type: "text", dbRef: "siret", label: t("clients:list-clients-page.siret") },
        { name: "zipCode", type: "text", dbRef: "zipCode", label: t("clients:list-clients-page.zipCode") },
        { name: "city", type: "text", dbRef: "city", label: t("clients:list-clients-page.city") },
        { name: "email", type: "text", dbRef: "email", label: t("clients:list-clients-page.email") },
        { name: "phone", type: "text", dbRef: "phone", label: t("clients:list-clients-page.phone") }
    ];

    useEffect(() => {
        const queryParam = {
            index: currentPage,
            limit: pageSize,
            count: false,
            ...clientSearchValues
        };
        if (isAuthenticated && user) {
            setLoading(true);
            getAllClients({ ...queryParam })
                .then((res) => {
                    const { clientsList: clientListData } = res.data;
                    const { countTotal } = res.data;
                    if (clientListData) {
                        if (selectedTiers) {
                            setClientsList(clientListData.filter((e) => e.pivotId === selectedTiers.pivotId));
                            setTotalClients(1);
                        } else {
                            setClientsList(clientListData);
                            if (countTotal != null && typeof countTotal === "number") {
                                setTotalClients(countTotal);
                            }
                        }
                        setLoading(false);
                    }
                })
                .catch(() => {
                    setLoading(false);
                    setHasClientError(true);
                });
        }
    }, [currentPage, isAuthenticated, clientSearchValues, user, selectedTiers]);

    const changePage = (pageNumber) => {
        history.push(`/proposals/new?step=client&page=${pageNumber}`);
    };

    if (loading) return <Loading />;

    return (
        <>
            <Lists.ListTable
                columns={[...userActions, ...columns]}
                content={clientsList}
                rowKey="tiersCode"
                selected={selectedClient}
                onSelect={handleClientChange}
            />
            <Lists.ListPagination
                numberElem={totalClients}
                page={currentPage}
                pageSize={pageSize}
                changePage={changePage}
                selected={selected}
                onSelect={setSelected}
            />
            <ModalAlert
                className="new-proposal__alert"
                icon="missing"
                visible={hasClientError}
                onClickButton={() => {
                    history.push("/proposals/list");
                }}
                title={t("proposals:sepa-loading.title-error")}
                acceptButtonText="Ok">
                <>
                    <div className="attachment-list__description">
                        <p>{t("proposals:sepa-loading.message-error")}</p>
                    </div>
                </>
            </ModalAlert>
        </>
    );
};

export default SelectClientList;
