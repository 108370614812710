import React, { useCallback, useContext, useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { format } from "date-fns";
import { pdf } from "@react-pdf/renderer";
import { AuthContext, roles, roles as rolesJson } from "../../../../context";
import { getAllProposals, getAllStatus, modifyProposal, exportProposals, downloadProposalPdf } from "../../services";
import { Lists, Loading, ModalAlert, PageWrapper, Toast, FormElements, Icon, Downloading } from "../../../../shared/components";
import { getFilialList } from "../../../clients/services/client.services";
import CartRefuse from "../../components/cart-refuse/cart-refuse";
import { handleProposalToast, handleRestrictions } from "../../utils/toast.handler";
import SearchMenuProposal from "../../components/search-menu-proposal/search-menu-proposal";
import "./list-proposals-page.scss";
import NewClient from "../../../clients/pages/new-client/new-client";
import ProposaPdf from "../proposal-pdf/proposal-pdf";

const ListProposalsPage = () => {
    const { user, permissions, logout } = useContext(AuthContext);
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const {
        raisonSociale = "",
        clientCode = "",
        commercialEmail = "",
        partnerCode = "",
        partnerRaisonSociale = "",
        page = "0",
        status,
        etablissement,
        subscription,
        originSbc,
        startDate,
        endDate,
        reminderDate,
        responseDate,
        probability,
        proposalReference
    } = queryString.parse(location.search);

    const [currentPage, setCurrentPage] = useState(parseInt(page, 10));
    const pageSize = 50;

    const [proposalsList, setProposalsList] = useState([]);
    const [totalProposals, setTotalProposals] = useState(0);
    const [statusList, setStatusList] = useState([]);
    const [showRefusePopup, setShowRefusePopup] = useState({});
    const [isValidRefusePopup, setIsValidRefusePopup] = useState(false);
    const [loading, setLoading] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [showToastMessage, setShowToastMessage] = useState("");
    const [secondaryMessage, setSecondaryMessage] = useState("");
    const [toastType, setToastType] = useState("");
    const [toastDiligencelink, setToastDiligencelink] = useState(false);

    const [showProposalDetail, setShowProposalDetail] = useState(false);
    const [selectedProposal, setSelectedProposal] = useState({});

    const [openSearchMenu, setOpenSearchMenu] = useState(false);
    const [queryParam, setQueryParam] = useState({
        clientCode,
        raisonSociale,
        commercialEmail,
        partnerCode,
        partnerRaisonSociale,
        page: currentPage,
        limit: pageSize,
        status,
        etablissement,
        subscription,
        originSbc,
        startDate,
        endDate,
        reminderDate,
        responseDate,
        probability,
        proposalReference
    });

    const [searchValue, setSearchValue] = useState({
        clientCode,
        raisonSociale,
        commercialEmail,
        partnerCode,
        partnerRaisonSociale,
        startDate,
        endDate,
        probability,
        proposalReference
    });
    const [searchOnClient, setSearchOnClient] = useState(true);
    const [isPartner, setIsPartner] = useState(false);
    const [isDeleteProposal, setIsDeleteProposal] = useState(false);
    const [proposalToDelete, setProposalToDelete] = useState({});
    const [disableExport, setDisableExport] = useState(false);
    const [establishmentList, setEstablishmentList] = useState([]);
    const [showAlertPdf, setShowAlertPdf] = useState(false);
    const buttonRef = useRef([]);

    const [isDownloading, setIsDownloading] = useState(false);
    const cleanQueryParams = (params) => {
        return Object.fromEntries(Object.entries(params).filter(([, v]) => v && v.toString().length >= 0));
    };

    const show = () => {
        // Show condition
        return true;
    };

    const updatePage = useCallback(() => {
        if (user) {
            setLoading(true);
            setTotalProposals(0);
            getAllProposals({ ...queryParam })
                .then((res) => {
                    const { count, proposals } = res.data;
                    if (res.data) {
                        setTotalProposals(count);
                        setProposalsList(proposals);
                    }
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }

        setCurrentPage(queryParam.page);
    }, [user, queryParam]);

    useEffect(() => {
        const partner = user.role === rolesJson.partner.role;
        setIsPartner(partner);
        setSearchOnClient(partner);
    }, [user]);

    useEffect(() => {
        const params = queryString.stringify(cleanQueryParams({ ...queryParam }));
        history.push(`/proposals/list?${params}`);
        updatePage();
    }, [user, queryParam, updatePage, history]);

    useEffect(() => {
        getAllStatus().then((res) => {
            setStatusList(res.data);
        });
    }, []);

    useEffect(() => {
        if (user && user.tiersParent && user.role === roles.partner.role) {
            getFilialList().then((res) => {
                const etablissementList = res.data;
                etablissementList.unshift({ name: `${user.tiersName} (${t("proposals:list-proposals-page.tiers-parent")})`, value: user.pivotId });
                setEstablishmentList(etablissementList);
            });
        }
    }, [user, t]);

    useEffect(() => {
        handleProposalToast(location, history, setShowToastMessage, setToastType, setShowToast);
    }, [history, location]);

    const changePage = (pageNumber) => {
        setQueryParam({
            ...queryParam,
            page: pageNumber
        });
    };

    const handleResetSearch = () => {
        history.push("/proposals/list");
        setOpenSearchMenu(false);
        setQueryParam({
            ...queryParam,
            raisonSociale: "",
            clientCode: "",
            commercialEmail: "",
            partnerCode: "",
            partnerRaisonSociale: "",
            page: 0,
            startDate: "",
            endDate: "",
            reminderDate: "",
            responseDate: "",
            probability: "",
            proposalReference: ""
        });
        setSearchValue({
            raisonSociale: "",
            clientCode: "",
            commercialEmail: "",
            partnerCode: "",
            partnerRaisonSociale: "",
            startDate: "",
            endDate: "",
            reminderDate: "",
            responseDate: "",
            probability: "",
            proposalReference: ""
        });
    };

    const handleProposalSearchChange = (e) => {
        setSearchValue({
            ...searchValue,
            page: 0,
            [e.target.name]: e.target.value
        });
    };

    const handleProposalSearch = () => {
        setOpenSearchMenu(false);
        setQueryParam({
            ...queryParam,
            ...searchValue
        });
    };

    const handleSearchCheckboxChange = () => {
        const useClient = !searchOnClient;
        setSearchOnClient(useClient);

        if (useClient) {
            setSearchValue({
                ...searchValue,
                partnerRaisonSociale: "",
                partnerCode: ""
            });
        } else {
            setSearchValue({
                ...searchValue,
                raisonSociale: "",
                clientCode: ""
            });
        }
    };

    const handleShowRefusePopup = (row, mode) => {
        const { reference, cartRefuseReason, cartRefuseComment } = row;
        setShowRefusePopup({ reference, cartRefuseReason, cartRefuseComment, mode });
    };

    const handleRefuse = () => {
        setShowRefusePopup({});
        setIsValidRefusePopup(false);
        updatePage();
        setShowToastMessage(t("proposals:list-proposals-page.refuse-proposal.success"));
        setToastType("success");
        setShowToast(true);
    };

    const getOrderLink = (row) => {
        const { reference } = row;
        return `/proposals/${reference}/sepa?step=order`;
    };

    const resendEmail = (row) => {
        const { reference, usageCode } = row;
        modifyProposal(reference, "email", { usageCode })
            .then((res) => {
                if (res.data) {
                    setShowToastMessage(t("proposals:list-proposals-page.resend.success"));
                    setToastType("success");
                    setShowToast(true);
                } else throw new Error();
            })
            .catch(() => {
                setShowToastMessage(t("error.message"));
                setToastType("error");
                setShowToast(true);
            });
    };

    const getEditLink = (row) => {
        const { statusUpdates, usageCode, status: selectedStatus } = row;
        if (statusUpdates.includes("refuse")) {
            return `/proposals/${row.reference}/edit?step=fin`;
        }
        if (selectedStatus === "accordee") {
            return `/proposals/${row.reference}/update?step=fin`;
        }
        return `/proposals/${row.reference}/edit?usageCode=${usageCode}&step=config`;
    };

    const getEmailLink = (row) => {
        const { clientContactEmail, commercialEmail: ce, partnerPivotId } = row;
        if (user.role === roles.credit.role && partnerPivotId) return `mailto: ${ce}?subject=${t("proposals:list-proposals-page.email.subject")}`;
        return `mailto: ${clientContactEmail}?subject=${t("proposals:list-proposals-page.email.subject")}`;
    };

    const handleDelete = () => {
        const { reference, usageCode } = proposalToDelete;
        modifyProposal(reference, "delete", { usageCode })
            .then(() => {
                updatePage();
                setShowToastMessage(t("proposals:list-proposals-page.delete-proposal.success"));
                setToastType("success");
                setShowToast(true);
                setProposalToDelete({});
                setIsDeleteProposal(false);
            })
            .catch(() => {
                setProposalToDelete({});
                setIsDeleteProposal(false);
            });
    };

    const handleMultiChangeStatus = (selected) => {
        setQueryParam({
            ...queryParam,
            status: selected.map((p) => p.value),
            page: 0
        });
    };

    const handleChangeDate = (e) => {
        setQueryParam({
            ...queryParam,
            startDate: e.target.value,
            page: 0,
            endDate: ""
        });
    };
    const handleChangeReminderDate = (e) => {
        setQueryParam({
            ...queryParam,
            reminderDate: e.target.value,
            page: 0
        });
    };
    const handleChangeResponseDate = (e) => {
        setQueryParam({
            ...queryParam,
            responseDate: e.target.value,
            page: 0
        });
    };
    const handleChangeFilial = (e) => {
        setQueryParam({
            ...queryParam,
            etablissement: e.target.value,
            page: 0
        });
    };

    const handleChangeOrigin = (e) => {
        setQueryParam({
            ...queryParam,
            originSbc: e.target.value,
            page: 0
        });
    };

    const handleBlock = (row) => {
        const { reference, usageCode } = row;
        modifyProposal(reference, "block", { usageCode })
            .then((res) => {
                if (res.data) {
                    updatePage();
                    setShowToastMessage(t("proposals:list-proposals-page.block-proposal.success"));
                    setToastType("success");
                    setShowToast(true);
                } else throw new Error();
            })
            .catch(() => {
                setShowToastMessage(t("error.message"));
                setToastType("error");
                setShowToast(true);
            });
    };

    const handleUnblock = (row) => {
        const { reference, usageCode } = row;
        modifyProposal(reference, "unblock", { usageCode })
            .then((res) => {
                if (res.data) {
                    updatePage();
                    setShowToastMessage(t("proposals:list-proposals-page.unblock-proposal.success"));
                    setToastType("success");
                    setShowToast(true);
                } else throw new Error();
            })
            .catch(() => {
                setShowToastMessage(t("error.message"));
                setToastType("error");
                setShowToast(true);
            });
    };

    const handleReview = (row) => {
        const { reference, usageCode } = row;
        modifyProposal(reference, "review", { usageCode })
            .then((res) => {
                if (res.data) {
                    updatePage();
                    setShowToastMessage(t("proposals:list-proposals-page.review-proposal.success", { reference }));
                    setToastType("success");
                    setShowToast(true);
                } else throw new Error();
            })
            .catch(() => {
                setShowToastMessage(t("error.message"));
                setToastType("error");
                setShowToast(true);
            });
    };

    const checkAction = (row, action) => {
        const { allowedActions } = row;
        return allowedActions.includes(action);
    };

    const checkNotVI = (row) => {
        const { partnerPivotId } = row;
        return !partnerPivotId;
    };

    const checkStatus = (row, action) => {
        const { statusUpdates } = row;
        return statusUpdates.includes(action);
    };

    const {
        proposals: { actions, hiddenFields }
    } = permissions;

    const handleOrder = (res, reference, usageCode) => {
        const { financialControlReason } = res.data;
        if (user.role === roles.partner.role && !financialControlReason) {
            history.push(`/proposals/${reference}/sepa?usageCode=${usageCode}&step=order`);
        } else if (user.role === roles.partner.role && financialControlReason === "balance") {
            setShowToastMessage(t("proposals:list-proposals-page.financial-control-balance"));
            setToastType("success");
            setShowToast(true);
        } else if (user.role === roles.partner.role && financialControlReason === "tiers-state") {
            setShowToastMessage(t("proposals:list-proposals-page.financial-control-tiers-state"));
            setToastType("success");
            setShowToast(true);
        } else {
            setShowToastMessage(t("proposals:list-proposals-page.order-proposal.success", { reference }));
            setToastType("success");
            setShowToast(true);
        }
    };

    const submitProposal = (row) => {
        const { reference, clientPivotId, usageCode } = row;

        modifyProposal(reference, "order", { cartMeta: { clientPivotId }, usageCode })
            .then((response) => {
                handleOrder(response, reference, usageCode);
                updatePage();
            })
            .catch((error) => {
                handleRestrictions(error, setShowToast, setToastType, setShowToastMessage, user, setToastDiligencelink, setSecondaryMessage);
            });
    };

    const checkParentTiers = (row) => {
        if (user.tiersParent) {
            return row.partnerCode === user.tiersCode;
        }

        return true;
    };
    const downloadProposal = (row, index) => {
        const { reference } = row;
        buttonRef.current[index].disabled = true;
        buttonRef.current[index].style.color = "gray";
        setIsDownloading(true);
        downloadProposalPdf(reference)
            .then((res) => {
                const { proposalMeta, productlist } = res.data;
                const link = document.createElement("a");
                link.download = `${reference}.pdf`;

                pdf(<ProposaPdf proposalMeta={proposalMeta} productlist={productlist} />)
                    .toBlob()
                    .then((blob) => {
                        link.href = window.URL.createObjectURL(blob);
                        document.body.append(link);
                        link.click();
                        link.remove();
                        buttonRef.current[index].disabled = false;
                        buttonRef.current[index].style.color = "var(--color-business-hover)";
                    });
                setIsDownloading(false);
            })
            .catch(() => {
                buttonRef.current[index].disabled = false;
                buttonRef.current[index].style.color = "var(--color-business-hover)";
                setShowAlertPdf(true);
            });
    };
    const showDownload = () => {
        return true;
    };
    const userActions = [
        {
            name: "details",
            type: "button",
            icon: "ui-cart",
            ariaLabel: t("proposals:list-proposals-page.actions.show"),
            tooltipPosY: "top",
            tooltipPosX: "right",
            onClick: (row) => {
                setSelectedProposal(row);
                setShowProposalDetail(true);
            },
            showCondition: show
        },
        {
            name: "mailto",
            type: "link",
            icon: "ui-mail",
            link: getEmailLink,
            ariaLabel: t("proposals:list-proposals-page.actions.mailto"),
            tooltipPosY: "top",
            tooltipPosX: "right",
            showCondition: ({ clientContactEmail }) => clientContactEmail && actions.includes("mailto")
        },
        {
            name: "order",
            type: "button",
            icon: user.role === roles.partner.role ? "ui-cart-buy" : "ui-submit",
            ariaLabel: user.role === roles.partner.role ? t("proposals:list-proposals-page.actions.submit") : t("proposals:list-proposals-page.actions.order"),
            tooltipPosY: "top",
            tooltipPosX: "right",
            onClick: (row) => submitProposal(row),
            showCondition: (row) => checkAction(row, "order") && actions.includes("order") && checkParentTiers(row)
        },
        {
            name: "review",
            type: "button",
            icon: "actions-refresh",
            ariaLabel: t("proposals:list-proposals-page.actions.review"),
            tooltipPosY: "top",
            tooltipPosX: "right",
            onClick: (row) => handleReview(row),
            showCondition: (row) => checkAction(row, "review") && actions.includes("review") && checkParentTiers(row)
        },
        {
            name: "pay",
            type: "link",
            className: user.role === roles.endUser.role ? "success" : "",
            icon: user.role === roles.endUser.role ? "actions-validate" : "ui-cart-buy",
            iconColor: user.role === roles.endUser.role ? "var(--color-success)" : null,
            iconClass: user.role === roles.endUser.role ? "coche-validation" : "",
            link: getOrderLink,
            ariaLabel: t("proposals:list-proposals-page.actions.pay"),
            tooltipPosY: "top",
            tooltipPosX: "right",
            showCondition: (row) => checkAction(row, "pay") && actions.includes("pay") && checkParentTiers(row)
        },
        {
            name: "refuse",
            type: "button",
            icon: "actions-refuse",
            iconColor: "var(--color-error)",
            ariaLabel: t("proposals:list-proposals-page.actions.refuse"),
            tooltipPosY: "top",
            tooltipPosX: "right",
            onClick: (row) => handleShowRefusePopup(row, "edit"),
            showCondition: (row) => checkAction(row, "refuse") && actions.includes("refuse")
        },
        {
            name: "view-refusal",
            type: "button",
            icon: "ui-comments",
            iconColor: "#668592",
            ariaLabel: t("proposals:list-proposals-page.actions.view-refusal"),
            tooltipPosY: "top",
            tooltipPosX: "right",
            onClick: (row) => handleShowRefusePopup(row, "read"),
            showCondition: (row) => checkStatus(row, "refuse") && actions.includes("view-refusal")
        },
        {
            name: "block",
            type: "button",
            icon: "actions-refuse",
            iconColor: "var(--color-error)",
            ariaLabel: t("proposals:list-proposals-page.actions.block"),
            tooltipPosY: "top",
            tooltipPosX: "right",
            onClick: handleBlock,
            showCondition: (row) => checkAction(row, "block") && actions.includes("block")
        },
        {
            name: "unblock",
            type: "button",
            icon: "actions-validate",
            iconColor: "var(--color-success)",
            ariaLabel: t("proposals:list-proposals-page.actions.unblock"),
            tooltipPosY: "top",
            tooltipPosX: "right",
            onClick: handleUnblock,
            showCondition: (row) => checkAction(row, "unblock") && actions.includes("unblock")
        },
        {
            name: "email",
            type: "button",
            icon: "ui-mail-resend",
            ariaLabel: t("proposals:list-proposals-page.actions.email"),
            tooltipPosY: "top",
            tooltipPosX: "right",
            onClick: resendEmail,
            showCondition: (row) => checkAction(row, "email") && actions.includes("email") && checkNotVI(row)
        },
        {
            name: "edit",
            type: "link",
            icon: "ui-edit",
            link: getEditLink,
            ariaLabel: t("proposals:list-proposals-page.actions.edit"),
            tooltipPosY: "top",
            tooltipPosX: "right",
            showCondition: (row) => checkAction(row, "edit") && actions.includes("edit") && checkParentTiers(row)
        },
        {
            name: "delete",
            type: "button",
            icon: "ui-unlink",
            ariaLabel: t("proposals:list-proposals-page.actions.delete"),
            tooltipPosY: "top",
            tooltipPosX: "right",
            onClick: (row) => {
                setProposalToDelete(row);
                setIsDeleteProposal(true);
            },
            showCondition: (row) => checkAction(row, "delete") && actions.includes("delete") && checkParentTiers(row)
        },
        {
            name: "download",
            type: "button",
            ref: buttonRef,
            ariaLabel: t("proposals:list-proposals-page.actions.download"),
            icon: "ui-download",
            onClick: downloadProposal,
            showCondition: () => showDownload()
        }
    ];

    const columns = [
        { name: "reference", type: "text", dbRef: "reference", label: t("proposals:list-proposals-page.reference"), tooltip: "internalMonitoring" },
        { name: "client", type: "text", dbRef: "clientCode", label: t("proposals:list-proposals-page.client") },
        { name: "clientName", type: "text", dbRef: "clientName", label: t("proposals:list-proposals-page.client-name") },
        { name: "partnerCode", type: "text", dbRef: "partnerCode", label: t("proposals:list-proposals-page.partner") },
        { name: "partnerName", type: "text", dbRef: "partnerName", label: t("proposals:list-proposals-page.partner-name") },
        {
            ...(user.role === rolesJson.sales.role
                ? { name: "codeCamp", type: "text", dbRef: "codeCamp", label: t("proposals:list-proposals-page.code-campagne") }
                : { name: "clientReference", type: "text", dbRef: "clientReference", label: t("proposals:list-proposals-page.client-reference") })
        },
        { name: "probability", type: "probability", dbRef: "probability", label: t("proposals:list-proposals-page.probability") },
        { name: "created", type: "text", dbRef: "created", label: t("proposals:list-proposals-page.created") },
        { name: "validity", type: "text", dbRef: "validity", label: t("proposals:list-proposals-page.validity") },
        { name: "total", type: "text", dbRef: "total", label: t("proposals:list-proposals-page.total") },
        { name: "status", type: "pill", dbRef: "statusLabel", colorRef: "statusColor", label: t("proposals:list-proposals-page.status") },
        { name: "actions", type: "actions", actions: userActions }
    ].map((column) => ({ ...column, showCondition: () => !hiddenFields.includes(column.dbRef) }));

    const columnEndUser = [
        { name: "reference", type: "text", dbRef: "reference", label: t("proposals:list-proposals-page.reference") },
        { name: "client", type: "text", dbRef: "clientCode", label: t("proposals:list-proposals-page.client") },
        { name: "clientName", type: "text", dbRef: "clientName", label: t("proposals:list-proposals-page.client-name-enduser") },
        { name: "created", type: "text", dbRef: "created", label: t("proposals:list-proposals-page.created") },
        { name: "validity", type: "text", dbRef: "validity", label: t("proposals:list-proposals-page.validity") },
        { name: "total", type: "text", dbRef: "total", label: t("proposals:list-proposals-page.total") },
        { name: "actions", type: "actions", actions: userActions }
    ].map((column) => ({ ...column, showCondition: () => !hiddenFields.includes(column.dbRef) }));

    const establishementFilter =
        user && user.tiersParent && establishmentList.length > 1
            ? [
                  {
                      key: "establishment-filter",
                      label: t("proposals:list-proposals-page.filial-list"),
                      options: establishmentList,
                      className: "filial-filter",
                      handler: handleChangeFilial,
                      value: etablissement
                  }
              ].filter(({ key }) => actions.includes(key))
            : [];

    const statusListupdated = () => {
        const { status: seledtedStatus } = queryParam;
        if (seledtedStatus) {
            if (typeof seledtedStatus !== "string") {
                const mergeStatusList = statusList.map((st) => {
                    const exists = seledtedStatus.find((s) => s === st.value);
                    return exists ? { ...st, isSelected: true } : { ...st, isSelected: false };
                });
                return mergeStatusList;
            }
            const mergeStatusList = statusList.map((st) => {
                return st.value === seledtedStatus ? { ...st, isSelected: true } : { ...st, isSelected: false };
            });
            return mergeStatusList;
        }
        return statusList.map((p) => {
            return { ...p, label: p.name, isSelected: false };
        });
    };
    const getDefaultValues = () => {
        return statusListupdated().filter((p) => p.isSelected);
    };
    const multiStatusFilter = [
        {
            key: "multistatus",
            label: "multistatus",
            options: statusListupdated(),
            className: "multi-select",
            handler: handleMultiChangeStatus,
            value: status,
            multiValues: true,
            optionSelected: getDefaultValues(),
            previousSelectedValues: getDefaultValues()
        }
    ];

    const dateFilterDay = () => {
        return format(new Date(), "yyyy-MM-dd");
    };
    const getCurrentMonthDate = () => {
        const currentDate = new Date();
        const currentMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        return format(new Date(currentMonthDate), "yyyy-MM-dd");
    };
    const tomorrowFilter = () => {
        const date = new Date();
        return format(date.setDate(date.getDate() + 1), "yyyy-MM-dd");
    };
    const nextWeekFilter = () => {
        const today = new Date();
        const week = new Date(today.setDate(today.getDate() - today.getDay()));
        return format(new Date(week), "yyyy-MM-dd");
    };
    const nextMonthFilter = () => {
        const today = new Date();
        const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1);
        return format(new Date(nextMonth), "yyyy-MM-dd");
    };

    const dateFilter = [
        {
            key: "created",
            label: t("proposals:list-proposals-page.date-list"),
            options: [
                { value: `${dateFilterDay()}`, name: t("proposals:list-proposals-page.day") },
                { value: `${getCurrentMonthDate()}`, name: t("proposals:list-proposals-page.month") }
            ],
            className: "date-filter",
            handler: handleChangeDate,
            value: startDate
        }
    ];
    const reminderDateFilter = [
        {
            key: "reminderDate",
            label: t("proposals:list-proposals-page.reminder"),
            options: [
                { value: `${tomorrowFilter()}`, name: t("proposals:list-proposals-page.tomorrow") },
                { value: `${nextWeekFilter()}`, name: t("proposals:list-proposals-page.week") },
                { value: `${getCurrentMonthDate()}`, name: t("proposals:list-proposals-page.month") },
                { value: `${nextMonthFilter()}`, name: t("proposals:list-proposals-page.next-month") }
            ],
            className: "date-filter",
            handler: handleChangeReminderDate,
            value: reminderDate
        }
    ];
    const responseDateFilter = [
        {
            key: "responseDate",
            label: t("proposals:list-proposals-page.response"),
            options: [
                { value: `${tomorrowFilter()}`, name: t("proposals:list-proposals-page.tomorrow") },
                { value: `${nextWeekFilter()}`, name: t("proposals:list-proposals-page.week") },
                { value: `${getCurrentMonthDate()}`, name: t("proposals:list-proposals-page.month") },
                { value: `${nextMonthFilter()}`, name: t("proposals:list-proposals-page.next-month") }
            ],
            className: "date-filter",
            handler: handleChangeResponseDate,
            value: responseDate
        }
    ];
    const originFilter =
        user && user.role !== rolesJson.partner.role
            ? [
                  {
                      key: "origin",
                      label: t("proposals:list-proposals-page.origin-list"),
                      options: [
                          { value: "PORTAL_SBC", name: t("proposals:list-proposals-page.portal-sbc") },
                          { value: "CHECKOUT_SBC", name: t("proposals:list-proposals-page.sage-checkout") }
                      ],
                      className: "origin-filter",
                      handler: handleChangeOrigin,
                      value: originSbc
                  }
              ]
            : [];

    const mobileTabs = () => {
        const ownTab = [
            {
                value: "own",
                name: t("proposals:list-proposals-page.own"),
                link: "/proposals/list"
            }
        ];

        return ownTab
            .concat(
                statusList.map(({ value, name, rank }) => ({
                    value,
                    name,
                    rank,
                    link: `/proposals/list?status=${value}`
                }))
            )
            .sort((a, b) => a.rank - b.rank);
    };

    if (loading) {
        return (
            <PageWrapper className="list-proposals-page" fullPage>
                <Loading />
            </PageWrapper>
        );
    }

    const handleExport = () => {
        setDisableExport(true);
        setIsDownloading(true);
        exportProposals({ ...queryParam, ...{ limit: totalProposals, page: 0 } }).then((res) => {
            const { data } = res;
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
            const link = document.createElement("a");
            link.download = `Propositions-${format(new Date(), "dd/MM/yyyy")}.xlsx`;
            link.href = URL.createObjectURL(blob);
            document.body.append(link);
            link.click();
            link.remove();
            setDisableExport(false);
            setIsDownloading(false);
        });
    };

    return (
        <PageWrapper className="list-proposals-page" fullPage>
            {isDownloading && <Downloading> </Downloading>}
            <Lists.HeaderFullMobile title={t("proposals:list-proposals-page.title-mobile")}>
                <FormElements.Button className="full-page-header__button" onClick={() => setOpenSearchMenu(!openSearchMenu)}>
                    <Icon name="ui-search" />
                </FormElements.Button>
            </Lists.HeaderFullMobile>

            <Lists.ListHeaderMobile tabs={user.role === "external_end_user" ? [] : mobileTabs()} activeTab={status} />

            <Lists.ListHeader
                title={`${t("proposals:list-proposals-page.title")} (${totalProposals.toLocaleString()})`}
                description={user.role === "external_end_user" ? "" : t("proposals:list-proposals-page.description")}
                quickFilters={
                    user.role === "external_end_user"
                        ? []
                        : [...multiStatusFilter, ...establishementFilter, ...originFilter, ...dateFilter, ...reminderDateFilter, ...responseDateFilter]
                }
                popoverActions={
                    user.role === "external_end_user"
                        ? []
                        : [
                              {
                                  key: "search",
                                  label: t("proposals:list-proposals-page.search"),
                                  className: "medium secondary",
                                  icon: "ui-search",
                                  action: () => setOpenSearchMenu(!openSearchMenu),
                                  render: (
                                      <SearchMenuProposal
                                          titleSearchMenu={t("clients:list-clients-page.search")}
                                          openSearchMenu={openSearchMenu}
                                          setOpenSearchMenu={setOpenSearchMenu}
                                          handleSearchChange={handleProposalSearchChange}
                                          handleSearch={handleProposalSearch}
                                          searchValue={searchValue}
                                          searchOnClient={searchOnClient}
                                          handleSearchCheckboxChange={handleSearchCheckboxChange}
                                          handleResetSearch={handleResetSearch}
                                          isPartner={isPartner}
                                          displayRangeDate
                                          displayProbability
                                          displayProposalRef
                                      />
                                  )
                              }
                          ]
                }
                buttonActions={[
                    {
                        key: "export",
                        label: t("clients:list-clients-page.export"),
                        className: "medium secondary",
                        action: handleExport,
                        disabled: disableExport
                    }
                ].filter(({ key }) => actions.includes(key))}
            />

            <Lists.ListTable
                columns={user.role === "external_end_user" ? [...columnEndUser] : [...columns]}
                content={proposalsList}
                rowKey="reference"
                actionsCount="6"
                listRowClassName={user.role === "external_end_user"}
            />
            <Lists.ListPagination numberElem={totalProposals} page={currentPage} pageSize={pageSize} changePage={changePage} />

            <ModalAlert
                className="list-proposal__refuse"
                classNameContent="modal-alert__refuse"
                visible={showRefusePopup.reference}
                onCancel={() => {
                    setShowRefusePopup({});
                    setIsValidRefusePopup(false);
                }}
                onAccept={showRefusePopup.mode === "edit" && handleRefuse}
                title={`${t("proposals:cart-refuse.refuse-offer")} ${showRefusePopup.reference}`}
                cancelButtonText={t("proposals:cart-refuse.close")}
                acceptButtonText={t("proposals:cart-refuse.send")}
                submit="cart-refuse-form"
                enableSubmit={showRefusePopup.mode === "edit" && isValidRefusePopup}>
                <CartRefuse content={showRefusePopup} onValid={setIsValidRefusePopup} onSubmit={handleRefuse} readOnly={showRefusePopup.mode === "read"} />
            </ModalAlert>
            <Toast
                show={showToast}
                onDismiss={() => setShowToast(false)}
                message={showToastMessage}
                secondaryMessage={secondaryMessage}
                toastType={toastType}
                toastDiligencelink={toastDiligencelink}
            />

            <ModalAlert
                className="new-proposal__alert"
                visible={isDeleteProposal}
                onCancel={() => {
                    setIsDeleteProposal(false);
                    setProposalToDelete({});
                }}
                onAccept={handleDelete}
                title={t("proposals:modal-alert.delete-title")}
                icon="ui-cart-buy"
                cancelButtonText={t("proposals:modal-alert.cancel")}
                acceptButtonText={t("proposals:list-proposals-page.actions.delete")}>
                <p>{t("proposals:modal-alert.delete-description")}</p>
                <p>{t("proposals:modal-alert.delete-sub-description")}</p>
            </ModalAlert>
            <ModalAlert
                className="list-proposal__refuse"
                visible={user.role === "external_end_user" && totalProposals === 0}
                title={t("proposals:modal-alert.no-proposals-title")}
                onCancel={() => {
                    logout();
                }}
                cancelButtonText={t("proposals:cart-refuse.close")}>
                <p>{t("proposals:modal-alert.no-proposals")}</p>
            </ModalAlert>

            <ModalAlert
                className="new-proposal__alert"
                visible={showAlertPdf}
                onCancel={() => {
                    setShowAlertPdf(false);
                }}
                title={t("proposals:list-proposals-page.title-alert")}
                icon="ui-cart-buy"
                cancelButtonText={t("proposals:list-proposals-page.cancel")}>
                <p>{t("proposals:list-proposals-page.pdf-message")}</p>
                <p>{t("proposals:list-proposals-page.pdf-message-details")}</p>
            </ModalAlert>

            {showProposalDetail && (
                <NewClient
                    updateModal={setShowProposalDetail}
                    subscription={selectedProposal}
                    proposal
                    clientPivotId={selectedProposal.clientPivotId}
                    withTabs={false}
                />
            )}
        </PageWrapper>
    );
};

export default ListProposalsPage;
