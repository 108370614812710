import React from "react";
import { useParams } from "react-router-dom";
import NewProposal from "../../../proposals/components/new-proposal/new-proposal";

const NewSubscirptionPage = (props) => {
    const { mode } = props;
    const { reference } = useParams();

    return <NewProposal reference={reference} mode={mode} isSubcription />;
};

export default NewSubscirptionPage;
