import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";
import Icon from "../icon-svg/icon";
import "./toast.scss";

const Toast = (props) => {
    const { message, toastType = "success", show, onDismiss, insideNestedPortal = false, toastDiligencelink, secondaryMessage } = props;
    const { t } = useTranslation();
    useEffect(() => {
        const handler = setTimeout(() => {
            onDismiss();
        }, 6000);

        return () => {
            clearTimeout(handler);
        };
    }, [onDismiss]);

    const contentToast = () => {
        return (
            <div className={`toast ${toastType}`}>
                <div className="toast__icon">
                    {toastType === "success" && <Icon name="success" />}
                    {toastType === "error" && <Icon name="error" />}
                </div>
                <div className="toast__text">
                    {message}
                    {secondaryMessage && <p>{secondaryMessage}</p>}
                    {toastDiligencelink && (
                        <a href={`${t("clients:tiers.linkDiligence")}`} target="_blank" rel="noopener noreferrer">
                            {t("clients:tiers.linkICI")}
                        </a>
                    )}
                </div>

                <button type="button" className="toast__close" onClick={onDismiss}>
                    <Icon name="dialog-close" />
                </button>
            </div>
        );
    };

    if (!insideNestedPortal && show) {
        return createPortal(contentToast(), document.getElementById("toast-root"));
    }

    if (insideNestedPortal && show) {
        return contentToast();
    }

    return null;
};

export default Toast;
