import React from "react";
import "./downloading.scss";

const Downloading = () => (
    <div className="downloading">
        <div className="spinner" />
    </div>
);

export default Downloading;
