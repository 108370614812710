import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider } from "styled-components";
import aegeanTheme from "carbon-react/lib/style/themes/aegean";
import { detect } from "detect-browser";
import ErrorBoundary from "./shared/components/error-boundary/error-boundary";
import AppRoutes from "./app.routes";
import { CompatibilityPage } from "./domains/error";
import "./app.scss";

const auth0 = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: `${window.location.origin}/dashboard`,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE
};

const App = () => {
    const browser = detect();

    const version = browser.version.split(".")[0];
    if (browser && (browser.name === "ie" || (browser.name === "edge" && version < "79"))) {
        return <CompatibilityPage />;
    }

    return (
        <ErrorBoundary>
            <ThemeProvider theme={aegeanTheme}>
                <Router>
                    <Auth0Provider
                        audience={auth0.audience}
                        scope="read:token"
                        domain={auth0.domain}
                        clientId={auth0.clientId}
                        redirectUri={auth0.redirectUri}
                        cacheLocation="localstorage"
                        prompt="login"
                        useRefreshTokens>
                        <AppRoutes />
                    </Auth0Provider>
                </Router>
            </ThemeProvider>
        </ErrorBoundary>
    );
};

export default App;
