import React, { useState, useEffect, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { format } from "date-fns";
import { AuthContext, roles as rolesJson } from "../../../../context";
import { getAllSubscription, exportSubscriptions, checkOptionalsConfig } from "../../service/subscription.service";
import { getFilialList } from "../../../clients/services/client.services";
import { PageWrapper, Lists, Loading, FormElements, Icon, ModalAlert, Downloading } from "../../../../shared/components";
import FormResiliation from "../../components/form-resiliation";
import "./list-subscription-page.scss";
import NewClient from "../../../clients/pages/new-client/new-client";
import { handleRestrictions } from "../../../proposals/utils/toast.handler";
import SearchMenuProposal from "../../../proposals/components/search-menu-proposal/search-menu-proposal";

const ListSubscriptionPage = () => {
    const { isAuthenticated, user, permissions } = useContext(AuthContext);
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const {
        raisonSociale = "",
        clientCode = "",
        commercialEmail = "",
        partnerCode = "",
        partnerRaisonSociale = "",
        reference = "",
        page = "0",
        status,
        etablissement,
        startDate,
        endDate,
        originSbc
    } = queryString.parse(location.search);
    const currentPage = parseInt(page, 10);
    const pageSize = 50;

    const [totalSubscriptions, setTotalSubsriptions] = useState(0);
    const [subscriptions, setSubscriptions] = useState([]);
    const [showSubscriptionDetail, setShowSubscriptionDetail] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState({});
    const [openSearchMenu, setOpenSearchMenu] = useState(false);
    const [establishmentList, setEstablishmentList] = useState([]);
    const [showAlertResiliation, setShowAlertResiliation] = useState(false);
    const [showFormResiliation, setShowFormResiliation] = useState(false);
    const [queryParam, setQueryParam] = useState({
        clientCode,
        raisonSociale,
        commercialEmail,
        partnerCode,
        partnerRaisonSociale,
        limit: pageSize,
        etablissement,
        status,
        reference,
        startDate,
        endDate,
        originSbc
    });

    const [searchValue, setSearchValue] = useState({
        clientCode,
        raisonSociale,
        commercialEmail,
        partnerCode,
        partnerRaisonSociale,
        reference,
        startDate,
        endDate
    });
    const [searchOnClient, setSearchOnClient] = useState(true);
    const [isPartner, setIsPartner] = useState(false);
    const statusList = [
        { canDelete: false, canPurchase: false, color: "#ffb500", editable: false, value: "a_activer", name: "A activer" },
        { canDelete: false, canPurchase: false, color: "#00B000", editable: false, value: "en_service", name: "En service" },
        { canDelete: false, canPurchase: false, color: "#E96400", editable: false, value: "suspendue", name: "Suspendue" },
        { canDelete: false, canPurchase: false, color: "#C7384F", editable: false, value: "resiliée", name: "Résiliée" }
    ];

    const statusListSubscription = [
        { status: "Abandonné / résilié", name: "Abandonné / résilié" },
        { status: "Activé", name: "Activé" },
        { status: "Suspendu", name: "Suspendu" },
        { status: "Vendu", name: "Vendu" }
    ];
    const handleChangeStatus = (e) => {
        setQueryParam({
            ...queryParam,
            status: e.target.value,
            page: 0
        });
    };
    const handleChangeDate = (e) => {
        setQueryParam({
            ...queryParam,
            startDate: e.target.value,
            page: 0,
            endDate: ""
        });
    };
    const statusFilter = [
        {
            key: "status",
            label: t("proposals:list-proposals-page.status-list"),
            options: statusListSubscription,
            className: "status-filter",
            handler: handleChangeStatus,
            value: status
        }
    ];
    const dateFilterDay = () => {
        return format(new Date(), "yyyy-MM-dd");
    };
    const getCurrentMonthDate = () => {
        const currentDate = new Date();
        const currentMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        return format(new Date(currentMonthDate), "yyyy-MM-dd");
    };
    const dateFilter = [
        {
            key: "created",
            label: t("proposals:list-proposals-page.date-list"),
            options: [
                { value: `${dateFilterDay()}`, name: t("proposals:list-proposals-page.day") },
                { value: `${getCurrentMonthDate()}`, name: t("proposals:list-proposals-page.month") }
            ],
            className: "date-filter",
            handler: handleChangeDate,
            value: startDate
        }
    ];
    const [disableExport, setDisableExport] = useState(false);
    const [showNoOptionalModules, setShowNoOptionalModules] = useState(false);
    const [editionErrorMessage, setEditionErrorMessage] = useState("");
    const [editionMessageType, setEeditionMessageType] = useState("");
    const [toastDiligencelink, setToastDiligencelink] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const {
        subscriptions: { actions }
    } = permissions;

    const updatePage = useCallback(
        (currentPageParam) => {
            if (isAuthenticated && user) {
                setLoading(true);
                const { etablissement: selectedEtablissement } = queryParam;
                getAllSubscription({
                    index: currentPageParam,
                    showFilials: !(typeof selectedEtablissement === "string" && selectedEtablissement.length > 0),
                    ...queryParam
                })
                    .then((res) => {
                        const { totalCount, lignesParcs } = res.data;
                        setTotalSubsriptions(totalCount);
                        setSubscriptions(lignesParcs);
                        setLoading(false);
                    })
                    .catch(() => setLoading(false));
            }
        },
        [isAuthenticated, user, queryParam]
    );

    const cleanQueryParams = (params) => {
        return Object.fromEntries(Object.entries(params).filter(([, v]) => v && v.toString().length >= 0));
    };

    useEffect(() => {
        const partner = user.role === rolesJson.partner.role;
        setIsPartner(partner);
        setSearchOnClient(partner);
    }, [user]);

    useEffect(() => {
        updatePage(currentPage);
    }, [isAuthenticated, user, queryParam, updatePage, history, currentPage]);

    useEffect(() => {
        const params = queryString.stringify(cleanQueryParams({ ...queryParam }));
        history.push(`/subscriptions/list?${params}`);
        updatePage();
    }, [user, queryParam, updatePage, history]);

    useEffect(() => {
        if (user && user.tiersParent && user.role === rolesJson.partner.role) {
            getFilialList().then((res) => {
                const etablissementList = res.data;
                etablissementList.unshift({ name: `${user.tiersName} (${t("subscriptions:list-subscriptions-page.tiers-parent")})`, value: user.pivotId });
                setEstablishmentList(etablissementList);
            });
        }
    }, [user, t]);

    const mobileTabs = () => {
        return statusList.map(({ value, name }) => ({
            value,
            name,
            link: `/subscriptions/list?status=${value}`
        }));
    };

    const changePage = (pageNumber) => {
        const params = queryString.stringify(cleanQueryParams({ ...queryParam, page: pageNumber }));
        history.push(`/subscriptions/list?${params}`);
    };

    const show = () => {
        // Show condition
        return true;
    };
    const showResiliation = (row) => {
        if (row.status === "Vendu" || row.status === "Activé") {
            return true;
        }
        return false;
    };

    const handleResetSearch = () => {
        history.push("/subscriptions/list");
        setOpenSearchMenu(false);
        setQueryParam({
            ...queryParam,
            raisonSociale: "",
            clientCode: "",
            partnerCode: "",
            partnerRaisonSociale: "",
            reference: "",
            startDate: "",
            endDate: "",
            originSbc: ""
        });
        setSearchValue({
            raisonSociale: "",
            clientCode: "",
            partnerCode: "",
            partnerRaisonSociale: "",
            reference: "",
            startDate: "",
            endDate: "",
            originSbc: ""
        });
    };

    const handleSubscriptionSearchChange = (e) => {
        setSearchValue({
            ...searchValue,
            [e.target.name]: e.target.value
        });
    };

    const handleSubscriptionSearch = () => {
        setOpenSearchMenu(false);
        const params = queryString.stringify(cleanQueryParams({ ...queryParam, ...searchValue }));
        history.push(`/subscriptions/list?${params}`);
        setQueryParam({
            ...queryParam,
            ...searchValue
        });
    };

    const handleSearchCheckboxChange = () => {
        const useClient = !searchOnClient;
        setSearchOnClient(useClient);

        if (useClient) {
            setSearchValue({
                ...searchValue,
                partnerRaisonSociale: "",
                partnerCode: ""
            });
        } else {
            setSearchValue({
                ...searchValue,
                raisonSociale: "",
                clientCode: ""
            });
        }
    };

    const handleChangeFilial = (e) => {
        setQueryParam({
            ...queryParam,
            etablissement: e.target.value,
            page: 0
        });
    };

    const handleChangeOrigin = (e) => {
        setQueryParam({
            ...queryParam,
            originSbc: e.target.value,
            page: 0
        });
    };

    const handleClickButtonOk = () => {
        setShowAlertResiliation(false);
        setShowFormResiliation(true);
    };

    const checkParentTiers = (row) => {
        if (user.tiersParent) {
            return row.partnerCode === user.tiersCode;
        }

        return true;
    };

    const showEdit = (row) => {
        if (row.status === "Vendu" || row.status === "Activé") {
            return true;
        }
        return false;
    };

    // TODO : Add this check to subscription edition (userActions)

    const checkAction = (row, action) => {
        const { notAllowedActions } = row;
        return (notAllowedActions && !notAllowedActions.includes(action)) || !notAllowedActions;
    };

    const originFilter =
        user && user.role !== rolesJson.partner.role
            ? [
                  {
                      key: "origin",
                      label: t("proposals:list-proposals-page.origin-list"),
                      options: [
                          { value: "PORTAL_SBC", name: t("proposals:list-proposals-page.portal-sbc") },
                          { value: "CHECKOUT_SBC", name: t("proposals:list-proposals-page.sage-checkout") }
                      ],
                      className: "origin-filter",
                      handler: handleChangeOrigin,
                      value: originSbc
                  }
              ]
            : [];

    const handleEditSubscription = (row) => {
        const { reference: subscriptionreference, usageCode, tiersDententeurPivotId: clientPivotId, product } = row;
        checkOptionalsConfig({ usageCode, clientPivotId, product })
            .then(() => {
                history.push(`/subscriptions/${subscriptionreference}/edit?usageCode=${usageCode}&step=config`);
            })
            .catch((error) => {
                handleRestrictions(error, setShowNoOptionalModules, setEeditionMessageType, setEditionErrorMessage, user, setToastDiligencelink);
            });
    };
    const userActions = [
        {
            name: "terminate",
            type: "button",
            icon: "message-blocked",
            ariaLabel: t("subscriptions:list-subscriptions-page.terminate"),
            onClick: (row) => {
                setSelectedSubscription(row);
                setShowAlertResiliation(true);
            },
            showCondition: (row) => showResiliation(row)
        },
        {
            name: "edit",
            type: "button",
            icon: "ui-edit",
            onClick: (row) => {
                handleEditSubscription(row);
            },
            ariaLabel: t("subscriptions:list-subscriptions-page.edit"),
            tooltipPosY: "top",
            tooltipPosX: "right",
            showCondition: (row) => showEdit(row) && checkParentTiers(row) && checkAction(row, "edit")
        },
        {
            name: "order",
            type: "button",
            icon: "ui-cart",
            ariaLabel: t("subscriptions:list-subscriptions-page.show"),
            tooltipPosY: "top",
            tooltipPosX: "right",
            onClick: (row) => {
                setSelectedSubscription(row);
                setShowSubscriptionDetail(true);
            },
            showCondition: show
        },
        {
            name: "proposalconsult",
            type: "button",
            icon: "ui-document",
            ariaLabel: t("subscriptions:list-subscriptions-page.proposals-consult"),
            onClick: (row) => {
                const proposalReference = row.reference;
                history.push(`/proposals/list?subscription=${proposalReference}`);
            },
            showCondition: show
        },
        {
            name: "document",
            type: "button",
            icon: "ui-document-commercial",
            ariaLabel: t("subscriptions:list-subscriptions-page.invoices-consult"),
            onClick: (row) => {
                history.push(`/invoices/list?clientCode=${row.codeTiers}&limit=50${row.partnerCode ? `&partnerCode=${row.partnerCode}` : ""}`);
            },
            showCondition: show
        }
    ];

    const columns = [
        { name: "reference", type: "text", dbRef: "reference", label: t("subscriptions:list-subscriptions-page.reference") },
        { name: "code", type: "text", dbRef: "codeTiers", label: t("subscriptions:list-subscriptions-page.code") },
        { name: "client-name", type: "text", dbRef: "raisonSociale", label: t("subscriptions:list-subscriptions-page.client-name") },
        { name: "partnerCode", type: "text", dbRef: "partnerCode", label: t("proposals:list-proposals-page.partner") },
        { name: "partnerName", type: "text", dbRef: "partnerName", label: t("proposals:list-proposals-page.partner-name") },
        { name: "productName", type: "text", dbRef: "productName", label: t("subscriptions:list-subscriptions-page.offer") },
        { name: "dateService", type: "text", dbRef: "dateService", label: t("subscriptions:list-subscriptions-page.service_date") },
        { name: "usageSubscription", type: "text", dbRef: "usageSubscription", label: t("subscriptions:list-subscriptions-page.usage") },
        { name: "status", type: "pill", dbRef: "status", colorRef: "color", label: t("proposals:list-proposals-page.status") },
        { name: "actions", type: "actions", actions: userActions }
    ];

    const filters =
        user && user.tiersParent && establishmentList.length > 1
            ? [
                  {
                      key: "establishment-filter",
                      label: t("subscriptions:list-subscriptions-page.filial-list"),
                      options: establishmentList,
                      className: "establishment-filter",
                      handler: handleChangeFilial,
                      value: etablissement
                  }
              ].filter(({ key }) => actions.includes(key))
            : [];

    if (loading) {
        return (
            <PageWrapper className="list-clients-page" fullPage>
                <Loading />
            </PageWrapper>
        );
    }

    const handleExport = () => {
        setIsDownloading(true);
        setDisableExport(true);
        const { etablissement: selectedEtablissement } = queryParam;
        exportSubscriptions({
            showFilials: !(typeof selectedEtablissement === "string" && selectedEtablissement.length > 0),
            limit: totalSubscriptions,
            ...queryParam
        }).then((res) => {
            const { data } = res;
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
            const link = document.createElement("a");
            link.download = `Souscriptions-${format(new Date(), "dd/MM/yyyy")}.xlsx`;
            link.href = URL.createObjectURL(blob);
            document.body.append(link);
            link.click();
            link.remove();
            setDisableExport(false);
            setIsDownloading(false);
        });
    };

    return (
        <PageWrapper className="subscription-list-page" fullPage unscrollable={showSubscriptionDetail}>
            <Lists.HeaderFullMobile title={t("subscriptions:list-subscriptions-page.subscriptions")}>
                <FormElements.Button className="full-page-header__button" onClick={() => setOpenSearchMenu(!openSearchMenu)}>
                    <Icon name="ui-search" />
                </FormElements.Button>
            </Lists.HeaderFullMobile>
            {isDownloading && <Downloading> </Downloading>}
            <Lists.ListHeaderMobile tabs={mobileTabs()} activeTab={status} />

            <Lists.ListHeader
                title={`${t("subscriptions:list-subscriptions-page.title")} (${totalSubscriptions.toLocaleString()})`}
                description={t("subscriptions:list-subscriptions-page.description")}
                quickFilters={[...statusFilter, ...filters, ...originFilter, ...dateFilter]}
                popoverActions={[
                    {
                        key: "search",
                        label: t("proposals:list-proposals-page.search"),
                        className: "medium secondary",
                        icon: "ui-search",
                        action: () => setOpenSearchMenu(!openSearchMenu),
                        render: (
                            <SearchMenuProposal
                                titleSearchMenu={t("clients:list-clients-page.search")}
                                openSearchMenu={openSearchMenu}
                                setOpenSearchMenu={setOpenSearchMenu}
                                handleSearchChange={handleSubscriptionSearchChange}
                                handleSearch={handleSubscriptionSearch}
                                searchValue={searchValue}
                                searchOnClient={searchOnClient}
                                handleSearchCheckboxChange={handleSearchCheckboxChange}
                                handleResetSearch={handleResetSearch}
                                isPartner={isPartner}
                                displayCommercialEmail={false}
                                displaySubscriptionRef
                                displayRangeDate
                            />
                        )
                    }
                ]}
                buttonActions={[
                    {
                        key: "export",
                        label: t("clients:list-clients-page.export"),
                        className: "medium secondary",
                        action: handleExport,
                        disabled: disableExport
                    }
                ].filter(({ key }) => actions.includes(key))}
            />
            <Lists.ListTable columns={[...columns]} content={subscriptions} rowKey="reference" actionsCount="3" />
            <Lists.ListPagination numberElem={totalSubscriptions} page={currentPage} pageSize={pageSize} changePage={changePage} />
            {showSubscriptionDetail && (
                <NewClient
                    updateModal={setShowSubscriptionDetail}
                    clientPivotId={selectedSubscription.tiersDententeurPivotId}
                    subscription={selectedSubscription}
                    withTabs={false}
                />
            )}
            {showFormResiliation && <FormResiliation updatePage={updatePage} setShowForm={setShowFormResiliation} subscription={selectedSubscription} />}
            <ModalAlert
                className="new-proposal__alert"
                visible={showAlertResiliation}
                onCancel={() => {
                    setShowAlertResiliation(false);
                    setShowFormResiliation(false);
                }}
                onClickButton={handleClickButtonOk}
                title={t("subscriptions:list-subscriptions-page.resiliation-title")}
                icon="ui-cart-buy"
                cancelButtonText={t("subscriptions:list-subscriptions-page.resiliation-no")}
                acceptButtonText={t("subscriptions:list-subscriptions-page.resiliation-ok")}>
                <p>{t("subscriptions:list-subscriptions-page.resiliation-question")}</p>
            </ModalAlert>
            <ModalAlert
                className="new-proposal__alert"
                visible={showNoOptionalModules}
                onClickButton={() => {
                    setShowNoOptionalModules(false);
                }}
                title={t("subscriptions:list-subscriptions-page.edit-subscription-title")}
                icon="ui-cart-buy"
                acceptButtonText={t("subscriptions:list-subscriptions-page.ok")}>
                <div className="subscription-message__description">
                    <p className={editionMessageType}>
                        {editionErrorMessage}
                        {toastDiligencelink && (
                            <a href={`${t("clients:tiers.linkDiligence")}`} target="_blank" rel="noopener noreferrer">
                                {t("clients:tiers.linkICI")}
                            </a>
                        )}
                    </p>
                </div>
            </ModalAlert>
        </PageWrapper>
    );
};

export default ListSubscriptionPage;
