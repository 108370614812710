import React, { useRef } from "react";
import "./textarea.scss";

const Textarea = (props) => {
    const { className = "", name, value, onChange, disabled = false, placeholder, rows, maxLength } = props;
    const textareaRef = useRef();

    return (
        <div className={`carbon-form__textarea ${className}`} ref={textareaRef}>
            <textarea placeholder={placeholder} name={name} value={value} onChange={onChange} disabled={disabled} rows={rows} maxLength={maxLength} />
        </div>
    );
};

export default Textarea;
