import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { PageWrapper, Pill, PopupWrapper, Toast } from "../../../../shared/components";
import NewClientInformation from "./information/client-information";
import Icon from "../../../../shared/components/icon-svg/icon";
import Loading from "../../../../shared/components/loading/loading";
import { addTiers, getClient, getClientDetails, getCountries, getTiersType } from "../../services/client.services";
import { getInterlocutor, getSuspensionMsg } from "../../../users/service/user.services";
import "./new-client.scss";
import CartTotal from "../../../proposals/components/cart-total/cart-total";
import { getProposal, getSubstitutionList } from "../../../proposals/services";
import Cart from "../../../proposals/components/cart/cart";
import { getSubscription, getMotifTerminate } from "../../../subscription/service/subscription.service";

const NewClient = ({
    updateModal,
    setCartMeta,
    cartMeta,
    setFormValidity,
    formValidity,
    clientPivotId,
    subscription,
    proposal,
    withTabs,
    withPopup = true,
    profileAdmin,
    profile,
    setSelectedTiers
}) => {
    const { t } = useTranslation();

    const [clientMeta, setClientMeta] = useState({
        raisonClient: "",
        typeClient: "",
        siretClient: "",
        principalAddress: "",
        principalAdditionalAddress1: "",
        principalAdditionalAddress2: "",
        principalAddressCountry: "",
        principalAddressVille: "",
        principalAddressZipCode: "",
        interlocutorCivility: "",
        interlocutorFirstName: "",
        interlocutorLastName: "",
        interlocutorEmail: "",
        interlocutorPhone: "",
        invoiceContactCivility: "",
        invoiceContactFirstName: "",
        invoiceContactLastName: "",
        invoiceContactEmail: "",
        invoiceContactPhone: "",
        duplicatePrincipalContact: false,
        emptyPrincipalContact: false,
        emptyFacturationContact: false
    });
    const [showToast, setShowToast] = useState(false);
    const [showToastMessage, setShowToastMessage] = useState("");
    const [toastType, setToastType] = useState("");

    const [currentTab, setCurrentTab] = useState("info");
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const tabs = ["info", "address", "contacts"];
    const [subscriptionMeta, setSubscriptionMeta] = useState({});
    const [cartItems, setCartItems] = useState([]);
    const [tiersTypes, setTiersTypes] = useState([]);
    const [tiersCountries, setTiersCountries] = useState([]);
    const [motifdescription, setMotifdescription] = useState("");
    const [substitutionsInfos, setSubstitutionsInfos] = useState([]);
    const [metaInfo, setMetaInfo] = useState([]);
    const [suspensionMsg, setSuspensionMsg] = useState("");
    const handleChangeTab = (tab) => {
        setCurrentTab(tab);
    };

    useEffect(() => {
        if (!profile) {
            const windowOffset = window.scrollY;
            document.body.setAttribute("style", `position: fixed; top: -${windowOffset}px; left: 0; right: 0;`);
            document.getElementById("main-header").setAttribute("style", `top: ${windowOffset}px;`);

            return () => {
                document.body.setAttribute("style", "");
                document.getElementById("main-header").setAttribute("style", "top: 0;");
                window.scrollTo(0, windowOffset);
            };
        }
        return () => {};
    }, [profile]);

    useEffect(() => {
        getTiersType().then((res) => {
            const { data } = res;
            setTiersTypes(data);
        });

        getCountries().then((res) => {
            const { data } = res;
            setTiersCountries(data);
        });
    }, []);

    useEffect(() => {
        let unmounted = false;
        async function getDetailsInfos() {
            if (subscription) {
                let proposalData;
                const { reference } = subscription;
                try {
                    proposalData = await getProposal(reference);
                } catch (error) {
                    setShowToast(true);
                    setToastType("error");
                    setShowToastMessage(t("clients:tiers.proposal-not-found"));
                }
                if (proposal) {
                    if (proposalData) {
                        const { proposalMeta, proposalItems } = proposalData.data;
                        const subsData = await getSubstitutionList(reference);
                        setSubstitutionsInfos(subsData.data);
                        setCartItems(proposalItems);
                        setSubscriptionMeta(proposalMeta);
                        setMetaInfo(proposalMeta);
                    }
                } else {
                    let clientContactEmailProposal;
                    let administratorEmailProposal;
                    let proposalItemsp;
                    if (proposalData) {
                        const { proposalMeta, proposalItems } = proposalData.data;
                        const { clientContactEmail, administratorEmail } = proposalMeta;
                        clientContactEmailProposal = clientContactEmail;
                        administratorEmailProposal = administratorEmail;
                        proposalItemsp = proposalItems;
                        setMetaInfo(proposalMeta);
                    }
                    getSubscription(reference).then((res) => {
                        const { subscriptionItems, total, clientPivotId: clientId, partnerPivotId: partnerId } = res.data;

                        const mergeItems = subscriptionItems.map((subItem) => {
                            const { description } = (proposalItemsp && proposalItemsp.find((propItem) => propItem.product === subItem.product && propItem)) || {
                                description: ""
                            };
                            return { ...subItem, description };
                        });
                        setCartItems(mergeItems);
                        setSubscriptionMeta({
                            ...subscriptionItems[0],
                            total,
                            clientPivotId: clientId,
                            partnerPivotId: partnerId,
                            clientContactEmailProposal,
                            administratorEmailProposal
                        });
                        const item = subscriptionItems.length > 0 && subscriptionItems.find((cartItem) => cartItem.type === "principal");
                        if (item) {
                            const { motifResiliation } = item;
                            if (motifResiliation !== 0) {
                                getMotifTerminate(motifResiliation).then((result) => {
                                    const { description } = result.data;
                                    setMotifdescription(description);
                                });
                            }
                        }
                    });
                }
            }
        }
        getDetailsInfos();
        if (clientPivotId) {
            setLoading(true);
            getClientDetails(clientPivotId)
                .then(async (res) => {
                    if (!unmounted) {
                        const { tiersName, tiersType, tiersSiret } = res.data;
                        const { address1, address2, address3, zipCode, country, city } = res.data;
                        const { interlocutorPrincipalPivotId, contactFacturePivotId } = res.data;
                        const interlocutorInformation = {
                            interlocutorCivility: "",
                            interlocutorFirstName: "",
                            interlocutorLastName: "",
                            interlocutorEmail: "",
                            interlocutorPhone: "",
                            invoiceContactCivility: "",
                            invoiceContactFirstName: "",
                            invoiceContactLastName: "",
                            invoiceContactEmail: "",
                            invoiceContactPhone: "",
                            duplicatePrincipalContact: interlocutorPrincipalPivotId === contactFacturePivotId,
                            emptyPrincipalContact: interlocutorPrincipalPivotId === null,
                            emptyFacturationContact: contactFacturePivotId === null
                        };

                        if (interlocutorPrincipalPivotId !== null && !proposal) {
                            const interlocutorPrincipal = await getInterlocutor(interlocutorPrincipalPivotId);
                            if (interlocutorPrincipal) {
                                const { civility, email, firstName, lastName, phone } = interlocutorPrincipal.data;
                                interlocutorInformation.interlocutorCivility = civility;
                                interlocutorInformation.interlocutorFirstName = firstName;
                                interlocutorInformation.interlocutorLastName = lastName;
                                interlocutorInformation.interlocutorEmail = email;
                                interlocutorInformation.interlocutorPhone = phone;
                            }
                        }

                        if (!interlocutorInformation.duplicatePrincipalContact && contactFacturePivotId !== null && !proposal) {
                            const contactFacturation = await getInterlocutor(contactFacturePivotId);
                            if (contactFacturation) {
                                const { civility, email, firstName, lastName, phone } = contactFacturation.data;
                                interlocutorInformation.invoiceContactCivility = civility;
                                interlocutorInformation.invoiceContactFirstName = firstName;
                                interlocutorInformation.invoiceContactLastName = lastName;
                                interlocutorInformation.invoiceContactEmail = email;
                                interlocutorInformation.invoiceContactPhone = phone;
                            }
                        }

                        setClientMeta({
                            raisonClient: tiersName,
                            typeClient: tiersType,
                            siretClient: tiersSiret,
                            principalAddress: address1,
                            principalAdditionalAddress1: address2,
                            principalAdditionalAddress2: address3,
                            principalAddressCountry: country,
                            principalAddressVille: city,
                            principalAddressZipCode: zipCode,
                            ...interlocutorInformation
                        });

                        setLoading(false);
                    }
                })
                .catch(() => {
                    setLoading(false);
                });
        }
        return () => {
            unmounted = true;
        };
    }, [clientPivotId, subscription, proposal, t]);

    const suspensionMessage = useCallback(() => {
        getSuspensionMsg().then((res) => {
            if (res && res.data) {
                const { message } = res.data[0];
                setSuspensionMsg(message);
            }
        });
    }, []);
    useEffect(() => {
        suspensionMessage();
    }, [suspensionMessage]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (enableSubmit) {
            setEnableSubmit(false);
            addTiers(clientMeta).then((resp) => {
                if (resp.data && resp.data.tiers) {
                    getClient(resp.data.tiers.PivotId)
                        .then((response) => {
                            const {
                                pivotId,
                                tiersCode,
                                tiersName,
                                tiersCountry,
                                sanctionPolicy,
                                sanctionDateFin,
                                dueDiligence,
                                dueDiligenceDateFin,
                                tiersBloque
                            } = response.data;
                            setCartMeta((c) => ({
                                ...c,
                                clientPivotId: pivotId,
                                clientCode: tiersCode,
                                clientName: tiersName,
                                clientCountry: tiersCountry,
                                clientSanctionPolicy: sanctionPolicy,
                                clientSanctionDateFin: sanctionDateFin,
                                clientDueDiligence: dueDiligence,
                                clientDueDiligenceDateFin: dueDiligenceDateFin,
                                clientTiersBloque: tiersBloque,
                                clientContactEmail: "",
                                clientContactFirstName: "",
                                clientContactLastName: "",
                                clientContactCivility: "",
                                administratorEmail: "",
                                administratorFirstName: "",
                                administratorLastName: "",
                                administratorCivility: "",
                                administratorPivotId: ""
                            }));
                            setEnableSubmit(true);
                            updateModal(false);
                            setFormValidity({ ...formValidity, clientSelectedValid: true });
                        })
                        .catch(() => {
                            setEnableSubmit(true);
                        })
                        .catch(() => {
                            setEnableSubmit(true);
                        });
                }
            });
        }
    };

    const newClientPageCart = () => {
        /* Cart display only from subscription list */
        if (subscription && !loading) {
            const mode = "sepa";
            const { raisonClient } = clientMeta;
            const total = Object.entries(subscriptionMeta).length > 0 && typeof subscriptionMeta.total === "number" ? subscriptionMeta.total : 0;
            const clientId = subscriptionMeta.partnerPivotId || subscriptionMeta.clientPivotId;
            return (
                <div className="new-client__cart new-client__radius">
                    <div className="new-client__client new-client__radius">
                        <div className="new-client__client-avatar">
                            <Icon name="ui-avatar" />
                        </div>
                        <div className="new-client__client-content">
                            <div className="client-content__name">{raisonClient}</div>
                            <div className="client-content__description" />
                        </div>
                    </div>
                    <CartTotal
                        items={cartItems}
                        total={total}
                        mode={mode}
                        souscriptionDetail
                        clientPivotId={clientId}
                        selectedClient={subscriptionMeta.clientPivotId}
                    />
                    <Cart items={cartItems} mode={mode} />
                </div>
            );
        }
        return <></>;
    };

    const newClientPageFooter = () => {
        /* Creation Mode */
        if (!clientPivotId) {
            return (
                <div className="new-client__footer">
                    <button type="submit" className="button step-navigation__next medium primary" form="new-client-form" disabled={!enableSubmit}>
                        {t("clients:new-client.save")}
                    </button>
                </div>
            );
        }
        return <></>;
    };
    const getProductName = () => {
        const item = cartItems.length > 0 && cartItems.find((cartItem) => cartItem.type === "one-to-n");
        const { label } = item;
        const principalProducts = cartItems.filter(({ productParent }) => !productParent);
        if (principalProducts && principalProducts.length > 0) {
            const { label: pLabel } = principalProducts[0];
            return `${pLabel}  ${label ? `- ${label}` : ""} `;
        }
        return `${label ? `${label}` : ""} `;
    };

    const handleClientInformation = () => {
        if (proposal) {
            return (
                <NewClientInformation
                    enableSubmit={setEnableSubmit}
                    updateModal={updateModal}
                    setCartMeta={setCartMeta}
                    cartMeta={metaInfo}
                    clientMeta={clientMeta}
                    setClientMeta={setClientMeta}
                    clientPivotId={clientPivotId}
                    setFormValidity={setFormValidity}
                    formValidity={formValidity}
                    subscription={subscriptionMeta}
                    proposal
                    tiersTypes={tiersTypes}
                    tiersCountries={tiersCountries}
                    substitutionsInfos={substitutionsInfos}
                    productCommercialName={getProductName()}
                    cartItems={cartItems}
                    subscriptionInfos={subscription}
                />
            );
        }
        if (subscription) {
            return (
                <NewClientInformation
                    enableSubmit={setEnableSubmit}
                    updateModal={updateModal}
                    setCartMeta={setCartMeta}
                    cartMeta={metaInfo}
                    clientMeta={clientMeta}
                    setClientMeta={setClientMeta}
                    clientPivotId={clientPivotId}
                    setFormValidity={setFormValidity}
                    formValidity={formValidity}
                    subscription={subscriptionMeta}
                    proposal={false}
                    tiersTypes={tiersTypes}
                    tiersCountries={tiersCountries}
                    subscriptionInfos={subscription}
                />
            );
        }
        return (
            <NewClientInformation
                enableSubmit={setEnableSubmit}
                updateModal={updateModal}
                setCartMeta={setCartMeta}
                clientMeta={clientMeta}
                setClientMeta={setClientMeta}
                setFormValidity={setFormValidity}
                formValidity={formValidity}
                clientPivotId={clientPivotId}
                profileAdmin={profileAdmin}
                tiersTypes={tiersTypes}
                tiersCountries={tiersCountries}
                setSelectedTiers={setSelectedTiers}
                cartMeta={metaInfo}
            />
        );
    };
    const getSuspensionInfos = (item, status) => {
        const { endServiceDate } = item || { endServiceDate: "" };
        if (status === "Suspendu") {
            return (
                <div className="tabpanel-header__pill">
                    <div className="new-client__date">{`${t("clients:new-client.suspension-date")} : ${endServiceDate}`}</div>
                    <div className="new-client__date" dangerouslySetInnerHTML={{ __html: suspensionMsg }} />
                </div>
            );
        }
        return <></>;
    };
    const getClientResiliationInfos = (item, status) => {
        const { dateResiliation, endServiceDate } = item || { dateResiliation: "", endServiceDate: "" };
        if (status === "Abandonné / résilié") {
            return (
                <div className="tabpanel-header__pill">
                    <div className="new-client__date_top">{`${t("clients:new-client.resiliation-date")} : ${dateResiliation}`}</div>
                    <div className="new-client__date">{`${t("clients:new-client.motif-resiliation")} : ${motifdescription}`}</div>
                    <div className="new-client__date">{`${t("clients:new-client.end-service-date")} : ${endServiceDate}`}</div>
                </div>
            );
        }
        return <></>;
    };

    const clientInformationForm = () => {
        if (!withTabs) {
            if (proposal) {
                const { statusLabel } = subscription;

                return (
                    <div className="new-client__tabpanel">
                        <div className="new-client-tabpanel__header">
                            <h2 className="tabpanel-header__title">{getProductName()}</h2>
                            <div className="tabpanel-header__pill">
                                <Pill pillLabel={statusLabel} />
                            </div>
                        </div>
                        {handleClientInformation()}
                    </div>
                );
            }
            if (subscription) {
                const { productName, dateService, status, usageSubscription } = subscription;
                const item = cartItems.length > 0 && cartItems.find((cartItem) => cartItem.type === "principal");
                const { label } = item || { label: "" };

                return (
                    <div className="new-client__tabpanel">
                        <div className="new-client-tabpanel__header">
                            <h2 className="tabpanel-header__title">{`${productName}  ${label ? `- ${label}` : ""} `}</h2>
                            <div className="tabpanel-header__pill">
                                <div className="new-client__date">{`${t("clients:new-client.subscription-date")} : ${dateService}`}</div>
                                <div className="new-client__date">{`${t("proposals:select-fin.usage")} : ${usageSubscription}`}</div>
                                <Pill pillLabel={status} />
                                {getClientResiliationInfos(item, status)}
                                {getSuspensionInfos(item, status)}
                            </div>
                        </div>
                        {handleClientInformation()}
                    </div>
                );
            }

            return (
                <div className="new-client__tabpanel">
                    {!clientPivotId && <div className="new-client__subtitle">{t("clients:new-client.subtitle")}</div>}
                    {handleClientInformation()}
                </div>
            );
        }
        return <></>;
    };

    const contentWithTabs = () => {
        if (withTabs) {
            return (
                // TODO: add left and right arrows bindings to loop through the tabs when focus is in the tablist
                <>
                    <div role="tablist" className="new-client__tablist">
                        {tabs.map((tab) => (
                            <button
                                role="tab"
                                type="button"
                                aria-selected={currentTab === tab ? "true" : "false"}
                                aria-controls={`${tab}-tab`}
                                id={tab}
                                className={`new-client__tab ${currentTab === tab ? "new-client__tab--current" : ""}`}
                                onClick={() => handleChangeTab(tab)}
                                key={tab}
                                tabIndex={currentTab === tab && "-1"}>
                                {t(`clients:new-client.tabs.${tab}`)}
                            </button>
                        ))}
                    </div>
                    {currentTab === "info" && <div role="tabpanel" id="info-tab" aria-labelledby="info" className="new-client__tabpanel" tabIndex="0" />}
                    {currentTab === "address" && (
                        <div role="tabpanel" id="address-tab" aria-labelledby="address" className="new-client__tabpanel" tabIndex="0" />
                    )}
                    {currentTab === "contacts" && (
                        <div role="tabpanel" id="contacts-tab" aria-labelledby="contacts" className="new-client__tabpanel" tabIndex="0" />
                    )}
                </>
            );
        }
        return <></>;
    };

    const newClientTitle = () => {
        if (proposal) {
            return <h1 className="new-client__title">{`${t("clients:new-client.proposal")} ${subscription.reference} -${subscription.clientName}`}</h1>;
        }

        if (subscription) {
            return <h1 className="new-client__title">{`${t("clients:new-client.souscription")} ${subscription.reference} -${subscription.raisonSociale}`}</h1>;
        }
        if (clientPivotId) {
            if (profile) {
                return <h1 className="new-client__title">{t("clients:profile.title")}</h1>;
            }
            return <h1 className="new-client__title">{t("clients:detail-client.title")}</h1>;
        }

        return <h1 className="new-client__title">{t("clients:new-client.title")}</h1>;
    };

    const newClientPageHeader = () => {
        if (withPopup) {
            return (
                <div className="new-client__header">
                    {newClientTitle()}
                    <button type="button" className="new-client__close" onClick={() => updateModal(false)}>
                        <Icon name="dialog-close" />
                    </button>
                </div>
            );
        }
        return <></>;
    };

    const newClientPageBody = () => {
        if (loading) return <Loading />;
        return (
            <div className="new-client__content">
                <div className="new-client__tabs">
                    {clientInformationForm()}
                    {contentWithTabs()}
                </div>
            </div>
        );
    };

    const clientPageContent = () => {
        return (
            <div className="new-client__popup">
                {newClientPageHeader()}
                {newClientPageBody()}
                {newClientPageCart()}
                {newClientPageFooter()}
            </div>
        );
    };

    if (withPopup) {
        return (
            // <PageWrapper className="new-client-page" unscrollable fullPage>
            <PopupWrapper classNamePopup="popup--full-page" visible>
                <form id="new-client-form" onSubmit={handleSubmit} className="new-client">
                    <Toast show={showToast} onDismiss={() => setShowToast(false)} message={showToastMessage} toastType={toastType} />
                    {clientPageContent()}
                </form>
            </PopupWrapper>
            // </PageWrapper>
        );
    }

    return (
        <PageWrapper className="new-client-page" fullPage>
            <form id="new-client-form" onSubmit={handleSubmit} className="new-client">
                {clientPageContent()}
            </form>
        </PageWrapper>
    );
};

export default NewClient;
