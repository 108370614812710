import React from "react";
import FormElements from "../../form-elements";
import Icon from "../../icon-svg/icon";
import "./list-header.scss";

const ListHeader = (props) => {
    const { title, description, quickFilters, popoverActions, buttonActions, button, calendarInvoice } = props;
    const selectFilter = (filter) => {
        const { key, label, options, className, handler, value } = filter;
        return (
            <div className={className} key={key}>
                <FormElements.Select name={key} onChange={handler} options={options} label={label} value={value} />
            </div>
        );
    };

    const selectMultiFilter = (filter) => {
        const { key, label, options, className, handler, value, optionSelected, setOptionSelected, previousSelectedValues } = filter;
        return (
            <div className={className} key={key}>
                <FormElements.MultiSelectCompo
                    name={key}
                    onChange={handler}
                    options={options}
                    label={label}
                    value={value}
                    optionSelected={optionSelected}
                    setOptionSelected={setOptionSelected}
                    previousSelectedValues={previousSelectedValues}
                />
            </div>
        );
    };
    const popoverButton = ({ key, className, label, icon, action, render }) => (
        <div className="popover-button" key={key}>
            <FormElements.Button onClick={action} className={className}>
                {label}
                {icon && <Icon name={icon} />}
            </FormElements.Button>
            {render}
        </div>
    );

    const simpleButton = ({ key, className, label, icon, action, disabled }) => (
        <div className="simple-button" key={key}>
            <FormElements.Button onClick={action} className={className} disabled={disabled}>
                {label}
                {icon && <Icon name={icon} />}
            </FormElements.Button>
        </div>
    );

    return (
        <>
            <h1 className="heading">{title}</h1>
            <div className="description">
                <p>{description}</p>
            </div>
            <div className="list__header-actions">
                <div className="list__select-wrapper">
                    {quickFilters &&
                        quickFilters.length > 0 &&
                        quickFilters.map((filter) => (filter.multiValues ? selectMultiFilter(filter) : selectFilter(filter)))}
                </div>
                <div className="list__button-wrapper">
                    {calendarInvoice && calendarInvoice.length > 0 && calendarInvoice.map((action) => simpleButton(action))}
                    {button && button.length > 0 && button.map((action) => popoverButton(action))}
                    {popoverActions && popoverActions.length > 0 && popoverActions.map((action) => popoverButton(action))}
                    {buttonActions && buttonActions.length > 0 && buttonActions.map((action) => simpleButton(action))}
                </div>
            </div>
        </>
    );
};

export default ListHeader;
