import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Loading } from "../../../../shared/components";
import { getClientsDashboard } from "../../../clients/services/client.services";
import { AuthContext } from "../../../../context";
import ListTable from "../../../../shared/components/lists/list-table/list-table";
import "./dashboard-client.scss";
import { getDateByUTC } from "../../../../shared/utils/string.utils";
import DashboardError from "../dashboard-error/dashboard-error";

const DashboardClients = () => {
    const { t } = useTranslation();
    const { isAuthenticated, user } = useContext(AuthContext);

    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState();
    const history = useHistory();
    const redirectClient = (row) => {
        const { statusLabel } = row;
        if (result && result.pivotIdParent !== 0) {
            history.push(`/clients/list?clients=${statusLabel}&etablissement=${result.pivotIdParent}`);
        } else {
            history.push(`/clients/list?clients=${statusLabel}`);
        }
    };
    const columns = [
        {
            name: "total",
            type: "text-button",
            dbRef: "total",
            label: t("users:dashboard-clients.title"),
            onClick: (row) => {
                redirectClient(row);
            }
        },
        {
            name: "withSubscription",
            type: "text-button",
            dbRef: "withSubscription",
            label: t("users:dashboard-clients.with-subscription"),
            onClick: (row) => {
                redirectClient(row);
            }
        }
    ];
    useEffect(() => {
        if (isAuthenticated && user) {
            setLoading(true);
            getClientsDashboard({})
                .then((res) => {
                    setResult(res.data);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, [isAuthenticated, user]);
    const contentRows = [
        {
            total: result ? parseFloat(result.allClientsCount).toLocaleString() : "",
            withSubscription: result ? parseFloat(result.allClientsOneSub).toLocaleString() : "",
            statusLabel: "Avec souscription"
        },
        {
            total: result ? parseFloat(result.clientsCount).toLocaleString() : "",
            withSubscription: result ? parseFloat(result.clientsOneSub).toLocaleString() : "",
            statusLabel: "Avec souscription"
        },
        {
            total: result ? parseFloat(result.clientsCount).toLocaleString() : "",
            withSubscription: result ? parseFloat(result.clientsOneSub).toLocaleString() : "",
            statusLabel: "Avec souscription"
        }
    ];
    if (result && result.allClientsCount === -1) contentRows.splice(0, 1);
    if (result && result.pivotIdParent === 0) contentRows.splice(0, 1);
    if (result && result.pivotIdParent !== 0) contentRows.splice(1, 2);
    if (loading) return <Loading />;

    return result ? (
        <div className="dashboard ">
            <div className="borderdash">
                <div className="total__header dashbord_header">
                    <strong>{`${t("users:dashboard-clients.new-title", { totalClients: parseFloat(result.clientsCount).toLocaleString() })}`}</strong>
                    <div className="margin-title">Title</div>
                </div>
                {result ? <ListTable columns={[...columns]} content={contentRows} rowKey="withSubscription" /> : <DashboardError />}

                <div className="dashboard__lastUpdate">
                    <p>{`${t("users:last-update")}: ${getDateByUTC(result.lastUpdate)}`}</p>
                </div>
            </div>
        </div>
    ) : (
        <div className="dashboard ">
            <div className="borderdash">
                <div className="total__header dashbord_header">
                    <div className="total__header-count">
                        <strong>{result ? parseFloat(result.allClientsCount).toLocaleString() : ""}</strong>
                    </div>
                </div>
                <div className="total__title">
                    <div>
                        <strong>{`${t("users:dashboard-clients.title")}`}</strong>
                    </div>
                </div>
                <DashboardError />
            </div>
        </div>
    );
};

export default DashboardClients;
