import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../../../context";
import { checkTiers, getClient } from "../../../../clients/services/client.services";
import SelectClientList from "./client-list/select-client-list";
import { FormElements, Icon, Toast } from "../../../../../shared/components";
import NewClient from "../../../../clients/pages/new-client/new-client";
import checkTiersValidity from "../../../../clients/utils/tiers.validator";
import SearchClientList from "./client-search/search-client-list";
import { getSubtitutions, getParcMigrationList } from "../../../services/proposal.services";
import "./select-client.scss";

const SelectClient = (props) => {
    const {
        cartItems,
        cartMeta,
        setCartMeta,
        formValidity,
        setFormValidity,
        displayClientList,
        showToast,
        setShowToast,
        showToastMessage,
        toastType,
        setToastType,
        setShowToastMessage,
        setShowSubstitution,
        setUserSusbtitutions
    } = props;
    const { t } = useTranslation();
    const { user, permissions } = useContext(AuthContext);

    const [toastDiligencelink, setToastDiligencelink] = useState(false);
    const {
        proposal: { hiddenFields }
    } = permissions;
    const { clientPivotId, clientReference, clientCode, clientName } = cartMeta;
    const client = { clientPivotId, clientReference, clientCode, clientName };

    const history = useHistory();
    const [selectedTiers, setSelectedTiers] = useState(false);
    const [showNewClientModal, setShowNewClientModal] = useState(false);
    const [tiersValid, setTiersValid] = useState(true);
    const [searchValue, setSearchValue] = useState({
        codeTiers: "",
        raisonSociale: "",
        siret: ""
    });
    const [clientSearchValues, setClientSearchValues] = useState({ ...searchValue });

    const checkforSubstitutions = (clientPid) => {
        getSubtitutions().then((res) => {
            const { userSusbtitutions: susbtitutions } = res.data;
            const { pivotId } = user;
            const offers = cartItems && cartItems.length > 0 ? cartItems.filter(({ type }) => type === "principal") : [];
            const offerslist = offers.map((a) => a.product);
            getParcMigrationList(clientPid, { partnerId: pivotId, offerslist: offerslist.join() }).then((result) => {
                const parcs = result.data;
                if (parcs.length > 0) {
                    if (susbtitutions && susbtitutions.length > 0) {
                        const mergeParclist = parcs.map((parcItem) => {
                            const exists = susbtitutions.find((subItem) => subItem.parcPivotId === parcItem.parcPivotId);
                            return exists ? { ...parcItem, checkedValue: true } : { ...parcItem, checkedValue: false };
                        });
                        setUserSusbtitutions(mergeParclist);
                    } else {
                        setUserSusbtitutions(parcs);
                    }
                    setShowSubstitution(true);
                } else {
                    setShowSubstitution(false);
                    setUserSusbtitutions([]);
                }
                setFormValidity({ ...formValidity, clientSelectedValid: true, administratorEmailValid: false, contactEmailValid: false });
            });
        });
    };

    const updateMetaClient = (clientChoice) => {
        const { pivotId: Pid } = clientChoice;
        checkTiers(Pid).then((response) => {
            const { access, criteria } = response.data;
            const tiersValidity = checkTiersValidity(
                access,
                criteria,
                setShowToast,
                setToastType,
                setShowToastMessage,
                undefined,
                undefined,
                setToastDiligencelink
            );

            if (!tiersValidity) {
                setTiersValid(false);
                setFormValidity({ ...formValidity, clientSelectedValid: false });
            } else {
                if (!tiersValid) setTiersValid(true);
                getClient(clientChoice.pivotId).then((res) => {
                    const {
                        pivotId,
                        tiersCode,
                        tiersName,
                        tiersCountry,
                        sanctionPolicy,
                        sanctionDateFin,
                        dueDiligence,
                        dueDiligenceDateFin,
                        tiersBloque
                    } = res.data;

                    setCartMeta((c) => ({
                        ...c,
                        clientPivotId: pivotId,
                        clientCode: tiersCode,
                        clientName: tiersName,
                        clientCountry: tiersCountry,
                        clientSanctionPolicy: sanctionPolicy,
                        clientSanctionDateFin: sanctionDateFin,
                        clientDueDiligence: dueDiligence,
                        clientDueDiligenceDateFin: dueDiligenceDateFin,
                        clientTiersBloque: tiersBloque,
                        clientContactEmail: "",
                        clientContactFirstName: "",
                        clientContactLastName: "",
                        clientContactCivility: "",
                        administratorEmail: "",
                        administratorFirstName: "",
                        administratorLastName: "",
                        administratorCivility: "",
                        administratorPivotId: ""
                    }));
                });

                if (cartItems) {
                    checkforSubstitutions(Pid);
                } else {
                    setFormValidity({ ...formValidity, clientSelectedValid: true, administratorEmailValid: false, contactEmailValid: false });
                }
            }
        });
    };

    const handleSearch = () => {
        history.push("/proposals/new?step=client&page=0");
        if (selectedTiers) {
            setSelectedTiers();
        }
        setClientSearchValues({ ...searchValue });
    };

    const handleSearchChange = (e) => {
        setSearchValue({
            ...searchValue,
            [e.target.name]: e.target.value
        });
    };

    const handleResetSearch = () => {
        history.push("/proposals/new?step=client&page=0");
        if (selectedTiers) {
            setSelectedTiers();
        }
        setSearchValue({
            codeTiers: "",
            raisonSociale: "",
            siret: ""
        });
        setClientSearchValues({
            codeTiers: "",
            raisonSociale: "",
            siret: ""
        });
        setShowSubstitution(false);
    };

    return (
        <>
            <h2 className="new-proposal__content-title">{t("proposals:select-client.select-client")}</h2>
            <Toast
                show={showToast}
                onDismiss={() => setShowToast(false)}
                message={showToastMessage}
                toastType={toastType}
                insideNestedPortal
                toastDiligencelink={toastDiligencelink}
            />
            <div className="preview__section">
                {!displayClientList && (
                    <div className="preview__form-item">
                        <div className="select-client__button">
                            {!hiddenFields.includes("create_client_button") && (
                                <FormElements.Button className="select-interlocutor__button medium dashed" onClick={() => setShowNewClientModal(true)}>
                                    {t("proposals:select-client.create-client")}
                                    <Icon name="ui-add" />
                                </FormElements.Button>
                            )}
                        </div>

                        <SearchClientList
                            searchValue={searchValue}
                            handleSearchChange={handleSearchChange}
                            handleSearch={handleSearch}
                            handleSearchReset={handleResetSearch}
                        />

                        <SelectClientList client={client} onChange={updateMetaClient} clientSearchValues={clientSearchValues} selectedTiers={selectedTiers} />
                    </div>
                )}

                {showNewClientModal && (
                    <NewClient
                        setFormValidity={setFormValidity}
                        formValidity={formValidity}
                        updateModal={setShowNewClientModal}
                        setCartMeta={setCartMeta}
                        cartMeta={cartMeta}
                        withTabs={false}
                        setSelectedTiers={setSelectedTiers}
                    />
                )}
            </div>
        </>
    );
};

export default SelectClient;
