import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { FormElements, Icon, ModalAlert } from "../../../../../shared/components";
import { getTiersBySiret } from "../../../services/client.services";
import Contact from "../../../components/contact/contact";
import { emailRegex, nameRegex, phoneNumberRegex } from "../../../../proposals/utils/regex";
import { AuthContext, roles as rolesJson } from "../../../../../context";
import "./client-information.scss";

const NewClientInformation = ({
    enableSubmit,
    updateModal,
    setCartMeta,
    clientMeta,
    setClientMeta,
    clientPivotId,
    setFormValidity,
    formValidity,
    subscription,
    proposal,
    profileAdmin,
    tiersTypes,
    tiersCountries,
    substitutionsInfos,
    cartItems,
    subscriptionInfos,
    setSelectedTiers,
    cartMeta
}) => {
    const { t } = useTranslation();
    const disabled = !!clientPivotId;
    const clientFields = ["raisonClient", "typeClient", "siretClient"];
    const addressFields = [
        "principalAddress",
        "principalAdditionalAddress1",
        "principalAdditionalAddress2",
        "principalAddressZipCode",
        "principalAddressVille",
        "principalAddressCountry"
    ];
    const adminFields = ["administratorCivility", "administratorFirstName", "administratorLastName", "administratorEmail", "administratorTel"];
    const interlocutorFields = ["interlocutorCivility", "interlocutorFirstName", "interlocutorLastName", "interlocutorEmail", "interlocutorPhone"];
    const invoiceContactFields = ["invoiceContactCivility", "invoiceContactFirstName", "invoiceContactLastName", "invoiceContactEmail", "invoiceContactPhone"];
    const [existingSiret, setExistingSiret] = useState(false);
    const [existingClientModal, setExistingClientModal] = useState(false);
    const [existingClient, setExistingClient] = useState({});
    const [requiredSiret, setRequiredSiret] = useState(false);
    const [duplicatePrincipalContact, setDuplicatePrincipalContact] = useState(false);
    const [invalidSiret, setInvalidSiret] = useState(false);
    const { user } = useContext(AuthContext);
    const salesDisplay = user.role === rolesJson.sales.role || user.role === rolesJson.endUser.role;
    const { opecomlibelle, appliedPromo, opecomDescription } = cartMeta;
    const [interlocutorFirstNameError, setInterlocutorFirstNameError] = useState(false);
    const [interlocutorNameError, setInterlocutorNameError] = useState(false);
    const [invoiceNameError, setInvoiceNameError] = useState(false);
    const [invoiceFirstNameError, setInvoiceFirstNameError] = useState(false);
    const [interlocutorPhoneError, setInterlocutorPhoneError] = useState(false);
    const [interlocutorEmailError, setInterlocutorEmailError] = useState(false);
    const [invoiceContactEmailError, setInvoiceContactEmailError] = useState(false);
    const [invoiceContactPhoneError, setInvoiceContactPhoneError] = useState(false);

    const luhnChecksum = (code) => {
        const len = code.length;
        const parity = len % 2;
        let sum = 0;
        for (let i = len - 1; i >= 0; i -= 1) {
            let d = parseInt(code.charAt(i), 10);
            if (i % 2 === parity) {
                d *= 2;
            }
            if (d > 9) {
                d -= 9;
            }
            sum += d;
        }
        return sum % 10;
    };

    const luhnAlgorithm = (fullcode) => {
        return luhnChecksum(fullcode) === 0;
    };

    const adminFieldsRules = {
        administratorEmail: { type: "text", display: true, notRequired: true },
        administratorFirstName: { type: "text", display: true, notRequired: true },
        administratorLastName: { type: "text", display: true, notRequired: true },
        administratorTel: { type: "text", display: true, notRequired: true },
        administratorCivility: {
            type: "select",
            options: [
                { value: 100000000, name: "M" },
                { value: 100000001, name: "Mme" }
            ]
        }
    };

    const fields = {
        raisonClient: { type: "text", validator: (value) => value.length > 0, display: true, required: true },
        typeClient: {
            type: "select",
            options: tiersTypes,
            validator: (value) => value.length > 0,
            display: true
        },
        siretClient: {
            type: "number",
            validator: (value) => (requiredSiret ? value.length === 14 && luhnAlgorithm(value) : true),
            display: requiredSiret
        },
        principalAddress: { type: "text", validator: (value) => value.length > 0, display: true },
        principalAddressCountry: { type: "select", options: tiersCountries, validator: (value) => value.length > 0, display: true },
        principalAdditionalAddress1: { type: "text", display: true, notRequired: true, validator: () => true },
        principalAdditionalAddress2: { type: "text", display: true, notRequired: true, validator: () => true },
        principalAddressVille: { type: "text", validator: (value) => value.length > 0, display: true },
        principalAddressZipCode: { type: "text", validator: (value) => value.length > 0, display: true },
        duplicatePrincipalContact: { validator: () => true },
        emptyPrincipalContact: { validator: () => true },
        emptyFacturationContact: { validator: () => true },
        interlocutorCivility: {
            type: "select",
            validator: (value) => value === "100000000" || value === "100000001",
            options: [
                { value: 100000000, name: "M" },
                { value: 100000001, name: "Mme" }
            ],
            label: t("proposals:select-fin.interlocutor.civility"),
            placeholder: t("proposals:select-fin.interlocutor.civility-placeholder"),
            display: true
        },
        interlocutorEmail: {
            type: "text",
            validator: (value) => value.length > 0 && emailRegex.test(value),
            label: t("proposals:select-fin.interlocutor.email"),
            placeholder: t("proposals:select-fin.interlocutor.email-placeholder"),
            errorMessage: true,
            displayError: interlocutorEmailError
        },
        interlocutorPhone: {
            type: "text",
            validator: (value) => value.length > 0 && phoneNumberRegex.test(value),
            label: t("proposals:select-fin.interlocutor.phone"),
            placeholder: t("proposals:select-fin.interlocutor.phone-placeholder"),
            errorMessage: true,
            displayError: interlocutorPhoneError
        },
        interlocutorFirstName: {
            type: "text",
            validator: (value) => value.length > 0 && nameRegex.test(value),
            label: t("proposals:select-fin.interlocutor.firstName"),
            placeholder: t("proposals:select-fin.interlocutor.firstName-placeholder"),
            errorMessage: true,
            displayError: interlocutorFirstNameError
        },
        interlocutorLastName: {
            type: "text",
            validator: (value) => value.length > 0 && nameRegex.test(value),
            label: t("proposals:select-fin.interlocutor.lastName"),
            placeholder: t("proposals:select-fin.interlocutor.lastName-placeholder"),
            errorMessage: true,
            displayError: interlocutorNameError
        },
        invoiceContactCivility: {
            type: "select",
            validator: (value) => (!duplicatePrincipalContact ? value === "100000000" || value === "100000001" : true),
            options: [
                { value: 100000000, name: "M" },
                { value: 100000001, name: "Mme" }
            ],
            label: t("proposals:select-fin.interlocutor.civility"),
            placeholder: t("proposals:select-fin.interlocutor.civility-placeholder")
        },
        invoiceContactEmail: {
            type: "text",
            validator: (value) => (!duplicatePrincipalContact ? value.length > 0 && emailRegex.test(value) : true),
            label: t("proposals:select-fin.interlocutor.email"),
            placeholder: t("proposals:select-fin.interlocutor.email-placeholder"),
            errorMessage: true,
            displayError: invoiceContactEmailError
        },
        invoiceContactPhone: {
            type: "text",
            validator: (value) => (!duplicatePrincipalContact ? value.length > 0 && phoneNumberRegex.test(value) : true),
            label: t("proposals:select-fin.interlocutor.phone"),
            placeholder: t("proposals:select-fin.interlocutor.phone-placeholder"),
            errorMessage: true,
            displayError: invoiceContactPhoneError
        },
        invoiceContactFirstName: {
            type: "text",
            validator: (value) => (!duplicatePrincipalContact ? value.length > 0 && nameRegex.test(value) : true),
            label: t("proposals:select-fin.interlocutor.firstName"),
            placeholder: t("proposals:select-fin.interlocutor.firstName-placeholder"),
            errorMessage: true,
            displayError: invoiceFirstNameError
        },
        invoiceContactLastName: {
            type: "text",
            validator: (value) => (!duplicatePrincipalContact ? value.length > 0 && nameRegex.test(value) : true),
            label: t("proposals:select-fin.interlocutor.lastName"),
            placeholder: t("proposals:select-fin.interlocutor.lastName-placeholder"),
            errorMessage: true,
            displayError: invoiceNameError
        }
    };

    useEffect(() => {
        const { duplicatePrincipalContact: duplicateContact, typeClient } = clientMeta;
        setDuplicatePrincipalContact(duplicateContact);

        if (typeClient && typeClient.toString() === "279640000") {
            setRequiredSiret(true);
        }
    }, [clientMeta]);

    const chooseClient = () => {
        const { pivotId, tiersCode, tiersName, tiersCountry, sanctionPolicy, sanctionDateFin, dueDiligence, dueDiligenceDateFin, tiersBloque } = existingClient;
        setCartMeta((c) => ({
            ...c,
            clientPivotId: pivotId,
            clientCode: tiersCode,
            clientName: tiersName,
            clientCountry: tiersCountry,
            clientSanctionPolicy: sanctionPolicy,
            clientSanctionDateFin: sanctionDateFin,
            clientDueDiligence: dueDiligence,
            clientDueDiligenceDateFin: dueDiligenceDateFin,
            clientTiersBloque: tiersBloque,
            clientContactEmail: "",
            clientContactFirstName: "",
            clientContactLastName: "",
            clientContactCivility: "",
            administratorEmail: "",
            administratorFirstName: "",
            administratorLastName: "",
            administratorCivility: "",
            administratorPivotId: ""
        }));
        setExistingClientModal(false);
        setFormValidity({ ...formValidity, clientSelectedValid: true });
        setSelectedTiers(existingClient);
        updateModal(false);
    };

    const handleClientChange = async (e) => {
        const newInfo = {
            ...clientMeta,
            [e.target.name]: e.target.value
        };
        let existSiretTemp = existingSiret;

        if (e.target.name === "raisonClient") {
            if (e.target.value.length <= 32) {
                setClientMeta({
                    ...newInfo,
                    [e.target.name]: e.target.value.toUpperCase()
                });
            }
        } else if (e.target.name === "siretClient") {
            if (existingSiret) setExistingSiret(false);
            if (e.target.value.length <= 14) {
                setClientMeta({
                    ...newInfo,
                    [e.target.name]: e.target.value
                });
                if (e.target.value.length === 14 && fields.siretClient.validator(e.target.value)) {
                    setInvalidSiret(false);
                    const response = await getTiersBySiret(e.target.value);
                    if (Object.keys(response.data).length > 0 && response.data.constructor === Object) {
                        existSiretTemp = true;
                        setExistingSiret(existSiretTemp);
                        setExistingClient(response.data);
                    }
                } else {
                    setInvalidSiret(true);
                }
            }
        } else if (e.target.name === "typeClient") {
            if (e.target.value === "279640000") {
                setRequiredSiret(true);
            } else {
                setRequiredSiret(false);
                setClientMeta({
                    ...newInfo,
                    siretClient: null
                });
                if (existingSiret) setExistingSiret(false);
            }
            setClientMeta({
                ...newInfo,
                [e.target.name]: e.target.value
            });
        } else if (e.target.name === "principalAdditionalAddress1" || e.target.name === "principalAdditionalAddress2" || e.target.name === "principalAddress") {
            if (e.target.value.length <= 38) {
                setClientMeta({
                    ...newInfo,
                    [e.target.name]: e.target.value
                });
            }
        } else if (e.target.name === "interlocutorFirstName") {
            if (e.target.value.length <= 1 || nameRegex.test(e.target.value)) {
                setInterlocutorFirstNameError(false);
            } else {
                setInterlocutorFirstNameError(true);
            }

            setClientMeta({
                ...newInfo,
                [e.target.name]: e.target.value.toUpperCase()
            });
        } else if (e.target.name === "interlocutorLastName") {
            if (e.target.value.length <= 1 || nameRegex.test(e.target.value)) {
                setInterlocutorNameError(false);
            } else {
                setInterlocutorNameError(true);
            }
            setClientMeta({
                ...newInfo,
                [e.target.name]: e.target.value.toUpperCase()
            });
        } else if (e.target.name === "invoiceContactFirstName") {
            if (e.target.value.length <= 1 || nameRegex.test(e.target.value)) {
                setInvoiceFirstNameError(false);
            } else {
                setInvoiceFirstNameError(true);
            }
            setClientMeta({
                ...newInfo,
                [e.target.name]: e.target.value.toUpperCase()
            });
        } else if (e.target.name === "invoiceContactLastName") {
            if (e.target.value.length <= 1 || nameRegex.test(e.target.value)) {
                setInvoiceNameError(false);
            } else {
                setInvoiceNameError(true);
            }
            setClientMeta({
                ...newInfo,
                [e.target.name]: e.target.value.toUpperCase()
            });
        } else if (e.target.name === "invoiceContactEmail") {
            if (e.target.value.length === 0 || emailRegex.test(e.target.value)) {
                setInvoiceContactEmailError(false);
            } else {
                setInvoiceContactEmailError(true);
            }
            setClientMeta({
                ...newInfo,
                [e.target.name]: e.target.value
            });
        } else if (e.target.name === "invoiceContactPhone") {
            if (e.target.value.length === 0 || phoneNumberRegex.test(e.target.value)) {
                setInvoiceContactPhoneError(false);
            } else {
                setInvoiceContactPhoneError(true);
            }
            setClientMeta({
                ...newInfo,
                [e.target.name]: e.target.value
            });
        } else if (e.target.name === "interlocutorPhone") {
            if (e.target.value.length === 0 || phoneNumberRegex.test(e.target.value)) {
                setInterlocutorPhoneError(false);
            } else {
                setInterlocutorPhoneError(true);
            }
            setClientMeta({
                ...newInfo,
                [e.target.name]: e.target.value
            });
        } else if (e.target.name === "interlocutorEmail") {
            if (e.target.value.length === 0 || emailRegex.test(e.target.value)) {
                setInterlocutorEmailError(false);
            } else {
                setInterlocutorEmailError(true);
            }
            setClientMeta({
                ...newInfo,
                [e.target.name]: e.target.value
            });
        } else {
            setClientMeta({
                ...newInfo,
                [e.target.name]: e.target.value
            });
        }

        const formClientNotValid = Object.entries(newInfo)
            .map(([key, value]) => fields[key].validator(value))
            .includes(false);
        enableSubmit(!formClientNotValid);
    };

    const handleContactCheckboxChange = () => {
        if (!duplicatePrincipalContact === true) {
            setClientMeta({
                ...clientMeta,
                duplicatePrincipalContact: true
            });

            const {
                invoiceContactCivility,
                invoiceContactFirstName,
                invoiceContactLastName,
                invoiceContactEmail,
                invoiceContactPhone,
                ...newClientMeta
            } = clientMeta;

            const formClientNotValid = Object.entries(newClientMeta)
                .map(([key, value]) => fields[key].validator(value))
                .includes(false);
            enableSubmit(!formClientNotValid);
        } else {
            setClientMeta({
                ...clientMeta,
                duplicatePrincipalContact: false,
                invoiceContactCivility: "",
                invoiceContactFirstName: "",
                invoiceContactLastName: "",
                invoiceContactEmail: "",
                invoiceContactPhone: ""
            });
            enableSubmit(false);
        }
        setDuplicatePrincipalContact(!duplicatePrincipalContact);
    };

    const SubstitutionPanelVI = () => {
        const offers = substitutionsInfos && substitutionsInfos.length > 0 && cartItems ? cartItems.filter(({ type }) => type === "principal") : [];
        return (
            <>
                <div className="preview__section">
                    <h2 className="preview__section-title">{t("proposals:select-subtitution.title")}</h2>
                    <div className="preview__Substitution_card">
                        <div className="preview_Substitution-description">{t("proposals:select-subtitution.offerslist")}</div>
                        {offers.map((pp) => {
                            const { product: referenceproduit, label: productCommercialName, usageCode } = pp;
                            const productfiltered = substitutionsInfos.filter((e) => e.offerProduct === referenceproduit);
                            if (productfiltered && productfiltered.length > 0) {
                                return (
                                    <div>
                                        <p className="preview__card-title">{productCommercialName}</p>
                                        <ul>
                                            {productfiltered.map((p) => {
                                                const { label, parcPivotId } = p;
                                                return (
                                                    <li className="substitution__list" key={`li_${parcPivotId}`}>
                                                        {label}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                        {salesDisplay && (
                                            <div className="preview_Substitution-description">
                                                <p>
                                                    {t(
                                                        `proposals:select-subtitution.${
                                                            referenceproduit === "CSPSA0001" && usageCode === "US_EQUIPCLI" ? "prorataPaieVD" : "prorataVD"
                                                        }`,
                                                        { newParcCommercialName: productCommercialName }
                                                    )}
                                                </p>
                                            </div>
                                        )}
                                        {!salesDisplay && (
                                            <div className="preview_Substitution-description">
                                                <p>
                                                    {t(
                                                        `proposals:select-subtitution.${
                                                            referenceproduit === "CSPSA0001" && usageCode === "US_EQUIPCLI" ? "confirmPaie" : "confirmMessage"
                                                        }`,
                                                        { newParcCommercialName: productCommercialName }
                                                    )}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                );
                            }
                            return <div />;
                        })}
                    </div>
                </div>
            </>
        );
    };
    const sectionForm = (objectFields, title) => {
        if (subscription) {
            return (
                <>
                    <div className="section__header">{title}</div>
                    <div className="section__content">
                        <div className="new-client-form__item">
                            <div className="new-client-form__label">{t("proposals:select-fin.reference")}</div>
                            <FormElements.Input name="reference" value={subscription.reference} disabled />
                        </div>

                        {proposal && (
                            <div className="new-client-form__item">
                                <div className="new-client-form__label">{t("proposals:select-fin.clientReference")}</div>
                                <FormElements.Input name="clientReference" value={subscription.clientReference} disabled />
                            </div>
                        )}

                        <div className="new-client-form__item">
                            <div className="new-client-form__label">{t("clients:client-information.raisonClient")}</div>
                            <FormElements.Input name="raisonClient" value={clientMeta.raisonClient} disabled />
                        </div>

                        <div className="new-client-form__item">
                            <div className="new-client-form__label">{t("clients:client-information.siretClient")}</div>
                            <FormElements.Input name="siretClient" value={clientMeta.siretClient} disabled />
                        </div>

                        <div className="new-client-form__item">
                            <div className="new-client-form__label">{t("clients:client-information.principalAddressZipCode")}</div>
                            <FormElements.Input name="principalAddressZipCode" value={clientMeta.principalAddressZipCode} disabled />
                        </div>

                        <div className="new-client-form__item">
                            <div className="new-client-form__label">{t("clients:client-information.principalAddressVille")}</div>
                            <FormElements.Input name="principalAddressVille" value={clientMeta.principalAddressVille} disabled />
                        </div>
                        {user.role === rolesJson.sales.role && (
                            <div className="new-client-form__item">
                                <div className="new-client-form__label">{t("proposals:select-fin.codeCamp")}</div>
                                <FormElements.Input name="codeCamp" value={subscription.codeCamp} disabled />
                            </div>
                        )}
                        {(user.role === rolesJson.sales.role || user.role === rolesJson.partner.role) && (
                            <>
                                <div className="new-client-form__item">
                                    <div className="new-client-form__label">{t("proposals:select-fin.internal")}</div>
                                    <FormElements.Textarea name="internalMonitoring" value={subscription.internalMonitoring} disabled />
                                </div>
                                <div className="new-client-form__item">
                                    <div className="new-client-form__label">{t("proposals:select-fin.reminder")}</div>
                                    <FormElements.Input name="reminderDate" value={subscription.reminderDate} disabled />
                                </div>
                                <div className="new-client-form__item">
                                    <div className="new-client-form__label">{t("proposals:select-fin.response")}</div>
                                    <FormElements.Input name="responseDate" value={subscription.responseDate} disabled />
                                </div>
                            </>
                        )}
                    </div>
                </>
            );
        }

        return (
            <>
                <div className="section__header">{title}</div>
                <div className="section__content">
                    {objectFields
                        .filter((key) => fields[key].display)
                        .map((key) => (
                            <div key={`new-client_${key}`} className="new-client-form__item">
                                <div className="new-client-form__label">
                                    {`${t(`clients:client-information.${key}`)} ${fields[key].notRequired || disabled ? "" : "*"}`}
                                </div>
                                {(fields[key].type === "text" || fields[key].type === "number") && (
                                    <FormElements.Input
                                        type={fields[key].type}
                                        name={key}
                                        value={clientMeta[key]}
                                        placeholder={!disabled ? t(`clients:client-information.${key}-placeholder`) : ""}
                                        onChange={handleClientChange}
                                        validator={fields[key].validator}
                                        disabled={disabled}
                                    />
                                )}

                                {key === "siretClient" && existingSiret && (
                                    <>
                                        <div className="new-client__message">{t("clients:client-information.siret-message")}</div>
                                        <FormElements.Button
                                            className="new-client-form__button button step-navigation__next medium primary"
                                            onClick={() => setExistingClientModal(true)}
                                            disabled={disabled}>
                                            {t("clients:client-information.information-client")}
                                        </FormElements.Button>
                                    </>
                                )}

                                {key === "siretClient" && invalidSiret && (
                                    <div className="new-client__error">{`${t("clients:client-information.siret-error")}`}</div>
                                )}

                                {fields[key].type === "select" && (
                                    <FormElements.Select
                                        name={key}
                                        onChange={handleClientChange}
                                        options={fields[key].options}
                                        label={!disabled ? t(`clients:client-information.${key}-placeholder`) : ""}
                                        value={clientMeta[key]}
                                        disabled={disabled}
                                    />
                                )}
                            </div>
                        ))}
                </div>
            </>
        );
    };

    return (
        <div role="tabpanel" id="info-tab" aria-labelledby="info" tabIndex="0">
            <section className="new-client__section">{sectionForm(clientFields, t("clients:client-information.information"))}</section>
            {!subscription && <section className="new-client__section">{sectionForm(addressFields, t("clients:client-information.titleAddress"))}</section>}
            {profileAdmin && (
                <section className="new-client__section">
                    <div className="section__header">{t("clients:client-information.administrator")}</div>
                    <Contact
                        contactMeta={profileAdmin}
                        contactFields={adminFields}
                        fieldsRules={adminFieldsRules}
                        i18nPrefix="clients:client-information"
                        showRequired={false}
                        disabled
                    />
                </section>
            )}
            {!clientMeta.emptyPrincipalContact && !subscription && (
                <section className="new-client__section">
                    <div className="section__header">{t("clients:client-information.interlocutor")}</div>
                    <Contact
                        contactMeta={clientMeta}
                        contactFields={interlocutorFields}
                        fieldsRules={fields}
                        handleChange={handleClientChange}
                        i18nPrefix="proposals:select-fin.interlocutor"
                        showRequired={false}
                        disabled={disabled}
                    />

                    <div className="new-client-form__item">
                        <label className="search-menu__checkbox carbon-form__checkbox">
                            <input
                                type="checkbox"
                                name="code-checkbox"
                                checked={duplicatePrincipalContact}
                                onChange={handleContactCheckboxChange}
                                disabled={disabled}
                            />
                            <div className="carbon-form__checkbox-icon" />
                            <span>{`${!clientPivotId ? t("clients:client-information.duplicate") : t("clients:detail-client.duplicate")}`}</span>
                        </label>
                    </div>
                </section>
            )}

            {!duplicatePrincipalContact && !clientMeta.emptyFacturationContact && !subscription && (
                <section className="new-client__section">
                    <div className="section__header">{t("clients:client-information.contact-facturation")}</div>
                    <Contact
                        contactMeta={clientMeta}
                        contactFields={invoiceContactFields}
                        fieldsRules={fields}
                        handleChange={handleClientChange}
                        i18nPrefix="proposals:select-fin.interlocutor"
                        showRequired={false}
                        disabled={disabled}
                    />
                </section>
            )}

            {proposal && (
                <>
                    <div className="new-client-form__item">
                        <div className="new-client-form__label">{t("proposals:select-fin.admin-contact")}</div>
                        <FormElements.Input value={subscription.administratorEmail} name="administratorEmail" disabled />
                    </div>

                    <div className="new-client-form__item">
                        <div className="new-client-form__label">{t("proposals:select-fin.client-contact")}</div>
                        <FormElements.Input value={subscription.clientContactEmail} name="interlocutorPrincipal" disabled />
                    </div>

                    <div className="new-client-form__item">
                        <div className="new-client-form__label">{t("proposals:select-fin.commercial-contact")}</div>
                        <FormElements.Input name="commercialEmail" value={subscription.commercialEmail} disabled />
                    </div>

                    <div className="new-client-form__item">
                        <div className="new-client-form__label">
                            {t("proposals:select-fin.comment")}
                            <FormElements.Textarea name="cartComment" value={subscription.cartComment} rows={4} maxLength="256" disabled />
                        </div>
                    </div>
                    {substitutionsInfos && substitutionsInfos.length > 0 && SubstitutionPanelVI()}
                    <div className="new-client-form__item">
                        <div className="preview__card">
                            <Icon name="ui-calendar" className="preview__card-icon" />
                            <div>
                                <div className="preview__card-title">{t("proposals:sepa-recap.information.engagement.title")}</div>
                                <div className="preview__card-description">{t("proposals:sepa-recap.information.engagement.monthly")}</div>
                            </div>
                        </div>

                        <div className="preview__card">
                            <Icon name="ui-bank" className="preview__card-icon" />
                            <div>
                                <div className="preview__card-title">{t("proposals:sepa-recap.information.payment.title")}</div>
                                {user.role === rolesJson.partner.role || (subscriptionInfos.partnerCode && subscriptionInfos.partnerCode.length > 0) ? (
                                    <>
                                        <div className="preview__card-description">{t("proposals:sepa-recap.information.payment.lcr")}</div>
                                        <div className="preview__card-description">{t("proposals:sepa-recap.information.payment.withdrawal")}</div>
                                        <div className="preview__card-description">{t("proposals:sepa-recap.information.payment.mandate")}</div>
                                    </>
                                ) : (
                                    <>
                                        <div className="preview__card-description">{t("proposals:sepa-recap.information.payment.withdrawal")}</div>
                                        <div className="preview__card-description">{t("proposals:sepa-recap.information.payment.mandate")}</div>
                                    </>
                                )}
                            </div>
                        </div>
                        {appliedPromo && (
                            <div className="preview__card">
                                <Icon name="ui-document" className="preview__card-icon" />
                                <div>
                                    <div className="preview__card-title">{t("proposals:select-fin.commercial-remise")}</div>
                                    <div className="preview__card-description">{`${opecomlibelle}`}</div>
                                    <div className="preview__card-description">{`${opecomDescription}`}</div>
                                </div>
                            </div>
                        )}
                        <div className="preview__card">
                            <Icon name="ui-document-commercial" className="preview__card-icon" />
                            <div>
                                <div className="preview__card-title">{t("proposals:sepa-recap.information.invoice.title")}</div>
                                <div className="preview__card-description">{t("proposals:sepa-recap.information.invoice.partner-description")}</div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {subscription && !proposal && (
                <>
                    <div className="new-client-form__item">
                        <div className="new-client-form__label">{t("proposals:select-fin.admin-contact")}</div>
                        <FormElements.Input value={subscription.administratorEmailProposal} name="administratorEmail" disabled />
                    </div>

                    <div className="new-client-form__item">
                        <div className="new-client-form__label">{t("proposals:select-fin.client-contact")}</div>
                        <FormElements.Input value={subscription.clientContactEmailProposal} name="interlocutorPrincipal" disabled />
                    </div>

                    <div className="new-client-form__item">
                        <div className="new-client-form__label">{t("proposals:select-fin.commercial-contact")}</div>
                        <FormElements.Input name="commercialEmail" value={subscription.commercialEmail} disabled />
                    </div>

                    <div className="new-client-form__item">
                        <div className="preview__card">
                            <Icon name="ui-calendar" className="preview__card-icon" />
                            <div>
                                <div className="preview__card-title">{t("proposals:sepa-recap.information.engagement.title")}</div>
                                <div className="preview__card-description">{t("proposals:sepa-recap.information.engagement.monthly")}</div>
                            </div>
                        </div>

                        <div className="preview__card">
                            <Icon name="ui-bank" className="preview__card-icon" />
                            <div>
                                <div className="preview__card-title">{t("proposals:sepa-recap.information.payment.title")}</div>
                                {user.role === rolesJson.partner.role || (subscriptionInfos.partnerCode && subscriptionInfos.partnerCode.length > 0) ? (
                                    <>
                                        <div className="preview__card-description">{t("proposals:sepa-recap.information.payment.lcr")}</div>
                                        <div className="preview__card-description">{t("proposals:sepa-recap.information.payment.withdrawal")}</div>
                                        <div className="preview__card-description">{t("proposals:sepa-recap.information.payment.mandate")}</div>
                                    </>
                                ) : (
                                    <>
                                        <div className="preview__card-description">{t("proposals:sepa-recap.information.payment.withdrawal")}</div>
                                        <div className="preview__card-description">{t("proposals:sepa-recap.information.payment.mandate")}</div>
                                    </>
                                )}
                            </div>
                        </div>
                        {appliedPromo && (
                            <div className="preview__card">
                                <Icon name="ui-document" className="preview__card-icon" />
                                <div>
                                    <div className="preview__card-title">{t("proposals:select-fin.commercial-remise")}</div>
                                    <div className="preview__card-description">{`${opecomlibelle}`}</div>
                                    <div className="preview__card-description">{`${opecomDescription}`}</div>
                                </div>
                            </div>
                        )}
                        <div className="preview__card">
                            <Icon name="ui-document-commercial" className="preview__card-icon" />
                            <div>
                                <div className="preview__card-title">{t("proposals:sepa-recap.information.invoice.title")}</div>
                                <div className="preview__card-description">{t("proposals:sepa-recap.information.invoice.partner-description")}</div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {!disabled && <div className="contact-form__mandatory">{t("forms.mandatory")}</div>}

            <ModalAlert
                className="new-client__contact"
                visible={existingClientModal}
                onClickButton={() => chooseClient()}
                onCancel={() => {
                    setExistingClientModal(false);
                }}
                title={t("clients:client-information.titleClient")}
                cancelButtonText={t("navigation.cancel")}
                acceptButtonText={t("navigation.choose")}>
                <div className="new-client-form__item">
                    <div className="new-client-form__label">{t("clients:client-information.siretClient")}</div>
                    <FormElements.Input name="siret" value={existingClient && existingClient.tiersSiret} disabled />
                </div>

                <div className="new-client-form__item">
                    <div className="new-client-form__label">{t("clients:client-information.raisonClient")}</div>
                    <FormElements.Input name="raison" value={existingClient && existingClient.tiersName} disabled />
                </div>

                <div className="new-client-form__item">
                    <div className="new-client-form__label">{t("clients:client-information.phoneClient")}</div>
                    <FormElements.Input name="phone" value={existingClient && existingClient.tiersTelephone} disabled />
                </div>

                <div className="new-client-form__item">
                    <div className="new-client-form__label">{t("clients:client-information.emailClient")}</div>
                    <FormElements.Input name="email" value={existingClient && existingClient.tiersEmail} disabled />
                </div>
            </ModalAlert>
        </div>
    );
};

export default NewClientInformation;
