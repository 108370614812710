import i18next from "../../config/i18next";
import i18nProposals from "./i18n/fr.json";
import NewProposalPage from "./pages/new-proposal-page/new-proposal-page";
import ListProposalsPage from "./pages/list-proposals-page/list-proposals-page";
import NewSubscirptionPage from "../subscription/pages/new-subscription-page/new-subscription-page";

i18next.addResourceBundle("fr", "proposals", i18nProposals);

const Offers = { NewProposalPage, ListProposalsPage, NewSubscirptionPage };

export default Offers;
