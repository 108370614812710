import React, { Component } from "react";
import { ErrorPage } from "../../../domains/error";

// ErrorBoundary is not a hooks component because error boundary feature is only available as class component.
// TODO: Refactor in hooks when error boundary feature is available.

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
        this.t = props.t;
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorPage />;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
