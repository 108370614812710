/**
 * Get ranks object from price array
 * @param {*} priceArray The given price array
 * @param {*} quantity The quantity used to select the right ranks
 * @returns The corresponding ranks { rank1: number, rank2: number }
 */
const getMatchingRanks = (priceArray, quantity) => {
    if (!priceArray && priceArray.length <= 0) return { rank1: 0, rank2: 0 };

    const priceItem = priceArray.find((p) => {
        const { blockOperator, blockStart, blockEnd } = p;
        // The last condition to apply fix price when blockOperator, blockStart, block are null
        return (
            (blockOperator === "=" && quantity === blockStart) ||
            (blockOperator === "<=" && quantity <= blockStart) ||
            (blockOperator === ">=" && quantity >= blockStart) ||
            (blockOperator === "between" && quantity >= blockStart && quantity <= blockEnd) ||
            ((blockOperator === "" || !blockOperator) && !blockStart && !blockEnd)
        );
    });

    if (!priceItem) return { rank1: 0, rank2: 0 };

    return priceItem;
};

/**
 * Get the price from rank ( apply ax+b )
 * @param {*} ranks Given ranks
 * @param {*} quantity Given quantity
 * @returns The computed price
 */
const getPriceFromRanksAndQuantity = (ranks, quantity) => {
    const { rank1, rank2 } = ranks;
    return rank1 * quantity + rank2;
};

/**
 * Get the price of a product following the progressivity principle.
 * @param {*} pricing The pricing of the product
 * @param {*} quantity The quantity of the product
 * @param {*} refProduct The referenced product
 * @returns The computed price
 */
const getProgressiveProductPrice = (pricing, quantity, refProduct) => {
    // Get the ranks depending on ref properties of pricing and referenced product quantity
    const newRefRanks = getMatchingRanks(
        pricing.map((currentPrice) => ({
            rank1: currentPrice.rank1,
            rank2: currentPrice.rank2,
            blockOperator: currentPrice.linkedRefOperator,
            blockStart: currentPrice.linkedRefBlockStart,
            blockEnd: currentPrice.linkedRefBlockEnd
        })),
        refProduct.quantity
    );
    return getPriceFromRanksAndQuantity(newRefRanks, quantity);
};

/**
 * Get the price of a product following the degressivity principle.
 * @param {*} pricing The pricing of the product
 * @param {*} quantity The quantity of the product
 * @returns The computed price
 */
const getDegressiveProductPrice = (pricing, quantity) => {
    const ranks = getMatchingRanks(pricing, quantity);
    return getPriceFromRanksAndQuantity(ranks, quantity);
};

/**
 * Get the price of a product
 * With an optionnal refProduct (degressivity or progressivity)
 * @param {*} pricing The pricing of the product
 * @param {*} quantity The quantity of the product
 * @param {*} refProduct The referenced product
 * @returns The computed price
 */
const getProductPrice = (pricing, quantity, refProduct) => {
    // First calculate the price using degressivity
    let calculatePrice = getDegressiveProductPrice(pricing, quantity);

    // If there is a referenced product, then calculate price with progressivity
    if (refProduct) {
        calculatePrice = getProgressiveProductPrice(pricing, quantity, refProduct);
    }

    return calculatePrice;
};

/**
 * Get the price of a product
 * With cart in order to find referenced product if there is one (degressivity or progressivity)
 * @param {*} pricing The pricing of the product
 * @param {*} quantity The quantity of the product
 * @param {*} cartItems The cart
 * @returns The computed price
 */
const getProductPriceFromCartItems = (pricing, quantity, cartItems) => {
    // Check in the pricing if there is a linkedRef
    const linkedRef = pricing.find((pri) => pri.linkedRef !== undefined);
    // If a linkedRef is found, then get the corresponding product in the cart
    const refProduct = linkedRef ? cartItems.find((item) => item.product === linkedRef.linkedRef) : undefined;
    return getProductPrice(pricing, quantity, refProduct);
};

const parseAmount = (amount) => {
    if (!amount) return 0;
    const parsed = parseFloat(parseFloat(amount).toFixed(4));
    return parseFloat(parsed.toFixed(2));
};

export { parseAmount, getProductPrice, getProductPriceFromCartItems };
