import { portalAPI, proxyPivotAPI } from "../../../config";

const { REACT_APP_FAILOVER } = process.env;

const getPortalUserRole = () => {
    return portalAPI.get("/user/me/role");
};

const checkUserAuth = () => {
    return portalAPI.get("/user/me");
};

const getAttachmentList = () => {
    return proxyPivotAPI.get("/interlocutor/me/tiers", { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const setAttachment = (attachment) => {
    return proxyPivotAPI.post("/interlocutor/attach", attachment, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};
const getIdShadow = () => {
    return proxyPivotAPI.get("/tiers/shadow");
};

const checkExistAdmin = (administratorEmail, codeTiers, tokenShadow) => {
    return proxyPivotAPI.get(`/tiers/checkadmin/${administratorEmail}`, { params: { tiersCode: codeTiers, tokenShadow } });
};

const getInterlocutors = (tiersPivotId, query) => {
    return proxyPivotAPI.get(`/interlocutor/${tiersPivotId}`, { params: { ...query }, headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const getInterlocutor = async (tiersPivotId) => {
    return proxyPivotAPI.get(`/interlocutor/${tiersPivotId}`, { params: { single: true }, headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const addInterlocutorsToTiersPivotId = (pivotId, interlocutor) => {
    return proxyPivotAPI.post(`/interlocutor/${pivotId}`, interlocutor);
};

const getUserPermissions = () => {
    return portalAPI.get("/user/check/me");
};

const checkPartnerRolesPivotId = (pivotId) => {
    return proxyPivotAPI.get(`/tiers/partner/${pivotId}`);
};
const checkExistUsageFree = (usaceCode, product) => {
    return proxyPivotAPI.get(`/tiers/check/${usaceCode}`, { params: { productP: product }, headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const reportAuthentification = (listTiers) => {
    return portalAPI.post("/user/auth/report", { listTiers, failover: REACT_APP_FAILOVER });
};

const getActuMessage = () => {
    return portalAPI.get("/user/actu");
};
const getInvoicePopUp = () => {
    return portalAPI.get("/user/runBilling");
};
const getLatestNews = () => {
    return portalAPI.get("/user/latestupdates");
};
const getFAQ = () => {
    return portalAPI.get("/user/faq");
};
const getFAQHead = () => {
    return portalAPI.get("/user/faqhead");
};
const getSuspensionMsg = () => {
    return portalAPI.get("/user/suspension");
};

const getAllPromoSBC = () => {
    return portalAPI.get(`/promo/All`, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};
const deletePromo = (codePromo) => {
    return portalAPI.put(`/promo/${codePromo}`, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};
const updatePromo = (codePromo, promoToEdit) => {
    return portalAPI.put(`/promo/update/${codePromo}`, promoToEdit, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};
const createNewPromo = (newPromo) => {
    return portalAPI.post(
        `/promo/create`, { newPromo},{ headers: { "X-BACKEND": REACT_APP_FAILOVER } }
    );
};
const createRemise = (newRemise) => {
    return portalAPI.post(
        `/promo/ca/create`, { newRemise},{ headers: { "X-BACKEND": REACT_APP_FAILOVER } }
    );
};
const updateRemise = (idRemise, remiseToEdit) => {
    return portalAPI.put(`/promo/ca/update/${idRemise}`, remiseToEdit, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};
const deleteRemise = (idRemise) => {
    return portalAPI.put(`/promo/ca/delete/${idRemise}`, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};
const getAllRemiseSBC = () => {
    return portalAPI.get(`/promo/ca/All`, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const getDetailsRemise = (cartItems,pivotId, userRole) => {
    return portalAPI.post(
        `/promo/detailRemise`, { cartItems, pivotId, userRole},{ headers: { "X-BACKEND": REACT_APP_FAILOVER } }
    );
};

export {
    getPortalUserRole,
    getAttachmentList,
    getIdShadow,
    checkExistAdmin,
    setAttachment,
    checkUserAuth,
    getUserPermissions,
    getInterlocutors,
    addInterlocutorsToTiersPivotId,
    getInterlocutor,
    checkPartnerRolesPivotId,
    checkExistUsageFree,
    reportAuthentification,
    getActuMessage,
    getInvoicePopUp,
    getLatestNews,
    getFAQ,
    getFAQHead,
    getSuspensionMsg,
    getAllPromoSBC,
    deletePromo,
    updatePromo,
    createNewPromo,
    createRemise,
    updateRemise,
    deleteRemise,
    getAllRemiseSBC,
    getDetailsRemise
};
