import React, { useState, useCallback, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { v4 } from "uuid";
import { Link } from "react-router-dom";
import Icon from "../../components/icon-svg/icon";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import { getFAQ, getFAQHead } from "../../../domains/users/service/user.services";
import "./faq.scss";

const FaqPage = () => {
    const { t } = useTranslation();

    const [collapseState, setCollapseState] = useState([]);
    const [faqSection, setFaqSection] = useState([]);
    const [faqHeader, setFaqHeader] = useState([]);
    const collapse = (sectionIndex, questionIndex) => {
        const newState = collapseState.map((section, i) =>
            i === sectionIndex ? section.map((question, j) => (j === questionIndex ? !question : question)) : section
        );
        setCollapseState(newState);
    };

    const faqFunct = useCallback(() => {
        getFAQ().then((res) => {
            if (res && res.data) {
                setFaqSection(res.data);
                setCollapseState(res.data.map(({ items }) => items.map(() => false)));
            }
        });
    }, []);

    const faqHead = useCallback(() => {
        getFAQHead().then((res) => {
            if (res && res.data) {
                setFaqHeader(res.data);
            }
        });
    }, []);
    useEffect(() => {
        faqFunct();
        faqHead();
    }, [faqFunct, faqHead]);

    return (
        <PageWrapper className="faq-wrapper" fullPage>
            {/* Mobile header */}
            <header className="full-page-header">
                <Link to={{ pathname: "/" }} className="full-page-header__button">
                    <Icon name="dialog-arrow-left" />
                </Link>

                <h1 className="full-page-header__title">{t("faq-page.title")}</h1>
            </header>

            {/* Desktop header */}
            {faqHeader.map(({ title, description }) => {
                return (
                    <>
                        <header>
                            <h1 className="heading">{title}</h1>
                        </header>

                        <div className="description">
                            <p>{description}</p>
                        </div>
                    </>
                );
            })}
            <div className="faq">
                {faqSection.map((section, sectionIndex) => {
                    return (
                        <React.Fragment key={v4()}>
                            <h2 className="faq__section-title">{section.group}</h2>
                            <dl>
                                {section.items.map(({ question, response }, questionIndex) => {
                                    return (
                                        <div
                                            className={`faq__item ${
                                                collapseState.length > 0 && collapseState[sectionIndex][questionIndex] ? "is-open" : "is-closed"
                                            }`}
                                            key={v4()}>
                                            <dt>
                                                <button
                                                    className="faq__question"
                                                    type="button"
                                                    aria-expanded={collapseState.length > 0 && collapseState[sectionIndex][questionIndex]}
                                                    aria-controls={`faq__answer-${sectionIndex}-${questionIndex}`}
                                                    onClick={() => collapse(sectionIndex, questionIndex)}>
                                                    <Icon name="dialog-carret-right" />
                                                    {question}
                                                </button>
                                            </dt>
                                            <dd
                                                id={`faq__answer-${sectionIndex}-${questionIndex}`}
                                                className={`faq__answer ${
                                                    collapseState.length > 0 && collapseState[sectionIndex][questionIndex] ? "is-visible" : "is-hidden"
                                                }`}>
                                                <Trans parent="div">{response}</Trans>
                                            </dd>
                                        </div>
                                    );
                                })}
                            </dl>
                        </React.Fragment>
                    );
                })}
            </div>
        </PageWrapper>
    );
};

export default FaqPage;
