import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getAttachmentList } from "../../service/user.services";
import ListTable from "../../../../shared/components/lists/list-table/list-table";
import Loading from "../../../../shared/components/loading/loading";
import Interlocutor from "../../../proposals/components/interlocutor/interlocutor";
import "./attachment-list.scss";
import { ModalAlert, FormElements } from "../../../../shared";
import { attachInterlocutor } from "../../../clients/services/client.services";
import CodeTiersFormAuth from "../codeTiers-auth/codeTiers-auth";

const AttachmentList = (props) => {
    const { t } = useTranslation();
    const { attachmentFail, setAttachmentFail, onSubmitAttachment, userEmail, setEnableButton } = props;
    const {
        persona,
        tiersDiligenceFail,
        tiersSanctionFail,
        onTiersBlocked,
        uniqueTiers,
        setUniqueTiers,
        setEnableCodeAuth,
        enableCodeAuth,
        onFailAttachment
    } = props;
    const [list, setList] = useState(undefined);

    const [selected, setSelected] = useState();
    const [newInterlocutor, setNewInterlocutor] = useState({});
    const [visibleModalErrorMessage, setVisibleModalErrorMessage] = useState(false);
    const [infoMessage, setInfoMessage] = useState("");
    const [siret, setSiret] = useState("");
    const [codeTiers, setCodeTiers] = useState("");
    const [tiersName, setTiersName] = useState("");
    const [selectedPartnerCode, setSelectedPartnerCode] = useState("");
    const [visiblePartnerErrorMessage, setVisiblePartnerErrorMessage] = useState(false);

    useEffect(() => {
        let isSubscribed = true;

        if (!tiersDiligenceFail && !attachmentFail && !tiersSanctionFail) {
            getAttachmentList()
                .then((response) => {
                    if (isSubscribed) {
                        const tiersList = response.data;
                        if (tiersList.length > 1) {
                            if (uniqueTiers) setUniqueTiers(false);

                            const tiersParents = tiersList.filter(({ tiersParentPivotId }) => !tiersParentPivotId);

                            if (tiersParents && tiersParents.length > 0) {
                                if (tiersParents.length === 1) {
                                    const { pivotId: parentid } = tiersParents[0];
                                    const nochildTiers = tiersList.filter(({ tiersParentPivotId }) => tiersParentPivotId && tiersParentPivotId !== parentid);

                                    if (nochildTiers && nochildTiers.length > 0) {
                                        setEnableCodeAuth(true);
                                    }
                                } else {
                                    setEnableCodeAuth(true);
                                }
                            }
                            setList(tiersList);
                        } else if (tiersList.length === 1) {
                            onSubmitAttachment(tiersList[0]);
                            setUniqueTiers(true);
                        } else {
                            setAttachmentFail(true);
                        }
                    }
                })
                .catch(() => {
                    setAttachmentFail(true);
                });
        }
        return () => {
            isSubscribed = false;
        };
    }, [persona, setAttachmentFail, onSubmitAttachment, tiersDiligenceFail, attachmentFail, tiersSanctionFail, uniqueTiers, setUniqueTiers, setEnableCodeAuth]);

    const userActions = [{ name: "tiers", type: "radio" }];

    const columns = [
        { name: "code", type: "text", dbRef: "tiersCode", label: t("clients:list-clients-page.code") },
        { name: "name", type: "text", dbRef: "tiersName", label: t("clients:list-clients-page.name") },
        { name: "siret", type: "text", dbRef: "tiersSiret", label: t("clients:list-clients-page.siret") },
        { name: "zipcode", type: "text", dbRef: "zipCode", label: t("clients:list-clients-page.zipCode") },
        { name: "city", type: "text", dbRef: "city", label: t("clients:list-clients-page.city") }
    ];

    const handleAttachInterlocutor = async (e) => {
        e.preventDefault();
        const { siretClient, partnerCode } = newInterlocutor;
        setEnableButton(false);
        attachInterlocutor(newInterlocutor)
            .then((resp) => {
                const { tiersName: tierslibelle } = resp.data;
                setSelected(resp.data);
                setSiret(siretClient);
                setCodeTiers(partnerCode);
                setTiersName(tierslibelle);
                setInfoMessage(t("attachmentSuccess"));
                setVisibleModalErrorMessage(true);
            })
            .catch((err) => {
                const { apiCode } = err.response.data;
                if (err.response && err.response.data) {
                    if (apiCode === "codeTiersNotFound") {
                        setInfoMessage("codeNotFound");
                    } else if (apiCode === "invalidSiretAndTiers") {
                        setInfoMessage("invalidTiersSiret");
                    } else {
                        setInfoMessage("genericErrorMsg");
                    }
                    setVisibleModalErrorMessage(true);
                }
            });
    };
    const handleAccept = (e) => {
        e.preventDefault();
        setVisibleModalErrorMessage(false);
        setEnableButton(true);
        if (tiersName) {
            onSubmitAttachment(selected);
        }
    };
    const handleCodeBasedAuth = (e) => {
        e.preventDefault();
        setEnableButton(false);
        const exists = list.filter(({ tiersCode }) => tiersCode && tiersCode === selectedPartnerCode);
        if (exists && exists.length > 0) {
            onSubmitAttachment(exists[0], list);
        } else {
            setVisiblePartnerErrorMessage(true);
        }
    };
    if (attachmentFail) {
        return (
            <form id="attachment-list__form" onSubmit={handleAttachInterlocutor} className="attachment-list__form">
                <Trans i18nKey="users:attachment.fail" parent="div" className="attachment-list__description" />
                <Interlocutor setInterlocutorDetails={setNewInterlocutor} enableSubmit={setEnableButton} displaySiretPartner userEmail={userEmail} />
                <ModalAlert
                    className="attachment__alert"
                    enableSubmit
                    visible={visibleModalErrorMessage}
                    onClickButton={(e) => handleAccept(e)}
                    title={t("users:attachment.modalTitle")}
                    acceptButtonText={t("users:attachment.ok")}>
                    {infoMessage !== "attachmentSuccess" && (
                        <Trans i18nKey={`users:attachment.${infoMessage}`} parent="div" className="attachment-list__description" />
                    )}
                    {infoMessage === "attachmentSuccess" && (
                        <>
                            <div className="attachment-list__description">{t("users:attachment.attachmentSuccess")}</div>
                            <div className="attachment-form__item">
                                <div className="attachment-form__label">{t("users:attachment.partner")}</div>
                                <FormElements.Input name="tiers" value={codeTiers} disabled />
                            </div>

                            <div className="attachment-form__item">
                                <div className="attachment-form__label">{t("users:attachment.tiersName")}</div>
                                <FormElements.Input name="raison" value={tiersName} disabled />
                            </div>

                            <div className="attachment-form__item">
                                <div className="attachment-form__label">{t("users:attachment.siret")}</div>
                                <FormElements.Input name="siret" value={siret} disabled />
                            </div>
                        </>
                    )}
                </ModalAlert>
            </form>
        );
    }

    if (tiersDiligenceFail) {
        return (
            <form id="attachment-list__form" onSubmit={onTiersBlocked} className="attachment-list__form">
                <Trans i18nKey="users:diligence.fail" parent="div" className="attachment-list__description" />
            </form>
        );
    }
    if (tiersSanctionFail) {
        return (
            <form id="attachment-list__form" onSubmit={onTiersBlocked} className="attachment-list__form">
                <Trans i18nKey="users:sanction.fail" parent="div" className="attachment-list__description" />
            </form>
        );
    }
    if (enableCodeAuth) {
        return (
            <form id="attachment-list__form" onSubmit={handleCodeBasedAuth} className="attachment-list__form">
                <CodeTiersFormAuth tiersList={list} enableSubmit={setEnableButton} selectedPartnerCode={setSelectedPartnerCode} />
                <ModalAlert
                    className="attachment__alert"
                    enableSubmit
                    visible={visiblePartnerErrorMessage}
                    onClickButton={onFailAttachment}
                    title={t("users:attachment.codeErrorModaleTitle")}
                    acceptButtonText={t("users:attachment.ok")}>
                    <Trans i18nKey="users:attachment.partnerCodeNotExist" parent="div" className="attachment-list__description" />
                </ModalAlert>
            </form>
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmitAttachment(selected);
    };

    if (!list) return <Loading />;

    return (
        <form id="attachment-list__form" onSubmit={handleSubmit} className="attachment-list__form">
            <div className="attachment-list__description">
                <p>{t("users:attachment.description", { persona })}</p>
                <p>{t("users:attachment.sub-description")}</p>
            </div>
            <div className="attachment-list__table">
                <ListTable columns={[...userActions, ...columns]} content={list} rowKey="tiersCode" selected={selected} onSelect={setSelected} />
            </div>
        </form>
    );
};

export default AttachmentList;
