import React from "react";
import Icon from "../../icon-svg/icon";
import "./quantity-picker.scss";

const QuantityPicker = (props) => {
    const { minQuantity, maxQuantity, changeQuantity, quantity, className, readOnly = false } = props;

    const handleChange = (value) => {
        if (minQuantity <= value && value <= maxQuantity) changeQuantity(value);
    };

    return (
        <div className={`quantity-picker ${className}`}>
            {readOnly && (
                <>
                    <button type="button" className="quantity-picker__button" disabled>
                        <Icon name="actions-substract" />
                    </button>
                    <input type="number" className="quantity-picker__input" name="quantity" value={quantity} disabled />
                    <button type="button" className="quantity-picker__button" disabled>
                        <Icon name="actions-add" />
                    </button>
                </>
            )}
            {!readOnly && (
                <>
                    <button type="button" className="quantity-picker__button" onClick={() => handleChange(parseInt(quantity, 10) - 1)}>
                        <Icon name="actions-substract" />
                    </button>
                    <input
                        type="number"
                        className="quantity-picker__input"
                        name="quantity"
                        value={quantity}
                        onChange={(e) => handleChange(parseInt(e.target.value, 10))}
                    />
                    <button type="button" className="quantity-picker__button" onClick={() => handleChange(parseInt(quantity, 10) + 1)}>
                        <Icon name="actions-add" />
                    </button>
                </>
            )}
        </div>
    );
};

export default QuantityPicker;
