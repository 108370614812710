import React from "react";
import Icon from "../../icon-svg/icon";
import Pill from "../../pill/pill";
import "./list-table.scss";

const ListTable = (props) => {
    //
    // columns are used to build table structure
    // columns = { name, label, type, dbRef, icon, link, onClick, showCondition }
    //
    // @name(mandatory): unique identifier, used for classNames (grid display) and keys
    // @type(mandatory): type of cell; can be: "text" | "link" (href) | "action" (onClick) | "pill" (similar to text, uses Carbon component)
    //
    // @label(optional): label used for the list header
    // @dbRef(optional): only required for cells of type = "text", references the key used in the Database (content object)
    // @icon(optional): only required for cells of type = "link" | "action"; icon name
    // @ariaLabel(optional): label used for the action tooltip and accessibility concerns
    // @tooltipPosY(optional): tooltip vertical position; can be "top" | "bottom"
    // @tooltipPosX(optional): tooltip horizontal position; can be "left" | "center" | "right"
    // @link(optional, function): only required for cells of type "link" => function that returns the url to be used in the <a href element
    // @onClick(optional, function): only required for cells of type "action" => onClick function
    // @showCondition(optional, function): only required for cells of type "link" => only display the cell if function returns true
    //
    const { columns, content, rowKey, selected, onSelect, actionsCount, listRowClassName } = props;
    const rowStyle = {
        "--actions-count": actionsCount
    };

    const columnHeader = (column) => (
        <div key={column.name} className={`list__header-column list__${column.name} list__${column.type}`}>
            {column.label}
        </div>
    );

    const actionContent = (action, row, index) => {
        const { type, ariaLabel, tooltipPosY, tooltipPosX, showCondition, name, onClick, link, icon, iconColor, ref, iconClass = "" } = action;

        const hasTooltip = ariaLabel && ariaLabel !== "" ? "has-tooltip" : null;
        const tooltipY = tooltipPosY ? `tooltip-${tooltipPosY}` : "tooltip-top";
        const tooltipX = tooltipPosX ? `tooltip-${tooltipPosX}` : "tooltip-center";
        const actionStyle = {};

        if (iconColor) {
            actionStyle["--color"] = iconColor;
        }

        if (showCondition(row)) {
            return (
                <div key={`${row[rowKey]}-${name}`}>
                    {type === "button" && (
                        <button
                            type="button"
                            className={(hasTooltip && `${hasTooltip} ${tooltipY} ${tooltipX}`) || null}
                            style={actionStyle}
                            aria-label={ariaLabel}
                            ref={
                                ref
                                    ? (el) => {
                                          ref.current[index] = el;
                                      }
                                    : null
                            }
                            onClick={() => onClick(row, index)}>
                            <Icon name={icon} className="icons" />
                        </button>
                    )}
                    {type === "link" && (
                        <a
                            href={link(row)}
                            className={(hasTooltip && `${hasTooltip} ${tooltipY} ${tooltipX}`) || null}
                            style={actionStyle}
                            aria-label={ariaLabel}>
                            <Icon name={icon} className={`icons ${iconClass}`} />
                        </a>
                    )}
                </div>
            );
        }
        return <></>;
    };
const textContent = (row, column) => {
    if (row[column.tooltip]) {
        return (
            <div aria-label={row[column.tooltip]} className="has-tooltip tooltip-left">
                {row[column.dbRef]}
{" "}
            </div>
        );
    }
    if (row[column.dbRef]) {
        return `${row[column.dbRef]}`;
    }
};


    const columnContent = (column, row, index) => {
        const { showCondition = () => true } = column;

        return (
            <div
                className={`list__row-column ${listRowClassName ? "list__row-column_coche-validation" : ""}  list__${column.name} list__${column.type} ${
                    column.className || ""
                }`}
                key={`${row[rowKey]}-${column.name}`}>
                {showCondition(row) && (
                    <>
                        {column.type === "text" && textContent(row,column)}
                        {column.type === "probability" && row[column.dbRef] ? `${row[column.dbRef]} %` : ""}
                        {column.type === "text-button" && (
                            <button type="button" tabIndex={0} data-value={row[column.dbRef]} onClick={() => column.onClick(row)}>
                                {row[column.dbRef].toString()}
                            </button>
                        )}
                        {column.type === "actions" && column.actions.map((action) => actionContent(action, row, index))}
                        {column.type === "pill" && <Pill pillColor={row[column.colorRef]} pillLabel={row[column.dbRef]} />}
                        {column.type === "radio" && (
                            <div className="carbon-form__radio">
                                <input type="radio" name={column.name} onChange={() => onSelect(row)} />
                                <div className="carbon-form__radio-icon" />
                            </div>
                        )}
                        {column.type === "checkbox" && (
                            <div className="carbon-form__checkbox">
                                <input type="checkbox" value={row[column.value]} disabled={row[column.disabled]} onChange={(e,rw) => column.onChange(e,row,rw)} defaultChecked= {row[column.defaultChecked]}/>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    };

    const rowContent = (row, index) => {
        const isSelected = selected && selected[rowKey] === row[rowKey];

        return (
            <div className={`list__row${listRowClassName ? "_enduser" : ""} ${isSelected ? "selected" : ""}`} style={rowStyle} key={row[rowKey]}>
                {columns && columns.length > 0 && columns.map((column) => columnContent(column, row, index))}
            </div>
        );
    };

    return (
        <div className="list">
            <div className={`${columns.length > 8 ? "list__header_big" : "list__header"}`} style={rowStyle}>
                {columns && columns.length > 0 && columns.map((column) => columnHeader(column))}
            </div>
            {content && content.length > 0 && content.map((row, index) => rowContent(row, index))}
        </div>
    );
};

export default ListTable;
