import React, { useRef, useState } from "react";
import Icon from "../../icon-svg/icon";
import useOnClickOutside from "../../../custom-hook/use-click-outside";
import useOnTabOutside from "../../../custom-hook/use-tab-outside";
import "./input.scss";

const Input = (props) => {
    const { className = "", type, name, value = "", onChange, disabled = false, validator = () => true, placeholder, icon, maxLength } = props;
    const inputRef = useRef();

    // const [focused, setFocused] = useState("unfocused");
    const [valid, setValid] = useState("is-valid");
    const [touched, setTouched] = useState(false);

    const handleExit = () => {
        if (touched) {
            if (validator(value)) setValid("is-valid");
            else setValid("is-invalid");
        }
    };

    const handleEnter = () => {
        setTouched(true);
    };

    useOnClickOutside(inputRef, handleExit);
    useOnTabOutside(inputRef, handleExit);

    return (
        <div className={`carbon-form__input ${className}`} ref={inputRef}>
            <input
                className={valid}
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                disabled={disabled}
                placeholder={placeholder}
                onFocus={handleEnter}
                autoComplete="chrome-off"
                maxLength={maxLength}
            />
            {valid === "is-invalid" && (
                <div className="carbon-form__input-icon">
                    <Icon name="message-error" />
                </div>
            )}
            {icon && (
                <div className="carbon-form__valid-input-icon">
                    <Icon name={icon} />
                </div>
            )}
        </div>
    );
};

export default Input;
