import React, { useEffect, useRef } from "react";
import "./list-header-mobile.scss";

const ListHeaderMobile = (props) => {
    const { tabs = [], activeTab } = props;
    const tabsList = useRef();
    const tabsListWrapper = useRef();

    useEffect(() => {
        const { clientWidth, scrollWidth } = tabsList.current;
        if (scrollWidth > clientWidth) {
            tabsListWrapper.current.setAttribute("data-scroll", "right");
        }
    }, []);

    const handleScroll = () => {
        const { scrollLeft, clientWidth, scrollWidth } = tabsList.current;
        if (scrollLeft <= 0) {
            tabsListWrapper.current.setAttribute("data-scroll", "right");
        } else if (scrollLeft + clientWidth >= scrollWidth) {
            tabsListWrapper.current.setAttribute("data-scroll", "left");
        } else {
            tabsListWrapper.current.setAttribute("data-scroll", "both");
        }
    };

    return (
        <div className="list-header-mobile" ref={tabsListWrapper}>
            <div className="list-tabs" ref={tabsList} onScroll={handleScroll}>
                {tabs.map((tab) => (
                    <a className={`list-tabs__tab ${tab.value === activeTab ? "is-active" : ""}`} key={tab.value} href={tab.link}>
                        {tab.name}
                    </a>
                ))}
            </div>
        </div>
    );
};

export default ListHeaderMobile;
