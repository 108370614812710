import React, { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { AuthContext, roles as rolesJson } from "../../../../context";
import { downloadInvoicePdf, getAllInvoices, exportInvoices } from "../../service/invoices.services";
import { Lists, Loading, PageWrapper, ModalAlert, Downloading } from "../../../../shared/components";
import NewClient from "../../../clients/pages/new-client/new-client";
import { getFilialList } from "../../../clients/services/client.services";
import SearchMenuProposal from "../../../proposals/components/search-menu-proposal/search-menu-proposal";
import PopUpMenuInvoice from "../../../proposals/components/run-billing-popup/invoice-menu-popup";
import "./list-invoices-page.scss";
import calendrierFacture from "./config/calendrierFacture.pdf";

const ListInvoicesPage = () => {
    const { isAuthenticated, user, permissions } = useContext(AuthContext);
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const {
        raisonSociale = "",
        clientCode = "",
        commercialEmail = "",
        partnerCode = "",
        partnerRaisonSociale = "",
        page = "0",
        status,
        etablissement,
        startDate,
        endDate,
        dateFilter
    } = queryString.parse(location.search);
    const currentPage = parseInt(page, 10);
    const pageSize = 50;
    const [disableExport, setDisableExport] = useState(false);
    const [totalInvoices, setTotalInvoices] = useState(0);
    const [invoices, setInvoices] = useState([]);
    const [showClientDetail, setShowClientDetails] = useState(false);
    const [selectedClient, setSelectedClient] = useState();
    const [showAlertPdf, setShowAlertPdf] = useState(false);
    const [searchOnClient, setSearchOnClient] = useState(true);
    const [isPartner, setIsPartner] = useState(false);
    const [messagePagination, setMessagePagination] = useState(false);
    const [establishmentList, setEstablishmentList] = useState([]);
    const [isDownloading, setIsDownloading] = useState(false);
    const buttonRef = useRef([]);
    const message = messagePagination ? <>{t("invoices:list-invoices-page.limit-page")}</> : "  ";
    const [searchValue, setSearchValue] = useState({
        clientCode,
        raisonSociale,
        commercialEmail,
        partnerCode,
        partnerRaisonSociale,
        etablissement,
        startDate,
        endDate,
        dateFilter
    });

    const [queryParam, setQueryParam] = useState({
        clientCode,
        raisonSociale,
        commercialEmail,
        partnerCode,
        partnerRaisonSociale,
        page: currentPage,
        limit: pageSize,
        status,
        etablissement,
        startDate,
        endDate,
        dateFilter
    });

    const statusList = [
        { canDelete: false, canPurchase: false, color: "#ffb500", editable: false, value: "a_activer", name: "A activer" },
        { canDelete: false, canPurchase: false, color: "#00B000", editable: false, value: "en_service", name: "En service" },
        { canDelete: false, canPurchase: false, color: "#E96400", editable: false, value: "suspendue", name: "Suspendue" },
        { canDelete: false, canPurchase: false, color: "#C7384F", editable: false, value: "resiliée", name: "Résiliée" }
    ];

    const {
        invoices: { actions }
    } = permissions;

    const [openSearchMenu, setOpenSearchMenu] = useState(false);
    const [openBillingPopup, setOpenBillingPopup] = useState(false);

    const cleanQueryParams = (params) => {
        return Object.fromEntries(Object.entries(params).filter(([, v]) => v && v.toString().length >= 0));
    };

    useEffect(() => {
        if (isAuthenticated && user) {
            setLoading(true);
            const { etablissement: selectedEtablissement } = queryParam;
            getAllInvoices({
                index: currentPage,
                showFilials: !(typeof selectedEtablissement === "string" && selectedEtablissement.length > 0),
                limit: pageSize,
                ...queryParam
            })
                .then((res) => {
                    const { totalCount, invoices: i } = res.data;
                    setTotalInvoices(totalCount);
                    setMessagePagination(currentPage > 198);
                    setInvoices(i);
                    setLoading(false);
                })
                .catch(() => setLoading(false));
        }
    }, [isAuthenticated, user, queryParam, history, currentPage]);

    useEffect(() => {
        const params = queryString.stringify(cleanQueryParams({ ...queryParam }));
        history.push(`/invoices/list?${params}`);
    }, [user, queryParam, history]);

    useEffect(() => {
        const partner = user.role === rolesJson.partner.role;
        setIsPartner(partner);
        setSearchOnClient(partner);
    }, [user]);

    useEffect(() => {
        if (user && user.tiersParent && user.role === rolesJson.partner.role) {
            getFilialList().then((res) => {
                const etablissementList = res.data;
                etablissementList.unshift({ name: `${user.tiersName} (${t("subscriptions:list-subscriptions-page.tiers-parent")})`, value: user.pivotId });
                setEstablishmentList(etablissementList);
            });
        }
    }, [user, t]);

    const mobileTabs = () => {
        return statusList.map(({ value, name }) => ({
            value,
            name,
            link: `/invoices/list?status=${value}`
        }));
    };

    const changePage = (pageNumber) => {
        const params = queryString.stringify(cleanQueryParams({ ...queryParam, page: pageNumber }));
        history.push(`/invoices/list?${params}`);
    };

    const downloadInvoice = (row, index) => {
        const { id, lienPDFFacture } = row;
        buttonRef.current[index].disabled = true;
        buttonRef.current[index].style.color = "gray";
        setIsDownloading(true);
        downloadInvoicePdf(id, lienPDFFacture)
            .then((res) => {
                setIsDownloading(false);
                const datasize = res.data;
                if (datasize.size > 0) {
                    const link = document.createElement("a");
                    link.download = `${id}.pdf`;
                    const blob = new Blob([res.data], { type: "application/pdf" });
                    link.href = window.URL.createObjectURL(blob);
                    document.body.append(link);
                    link.click();
                    link.remove();
                    buttonRef.current[index].disabled = false;
                    buttonRef.current[index].style.color = "var(--color-business-hover)";
                } else {
                    setShowAlertPdf(true);
                    buttonRef.current[index].disabled = false;
                    buttonRef.current[index].style.color = "var(--color-business-hover)";
                }
            })
            .catch(() => {
                setIsDownloading(false);
                buttonRef.current[index].disabled = false;
                buttonRef.current[index].style.color = "var(--color-business-hover)";
                setShowAlertPdf(true);
            });
    };

    const showClientPopup = (row) => {
        const { clientPivotId } = row;
        setSelectedClient(clientPivotId);
        setShowClientDetails(true);
    };

    const handleResetSearch = () => {
        history.push("/invoices/list");
        setOpenSearchMenu(false);
        setOpenBillingPopup(false);
        setQueryParam({
            ...queryParam,
            raisonSociale: "",
            clientCode: "",
            partnerCode: "",
            partnerRaisonSociale: "",
            page: 0,
            startDate: "",
            endDate: "",
            dateFilter: ""
        });
        setSearchValue({
            raisonSociale: "",
            clientCode: "",
            partnerCode: "",
            partnerRaisonSociale: "",
            startDate: "",
            endDate: "",
            dateFilter: ""
        });
    };

    const handleInvoiceSearchChange = (e) => {
        setSearchValue({
            ...searchValue,
            [e.target.name]: e.target.value
        });
    };

    const handleChangeDate = (e) => {
        setQueryParam({
            ...queryParam,
            dateFilter: e.target.value,
            page: 0,
            endDate: "",
            startDate: ""
        });
    };

    const dateFilters = [
        {
            key: "created",
            label: t("proposals:list-proposals-page.date-list"),
            options: [
                { value: "current", name: t("proposals:list-proposals-page.day") },
                { value: "month", name: t("proposals:list-proposals-page.month") }
            ],
            className: "date-filter",
            handler: handleChangeDate,
            value: dateFilter
        }
    ];

    const handleInvoiceSearch = () => {
        const params = queryString.stringify(cleanQueryParams({ ...queryParam, ...searchValue }));
        history.push(`/invoices/list?${params}`);
        setOpenSearchMenu(false);
        setQueryParam({
            ...queryParam,
            ...searchValue
        });
    };

    const handleSearchCheckboxChange = () => {
        const useClient = !searchOnClient;
        setSearchOnClient(useClient);

        if (useClient) {
            setSearchValue({
                ...searchValue,
                partnerRaisonSociale: "",
                partnerCode: ""
            });
        } else {
            setSearchValue({
                ...searchValue,
                raisonSociale: "",
                clientCode: ""
            });
        }
    };

    const userActions = [
        {
            name: "client",
            type: "button",
            icon: "ui-clients",
            ariaLabel: t("invoices:list-invoices-page.client-consult"),
            onClick: showClientPopup,
            showCondition: () => true
        },
        {
            name: "download",
            type: "button",
            ref: buttonRef,
            ariaLabel: t("invoices:list-invoices-page.download-consult"),
            icon: "ui-download",
            onClick: downloadInvoice,
            showCondition: () => true
        }
    ];

    const handleExport = () => {
        setIsDownloading(true);
        setDisableExport(true);
        const { etablissement: selectedEtablissement } = queryParam;
        exportInvoices({
            showFilials: !(typeof selectedEtablissement === "string" && selectedEtablissement.length > 0),
            etablissement: selectedEtablissement,
            ...queryParam,
            limit: totalInvoices,
            index: 0
        }).then((res) => {
            const { data } = res;
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
            const link = document.createElement("a");
            link.download = `factures-${format(new Date(), "dd/MM/yyyy")}.xlsx`;
            link.href = URL.createObjectURL(blob);
            document.body.append(link);
            link.click();
            link.remove();
            setDisableExport(false);
            setIsDownloading(false);
        });
    };

    const handleChangeFilial = (e) => {
        setQueryParam({
            ...queryParam,
            etablissement: e.target.value,
            page: 0
        });
        setSearchValue({
            ...searchValue,
            etablissement: e.target.value
        });
    };
    const downloadPdf = () => {
        const link = document.createElement("a");
        link.download = "Comprendre ma facture.pdf";
        link.href = calendrierFacture;
        document.body.append(link);
        link.click();
        link.remove();
    };
    const columns = [
        { name: "date", type: "text", dbRef: "date", label: t("invoices:list-invoices-page.date") },
        { name: "id", type: "text", dbRef: "id", label: t("invoices:list-invoices-page.id") },
        { name: "TypeFacture", type: "text", dbRef: "TypeFacture", label: t("invoices:list-invoices-page.type") },
        { name: "reference", type: "text", dbRef: "reference", label: t("invoices:list-invoices-page.reference") },
        { name: "clientCode", type: "text", dbRef: "clientCode", label: t("invoices:list-invoices-page.client-code") },
        { name: "clientName", type: "text", dbRef: "clientName", label: t("invoices:list-invoices-page.client-name") },
        { name: "partnerCode", type: "text", dbRef: "partnerCode", label: t("invoices:list-invoices-page.partner-code") },
        { name: "partnerName", type: "text", dbRef: "partnerName", label: t("invoices:list-invoices-page.partner-name") },
        { name: "htTotal", type: "text", dbRef: "htTotal", label: t("invoices:list-invoices-page.ht-total") },
        { name: "ttcTotal", type: "text", dbRef: "ttcTotal", label: t("invoices:list-invoices-page.ttc-total") },
        { name: "status", type: "pill", dbRef: "status", colorRef: "colorRef", label: t("invoices:list-invoices-page.status") },
        { name: "actions", type: "actions", actions: userActions }
    ];

    const filters =
        user && user.tiersParent && establishmentList.length > 1
            ? [
                  {
                      key: "establishment-filter",
                      label: t("subscriptions:list-subscriptions-page.filial-list"),
                      options: establishmentList,
                      className: "establishment-filter",
                      handler: handleChangeFilial,
                      value: etablissement
                  }
              ].filter(({ key }) => actions.includes(key))
            : [];

    if (loading) {
        return (
            <PageWrapper className="list-invoices-page" fullPage>
                <Loading />
            </PageWrapper>
        );
    }

    return (
        <PageWrapper className="list-invoices-page" fullPage>
            <Lists.HeaderFullMobile title={t("invoices:list-invoices-page.title")} />
            <Lists.ListHeaderMobile tabs={mobileTabs()} activeTab={status} />
            {isDownloading && <Downloading> </Downloading>}
            <Lists.ListHeader
                title={`${t("invoices:list-invoices-page.title")} (${totalInvoices.toLocaleString()})`}
                description={t("invoices:list-invoices-page.description")}
                quickFilters={[...filters, ...dateFilters]}
                calendarInvoice={[
                    {
                        key: "download",
                        label: t("proposals:list-proposals-page.invoice"),
                        className: "medium secondary",
                        action: downloadPdf
                    }
                ]}
                button={[
                    {
                        key: "invoice",
                        label: t("proposals:list-proposals-page.popup"),
                        className: "medium secondary",
                        action: () => setOpenBillingPopup(!openBillingPopup),
                        render: (
                            <PopUpMenuInvoice
                                openPopUpMenu={openBillingPopup}
                                setOpenPopUpMenu={setOpenBillingPopup}
                                onClose={() => {
                                    setOpenBillingPopup(false);
                                }}
                            />
                        )
                    }
                ]}
                popoverActions={[
                    {
                        key: "search",
                        label: t("proposals:list-proposals-page.search"),
                        className: "medium secondary",
                        icon: "ui-search",
                        action: () => setOpenSearchMenu(!openSearchMenu),
                        render: (
                            <SearchMenuProposal
                                titleSearchMenu={t("clients:list-clients-page.search")}
                                openSearchMenu={openSearchMenu}
                                setOpenSearchMenu={setOpenSearchMenu}
                                handleSearchChange={handleInvoiceSearchChange}
                                handleSearch={handleInvoiceSearch}
                                searchValue={searchValue}
                                searchOnClient={searchOnClient}
                                handleSearchCheckboxChange={handleSearchCheckboxChange}
                                handleResetSearch={handleResetSearch}
                                isPartner={isPartner}
                                displayCommercialEmail={false}
                                translationSource="invoices:list-invoices-page"
                                displayRangeDate
                            />
                        )
                    }
                ]}
                buttonActions={[
                    {
                        key: "export",
                        label: t("clients:list-clients-page.export"),
                        className: "medium secondary",
                        action: handleExport,
                        disabled: disableExport
                    }
                ].filter(({ key }) => actions.includes(key))}
            />
            <Lists.ListTable columns={[...columns]} content={invoices} rowKey="id" actionsCount="2" />
            <Lists.ListPagination
                numberElem={totalInvoices >= 10000 ? 10000 : totalInvoices}
                page={currentPage}
                pageSize={pageSize}
                changePage={changePage}
                message={message}
            />

            {showClientDetail && <NewClient updateModal={setShowClientDetails} clientPivotId={selectedClient} withTabs={false} />}
            <ModalAlert
                className="new-proposal__alert"
                visible={showAlertPdf}
                onCancel={() => {
                    setShowAlertPdf(false);
                }}
                title={t("invoices:list-invoices-page.title-alert")}
                icon="ui-cart-buy"
                cancelButtonText={t("invoices:list-invoices-page.cancel")}>
                <p>{t("invoices:list-invoices-page.pdf-message")}</p>
                <p>{t("invoices:list-invoices-page.pdf-message-details")}</p>
            </ModalAlert>
        </PageWrapper>
    );
};

export default ListInvoicesPage;
