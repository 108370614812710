import React from "react";
import { useTranslation } from "react-i18next";
import "./checkboxSubstitution.scss";

const CheckboxSubstitution = (props) => {
    const { userSusbtitutions, handleSelectParc, offer, handleSelectAucun, aucunCheckedValue } = props;
    const filtredSubs = userSusbtitutions.filter((e) => e.offerProduct === offer);
    const { t } = useTranslation();
    const filteredParclist = filtredSubs.map((p) => {
        const { parcPivotId, label, checkedValue } = p;
        const nameattrib = `code-checkbox-${parcPivotId}`;
        const nameattribspan = `span-code-checkbox-${parcPivotId}`;
        return (
            <div className="substitution__item" key={`dv${parcPivotId}`}>
                <label className="substitution__checkbox" key={nameattrib}>
                    <input
                        key={parcPivotId}
                        type="checkbox"
                        id={parcPivotId}
                        checked={checkedValue||false}
                        onChange={() => {
                            handleSelectParc(parcPivotId);
                        }}
                    />
                    <span key={nameattribspan}>{label}</span>
                </label>
            </div>
        );
    });
    return (
        <div key={`dvPanel${offer}`}>
            {filteredParclist}
            <div className="substitution__item" key={`dv${offer}`}>
                <label className="substitution__checkbox" key={offer}>
                    <input
                        key={offer}
                        type="checkbox"
                        id={offer}
                        checked={aucunCheckedValue}
                        onChange={() => {
                            handleSelectAucun(offer);
                        }}
                    />
                    <span key={`sp${offer}`}>{t("proposals:select-subtitution.aucune")}</span>
                </label>
            </div>
        </div>
    );
};
export default CheckboxSubstitution;
