import React from "react";
import { useTranslation } from "react-i18next";
import { FormElements } from "../../../../shared";
import "./contact.scss";

const Contact = ({ contactMeta, contactFields, fieldsRules, handleChange, i18nPrefix, showRequired = true, disabled }) => {
    const { t } = useTranslation();
    return (
        <>
            {contactFields.map((key) => (
                <div key={`contact_${key}`} className="contact-form__item">
                    <div className="contact-form__label">
                        {fieldsRules[key].label ? `${fieldsRules[key].label} ${disabled ? "" : "*"}` : `${t(`${i18nPrefix}.${key}`)} ${disabled ? "" : "*"}`}
                    </div>
                    {fieldsRules[key].type === "text" && (
                        <>
                            <FormElements.Input
                                className="contact-form__input"
                                type={fieldsRules[key].type}
                                name={key}
                                value={contactMeta[key]}
                                placeholder={!disabled && fieldsRules[key].placeholder ? fieldsRules[key].placeholder : ""}
                                onChange={handleChange}
                                validator={fieldsRules[key].validator}
                                disabled={!disabled ? fieldsRules[key].disabled : disabled}
                            />
                            {fieldsRules[key].errorMessage && fieldsRules[key].displayError && (
                                <div className="new-client__error">{`${t("clients:client-information.name-error")}`}</div>
                            )}
                        </>
                    )}
                    {fieldsRules[key].type === "select" && (
                        <FormElements.Select
                            name={key}
                            onChange={handleChange}
                            options={fieldsRules[key].options}
                            label={!disabled && fieldsRules[key].placeholder ? fieldsRules[key].placeholder : ""}
                            value={contactMeta[key]}
                            disabled={disabled}
                        />
                    )}
                </div>
            ))}
            {showRequired && <div className="contact-form__mandatory">{t("forms.mandatory")}</div>}
        </>
    );
};

export default Contact;
