import React from "react";
import { Lists} from "../../../components";
import { useTranslation } from "react-i18next";


const RemiseForm = ({ remiseList}) => {
    const { t } = useTranslation();
    const columns = [
        { name: "name", type: "text", dbRef: "name", label: t("users:user-menu.libelle") },
        { name: "description", type: "text", dbRef: "description", label: t("users:user-menu.description") }
    ];
    return (
        <Lists.ListTable columns={[...columns]} content={remiseList} />
    );
};

export default RemiseForm;
