import i18next from "../../config/i18next";
import i18nClients from "./i18n/fr.json";
import ListClientsPage from "./pages/list-clients-page/list-clients-page";
import NewClient from "./pages/new-client/new-client";
import Impersonate from "./components/impersonate/impersonate";

i18next.addResourceBundle("fr", "clients", i18nClients);

const Clients = { ListClientsPage, NewClient, Impersonate };

export default Clients;
