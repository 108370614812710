import axios from "axios";
import setupInterceptor from "./setup-interceptor";

const { REACT_APP_API_BASE_URL, REACT_APP_API_PROXY_PIVOT } = process.env;

const portalAPI = axios.create({
    baseURL: REACT_APP_API_BASE_URL,
    validateStatus: (status) => status < 400
});

const proxyPivotAPI = axios.create({
    baseURL: REACT_APP_API_PROXY_PIVOT,
    validateStatus: (status) => status < 400
});

setupInterceptor(portalAPI);
setupInterceptor(proxyPivotAPI);

export { portalAPI, proxyPivotAPI };
