import React from "react";
import { Trans } from "react-i18next";
import { Icon } from "../../../../shared/components";

const DashboardError = () => {
    return (
        <div className="dashboard-error">
            <Icon name="message-warning" />
            <Trans i18nKey="users:dashboard.error" parent="div" />
        </div>
    );
};

export default DashboardError;
