import { proxyPivotAPI, portalAPI } from "../../../config";

const { REACT_APP_FAILOVER } = process.env;

const getAllSubscription = async (query) => {
    return proxyPivotAPI.get("/subscription", { params: { ...query }, headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const getSubscription = (reference) => {
    return proxyPivotAPI.get(`/subscription/${reference}`, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const exportSubscriptions = async (query) => {
    return proxyPivotAPI.get("/subscription/export", { headers: { "X-BACKEND": REACT_APP_FAILOVER }, responseType: "blob", params: { ...query } });
};

const terminateSubscription = async (subscription, lastDate, lastDateFormatted, firstDateResil, resiliationInf) => {
    return proxyPivotAPI.post("/subscription/terminate", { subscription, lastDate, lastDateFormatted, firstDateResil, resiliationInf });
};

const getReasonsTerminate = (query) => {
    return proxyPivotAPI.get("/lov/motifAbandon", { params: { ...query }, headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const getMotifTerminate = (motif) => {
    return proxyPivotAPI.get(`/lov/motifAbandonByCode/${motif}`, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const getSubscriptionUpdated = (reference, usageCode) => {
    return portalAPI.get(`/subscription/${reference}`, { params: { usageCode }, headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const checkOptionalsConfig = (query) => {
    return portalAPI.get("/product/optionals", { params: { ...query }, headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

export {
    getAllSubscription,
    exportSubscriptions,
    getSubscription,
    terminateSubscription,
    getReasonsTerminate,
    getMotifTerminate,
    getSubscriptionUpdated,
    checkOptionalsConfig
};
