import i18n from "../../../config/i18next";

const handleRestrictions = (error, setShowToast, setToastType, setShowToastMessage, user, setToastDiligencelink, setSecondaryMessage) => {
    if (error.response && error.response.data) {
        setShowToast(true);
        setToastType("error");
        if (error.response.data.errorCode === "restricted_action") {
            if (user.roleLabel === "blocked_tiers") {
                setShowToastMessage(i18n.t("proposals:new-proposal.tiers_blocked"));
            }
            if (user.roleLabel === "tiers_diligence") {
                setToastDiligencelink(true);
                setShowToastMessage(i18n.t("clients:tiers.dueDiligence"));
            } else {
                setShowToastMessage(i18n.t("proposals:new-proposal.tiers_sanction"));
            }
        } else if (error.response.data.errorCode === "unauthorized_tiersBlocked") {
            setShowToastMessage(i18n.t("clients:tiers.tiersBlocked"));
        } else if (error.response.data.errorCode === "unauthorized_dueDiligence") {
            setToastDiligencelink(true);
            setShowToastMessage(i18n.t("clients:tiers.dueDiligence"));
        } else if (error.response.data.errorCode === "unauthorized_sanctionPolicy_denied") {
            setShowToastMessage(i18n.t("clients:tiers.sanctionPolicy-denied"));
        } else if (error.response.data.errorCode === "unauthorized_sanctionPolicy_limited") {
            setShowToastMessage(i18n.t("clients:tiers.sanctionPolicy-limited"));
        } else if (error.response.data.errorCode === "noOptionalModulesFound" || error.response.data.errorCode === "notConsommationOffer") {
            setShowToastMessage(i18n.t("subscriptions:list-subscriptions-page.no-options-message"));
        } else if (error.response.data.errorCode === "unauthorized_quantity_change") {
            setShowToastMessage(i18n.t("proposals:new-proposal.unauthorized_quantity_change"));
        } else if (error.response.data.errorCode === "unauthorized_no_modification") {
            setShowToastMessage(i18n.t("proposals:new-proposal.unauthorized_no_modification"));
        } else if (error.response.data.errorCode === "unauthorized_vda_pei") {
            setShowToastMessage(i18n.t("proposals:modal-alert.blocage-vda-pei"));
            setSecondaryMessage(i18n.t("proposals:modal-alert.blocage-vda-pei2"));
        } else {
            setShowToastMessage(i18n.t("clients:tiers.tiersIssue"));
        }
    }
};

const handleProposalToast = (location, history, setShowToastMessage, setToastType, setShowToast, setToastDiligencelink) => {
    if (location.state) {
        if (location.state.action === "create") {
            setShowToastMessage(i18n.t("proposals:list-proposals-page.save-proposal.success", { reference: location.state.reference }));
            setToastType("success");
            setShowToast(true);
        }

        if (location.state.action === "edit") {
            setShowToastMessage(i18n.t("proposals:list-proposals-page.edit-proposal.success", { reference: location.state.reference }));
            setToastType("success");
            setShowToast(true);
        }

        if (location.state.action === "order") {
            if (location.state.financialControlReason && location.state.financialControlReason !== "") {
                setShowToastMessage(i18n.t(`proposals:list-proposals-page.financial-control-${location.state.financialControlReason}`));
                setToastType("success");
                setShowToast(true);
            } else {
                setShowToastMessage(i18n.t("proposals:list-proposals-page.order-proposal.success", { reference: location.state.reference }));
                setToastType("success");
                setShowToast(true);
            }
        }

        if (location.state.status === "blockedTiers") {
            setToastType("error");
            setShowToastMessage(i18n.t("clients:tiers.tiersBlocked-limited"));
            setShowToast(true);
        }

        if (location.state.status === "diligenceBlocked") {
            setToastType("error");
            setToastDiligencelink(true);
            setShowToastMessage(i18n.t("clients:tiers.dueDiligence"));
            setShowToast(true);
        }

        if (location.state.status === "sanctionPolicy") {
            setToastType("error");
            setShowToastMessage(i18n.t("clients:tiers.sanctionPolicy-limited"));
            setShowToast(true);
        }

        history.replace({ state: undefined });
    }
};

export { handleRestrictions, handleProposalToast };
