import React, { useState } from "react";
import "./impersonate.scss";
import { useTranslation } from "react-i18next";
import ModalAlert from "../../../../shared/components/modal-alert/modal-alert";
import Input from "../../../../shared/components/form-elements/input/input";
import { emailRegex } from "../../../proposals/utils/regex";

const Impersonate = ({ impersonate, handleCancelImpersonate, visibleImpersonate }) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");

    const handleAccept = () => {
        impersonate(email);
    };

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    return (
        <ModalAlert
            className="new-client__contact"
            enableSubmit={emailRegex.test(email) && !email.endsWith("@sage.com")}
            visible={visibleImpersonate}
            onClickButton={() => handleAccept()}
            onCancel={() => {
                handleCancelImpersonate();
            }}
            title={t("clients:impersonate.title")}
            cancelButtonText={t("navigation.cancel")}
            acceptButtonText={t("navigation.choose")}>
            <div className="new-client-form__item">
                <div className="new-client-form__label">{t("clients:impersonate.email")}</div>
                <Input name="email" value={email} onChange={handleChange} placeholder={t("clients:impersonate.placeholder")} />
                <div className="contact-form__mandatory">{t("clients:impersonate.mandatory")}</div>
            </div>
        </ModalAlert>
    );
};

export default Impersonate;
