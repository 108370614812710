import { proxyPivotAPI } from "../../../config";

const { REACT_APP_FAILOVER } = process.env;

const getClientsDashboard = (query) => {
    return proxyPivotAPI.get("/elk/tiers/dashboard", { params: { ...query }, headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const getAllClients = (query) => {
    return proxyPivotAPI.get("/tiers", { params: { ...query }, headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const getClient = (pivotId) => {
    return proxyPivotAPI.get(`/tiers/${pivotId}`, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};
const getClientSimple = (pivotId) => {
    return proxyPivotAPI.get(`/tiers/simple/${pivotId}`, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};
const getClientTaxe = (code) => {
    return proxyPivotAPI.get(`/tiers/taxe/${code}`, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const getClientDetails = (pivotId) => {
    return proxyPivotAPI.get(`/tiers/address/${pivotId}`, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const checkEmailIsPartner = (email) => {
    return proxyPivotAPI.get(`/tiers/interlocutor/${email}`, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const getTiersBySiret = (siret) => {
    return proxyPivotAPI.get(`/tiers/siret/${siret}`, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const getTiersType = () => {
    return proxyPivotAPI.get("/lov/tiers/type", { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const addTiers = (tiers) => {
    return proxyPivotAPI.post("/tiers", tiers);
};

const getCountries = () => {
    return proxyPivotAPI.get("/lov/tiers/countries", { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const checkTiers = (pivotId) => {
    return proxyPivotAPI.get(`/tiers/validity/${pivotId}`, { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

const exportTiers = (query) => {
    return proxyPivotAPI.get("/tiers/export", { params: { ...query }, headers: { "X-BACKEND": REACT_APP_FAILOVER }, responseType: "blob" });
};
const exportTiersAsMail = (query) => {
    return proxyPivotAPI.post("/tiers/exportAsMail", { params: { ...query } });
};
const attachInterlocutor = (interlocutor) => {
    return proxyPivotAPI.post("/tiers/partner/", interlocutor);
};

const getFilialList = () => {
    return proxyPivotAPI.get("/tiers/filial", { headers: { "X-BACKEND": REACT_APP_FAILOVER } });
};

export {
    getAllClients,
    getClient,
    getClientDetails,
    getTiersBySiret,
    getTiersType,
    addTiers,
    checkEmailIsPartner,
    getCountries,
    checkTiers,
    exportTiers,
    exportTiersAsMail,
    attachInterlocutor,
    getFilialList,
    getClientTaxe,
    getClientSimple,
    getClientsDashboard
};
